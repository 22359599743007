import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { selectAllChildrenCampaigns } from "../../../redux/campaigns/childrenCampaignsAdapter";
import { CampaignRow } from ".";
import { getTrafficType, getParentTrafficValue } from "../../../utils";

const bgColorMap = {
	on: "rgb(255, 255, 255)",
	off: "rgb(232, 232, 232)",
	test: "rgb(245, 235, 225)",
	wip: "rgb(230, 202, 217)",
};

const CampaignBlock = ({ campaign, scheduledUTCValue, scheduledTime }) => {
	const includedCampaigns = useSelector(selectAllChildrenCampaigns);
	const childrenData = campaign?.relationships?.children?.data || [];
	const childrenIds = childrenData.map((campaign) => campaign.id);
	const isParentArchived = !campaign?.attributes?.active;
	const childrenCampaigns = includedCampaigns.filter((campaign) =>
		childrenIds.includes(campaign.id)
	);

	const parentTrafficType = getTrafficType(campaign);
	const parentTrafficValue = getParentTrafficValue(childrenCampaigns, parentTrafficType);

	return (
		<CampaignBlockContainer data-qa="campaign-parent">
			<CampaignRow
				campaign={campaign}
				isParent={true}
				bgColor={bgColorMap[parentTrafficType]}
				parentTrafficValue={parentTrafficValue}
				parentTrafficType={parentTrafficType}
				scheduledUTCValue={scheduledUTCValue}
				scheduledTime={scheduledTime}
			/>
			{childrenCampaigns.map((campaign) =>
				<CampaignRow
					key={campaign.id}
					campaign={campaign}
					bgColor={bgColorMap[parentTrafficType]}
					parentTrafficType={parentTrafficType}
					isParentArchived={isParentArchived}
					scheduledUTCValue={scheduledUTCValue}
					scheduledTime={scheduledTime}
				/>
			)}
		</CampaignBlockContainer>
	)
};

CampaignBlock.propTypes = {
	campaign: PropTypes.object.isRequired,
	scheduledUTCValue: PropTypes.string,
	scheduledTime: PropTypes.bool,
};

const CampaignBlockContainer = styled.div`
	width: 100%;
	margin-bottom: 22px;
`;

export default CampaignBlock;
