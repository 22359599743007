import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Section, Dropdown } from "../../../../../../components/form";
import {
	DividerWithTextHeader,
	DividerWithText,
} from "../../../../../../pages/stats/styledComponents";
import { progression, eligibilityTriggersOptions } from "../../../../eligibility";
import { useSelectInboxCampaigns } from "../../../../../../hooks/selectors/useSelectInboxCampaigns";

const EligibilityTriggers = () => {
	const {
		params: { campaignId },
	} = useSelector((state) => state.page);
	const campaign = useSelector(useSelectInboxCampaigns)?.[campaignId];
	const eligibilityTriggersData = JSON.parse(
		campaign?.attributes?.["eligibility-triggers"] ?? null
	);

	const inheritedTriggers = eligibilityTriggersData?.map((e) => {
		return {
			name:
				eligibilityTriggersOptions?.find((option) => option.value === e.EventName)?.name ??
				"",
			value: e.EventName,
		};
	});

	const [values, setValues] = useState(inheritedTriggers);

	if (!eligibilityTriggersData) {
		return null;
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setValues({
			...values,
			[name]: value,
		});
	};

	const addEligibilityTrigger = () => inheritedTriggers.push({ name: "", value: "" });

	return (
		<Section title="Eligibility Triggers">
			{values ? (
				values.map((trigger) => {
					<>
						<Dropdown
							label="Trigger"
							field="trigger"
							onChange={handleInputChange}
							value={trigger.value}
							options={eligibilityTriggersOptions}
						/>
						{trigger.value === "progression" && (
							<>
								<Dropdown
									label="Progression"
									field="Progression"
									onChange={handleInputChange}
									value={trigger.value}
									options={progression}
								/>
								{/* List of inboxCampaignsForAutocompleteNotImmediateFamily */}
								<Dropdown
									label=""
									field=""
									onChange={handleInputChange}
									value={trigger.value}
									options={progression}
								/>
							</>
						)}
						<i className="icon-add-thin" onClick={() => addEligibilityTrigger()} />
						<DividerWithTextHeader>
							<DividerWithText>OR</DividerWithText>
						</DividerWithTextHeader>
					</>;
				})
			) : (
				<div />
			)}
		</Section>
	);
};

export default EligibilityTriggers;
