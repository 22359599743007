import React from "react";
import PropTypes from "prop-types";
import styled, { createGlobalStyle } from "styled-components";

import Button from "./buttons/Button";

const ErrorFallback = ({ error, resetErrorBoundary }) => (
	<Alert role="alert">
		<GlobalStyle />
			<AlertWindow>
			<h3>An error occured:</h3>
			<pre style={{color: 'red'}}>{error.message}</pre>
			<Button onClick={resetErrorBoundary} theme="blue">Try again</Button>
		</AlertWindow>
	</Alert>
);

ErrorFallback.propTypes = {
	error: PropTypes.shape({
		message: PropTypes.string,
	}),
	resetErrorBoundary: PropTypes.func
};


const GlobalStyle = createGlobalStyle`
	.ember-view {
		display: none;
	}
`;

const Alert = styled.div`
	height: 100vh;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;

	h3 {
		margin: 0 0 1rem 0;
	}

	pre {
		margin: 0 0 2rem 0;
	}
`;

const AlertWindow = styled.div`
	padding: 4rem;
	background: #fff;
	border: 1px solid rgb(48, 61, 120);
	border-radius: 5px;
	display: flex;
    flex-direction: column;
    align-items: center;
`;

export default ErrorFallback;
