import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { TBody } from ".";

const Body = ({
	data = [],
	createCells = () => {},
	cellPadding,
	background,
	scrollAfterAppend = false,
}) => {
	const [prevDataLength, setPrevDataLength] = useState(0);
	const refForScroll = useRef(null);

	useEffect(() => {
		if (!scrollAfterAppend) {
			return;
		}

		if (prevDataLength && data.length > prevDataLength) {
			refForScroll.current.scrollIntoView({ behavior: "smooth" });
			setPrevDataLength(data.length);
		} else {
			setPrevDataLength(data.length);
		}
	}, [data]);

	const rows = data.map((obj, index, array) => {
		if (index === array.length - 1 && scrollAfterAppend) {
			return (
				<tr key={index} ref={refForScroll}>
					{createCells(obj)}
				</tr>
			);
		}

		return <tr key={index}>{createCells(obj)}</tr>
	});
	return (
		<TBody background={background} cellPadding={cellPadding}>
			{rows}
		</TBody>
	);
};
Body.propTypes = {
	data: PropTypes.array.isRequired,
	createCells: PropTypes.func.isRequired,
	cellPadding: PropTypes.string,
	background: PropTypes.string,
	scrollAfterAppend: PropTypes.bool,
};
export default Body;
