import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "urql";
import PropTypes from "prop-types";
import { Loader, Wrapper } from "../../components";
import { reportTypes } from "../../constants/common";
import {
	CampaignHeader as Header,
	CampaignControls as Controls,
	Title,
	Graph,
	CampaignTable as Table,
} from "./components";

import {
	websiteQuery, parentCampaignQuery, childCampaignQuery,
} from "./queries";
import { setDataQl } from "../../redux/report/slice";
import { selectCampaignTableData, selectCampaignTableTitle } from "../../redux/report/selectors";

const CAMPAIGN_QUERIES_MAP = {
	[reportTypes.websiteCampaign]: websiteQuery,
	[reportTypes.parentCampaign]: parentCampaignQuery,
	[reportTypes.childCampaign]: childCampaignQuery,
};

const Campaign = (props) => {
	const {
		website_id = -1,
		parent_campaign_id = -1,
		campaign_id = -1,
		type = "",
		reportOptions = {},
		onChangeReportOptions = () => {},
	} = props;
	const dispatch = useDispatch();
	const data = useSelector(selectCampaignTableData);
	const title = useSelector(selectCampaignTableTitle);
	const { date_start = "", date_end = "", date_interval } = reportOptions;
	const qlParamsForParent = type === reportTypes.parentCampaign ? { parentId: parent_campaign_id } : undefined;
	const qlParamsForChild = type === reportTypes.childCampaign ? { childId: campaign_id } : undefined;

	const [result] = useQuery({
		query: CAMPAIGN_QUERIES_MAP[type] || websiteQuery,
		pause: !date_start || !date_end, // wait on report options
		variables: {
			websiteId: website_id,
			start: date_start,
			end: date_end,
			statsGranularity: date_interval === "hour" ? "HOUR" : "DAY",
			...qlParamsForParent,
			...qlParamsForChild,
		},
	});

	const { data: QueryData, fetching } = result;

	useEffect(() => {
		if (QueryData) {
			dispatch(setDataQl(QueryData));
		}
	}, [QueryData]);

	useEffect(() => { dispatch(setDataQl(null)) }, []); // clear data on first render

	return (
		<Wrapper>
			<Loader isLoading={fetching} />
			<Header
				website_id={website_id}
				parent_campaign_id={parent_campaign_id}
				campaign_id={campaign_id}
				type={type}
			/>
			<Controls
				reportOptions={reportOptions}
				onChangeReportOptions={onChangeReportOptions}
			/>
			<Title title={title} />
			<Graph data={data} show={showGraph(data)} />
			<Table
				data={data}
				type={type}
				website_id={website_id}
				parent_campaign_id={parent_campaign_id}
				campaign_id={campaign_id}
				date_start={date_start}
				date_end={date_end}
				date_interval={date_interval}
			/>
		</Wrapper>
	);
};
const showGraph = (data) => {
	//INFO: add any future custom logic here on when to show the graph.
	return data.length > 1;
};

Campaign.propTypes = {
	website_id: PropTypes.number.isRequired,
	parent_campaign_id: PropTypes.number,
	campaign_id: PropTypes.number,
	type: PropTypes.string.isRequired,
	reportOptions: PropTypes.object.isRequired,
	onChangeReportOptions: PropTypes.func.isRequired,
};
export default Campaign;
