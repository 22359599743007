const formatPercentage = (num = 0, dom = 0) => {
	if (dom === 0) {
		return {
			top: Number(num).toLocaleString(...numberFormat),
			bottom: "0.00%",
		};
	}
	const percentage = getPercentage(num, dom);
	return {
		top: Number(num).toLocaleString(...numberFormat),
		bottom: `${percentage}%`,
	};
};
const getPercentage = (num = 0, dom = 0) => {
	if (dom === 0) {
		return 0;
	}
	const stringNum = Number.parseFloat((num / dom) * 100);

	return Number(stringNum).toLocaleString(...numberFormat);
};
const numberFormat = [
	"en",
	{ minimumFractionDigits: 2, maximumFractionDigits: 2 },
];
const toFixed = (num = 0) => {
	return Number(Number(num).toFixed(2));
};
const formatOutput = (num = 0, dom = 0) => {
	return {
		top: num,
		bottom: `${dom}%`,
	};
};
const formatDateTime = (dateString) => {
	const rawDate = new Date(dateString);
	const year = rawDate.getUTCFullYear();
	const month = rawDate.getUTCMonth() + 1;
	const date = rawDate.getUTCDate();
	const hour = rawDate.getUTCHours();
	const minute = rawDate.getUTCMinutes();
	const seconds = rawDate.getUTCSeconds();
	return `${year}-${month}-${date} ${hour}:${minute}:${seconds}`;
};

export const appendQueryParams = (baseUrl, queryParamsObj) => {
	const paramsArray = [];

	for (let queryKey in queryParamsObj) {
		const value = queryParamsObj[queryKey];
		paramsArray.push(`${queryKey}=${value}`);
	}

	const suffix = paramsArray.length
		? `?${paramsArray.join("&")}`
		: "";

	return `${baseUrl}${suffix}`;
};

export {
	formatPercentage,
	getPercentage,
	numberFormat,
	toFixed,
	formatOutput,
	formatDateTime,
};
