import { gql } from "urql";
import { COMMON_VALUES, CAMPAIGN_STATS } from "./fragments";

const childCampaignQuery = gql`
    ${CAMPAIGN_STATS}
    ${COMMON_VALUES}
    query($websiteId: Int!, $childId: Int!, $start: DateTime!, $end: DateTime!, $statsGranularity: StatsGranularity) {
        website (id: $websiteId, start: $start, end: $end) {
            id
            name
            onsiteProduct(statsGranularity: $statsGranularity) {
                variation(id: $childId) {
                    common {
                        ...commonValues
                    }
                    stats {
                        ...campaignStats
                    }
                    timeSeries {
                        date
                        stats {
                            ...campaignStats
                        }
                    }
                }
            }
        }
        overall: website(id: $websiteId, start: $start, end: $end) {
            id
            onsiteProduct(websiteAttribution: ALL_CONVERSION, statsGranularity: $statsGranularity) {
                variation(id: $childId) {
                    common {
                        ...commonValues
                    }
                    stats {
                        ConversionStats {
                            Conversions
                        }
                    }
                    timeSeries {
                        date
                        stats {
                            ConversionStats {
                                Conversions
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default childCampaignQuery;
