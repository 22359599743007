import React from "react";
import { useSelector } from "react-redux";
import { ReactLink } from "../../../../../components";
import {
	Traffic,
	Preview,
	Masthead,
	DoNotSend,
	SendLimits,
	RenderedItems,
	EligibilityJS,
	Classification,
	DataScienceRec,
	SendESPExtraFields,
	EligibilityTriggers,
	EligibilityCriteria,
	LegacyEligibilityJS,
	ClickThroughParameters,
	UnsubscribeESPExtraFields,
} from "./sections";

const EditContainer = () => {
	const {
		params: { campaignId },
	} = useSelector((state) => state.page);
	const campaignData = useSelector(
		(state) => state.inboxCampaigns?.response?.data?.entities?.[campaignId]
	);
	const selectedCampaign = useSelector((state) => state.inboxCampaigns?.selectedInboxCampaign);
	const isVariantPage = selectedCampaign?.attributes["test-type"] === "variation";
	if (!campaignData) {
		return null;
	}

	const masterCampaignId = campaignData.attributes?.masterCampaignId || "";

	return (
		<>
			{masterCampaignId && (
				<ReactLink href={`/experiences/inbox-react/${masterCampaignId}/edit}`}>
					This Campaign was duplicated from {masterCampaignId}
				</ReactLink>
			)}
			<Classification />
			{isVariantPage && (
				<>
					<Traffic />
					<DataScienceRec />
				</>
			)}
			<Masthead />
			<ClickThroughParameters />
			{isVariantPage && (
				<>
					<EligibilityJS />
					<Preview />
				</>
			)}
			{!isVariantPage && (
				<>
					<EligibilityTriggers />
					<EligibilityCriteria />
					<SendLimits />
					<DoNotSend />
					<RenderedItems />
					<LegacyEligibilityJS />
					<SendESPExtraFields />
					<UnsubscribeESPExtraFields />
				</>
			)}
		</>
	);
};

export default EditContainer;
