import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Pagination = ({ currentPage, totalPages, setPage }) => {
	return (
		<PaginationContainer>
			<UnclickableItem>Page:</UnclickableItem>
			{
				Array(totalPages).fill().map((page, pageIndex) => {
					const pageNumber = String(pageIndex + 1);
					return pageNumber === currentPage
						? <SelectedItem key={pageIndex}>{pageNumber}</SelectedItem>
						: <Item key={pageIndex} onClick={() => setPage(pageNumber)}>{pageNumber}</Item>;
				})
			}
			{
				currentPage === "all"
					? <SelectedItem>All</SelectedItem>
					: <Item onClick={() => setPage("all")}>All</Item>
			}
		</PaginationContainer>
	);
}

Pagination.propTypes = {
	currentPage: PropTypes.string.isRequired,
	setPage: PropTypes.func.isRequired,
	totalPages: PropTypes.number,
};

PropTypes.defaultProps = {
	totalPages: 1,
};

const PaginationContainer = styled.div`
	display: flex;
	align-items: center;
`;

const UnclickableItem = styled.div`
	font-weight: bold;
	width: 100%;
	color: #5a5959;
	margin: 0.75em 0.5em;
`;

const Item = styled(UnclickableItem)`
	color: rgba(0,0,0,0.3);
	cursor: pointer;
`;

const SelectedItem = styled(Item)`
	color: #5a5959;
`;

export default Pagination;
