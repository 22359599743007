import React from 'react';
import PropTypes from 'prop-types';
import { InputContainer, InputLabel, Input } from './styles'

const InputNumber = ({
    label,
    value,
    setValue,
    min,
    max,
    margin,
    width,
    flexBasis
}) => {
    return (
        <InputContainer margin={margin} width={width} flexBasis={flexBasis}>
            <InputLabel>{label}</InputLabel>
            <Input
                type="number"
                min={min}
                max={max}
                value={value}
                onChange={(e) => setValue(e)}
            />
        </InputContainer>
    )
}

InputNumber.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    setValue: PropTypes.func.isRequired,
    min: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    max: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    margin: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    flexBasis: PropTypes.string,
}

export default InputNumber;
