import { createSlice } from "@reduxjs/toolkit";
import { getUploadFiles, postUploadFiles, deleteFile } from "./thunks";

const initialState = {
	isLoading: false,
	response: {
		cdn_list: [],
		paging: {},
	},
};

const uploadFilesSlice = createSlice({
	name: "uploadFiles",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// getUploadFiles
		builder.addCase(getUploadFiles.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getUploadFiles.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response = action.payload;
		});
		builder.addCase(getUploadFiles.rejected, (state) => {
			state = initialState;
		});
		// postUploadFiles
		builder.addCase(postUploadFiles.pending, (state) => state);
		builder.addCase(postUploadFiles.fulfilled, (state) => state);
		builder.addCase(postUploadFiles.rejected, (state) => state);
		// deleteFile
		builder.addCase(deleteFile.pending, (state) => state);
		builder.addCase(deleteFile.fulfilled, (state) => state);
		builder.addCase(deleteFile.rejected, (state) => state);
	},
});

export default uploadFilesSlice.reducer;
