import { createSlice } from "@reduxjs/toolkit";
import { getWebsites, createNewWebsite, getWebsite } from "./thunks";
import websitesAdapter from "./websitesAdapter";
import { isEmpty } from "lodash";

const initialState = {
	isLoading: false,
	isFetchError: false,
	isCreateWebsiteError: false,
	response: {
		data: websitesAdapter.getInitialState(),
		meta: {},
	},
	selectedWebsite: null,
};

const websitesSlice = createSlice({
	name: "websites",
	initialState,
	reducers: {
		selectWebsite(state, action) {
			state.selectedWebsite = action.payload;
		},
		clearWebsitesApiError(state) {
			state.isFetchError = false;
			state.isCreateWebsiteError = false;
		},
	},
	extraReducers: (builder) => {
		// getWebsites
		builder.addCase(getWebsites.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getWebsites.fulfilled, (state, action) => {
			const json = action.payload.json;
			const firstAsSelected = action.payload.firstAsSelected;

			state.isLoading = false;
			state.response.meta = json.meta;
			websitesAdapter.setAll(state.response.data, json.data);

			if (firstAsSelected && json.data.length) {
				state.selectedWebsite = json.data[0];
			}
		});
		builder.addCase(getWebsites.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			websitesAdapter.removeAll(state.response.data);
		});
		//getWebsite
		builder.addCase(getWebsite.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getWebsite.fulfilled, (state, action) => {
			const {
				meta,
				payload: { data, included },
			} = action;

			state.isLoading = false;
			state.response.meta = meta;
			websitesAdapter.updateOne(state.response.data, {
				id: data.id,
				changes: {
					...data,
					included: included,
				},
			});
			if (!isEmpty(data)) {
				state.selectedWebsite = data;
			} else {
				state.selectedWebsite = null;
			}
		});
		builder.addCase(getWebsite.rejected, (state) => {
			state.isFetchError = true;
		});
		//createWebsite
		builder.addCase(createNewWebsite.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(createNewWebsite.fulfilled, (state, action) => {
			state.isLoading = false;

			if (action.payload.notSaveInStore) return;

			websitesAdapter.addOne(state.response.data, action.payload.json);
		});
		builder.addCase(createNewWebsite.rejected, (state) => {
			state.isLoading = false;
			state.isCreateWebsiteError = true;
		});
	},
});

export const { selectWebsite, clearWebsitesApiError } = websitesSlice.actions;

export default websitesSlice.reducer;
