/**
 * ESP Profiles Thunks
 */

import { createAsyncThunk } from "@reduxjs/toolkit";
import { toEspApiPayload } from "../../pages/websites/edit/esp-profiles/utils";
import {
	createProfile,
	getProfile,
	getProfiles,
	updateProfile,
	getCampaignsByProfile,
	getEsps,
} from "../../service/espProfilesApi";

import { NAMESPACE } from "./constants";

export const getProfileThunk = createAsyncThunk(
	`${NAMESPACE}/getProfile`,
	async ({ websiteId, espProfileId }) => getProfile(websiteId, espProfileId),
);

export const getProfilesThunk = createAsyncThunk(
	`${NAMESPACE}/getProfiles`,
	async ({ websiteId }) => getProfiles(websiteId),
);

export const createProfileThunk = createAsyncThunk(
	`${NAMESPACE}/createProfile`,
	async ({ websiteId, profileDto }) => {
		const profileToCreate = toEspApiPayload(profileDto);
		return createProfile(websiteId, profileToCreate);
	}
);

export const cloneProfileThunk = createAsyncThunk(
	`${NAMESPACE}/cloneProfile`,
	async ({ websiteId, profileDto }) => {
		const profileToClone = toEspApiPayload(profileDto);
		return createProfile(websiteId, profileToClone);
	}
);

export const updateProfileThunk = createAsyncThunk(
	`${NAMESPACE}/updateProfile`,
	async ({ websiteId, espProfileId, profileDto }) => {
		const profileToUpdate = toEspApiPayload(profileDto);
		return updateProfile(websiteId, espProfileId, profileToUpdate);
	},
);

export const deleteProfileThunk = createAsyncThunk(
	`${NAMESPACE}/deleteProfile`,
	async ({ websiteId, espProfileId, profileDto }) => {
		// Setting to indicate this will be a soft delete. Core API will overwrite this.
		// If an ESP Profile is utilized by a campaign in "live" or "test" modes, the backend will reject the deletion
		const profileToDelete = toEspApiPayload(profileDto, {
			dtDeleted: new Date().toISOString(),
		});

		return updateProfile(websiteId, espProfileId, profileToDelete);
	},
);

export const getCampaignsByProfileThunk = createAsyncThunk(
	`${NAMESPACE}/getCampaignsByProfile`,
	async ({ websiteId, espProfileId }) => getCampaignsByProfile(websiteId, espProfileId),
);

export const getEspsThunk = createAsyncThunk(
	`${NAMESPACE}/getEsps`,
	async () => getEsps(),
);
