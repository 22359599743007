import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const RightCMSInfoBlock = () => {
	const { selectedCampaigns } = useSelector((state) => state.campaigns);

	const {
		response: { data: campaignTypes = [] },
	} = useSelector((state) => state.campaignTypes);
	const {
		response: { data: purposes = [] },
	} = useSelector((state) => state.purposes);

	const {
		response: { data: parentCampaignTypes = [] },
	} = useSelector((state) => state.parentCampaignTypes);

	const isCampaigns = !!selectedCampaigns.length;
	const isMultipleCampaigns = selectedCampaigns.length > 1;
	const {
		name,
		"dt-edited": dtEdited,
		"edited-by-name": editedByName,
	} = selectedCampaigns[0]?.attributes || {};

	const getPurpose = () => {
		const campaignTypeId = selectedCampaigns[0]?.relationships?.["campaign-type"]?.data?.id;
		const isControl = selectedCampaigns[0]?.attributes?.["test-type"] === "control";
		const campaignType = campaignTypes.find((purpose) => purpose.id === campaignTypeId);
		const campaignName = campaignType?.attributes?.name || "";

		if (campaignName === "Not Set") {
			const parentCampaignTypeId =
				selectedCampaigns[0]?.relationships["parent-campaign-type"]?.data?.id;
			const campaignPurposeId =
				selectedCampaigns[0]?.relationships["campaign-purpose"]?.data?.id;
			const purpose = purposes.find((purpose) => purpose.id === campaignPurposeId);
			const purposeName = purpose?.attributes?.name;
			const parentCampaignType = parentCampaignTypes.find(
				(purpose) => purpose.id === parentCampaignTypeId
			);
			const parentCampaignTypeName = parentCampaignType?.attributes?.name;

			return `${purposeName} (${parentCampaignTypeName})`;
		}

		return isControl ? `${campaignName} (Control)` : campaignName;
	};

	const showName = () => {
		return editedByName ? `by ${editedByName}` : "";
	};

	return (
		<InfoContainer>
			{isCampaigns && (
				<>
					{isMultipleCampaigns ? (
						<List>
							{selectedCampaigns.map((campaign) => (
								<ListElement key={campaign.id}>
									{campaign.attributes?.name}
								</ListElement>
							))}
						</List>
					) : (
						<>
							<Title data-qa="header-brand">
								{getPurpose()} - {name}
							</Title>
							<Element>
								Last edited on{" "}
								{new Date(dtEdited?.toString().replace(/-/g, "/")).toDateString()}{" "}
								{showName()}
							</Element>
						</>
					)}
				</>
			)}
		</InfoContainer>
	);
};

const InfoContainer = styled.div`
	margin-bottom: 22px;
`;

const Title = styled.h1`
	font-size: 14px;
	font-weight: 600;
	margin: 0 0 5px;
`;

const Element = styled.div`
	margin: 5px 0;
	font-size: 12px;
`;

const List = styled.ul`
	margin: 0;
`;

const ListElement = styled.li`
	font-weight: 600;
	font-size: 12px;
	margin: 5px 0;
`;

export default RightCMSInfoBlock;
