import React, { useState } from "react";
import styled from "styled-components";
import { Section } from "../../../../../../components/form";

const LegacyEligibilityJS = () => {
	const [legacy, setLegacy] = useState(false);

	return (
		<Section>
			<Spacer>
				<Label htmlFor="legacy">Legacy Eligibility JS</Label>
				<Input
					type="checkbox"
					checked={legacy}
					onChange={(event) => setLegacy(event.target.checked)}
					name="legacy"
				/>
			</Spacer>
		</Section>
	);
};

const Spacer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	margin: 1rem;
	> * {
		margin: 0 1rem 0 0;
	}
`;

const Input = styled.input`
	padding: 5px;
`;

const Label = styled.label`
	font-size: 12px;
	cursor: "pointer";
	vertical-align: top;
	display: inline-block;
	margin: 0 3rem 0 0.5rem;
`;

export default LegacyEligibilityJS;
