import React, { useState } from "react";
import PropTypes from "prop-types";
import { SubmitEPSModal } from "../modals";
import {
	ConversationHeader as Header,
	Body,
	Table,
	Link,
} from "./components";

const noop = () => {
	// Empty fn
};

const EmailCaptureTable = ({ data }) => {
	const [showModal, toggleModal] = useState(false);
	const [submitedData, setSubmittedData] = useState({});
	const openModal = (dataObj) => {
		return () => {
			setSubmittedData(dataObj);
			toggleModal(true);
		};
	};

	return (
		<React.Fragment>
			<Table>
				<Header headers={columnArray} />
				<Body data={data} createCells={createCustomCell(openModal)} />
			</Table>
			<SubmitEPSModal
				showModal={showModal}
				toggleModal={toggleModal}
				data={submitedData}
			/>
		</React.Fragment>
	);
};
EmailCaptureTable.propTypes = {
	data: PropTypes.array.isRequired,
};
const createCustomCell =
	(clickModal) =>
	(row = {}) => {
		const cellKeys = columnArray.map(({ id }) => id);

		return cellKeys.map((cell) => {
			const canClick = ["esp_submitted", "email"].includes(cell);
			const onClick = canClick ? clickModal(row) : noop;

			let value = row[cell];

			if (cell === "esp_submitted") {
				value = row[cell] === "yes" && row?.email ? "Yes" : "No";
			}

			const renderedValue =
				cell === "email" ? value || "No Email" : value;

			return canClick ? (
				<td key={cell}>
					<Link style={{ cursor: "pointer" }} onClick={onClick}>
						{renderedValue}
					</Link>
				</td>
			) : (
				<td key={cell}>{renderedValue}</td>
			);
		});
	};

export default EmailCaptureTable;

const columnArray = [
	{
		date: "Feb, 09, 21 at 05:06pm",
		email: "foo@Foo.com",
		steps: 123,
		submitted: 123,
		first_name: "Foo",
		last_name: "Doe",
	},
	{
		id: "updated_at",
		name: "Date",
	},
	{
		id: "email",
		name: "Email",
	},
	{
		id: "step_completed",
		name: "Step Completed",
	},
	{
		id: "esp_submitted",
		name: "Submitted to ESP",
	},
];
