import React, { useState } from "react";
import {
	ActivationContainer,
	ActivationButton,
} from "./ActivationWidget.styled";

const ActivationWidget = () => {
	// TODO: this is not how this will ultimately be done
	// but good for the moment.
	const [selected, setSelected] = useState(0);

	return (
		<ActivationContainer selected={selected}>
			<ActivationButton
				color="#97d483"
				selected={selected === 0}
				onClick={() => {
					setSelected(0);
					alert("Activate 2!");
				}}
			>
				<i className="icon-check" />
			</ActivationButton>
			<ActivationButton
				color="#dba47f"
				selected={selected === 1}
				onClick={() => {
					setSelected(1);
					alert("Test 2!");
				}}
			>
				<i style={{ alignSelf: "center" }} className="icon-bolt" />
			</ActivationButton>
			<ActivationButton
				color="#a09e9c"
				selected={selected === 2}
				onClick={() => {
					setSelected(2);
					alert("Stop 2!");
				}}
			>
				<i className="icon-ban" />
			</ActivationButton>
		</ActivationContainer>
	);
};

export default ActivationWidget;
