import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Search = ({ onChange, value, label, placeholder, dataQA = "search-input" }) => {

	const changeHandler = (event) => {
		onChange(event.target.value);
	};

	return (
		<SearchContainer>
			{label &&
				<label>{label}</label>
			}
			<Input
				data-qa={dataQA}
				type="text"
				onChange={changeHandler}
				value={value}
				placeholder={placeholder}
			/>
		</SearchContainer>
	)
};

Search.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	dataQA: PropTypes.string,
};

const SearchContainer = styled.div`
	margin: 11px 0;
`;

const Input = styled.input`
	width: 100%;
	border-color: rgb(232, 232, 232);
	&:focus {
		border-color: #303D78;
		color: #191919;
		outline: none;
		background: none;
		&::placeholder {
			color: inherit;
		}
	}
`;

export default Search;
