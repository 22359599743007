import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import {
	selectFetchedEspProfiles,
	selectEsps,
	selectWebsiteId,
	selectFormattedEspProfiles,
} from "../../../../redux/espProfiles/selectors";
import { Loader } from "../../../../components";
import { useEspProfileActions } from "../../../../hooks/esp-profiles/useEspProfileActions";
import EmptyRow from "../../../../components/ui/EmptyRow";
import ESPSettingsRow from "./ESPSettingsRow";

const ESPSettingsTable = () => {
	const websiteId = useSelector(selectWebsiteId);
	const { getEspProfiles, getEsps } = useEspProfileActions(websiteId);
	const formattedProfiles = useSelector(selectFormattedEspProfiles);
	const { isLoading: areProfilesLoading } = useSelector(selectFetchedEspProfiles);
	const { isLoading: areEspsLoading } = useSelector(selectEsps);

	const isLoading = areProfilesLoading || areEspsLoading;
	const isLoaded = !isLoading;
	const hasProfiles = formattedProfiles && formattedProfiles.length > 0;

	useEffect(() => {
		/** Prepopulate these on initial load */
		getEspProfiles();
		getEsps();
	}, []);

	return (
		<TableContainer>
			<Loader isLoading={isLoading} />
			<TopRowContainer>
				ESP Settings
			</TopRowContainer>
			{isLoaded && hasProfiles && (
				formattedProfiles?.map((formattedProfile) => {
					return (
						<ESPSettingsRow
							formattedProfile={formattedProfile}
							key={formattedProfile.id}
						/>
					);
				})
			)}
			{isLoaded && !hasProfiles && (
				<EmptyRow>No profiles found</EmptyRow>
			)}
		</TableContainer>
	);
};

ESPSettingsTable.propTypes = {
	isLoading: PropTypes.bool,
};

const TableContainer = styled.div`
	width: 100%;
	max-height: 89vh;
	overflow-y: scroll;
`;

const TopRowContainer = styled.div`
	height: 44px;
	line-height: 44px;
	font-size: 12px;
	font-weight: 600;
	color: rgb(33, 69, 88);
	padding: 0 11px;
	border: 1px solid rgb(232, 232, 232);
	background-color: white;
	margin-top: -1px;
	padding: 0 22px;
	&::first-letter {
		text-transform: capitalize;
	}
	display: flex;
	position: relative;
	align-items: center;
`;

export default ESPSettingsTable;
