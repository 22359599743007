import { createSlice } from "@reduxjs/toolkit";
import { getParentCampaignTypes } from "./thunks";

const initialState = {
	isFetchError: false,
	response: {
		data: [],
	},
};

const parentCampaignTypes = createSlice({
	name: "parentCampaignTypes",
	initialState,
	reducers: {
		clearParentCampaignTypesApiError(state) {
			state.isFetchError = false;
		}
	},
	extraReducers: (builder) => {
		// getParentCampaignTypes
		builder.addCase(getParentCampaignTypes.fulfilled, (state, action) => {
			state.response = action.payload;
		});
		builder.addCase(getParentCampaignTypes.rejected, (state) => {
			state.isFetchError = true;
		});
	},
});

export const {
	clearParentCampaignTypesApiError,
} = parentCampaignTypes.actions;

export default parentCampaignTypes.reducer;
