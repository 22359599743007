
// Originally (c) 2011 John Resig (ejohn.org), licensed under the MIT and GPL licenses.
export function prettyDate(params) {
	// account for client clock drift
	var drift = window.bouncex ? window.bouncex.server_client_time_diff || 0 : 0;
	// @ts-ignore
	var date = new Date(new Date((params[0] || '').replace(/-/g, '/').replace(/[TZ]/g, ' ')) - drift * 1000);
	var now = new Date();

	// calculate difference in time and days
	var diff = (now.getTime() - date.getTime()) / 1000;
	var dayDiff = Math.floor(diff / 86400);

	if (isNaN(dayDiff) || dayDiff < 0) {
		return 'in the future';
	}

	return dayDiff === 0 && (
		diff < 60        && 'seconds ago' ||
		diff < 120       && 'a minute ago' ||
		diff < 3600      && Math.floor(diff / 60) + ' minutes ago' ||
		diff < 7200      && 'an hour ago' ||
		diff < 86400     && Math.floor(diff / 3600) + ' hours ago') ||
		'on ' + date.toLocaleDateString();
}