import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { getCurrentDayString, formatDateString } from "../../../../../utils";
import { Button } from "../../../../../components";

const ButtonRow = ({
	onClick = () => {},
}) => {
	const date_end = getCurrentDayString();
	// INFO: No Magic numbers
	const last7 = 7,
		last30 = 30,
		last60 = 60;
	const onSeven = _createButtonClick(onClick, last7, date_end);
	const onThirty = _createButtonClick(onClick, last30, date_end);
	const onSixty = _createButtonClick(onClick, last60, date_end);
	// const onLive = () => {
	// 	onClick({ date_start: "", date_end });
	// };

	return (
		<Row>
			<div>
				<Button theme="blue" onClick={onSeven}>
					Last 7 Days
				</Button>
				<Button theme="blue" onClick={onThirty}>
					Last 30 Days
				</Button>
				<Button theme="blue" onClick={onSixty}>
					Last 60 Days
				</Button>
				{/* Hidden until we figure out how to handle it */}
				{/* <Button theme="blue" onClick={onLive}>
					Live to Date
				</Button> */}
			</div>
		</Row>
	);
};

ButtonRow.propTypes = {
	onClick: PropTypes.func.isRequired,
};

const _createButtonClick = (
	onClick = () => {},
	numOfDates = 0,
	date_end = ""
) => {
	return () => {
		//INFO: get todays date and subtract the number of days
		const startDate = new Date();
		startDate.setDate(startDate.getDate() - numOfDates);
		const date_start = formatDateString(startDate);
		onClick({ date_start, date_end });
	};
};

const Row = styled.div`
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
	div {
		button {
			max-width: 100px;
			margin-right: 10px;
		}
	}
`;

export default ButtonRow;
