export const useCreateLookerDashLink = (campaignId) => {
	let startDate = new Date();
	startDate.setDate(startDate.getDate() - 6);
	startDate = startDate.toISOString().split("T")[0];

	let endDate = new Date();
	endDate.setDate(endDate.getDate() + 1);
	endDate = endDate.toISOString().split("T")[0];

	const lookerDashLink = campaignId
		? `https://looker.bounceexchange.com/dashboards/607?Parent%20Campaign%20ID=${campaignId}&Date%20Range=${startDate}%20to%20${endDate}`
		: undefined;

	return {
		lookerDashLink,
	};
};
