import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { Loader, EmptyRow } from "../../../components";
import { selectAllCampaigns } from "../../../redux/campaigns/campaignsAdapter";
import { campaignsSetOrder } from "../../../redux/campaigns/thunks";
import { clearSelectedCampaigns } from "../../../redux/campaigns/slice";
import { CampaignBlock, DraggableCampaignRow } from ".";

const CampaignsTable = ({ isLoading, view, scheduledUTCValue, scheduledTime }) => {
	const [draggingCampaignIds, setDraggingCampaignIds] = useState([]);
	const campaigns = useSelector(selectAllCampaigns);
	const websiteId = useSelector((state) => state?.page?.params?.websiteId);
	const campaignsOrder = campaigns.map((campaign) => campaign.id);
	const dispatch = useDispatch();

	const changeOrderHandler = async (newOrder) => {
		const response = await dispatch(
			campaignsSetOrder({
				website_id: websiteId,
				campaign_ids: newOrder,
			})
		);

		const { requestStatus } = response?.meta || {};

		if (requestStatus === "fulfilled") {
			dispatch(clearSelectedCampaigns());

			toast.success("Order saved!", { autoClose: 1000 });
		} else {
			toast.error("An error occurred", { autoClose: 1000 });
		}
	};

	if (campaigns.length === 0 && !isLoading) {
		return <EmptyRow>No Experiences Found</EmptyRow>;
	}

	return (
		<TableContainer>
			{isLoading && <Loader isLoading={isLoading} />}
			{view === "list" && (
				<>
					{campaigns.map((campaign) => (
						<CampaignBlock key={campaign.id} campaign={campaign} scheduledUTCValue={scheduledUTCValue} scheduledTime={scheduledTime} />
					))}
				</>
			)}
			{view === "reorder" && (
				<>
					{campaigns.map((campaign) => (
						<DraggableCampaignRow
							key={campaign.id}
							campaign={campaign}
							campaignsOrder={campaignsOrder}
							draggingCampaignIds={draggingCampaignIds}
							onChange={changeOrderHandler}
							onDragging={setDraggingCampaignIds}
						/>
					))}
				</>
			)}
		</TableContainer>
	);
};

const TableContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	background-color: rgb(243, 243, 243);
`;

CampaignsTable.propTypes = {
	view: PropTypes.oneOf(["list", "reorder"]).isRequired,
	isLoading: PropTypes.bool.isRequired,
	scheduledTime: PropTypes.bool,
	scheduledUTCValue: PropTypes.string,
};

export default CampaignsTable;
