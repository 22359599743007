import { gql } from "urql";
import { CAMPAIGN_STATS } from "./fragments";

const websiteQuery = gql`
    ${CAMPAIGN_STATS}
    query($websiteId: Int!, $start: DateTime!, $end: DateTime!, $statsGranularity: StatsGranularity) {
        website (id: $websiteId, start: $start, end: $end) {
            id
            name
            onsiteProduct(websiteAttribution: ALL_CREDIT_30, statsGranularity: $statsGranularity) {
                stats {
                    ...campaignStats
                }
                timeSeries {
                    date
                    stats {
                        ...campaignStats
                    }
                }
            }
        }
        overall: website(id: $websiteId, start: $start, end: $end) {
            id
            onsiteProduct(websiteAttribution: ALL_CONVERSION, statsGranularity: $statsGranularity) {
                stats {
                    ConversionStats {
                        Conversions
                    }
                }
                timeSeries {
                    date
                    stats {
                        ConversionStats {
                            Conversions
                        }
                    }
                }
            }
        }
    }
`;

export default websiteQuery;
