import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../../components";

const InboxCampaignEdit = () => {


	return (
		<Wrapper
			left="0"
			maxWidth="100%"
			style={{
				display: "flex",
				justifyContent: "space-between",
				bottom: "0",
			}}
		>
			<Aside>
                LeftSidebar
			</Aside>
			<Main>
				Inbox Campaign Edit Page
			</Main>
			<Aside>
                RightSidebar
            </Aside>
		</Wrapper>
	);
};

const Aside = styled.aside`
	width: 270px;
	background-color: #ffffff;
`;

const Main =styled.main`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

export default InboxCampaignEdit;
