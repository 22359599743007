import { createSlice } from "@reduxjs/toolkit";
import { downloadCreative, renderCreative, renderMultiCreative } from "./thunks";
import creativesAdapter from "./creativesAdapter";

const initialState = {
	isFetchError: false,
	renderedCreative: null,
	response: {
		data: creativesAdapter.getInitialState(),
	},
	renderedMultiCreative: [],
};

const creativesSlice = createSlice({
	name: "creatives",
	initialState,
	reducers: {
		clearCreativesApiError(state) {
			state.isFetchError = false;
		},
		removeAllCreatives(state) {
			creativesAdapter.removeAll(state.response.data);
		},
		clearRenderedCreative(state) {
			state.renderedCreative = null;
		},
	},
	extraReducers: (builder) => {
		// downloadGreative
		builder.addCase(downloadCreative.fulfilled, (state, action) => {
			creativesAdapter.setOne(state.response.data, action.payload);
		});
		builder.addCase(downloadCreative.rejected, (state) => {
			state.isFetchError = true;
		});
		// renderCreative
		builder.addCase(renderCreative.fulfilled, (state, action) => {
			state.renderedCreative = action.payload;
		});
		builder.addCase(renderCreative.rejected, (state) => {
			state.renderedCreative = null;
		});
		// renderCreative
		builder.addCase(renderMultiCreative.fulfilled, (state, action) => {
			state.renderedMultiCreative.push(action.payload);
		});
		builder.addCase(renderMultiCreative.rejected, (state) => {
			state.renderedMultiCreative = [];
		});
	},
});

export const {
	clearCreativesApiError,
	removeAllCreatives,
	clearRenderedCreative,
} = creativesSlice.actions;

export default creativesSlice.reducer;
