import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ConversationHeader as Header, Body, Table } from "./components";
const ConversationTable = ({ data, summary }) => {
	return (
		<Table>
			<Header headers={columnArray} />
			<Body
				background="#f3f3f2"
				data={summary}
				createCells={createSummaryCell()}
			/>
			<Body data={data} createCells={createCustomCell()} scrollAfterAppend />
		</Table>
	);
};
ConversationTable.propTypes = {
	data: PropTypes.array.isRequired,
	summary: PropTypes.array.isRequired,
};

const createSummaryCell = () => {
	return (row = {}) => {
		const cellKeys = columnArray.map(({ id }) => id);
		return cellKeys.map((cell, index) => {
			if (row[cell] === undefined) {
				return <td key={index}></td>;
			}
			if (cell === "amount") {
				return createAmount(index, row[cell]);
			}
			return <TDCentered key={index}>{row[cell]}</TDCentered>;
		});
	};
};
const createAmount = (index, value) => {
	return <td key={index}>${new Intl.NumberFormat().format(value)}</td>;
};
const createCustomCell = () => {
	return (row = {}) => {
		const cellKeys = columnArray.map(({ id }) => id);
		return cellKeys.map((cell, index) => {
			if (cell === "dt_date" && row[cell]) {
				const date = new Date(row[cell]);
				const time = date.toLocaleTimeString("en-US");
				const month = getMonth(date.getMonth());
				const year = date.getFullYear();

				return (
					<td
						key={index}
					>{`${month} ${date.getDate()} ${year} at ${time}`}</td>
				);
			}
			if (cell === "bx_credit") {
				return <TDCentered key={index}>&#10004;</TDCentered>;
			}
			if (index > 4) {
				return <TDCentered key={index}>{row[cell]}</TDCentered>;
			}
			if (cell === "amount") {
				return createAmount(index, row[cell]);
			}
			return <td key={index}>{row[cell] || ""}</td>;
		});
	};
};
const getMonth = (num) => {
	return {
		0: "Jan",
		1: "Feb",
		2: "Mar",
		3: "April",
		4: "May",
		5: "June",
		6: "July",
		7: "Aug",
		8: "Sep",
		9: "Oct",
		10: "Nov",
		11: "Dec",
	}[num];
};

const TDCentered = styled.td`
	text-align: center;
`;
export const columnArray = [
	{
		id: "dt_date",
		name: "Date",
	},
	{
		id: "order_id",
		name: "Order ID",
	},
	{
		id: "amount",
		name: "Amount",
	},
	{
		id: "email",
		name: "Email",
	},
	{
		id: "goal",
		name: "",
	},
	{
		id: "bx_credit",
		name: "BX Credit",
	},
	{
		id: "visit_credit",
		name: "Visit Credit",
	},
	{
		id: "cookie_credit",
		name: "Cookie Credit",
	},
	{
		id: "email_credit",
		name: "Email Credit",
	},
	{
		id: "impressions",
		name: "Impressions",
	},
	{
		id: "clicks",
		name: "Clicks",
	},
	{
		id: "submits",
		name: "Submits",
	},
	{
		id: "control_impressions",
		name: "Control Impressions",
	},
];

export default ConversationTable;
