import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "../../../../components"
import {
	getInboxCampaignDefaults,
	clearInboxCampaignDefaults,
} from "../../../../redux/inboxCampaignDefaults/thunks";
import {
	createInboxCampaign,
	getInboxCampaign,
} from "../../../../redux/inboxCampaigns/thunks";
import PropTypes from "prop-types";
import {
	InputContainer,
	InputLabel,
	Input,
	Select,
} from "../../../../components/styles";

const NewVariationModal = ({ closeModal }) => {
	const dispatch = useDispatch();
	const [variationName, setVariationName] = useState("New welcome variation");
	const [emailType, setEmailType] = useState("");
	const [testType, setTestType] = useState("variation");

	const disabled = !emailType || !testType;

	const campaignDefaults = useSelector(
		(state) => state.inboxCampaignDefaults
	);
	const campaignId = useSelector((state) => state.page.params?.campaignId);
	const selectedCampaign = useSelector(
		(state) => state.inboxCampaigns?.selectedInboxCampaign
	);

	const handleSubmit = () => {
		dispatch(getInboxCampaignDefaults({ testType, emailType }));
	};

	useEffect(() => {
		if (!selectedCampaign) {
			dispatch(getInboxCampaign(campaignId));
		}
		const newCampaignId = campaignDefaults?.response?.data?.ids[0];
		const campaign =
			campaignDefaults?.response?.data?.entities[newCampaignId];
		if (campaign && selectedCampaign) {
			const attributes = {
				...campaign.attributes,
				name: variationName,
			};
			const relationships = {
				...campaign.relationships,
				"inbox-series": selectedCampaign.relationships["inbox-series"],
				parent: selectedCampaign.relationships.parent,
				"parent-campaign-type":
					selectedCampaign.relationships["parent-campaign-type"],
				website: selectedCampaign.relationships.website,
				"linked-campaigns":
					selectedCampaign.relationships["linked-campaigns"],
			};
			dispatch(
				createInboxCampaign({
					attributes: attributes,
					relationships: relationships,
					type: "inbox-campaigns",
				})
			);
			dispatch(clearInboxCampaignDefaults());
			closeModal();
		}
	}, [campaignDefaults]);

	return (
		<ModalContainer>
			<HeaderText>New Variation</HeaderText>
			<InputContainer width="100%">
				<InputLabel>Variation Name</InputLabel>
				<Input
					type="text"
					value={variationName}
					onChange={(e) => setVariationName(e.target.value)}
				/>
			</InputContainer>
			<InputContainer width="100%">
				<InputLabel>Email Type</InputLabel>
				<Select
					value={emailType}
					onChange={(e) => setEmailType(e.target.value)}
				>
					<option value="">Select Type...</option>
					<option value="0">Not Set</option>
					<option value="5">Control</option>
					<option value="9">Dynamic</option>
					<option value="10">Static</option>
					<option value="25">Behavioral SMS</option>
				</Select>
			</InputContainer>
			<InputContainer width="100%">
				<InputLabel>Test Type</InputLabel>
				<Select
					value={testType}
					onChange={(e) => setTestType(e.target.value)}
				>
					<option value="variation" defaultValue={true}>
						Variation
					</option>
					<option value="control">Control</option>
				</Select>
			</InputContainer>
			<Button theme="blue" disabled={disabled} onClick={handleSubmit}>
				Create
			</Button>
		</ModalContainer>
	);
};

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const HeaderText = styled.h2`
	font-size: 1rem;
	font-weight: 600;
	width: 100%;
`;

NewVariationModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

export default NewVariationModal;
