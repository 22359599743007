import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { PAGE_MAP } from "../../redux/page/constants";

const CmsInboxSwitcher = ({ page, websiteId }) => {
	const redirectToCms = () => {
		window.location.href = `/experiences/cms?website_id=${websiteId}`;
	};

	const redirectToInbox = () => {
		window.location.href = `/experiences/inbox?website_id=${websiteId}`;
	};

	return (
		<Container>
			<Button
				data-qa="cms-button"
				isActive={page === PAGE_MAP.CMS_SERIES}
				onClick={redirectToCms}
			>
				CMS
			</Button>
			<Button
				data-qa="inbox-button"
				isActive={page === PAGE_MAP.INBOX_SERIES}
				onClick={redirectToInbox}
			>
				INBOX
			</Button>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	margin: 11px 0;
`;

const Button = styled.button`
	flex: 1 1 0;
	height: 42px;
	background-color: white;
	border: ${({ isActive }) => isActive
		? "1px solid rgb(48, 61, 120)"
		: "1px solid rgb(232, 232, 232)"
	};
	border-radius: 0;
	color: ${({ isActive }) => isActive
		? "rgb(48, 61, 120)"
		: "rgb(25, 25, 25)"
	};
	font-size: 10px;
	z-index: ${({ isActive }) => isActive ? "2" : "1"};
	&:not(:first-child) {
		margin-left: -1px;
	}
	&:hover {
		color: rgb(48, 61, 120);
		border: 1px solid rgb(48, 61, 120);
		z-index: 2;
	}
`;


CmsInboxSwitcher.propTypes = {
	page: PropTypes.string.isRequired,
	websiteId: PropTypes.string.isRequired,
};

export default CmsInboxSwitcher;
