import React from "react";
import { Wrapper } from "../../components";
import { Container, ListContainer, HalfColumn, Row, Header } from "./styledComponents";

const ToolsLandingPage = () => {
	return (
		<Wrapper>
			<Container>
				<Header>Tools</Header>
			</Container>

			<ListContainer>
				<a href="/tools/esps">
					<Row>
						<HalfColumn>
							<Header>ESPs</Header>
						</HalfColumn>
						<HalfColumn>View and edit ESPs</HalfColumn>
					</Row>
				</a>
				<a href="/tools/uploads">
					<Row>
						<HalfColumn>
							<Header>Uploads</Header>
						</HalfColumn>
						<HalfColumn>Upload pictures</HalfColumn>
					</Row>
				</a>
				<a href="https://img-resizer.wunderkind.co" target="_blank" rel="noreferrer">
					<Row>
						<HalfColumn>
							<Header>Image Resizer</Header>
						</HalfColumn>
						<HalfColumn>Resize images for optimal mobile + mms viewing</HalfColumn>
					</Row>
				</a>
				<a href="/fonts">
					<Row>
						<HalfColumn>
							<Header>Fonts</Header>
						</HalfColumn>
						<HalfColumn>Manage Fonts</HalfColumn>
					</Row>
				</a>
			</ListContainer>
		</Wrapper>
	);
};

export default ToolsLandingPage;
