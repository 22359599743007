import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import fetch from "isomorphic-fetch";

import { endpoints } from "../../constants/common";
import { LocalStorageService } from "../../service/localStorage";
import { convertFieldsArrayToObject, getUpdatedEsp } from "./utils";

export const getESP = createAsyncThunk(
	"esp/getESP",
	async (espId) => {
		const url = `${endpoints.coreApi}/esps/${espId}`;

		const token = LocalStorageService.getToken();

		const response = await fetch(url, {
			method: "GET",
			cache: "no-cache",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.json();
	}
);

export const createESP = createAsyncThunk(
	"esp/createESP",
	async ({ submitData, onSuccess }) => {
		const fieldsObject = convertFieldsArrayToObject(submitData.fields || []);
		const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");
		const url = `${endpoints.coreApi}/esps`;

		const token = LocalStorageService.getToken();

		const response = await fetch(url, {
			cache: "no-cache",
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				jsonapi: {
					version: "1.0",
				},
				data: {
					type: "esps",
					attributes: {
						name: submitData.name,
						type: submitData.type,
						fields: JSON.stringify(fieldsObject),
						active: submitData.active,
						submission: null,
						"custom-submission": false,
						deprecated: false,
						"dt-deprecated": null,
						"dt-inserted": currentDate,
						"dt-edited": currentDate,
					},
					relationships: {},
				},
				included: [],
			}),
		});

		const json = await response.json();
		onSuccess?.();

		return json;
	}
);

export const updateESP = createAsyncThunk(
	"esp/updateESP",
	async ({ submitData, response, onSuccess }) => {
		const esp = response.data;
		const url = `${endpoints.coreApi}/esps/${esp.id}`;

		const token = LocalStorageService.getToken();

		const res = await fetch(url, {
			cache: "no-cache",
			method: "PUT",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				...response,
				data: getUpdatedEsp(submitData, esp),
			}),
		});

		const json = await res.json();
		onSuccess?.();

		return json;
	}
);
