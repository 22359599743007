import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Section } from "../../../../../../components/form";
import { useSelectInboxCampaigns } from "../../../../../../hooks/selectors/useSelectInboxCampaigns";
import { updateInboxCampaign } from "../../../../../../redux/inboxCampaigns/thunks";

import { Spacer, Checkbox, TitleContainer } from "./utils/Sections.styled";

const DataScienceRec = () => {
	const dispatch = useDispatch();
	const {
		params: { campaignId },
	} = useSelector((state) => state.page);
	const campaign = useSelector(useSelectInboxCampaigns)?.[campaignId];

	const initialState = {
		attributes: {
			"overlay-recommendation-html":
				campaign?.attributes["overlay-recommendation-html"] ?? false,
		},
	};

	const [dataScienceRecFormData, setDataScienceRecFormData] = useState(initialState);

	useEffect(() => {
		dispatch(
			updateInboxCampaign({
				...dataScienceRecFormData,
				id: campaignId,
			})
		);
	}, [dataScienceRecFormData]);

	if (!campaign) {
		return null;
	}

	return (
		<Section>
			<Spacer>
				<TitleContainer />
				<Checkbox
					type="checkbox"
					checked={dataScienceRecFormData.dataScienceRec}
					onChange={(e) =>
						setDataScienceRecFormData({
							attributes: {
								"overlay-recommendation-html": e.target.checked,
							},
						})
					}
				/>
				Data Science Recommendation
			</Spacer>
		</Section>
	);
};

export default DataScienceRec;
