import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import ReactDom from 'react-dom';
import { setSelectedCampaign, addSelectedCampaign } from "../../../redux/campaigns/slice";

import { getTrafficType } from "../../../utils";

const bgColorMap = {
	on: "rgb(255, 255, 255)",
	off: "rgb(232, 232, 232)",
	test: "rgb(245, 235, 225)",
};

const DraggableCampaignRow = ({
	campaign,
	campaignsOrder,
	draggingCampaignIds,
	onDragging,
	onChange,
}) => {
	const campaignId = campaign?.id;
	const isLastCampaign = campaignsOrder.at(-1) === campaignId;
	const isDragging = !!draggingCampaignIds.length;
	const isMultiple = draggingCampaignIds.length > 1;
	const isCampaignOnDragging = draggingCampaignIds.includes(campaignId);

	const [isDropZoneHover, setIsDropZoneHover] = useState(false);
	const [isLastDropZoneHover, setIsLastDropZoneHover] = useState(false);
	const [imageContainer, setImageContainer] = useState(null);
	const [preparedForDraggingIds, setPreparedForDraggingIds] = useState([]);
	const dispatch = useDispatch();
	const dragContainerRef = useRef(null);

	useEffect(() => {
		onDragging(preparedForDraggingIds);
	}, [preparedForDraggingIds]);

	const {
		selectedCampaigns,
	} = useSelector((state) => state.campaigns);
	const isSelected = selectedCampaigns.some((campaign) => campaign.id === campaignId);

	const dropHandler = (isLastZone) => {
		setIsDropZoneHover(false);
		setIsLastDropZoneHover(false);

		if(!isMultiple && isCampaignOnDragging) {
			return;
		}

		const newCampaignsOrder = campaignsOrder.filter((campaignId) =>
			!draggingCampaignIds.includes(campaignId)
		);
		const insertBeforeIndex = newCampaignsOrder.indexOf(campaignId);

		if (isLastZone) {
			newCampaignsOrder.push(...draggingCampaignIds);
			onChange(newCampaignsOrder);

			return;
		}

		if (insertBeforeIndex >= 0) {
			newCampaignsOrder.splice(insertBeforeIndex, 0, ...draggingCampaignIds);
			onChange(newCampaignsOrder);
		}
	};

	const dragOverHandler = (event) => {
		event.dataTransfer.dropEffect = "move";
		event.stopPropagation();
		event.preventDefault();
	};

	const dragEnterHandler = () => {
		if (!isMultiple) {
			const draggingCampaignIndex = campaignsOrder.indexOf(draggingCampaignIds[0]);

			if (campaignsOrder[draggingCampaignIndex + 1] === campaignId) {
				return;
			}
		}

		if (isDragging && !isCampaignOnDragging) {
			setIsDropZoneHover(true);
		}
	};

	const dragEnterLastZoneHandler = () => {
		const lastCampaignId = campaignsOrder.at(-1);

		if (isDragging && !draggingCampaignIds.includes(lastCampaignId)) {
			setIsLastDropZoneHover(true);
		}
	};

	const selectCampaignHandler = (event) => {
		if (isSelected) {
			return;
		}

		if (event.ctrlKey || event.shiftKey || event.metaKey) {
			dispatch(addSelectedCampaign(campaign));

			return;
		}

		dispatch(setSelectedCampaign(campaign));
	};

	const createRow = (campaign) => {
		const order = campaign?.attributes?.order;
		const campaignSeriesName = campaign?.attributes?.["series-name"]
		const campaignSeriesId = campaign?.attributes?.["campaign-series-id"];
		return (
			<>
				<OrderContainer>
					{order}
				</OrderContainer>
				<NameContainer>
					{campaignSeriesName}
				</NameContainer>
				<IdContainer>
					{campaignSeriesId}
				</IdContainer>
			</>
		);
	};

	const dragStartHandler = (event) => {
		const selectedCampaignIds = [...selectedCampaigns]
			.sort((campA, campB) => campA?.attributes?.order - campB?.attributes?.order)
			.map((campaign) => campaign.id);
		setPreparedForDraggingIds(selectedCampaignIds);

		if (selectedCampaignIds.length <= 1) {
			return;
		}

		const body = document.body;
		const conntainer = document.createElement("div");
		const parentWidth = `${dragContainerRef.current.offsetWidth}px`;

		conntainer.style.width = parentWidth;
		conntainer.style.position = "absolute";
		conntainer.style.top = "-1000px"; // to hide the container from the screen
		body.appendChild(conntainer);
		event.dataTransfer.setDragImage(conntainer, 30, 20);
		setImageContainer(conntainer);
	};

	const dragEndHandler = () => {
		onDragging([]);

		if (imageContainer) {
			document.body.removeChild(imageContainer);
			setImageContainer(null);
		}
	};

	const createImageData = () => {
		const copySelectedCampaigns = [...selectedCampaigns];

		return(
			<>
				{copySelectedCampaigns
					.sort((campA, campB) => campA?.attributes?.order - campB?.attributes?.order)
					.map((campaign) => {
						const campaignRow = createRow(campaign);

						return (
							<DraggableContainer
								key={campaign.id}
								bgColor={backgroundColor}
								isSelected={isSelected}
							>
								{campaignRow}
							</DraggableContainer>
						)
					})
				}
			</>
		);
	};

	const trafficType = getTrafficType(campaign);
	const CampaignRow = createRow(campaign);
	const backgroundColor = isSelected ? "rgb(146, 152, 182)" : bgColorMap[trafficType];
	const ImagePortal = imageContainer ? ReactDom.createPortal(createImageData(), imageContainer) : null;


	return (
		<>
			<DropZone
				isHover={isDropZoneHover}
				isDragging={isDragging}
				onDragEnter={dragEnterHandler}
				onDragLeave={() => setIsDropZoneHover(false)}
				onDragOver={dragOverHandler}
				onDrop={() => dropHandler(false)}
			/>

			<DraggableContainer
				draggable
				bgColor={backgroundColor}
				isSelected={isSelected}
				onMouseDown={selectCampaignHandler}
				onDragEnd={dragEndHandler}
				onDragStart={dragStartHandler}
				ref={dragContainerRef}
			>
				{CampaignRow}
			</DraggableContainer>

			{isLastCampaign &&
				<DropZone
					isHover={isLastDropZoneHover}
					onDragEnter={dragEnterLastZoneHandler}
					onDragLeave={() => setIsLastDropZoneHover(false)}
					onDragOver={dragOverHandler}
					onDrop={() => dropHandler(true)}
				/>
			}
			{ImagePortal}
		</>
	)
};

const DraggableContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	height: 25px;
	cursor: move;
	border: 1px solid;
	border-color: ${({ isSelected }) => isSelected
		? "rgb(48, 61, 120)"
		: "rgb(232, 232, 232)"
	};
	background-color: ${({ bgColor }) => bgColor || "transparent"};
	z-index: ${({ isSelected }) => isSelected ? "5" : "1"};
	&:hover {
		border-color: rgb(48, 61, 120);
		z-index: 5;
		filter: brightness(95%);
	}
	&:not(:first-child) {
		margin-top: -1px;
	}
`;

const OrderContainer = styled.div`
	width: 30px;
	text-align: right;
	margin: 0 11px;
	font-size: 12px;
	font-weight: 600;
`;

const PurposeContainer = styled.div`
	width: 250px;
	margin: 0 11px;
	font-size: 12px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

const NameContainer = styled(PurposeContainer)`
	flex-grow: 1;
`;

const IdContainer = styled.div`
	width: 65px;
	margin: 0 11px;
	font-size: 12px;
`;

const DropZone = styled.div`
	position: relative;
	height: 24px;
	background-color: ${({ isHover }) => isHover
		? "lightgray"
		: "transparent"
	};
	margin-bottom: ${({ isHover }) => isHover ? "0" : "-25px"};
	transition: 100ms margin-bottom ease-out;
	z-index: ${({ isDragging }) => isDragging ? "99" : "1"};
`;

DraggableCampaignRow.propTypes = {
	campaign: PropTypes.object.isRequired,
	campaignsOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
	onChange: PropTypes.func.isRequired,
	draggingCampaignIds: PropTypes.arrayOf(PropTypes.string).isRequired,
	onDragging: PropTypes.func.isRequired,
};

export default DraggableCampaignRow;
