// eslint-disable-next-line no-unused-vars
import React from "react";
import styled from "styled-components";
const TBody = styled.tbody`
	background-color: ${({ background }) => background || "#fff"};
	color: #5a5959;
	font-family: Larsseit, "Helvetica Neue";
	font-size: 14px;
	font-weight: bold;
	tr {
		border: 1px solid rgb(225, 225, 225);
		height: 50px;
		td {
			&:first-child {
				padding-left: 20px;
			}
			&:last-child {
				padding-right: 20px;
			}
			padding: ${({ cellPadding }) => cellPadding || "20px 0"};
			@media (max-width: 768px) {
				font-size: 10px;
			}
		}
	}
`;
export default TBody;
