/**
 * Borrowed from Thorium validation
 */
import get from 'lodash/get';
import set from 'lodash/set';

const isEmpty = (value) => value === undefined || value === null || value === '';
const join = (rules) => (value, data) => rules.map((rule) => rule(value, data)).filter((error) => !!error)[0];

export function email(message) {
	return (value) => {
		// eslint-disable-next-line no-useless-escape
		const newRegex = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

		// If we want somethimg more complicated or to not do email we can remove this
		if (!isEmpty(value) && !newRegex.test(value)) {
			return message || 'Invalid email address';
		}

		return null;
	}
}

export function required(message) {
	return (value) => {
		if (isEmpty(value)) {
			return message || 'This field is Required';
		}
	
		return null;
	}
}

export function minLength(min) {
	return (value) => {
		// eslint-disable-next-line react/destructuring-assignment
		if (!isEmpty(value) && value.length < min) {
			return `Must be at least ${min} characters`;
		}

		return null;
	};
}

export function maxLength(max) {
	return (value) => {
		// eslint-disable-next-line react/destructuring-assignment
		if (!isEmpty(value) && value.length > max) {
			return `Must be no more than ${max} characters`;
		}

		return null;
	};
}

export function integer(message) {
	return (value) => {
		if (!Number.isInteger(Number(value))) {
			return message || 'Must be an integer';
		}

		return null;
	}
}

export function number(message) {
	return (value) => {
		if (typeof value !== 'undefined' && Number.isNaN(Number(value))) {
			return message || 'Must be a number';
		}

		return null;
	}
}


export function url(message) {
	return (value) => {
		if (isEmpty(value)) {
			return null;
		}

		try {
			new URL(value);
			return null;
		} catch (error) {
			return message ||'Must be a valid URL';
		}
	}
}

export function maxValue(max) {
	return (value) => {
		if (value > max) {
			return `Must be no more than ${max}`;
		}

		return null;
	};
}

export function minValue(min) {
	return (value) => {
		if (value < min) {
			return `Must be no less than ${min}`;
		}

		return null;
	};
}


export function oneOf(enumeration) {
	return (value) => {
		if (!~enumeration.indexOf(value)) { // eslint-disable-line no-bitwise
			return `Must be one of: ${enumeration.join(', ')}`;
		}

		return null;
	};
}

export function notOneOf(enumeration) {
	return (value) => {
		if (enumeration.includes(value)) {
			return `${value} already exists`;
		}

		return null;
	};
}

export function match(field) {
	return (value, data) => {
		if (data) {
			if (value !== data[field]) {
				return 'Do not match';
			}
		}

		return null;
	};
}


export function createValidator(rules) {
	return (data = {}) => {
		const errors = {};
		Object.keys(rules).forEach((key) => {
			const rule = join([].concat(rules[key])); // concat enables both functions and arrays of functions
			const error = rule(get(data, key), data);
			if (error) {
				set(errors, key, error);
			}
		});
		return errors;
	};
}
