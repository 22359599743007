import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
	PageSection,
	SimpleText,
	SectionTitle,
	SubsectionBreak,
	SubsectionContainer,
	InputText,
	Dropdown,
	Checkbox,
	ToolTip,
	ReactLink,
	InputNumber,
} from "../../../components";
import { UrlTemplate, generateSameDomainUrl } from "../../../components/utils/open-page";
import getPurposeMethods from "../../../redux/purposes/getPurposeMethods";
import {
	campaignSetDeploymentType,
	campaignSetName,
	campaignSetPurpose,
	campaignSetSubpurpose,
	campaignSetTrafficPercentage,
	campaignSetTrafficType,
} from "../../../redux/campaigns/thunks";

const initialState = {
	purposes: null,
	selectedPurpose: null,
	selectedSubpurposes: [],
	selectedSubpurpose: null,
	complianceOptions: {},
	selectedDeployment: null,
	variationTrafficType: "",
	variationTrafficPercent: null,
};

const manageState = (initialState) => {
	const [state, setState] = useState(initialState);

	const setStateValue = (obj = {}) => {
		setState({ ...state, ...obj });
	};

	return [state, setStateValue];
};

const groupByOptGroup = (types, product) => {
	return types
		.filter(({ attributes }) => attributes.product === product)
		.reduce((group, type) => {
			const groupName = type.attributes["optgroup-name"];
			if (!groupName) {
				return group;
			}

			if (!(groupName in group)) {
				group[groupName] = [];
			}

			group[groupName].push({
				name: type.attributes.name,
				value: type.attributes.id,
			});

			return group;
		}, {});
};

const CampaignEditMain = () => {
	const {
		selectedCampaigns: { [0]: campaign },
	} = useSelector((state) => state.campaigns);
	const dispatch = useDispatch();
	const duplicateCampaignId = campaign?.attributes?.["master-campaign-id"];
	const { getPurposeOptions } = getPurposeMethods();
	let parentTypes = {};

	const [campaignMainState, updateStateData] = manageState(initialState);

	const {
		parentCampaignTypes: {
			response: { data: parentTypesData },
		},
		campaignTypes: {
			response: { data: campaignTypes },
		},
	} = useSelector((state) => state);

	const { [1]: filteredOptionsMap, [2]: optionsList } = getPurposeOptions({
		productType: "bouncex",
	});

	const setSubpurposes = (selectedPurposeObj) => {
		const subpurposes = selectedPurposeObj?.relationships["parent-campaign-types"].data
			.map(({ id }) => {
				return {
					name: parentTypes[id]?.attributes.name,
					value: id,
				};
			})
			.filter((sub) => !!sub.name);

		if (selectedPurposeObj && !subpurposes.length) {
			subpurposes.push({
				name: selectedPurposeObj.attributes.name,
				value: selectedPurposeObj.attributes.id,
			});
		}

		return subpurposes;
	};

	useEffect(() => {
		if (campaign) {
			const selectedPurposeObj =
				filteredOptionsMap[campaign.relationships?.["campaign-purpose"].data.id];
			parentTypes = parentTypesData.reduce((allSoFar, current) => {
				return { ...allSoFar, [current.id]: current };
			}, {});
			const complianceJsonStr = campaign.attributes?.["compliance-json"];
			updateStateData({
				purposes: optionsList.options,
				selectedPurpose: selectedPurposeObj?.id,
				selectedSubpurposes: setSubpurposes(selectedPurposeObj),
				selectedSubpurpose:
					parentTypes[campaign.relationships?.["parent-campaign-type"].data.id]?.id,
				complianceOptions: complianceJsonStr ? JSON.parse(complianceJsonStr) : [],
				selectedDeployment: campaign.relationships?.["campaign-type"].data?.id,
				variationTrafficType: campaign.attributes?.["test-type"],
				variationTrafficPercent: campaign.attributes?.["test-traffic"],
			});
		}
	}, [campaign]);

	return (
		<>
			{campaign && (
				<>
					<PageSection marginBottom="22px" paddingLeft="0.786rem">
						{duplicateCampaignId && (
							<SimpleText margin="0 0.786rem">
								This campaign was duplicated from{" "}
								<ReactLink
									href={generateSameDomainUrl(UrlTemplate.CMS_CAMPAIGN, {
										campaignId: duplicateCampaignId,
									})}
									fontWeight="600"
								>
									{duplicateCampaignId}
								</ReactLink>
							</SimpleText>
						)}
						{duplicateCampaignId && <SubsectionBreak empty />}
						{campaignMainState && campaign.attributes["test-type"] === "parent" ? (
							<ParentClassification
								key={campaign.id}
								state={campaignMainState}
								update={updateStateData}
								dispatch={dispatch}
								name={campaign?.attributes?.name}
								setSubpurposes={setSubpurposes}
								filteredOptions={filteredOptionsMap}
							/>
						) : (
							<VariationClassification
								key={campaign.id}
								state={campaignMainState}
								update={updateStateData}
								dispatch={dispatch}
								name={campaign?.attributes?.name}
								setName={(name) => dispatch(campaignSetName({ name }))}
								setSubpurposes={setSubpurposes}
								groupedTypes={groupByOptGroup(campaignTypes, "bouncex")}
							/>
						)}
						<SubsectionBreak />
						{campaignMainState && campaign.attributes["test-type"] === "parent" ? (
							<ParentSections
								campaign={campaign}
								campaignMainState={campaignMainState}
							/>
						) : (
							<VariationSections
								type={campaignMainState.variationTrafficType}
								percentage={campaignMainState.variationTrafficPercent}
								dispatch={dispatch}
							/>
						)}
					</PageSection>
				</>
			)}
		</>
	);
};

const ParentClassification = ({
	state,
	update,
	dispatch,
	name,
	setSubpurposes,
	filteredOptions,
}) => {
	return (
		<>
			{state.purposes && (
				<SubsectionContainer display="flex">
					<SectionTitle>Classification</SectionTitle>
					<Dropdown
						label="Purpose"
						field="purpose"
						value={state.selectedPurpose}
						options={state.purposes?.map(({ attributes: { name, id } }) => {
							return { name, value: id };
						})}
						defaultOption="Select Purpose..."
						onChange={(e) => {
							const id = e.purpose;
							dispatch(campaignSetPurpose({ purpose: id }));
							dispatch(campaignSetSubpurpose({ subpurpose: undefined }));
							update({
								selectedSubpurposes: setSubpurposes(filteredOptions[id]),
							});
						}}
						margin="0 0.786rem"
						minWidth="12.143rem"
						width="12.143rem"
					/>
					<Dropdown
						label="Subpurpose"
						field="subpurpose"
						options={state.selectedSubpurposes}
						value={state.selectedSubpurpose}
						defaultOption="Select Subpurpose..."
						onChange={(e) =>
							dispatch(campaignSetSubpurpose({ subpurpose: e.subpurpose }))
						}
						margin="0 0.786rem"
						minWidth="12.143rem"
						width="12.143rem"
					/>
					<InputText
						label="Name"
						value={name}
						setValue={(e) => dispatch(campaignSetName({ name: e.target.value }))}
						flexBasis="100%"
						margin="0 0.786rem"
					/>
				</SubsectionContainer>
			)}
		</>
	);
};

ParentClassification.propTypes = {
	state: PropTypes.object.isRequired,
	update: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	setSubpurposes: PropTypes.func.isRequired,
	filteredOptions: PropTypes.object.isRequired,
};

const ParentSections = ({ campaign, campaignMainState }) => {
	return (
		<>
			<SubsectionContainer display="flex">
				<SectionTitle>Client Editable</SectionTitle>
				{campaign?.attributes?.["client-editable"] != undefined && (
					<ToolTip
						text="Allows campaign to be edited by users with Client Editor permissions."
						position="bottom"
					>
						<CheckboxText>Can Edit Campaign</CheckboxText>
					</ToolTip>
				)}
			</SubsectionContainer>
			<SubsectionBreak />
			<SubsectionContainer display="flex">
				<SectionTitle style={{ marginRight: "2.14rem" }}>Compliance</SectionTitle>
				{/** Check is intentionally "!=" as the check is to see if value is
				 * null or undefined, but a value like 0 is fine.
				 */}
				{campaignMainState?.complianceOptions?.gdpr != undefined && (
					<ToolTip
						text="Allow this campaign to show for European traffic and European domains."
						position="bottom"
					>
						<CheckboxText>GDPR Compliant (Allow in Europe)</CheckboxText>
					</ToolTip>
				)}
				{campaignMainState?.complianceOptions?.casl != undefined && (
					<ToolTip
						text="Allow this campaign to show for Canadian traffic and Canadian domains."
						position="bottom"
					>
						<CheckboxText>CASL Compliant (Allow in Canada)</CheckboxText>
					</ToolTip>
				)}
				{campaignMainState?.complianceOptions?.gmp != undefined && (
					<ToolTip
						text={
							"If this campaign is considered non-intrusive per Google’s policies" +
							" on mobile interstitials and product listing ads, check this box to allow" +
							" all activations for Google Organic and PLA traffic. Otherwise, specific" +
							" activations (Custom, Interstitial, Mouse Click, Persistent, Rewind," +
							" Scroll Gesture, Timer < 90 seconds, Waypoints) will not apply for Overlay" +
							" and Liquid Screen experiences for this traffic."
						}
						position="bottom"
					>
						{/** I don't know if "gmp" is for this text, it's a guess. */}
						<CheckboxText>Non-Intrusive (Allow on Google Organic and PLA)</CheckboxText>
					</ToolTip>
				)}
				{campaignMainState?.complianceOptions?.["whitelist_check"] != undefined && (
					<ToolTip text="Whitelist" position="bottom">
						{/** I could not find this in ember, but it does exist so is included here. */}
						<CheckboxText>WhiteList</CheckboxText>
					</ToolTip>
				)}
			</SubsectionContainer>
		</>
	);
};

ParentSections.propTypes = {
	campaign: PropTypes.object.isRequired,
	campaignMainState: PropTypes.object.isRequired,
};

const VariationClassification = ({ state, dispatch, name, setName, groupedTypes }) => {
	return (
		<>
			{
				<SubsectionContainer display="flex">
					<SectionTitle>Classification</SectionTitle>
					<Dropdown
						label="Deployment Type"
						field="deployment_type"
						value={state.selectedDeployment}
						options={groupedTypes}
						onChange={(e) =>
							dispatch(campaignSetDeploymentType({ type: e.deployment_type }))
						}
						margin="0 0.786rem"
						minWidth="12.143rem"
						width="12.143rem"
					/>
					<InputText
						label="Name"
						value={name}
						setValue={(e) => setName(e.target.value)}
						flexBasis="100%"
						margin="0 0.786rem"
					/>
				</SubsectionContainer>
			}
		</>
	);
};

VariationClassification.propTypes = {
	state: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	setName: PropTypes.func.isRequired,
	groupedTypes: PropTypes.object.isRequired,
};

const VariationSections = ({ type, percentage, dispatch }) => {
	return (
		<SubsectionContainer display="flex">
			<SectionTitle style={{ marginRight: "4.0rem" }}>Traffic</SectionTitle>
			<Dropdown
				label="Traffic Type"
				field="traffic_type"
				value={type && type !== "parent" ? type : "variation"}
				options={[
					{ name: "Variation", value: "variation" },
					{ name: "Control", value: "control" },
				]}
				onChange={(e) => dispatch(campaignSetTrafficType({ type: e.traffic_type }))}
				margin="0 0.786rem"
				minWidth="12.143rem"
				width="12.143rem"
			/>
			<InputNumber
				label="Traffic %"
				value={percentage ?? 0}
				setValue={(e) =>
					dispatch(campaignSetTrafficPercentage({ percent: e.target.value }))
				}
				min="0"
				max="100"
				margin="0 0.785rem"
				width="7.143rem"
			/>
		</SubsectionContainer>
	);
};

VariationSections.propTypes = {
	percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	type: PropTypes.oneOf(["variation", "control", "parent", ""]),
	dispatch: PropTypes.func.isRequired,
};

CampaignEditMain.propTypes = {};

const CheckboxText = ({ children, margin }) => {
	return (
		<div style={{ margin: margin ?? "0 0.786rem", display: "flex" }}>
			<Checkbox />
			<SimpleText margin="0 0.429rem">{children}</SimpleText>
		</div>
	);
};

CheckboxText.propTypes = {
	children: PropTypes.node.isRequired,
	margin: PropTypes.string,
};

export default CampaignEditMain;
