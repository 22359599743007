import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";

export const getFonts = createAsyncThunk(
	"fonts/getFonts",
	async (params = {}) => {
		const { website_id } = params;
		const url = `${endpoints.coreApi}/fonts?website_id=${website_id}&include=none`;
		const response = await ApiService.get(url, params);

		if (!response.ok) {
			throw new Error("Failed to download fonts!");
		}

		return response.json();
	}
);

export const updateFonts = createAsyncThunk(
	"fonts/updateFonts",
	async (params = {}) => {
		const { value, font } = params;

		const url = `${endpoints.core}/fonts/${font.id}`;
		const response = await ApiService.patch(url, font);
		console.log(response);
		// if (!response.ok) {
		// 	throw new Error("Failed to load fonts!");
		// }

		// return response.json();
	}
)
