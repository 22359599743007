import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const EmptyRow = ({ children }) => (
	<Empty>{children}</Empty>
);

const Empty = styled.div`
	font-size: 12px;
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 20px;
	width: 100%;
	background-color: #fff;
	height: 42px;
	border: 1px solid rgb(232, 232, 232);
`;

EmptyRow.propTypes = {
	children: PropTypes.node.isRequired
};

export default EmptyRow;
