import React from "react";
import { PlusCircle } from "react-feather";
import { ICON_SIZES } from "../../../../../../constants/common";
import { Button } from "../../../../../../components";
import { Section } from "../../../../../../components/form";
import { AndStatementWrapper, Text } from "../sections/utils/Sections.styled";

const SendLimits = () => {
	const addSendLimitsData = () => console.log("test");

	return (
		<AndStatementWrapper>
			<Section title="Send Limits">
				<Text>For this series, send a maximum of:</Text>
			</Section>
			<Button theme="blue" onClick={() => addSendLimitsData}>
				{/* add inputs here*/}
				<PlusCircle size={ICON_SIZES.medium} />
				AND Statement
			</Button>
		</AndStatementWrapper>
	);
};

export default SendLimits;
