import { createEntityAdapter } from "@reduxjs/toolkit";

const inboxAdapter = createEntityAdapter({
	selectId: (inbox) => inbox.id,
});

export const {
	selectAll: selectAllInbox,
} = inboxAdapter.getSelectors((state) => state.inbox.response.data);

export default inboxAdapter;
