import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Title, Button } from "..";
import { BackFillModal } from "../modals";
import { reportIdMap, API_LIMIT } from "../../../../constants/common";
import { Pagination } from "../controls/components";
import { downloadCSV } from "../../../../redux/report/utils";

const EmailCaptureHeader = ({
	title = "-1",
	type = "",
	id = -1,
	date_start = "",
	date_end = "",
	total,
	loadMore,
	csvFileName,
	csvURL,
	website_id,
}) => {
	const idType = reportIdMap[type];
	const [showModal, toggleModal] = useState(false);
	const showBackFillModal = () => {
		toggleModal(true);
	};
	const download = () => {
		return downloadCSV(csvURL, csvFileName);
	};
	return (
		<div>
			<Link href="/websites">All Websites</Link>
			<Link href={`/reports?type=${type}&${idType}=${id}&website_id=${website_id}`}>
				{title} Website Analytics
			</Link>
			<Title
				title={`Email Capture for ${title}   ${formatDate(date_start)} to ${formatDate(
					date_end
				)} (${total})`}
			>
				<Padding />
				<Button onClick={download}>Export as CSV</Button>
				<Button onClick={showBackFillModal}>Resubmit Failed Captures</Button>
			</Title>
			<BackFillModal
				showModal={showModal}
				toggleModal={toggleModal}
				website_id={website_id}
				date_start={date_start}
				date_end={date_end}
			/>
			<Pagination
				limit={API_LIMIT}
				loadMore={loadMore}
				currentDataCount={total}
				style={{ top: "75px", mediaWidth: "1500px", mediaTop: "23px" }}
			/>
		</div>
	);
};

EmailCaptureHeader.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	total: PropTypes.number.isRequired,
	date_start: PropTypes.string.isRequired,
	date_end: PropTypes.string.isRequired,
	loadMore: PropTypes.func,
	csvFileName: PropTypes.string.isRequired,
	csvURL: PropTypes.string.isRequired,
	website_id: PropTypes.number.isRequired,
};
const Padding = styled.span`
	display: inline-block;
	min-width: 20px;
`;
const Link = styled.a`
	font-weight: bold;
	display: inline-block;
	margin: 0 10px;
`;

export default EmailCaptureHeader;

function formatDate(strDate) {
	const dateIncludesHours = strDate.indexOf("T") > 0;
	return dateIncludesHours
		? new Date(strDate).toLocaleDateString("en-US", { hour: "numeric", minute: "2-digit" })
		: strDate;
}
