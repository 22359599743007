import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../components";
import { useCreateLookerDashLink } from "../../../hooks";
import { duplicateCampaign } from "../../../redux/campaigns/thunks";

const RightInboxButtonsBlock = ({ toggleCampaignsCloneModal, toggleArchiveModal, refreshCampaigns }) => {	
	const dispatch = useDispatch();

	const { selectedCampaigns } = useSelector((state) => state.inbox);
	const isMultipleCampaigns = selectedCampaigns?.length > 1;
	const disabled = !selectedCampaigns?.length;
	const isSeries = selectedCampaigns?.type === "inbox_series";
	const hasArchivedCampaign = selectedCampaigns?.some(
		(campaign) => !campaign?.attributes?.active
	);
	const archiveCampaignButton = hasArchivedCampaign ? "Unarchive" : "Archive";
	const hasParentCampaign = selectedCampaigns?.some(
		(campaign) => campaign.attributes?.["test-type"] === "parent"
	);

	const duplicateCampaignThunk = async () => {
		toast.info("Campaign Duplicating...", { autoClose: 200 });

		const campaignId = selectedCampaigns[0].attributes.id;
		const response = await dispatch(
			duplicateCampaign({
				campaignId: campaignId,
			})
		);


		const { requestStatus } = response?.meta;
		if (requestStatus === "fulfilled") {
			toast.success("Campaign duplicated successfully", {
				autoClose: 1000,
			});
			refreshCampaigns();
		} else {
			toast.error("An error occurred", { autoClose: 1000 });
		}
	};

    let lookerDashLink;
    if(selectedCampaigns && selectedCampaigns.length > 0)
    {lookerDashLink = useCreateLookerDashLink(selectedCampaigns[0].attributes.id).lookerDashLink;}

	return (
		<ButtonsContainer>
			{!isMultipleCampaigns && (
				<Button
				onClick={() => duplicateCampaignThunk()}
				dataQA="duplicate-button"
					fullWidth
					theme={disabled ? "gray" : "blue"}
					disabled={disabled}
				>
					{`Duplicate ${isSeries ? "Series" : hasParentCampaign ? "Group" : "Variation"}`}
				</Button>
			)}

			<Button
				dataQA="archive-button"
				onClick={toggleArchiveModal}
				fullWidth
				theme={disabled ? "gray" : "blue"}
				disabled={disabled}
			>
				{archiveCampaignButton}
			</Button>

			{hasParentCampaign && !isMultipleCampaigns && (
                <Button
                dataQA="looker-dashboard-button"
                as="a"
                url={lookerDashLink}
                fullWidth
                theme="blue"
                disabled={ disabled || (lookerDashLink === null)}
            >
                3-in-1 Looker Dashboard
            </Button>
			)}

			<Button
				dataQA="cloning-button"
				onClick={toggleCampaignsCloneModal}
				fullWidth
				theme="blue"
			>
				Campaign Template Cloning Tool
			</Button>
		</ButtonsContainer>
	);
};

const ButtonsContainer = styled.div`
	& > *:not(:last-child) {
		margin-bottom: 11px;
	}
`;

RightInboxButtonsBlock.propTypes = {
	toggleCampaignsCloneModal: PropTypes.func.isRequired,
	toggleArchiveModal: PropTypes.func.isRequired,
	refreshCampaigns: PropTypes.func.isRequired,
};

export default RightInboxButtonsBlock;
