export default {

	/* inputs */

	genericText: {
		isInput: true,
		type: "text",
	},
	genericTextLarge: {
		isInput: true,
		type: "text",
		size: "l",
		sizeString: "l"
	},
	genericTextExtraLarge: {
		isInput: true,
		type: "text",
		size: "xl",
		sizeString: "xl"
	},
	genericTextFill: {
		isInput: true,
		type: "text",
		size: "fill",
		sizeString: "fill"
	},
	genericNumber: {
		isInput: true,
		type: "number",
		size: "s",
		sizeString: "s"
	},
	numberText: {
		isInput: true,
		type: "number",
	},
	dateTime: {
		isDateTime: true
	},

	/* selects */

	smallMediumYesNoyesNo: {
		isSelect: true,
		size: "sm",
		options: [
			{
				label: "Yes",
				value: "yes"
			},
			{
				label: "No",
				value: "no"
			}
		]
	},
	mediumYesNo: {
		isSelect: true,
		size: "m",
		options: [
			{
				label: "Yes",
				value: "yes"
			},
			{
				label: "No",
				value: "no"
			}
		]
	},
	equalNotEqual: {
		isSelect: true,
		options: [
			{
				label: "Equal",
				value: "equal"
			},
			{
				label: "Not equal",
				value: "not_equal"
			}
		]
	},
	sessionOrEver: {
		isSelect: true,
		options: [
			{
				label: "Ever",
				value: "ever"
			},
			{
				label: "This visit",
				value: "session"
			}
		]
	},
	visitOrPage: {
		isSelect: true,
		options: [
			{
				label: "This visit",
				value: "session"
			},
			{
				label: "This page",
				value: "page"
			}
		]
	},
	landingOrAnyPage: {
		isSelect: true,
		options: [
			{
				label: "Landing Page",
				value: "landing_page"
			},
			{
				label: "Any Page",
				value: "any_page"
			}
		]
	},
	exactlyNotExactly: {
		isSelect: true,
		options: [
			{
				label: "Is Exactly",
				value: "exactly"
			},
			{
				label: "Not Exactly",
				value: "not_exactly"
			}
		]
	},
	exactlyNotExactlyOrContainedIn: {
		isSelect: true,
		options: [
			{
				label: "Is Exactly",
				value: "exactly"
			},
			{
				label: "Not Exactly",
				value: "not_exactly"
			},
			{
				label: "Contained In",
				value: "contained_in"
			},
			{
				label: "Not Contained In",
				value: "not_contained_in"
			}
		]
	},
	exactlyOrContained: {
		isSelect: true,
		options: [
			{
				label: "Contains",
				value: "contains"
			},
			{
				label: "Does not contain",
				value: "not_contains"
			},
			{
				label: "Is Exactly",
				value: "exactly"
			},
			{
				label: "Not Exactly",
				value: "not_exactly"
			}
		]
	},
	containsNotContains: {
		isSelect: true,
		options: [
			{
				label: "Contains",
				value: "contains"
			},
			{
				label: "Does not contain",
				value: "not_contains"
			},
			{
				label: "Is Exactly",
				value: "exactly"
			},
			{
				label: "Not Exactly",
				value: "not_exactly"
			},
			{
				label: "Contained In",
				value: "contained_in"
			},
			{
				label: "Not Contained In",
				value: "not_contained_in"
			}
		]
	},
	lessGreaterEqual: {
		isSelect: true,
		options: [
			{
				label: "Less than",
				value: "less"
			},
			{
				label: "Less than or equal to",
				value: "less_equal"
			},
			{
				label: "Greater than",
				value: "greater"
			},
			{
				label: "Greater than or equal to",
				value: "greater_equal"
			},
			{
				label: "Equal to",
				value: "equal"
			},
			{
				label: "Not equal to",
				value: "not_equal"
			}
		]
	},
	lessGreaterEqualContains: {
		isSelect: true,
		options: [
			{
				label: "Less than",
				value: "less"
			},
			{
				label: "Less than or equal to",
				value: "less_equal"
			},
			{
				label: "Greater than",
				value: "greater"
			},
			{
				label: "Greater than or equal to",
				value: "greater_equal"
			},
			{
				label: "Equal to",
				value: "equal"
			},
			{
				label: "Not equal to",
				value: "not_equal"
			},
			{
				label: "Contains",
				value: "contains"
			},
			{
				label: "Does not contain",
				value: "not_contains"
			},
			{
				label: "Contained In",
				value: "contained_in"
			},
			{
				label: "Not Contained In",
				value: "not_contained_in"
			}
		]
	},
	campaignEvent: {
		isSelect: true,
		usesOptionGroups: true,
		optionGroups: [
			{
				label: "Impression",
				options: [
					{
						label: "Has seen",
						value: "seen"
					},
					{
						label: "Has not seen",
						value: "not_seen"
					}
				]
			},
			{
				label: "Click",
				options: [
					{
						label: "Has clicked",
						value: "clicked"
					},
					{
						label: "Has not clicked",
						value: "not_clicked"
					}
				]
			},
			{
				label: "Submit", // Formerly "Email"
				options: [
					{
						label: "Has submitted",
						value: "submitted"
					},
					{
						label: "Has not submitted",
						value: "not_submitted"
					}
				]
			},
			{
				label: "Close",
				options: [
					{
						label: "Has closed",
						value: "closed"
					},
					{
						label: "Has not closed",
						value: "not_closed"
					}
				]
			},
			{
				label: "Push Status",
				options: [
					{
						label: "Has opted-in",
						value: "push_opted-in"
					},
					{
						label: "Has blocked",
						value: "push_blocked"
					}
				]
			}
		]
	},
	dayOfTheWeek: {
		isSelect: true,
		options: [
			{
				label: "Monday",
				value: "Monday"
			},
			{
				label: "Tuesday",
				value: "Tuesday"
			},
			{
				label: "Wednesday",
				value: "Wednesday"
			},
			{
				label: "Thursday",
				value: "Thursday"
			},
			{
				label: "Friday",
				value: "Friday"
			},
			{
				label: "Saturday",
				value: "Saturday"
			},
			{
				label: "Sunday",
				value: "Sunday"
			}
		]
	},
	sessionLengthUnit: {
		isSelect: true,
		options: [
			{
				label: "seconds",
				value: "seconds"
			},
			{
				label: "minutes",
				value: "minutes"
			},
			{
				label: "hours",
				value: "hours"
			},
			{
				label: "days",
				value: "days"
			}
		]
	},
	historicalLengthUnit: {
		isSelect: true,
		options: [
			{
				label: 'hours',
				value: 'hours',
			},
			{
				label: 'days',
				value: 'days',
			},
		]
	},
	activationOffsetPage: {
		isSelect: true,
		options: [
			{
				label: "No landing",
				value: "no_landing"
			},
			{
				label: "Any page",
				value: "anypage"
			}
		]
	},
	closeRedirectType: {
		isSelect: true,
		options: [
			{
				label: "None",
				value: ""
			},
			{
				label: "Reload Campaigns",
				value: "reload"
			},
			{
				label: "New tab",
				value: "_blank"
			},
			{
				label: "Redirect",
				value: "_self"
			}
		]
	},
	tabTitleEffect: {
		isSelect: true,
		options: [
			{
				label: "None",
				value: ""
			},
			{
				label: "Flash Forever",
				value: "flashForever"
			},
			{
				label: "Scroll",
				value: "scroll"
			}
		]
	},
	lessGreaterEqualMatches: {
		isSelect: true,
		noDefault: true,
		options: [
			{
				label: "Less than",
				value: "less"
			},
			{
				label: "Less than or equal to",
				value: "less_equal"
			},
			{
				label: "Greater than",
				value: "greater"
			},
			{
				label: "Greater than or equal to",
				value: "greater_equal"
			},
			{
				label: "Equal to",
				value: "equal"
			},
			{
				label: "Not equal to",
				value: "not_equal"
			},
			{
				label: "Contains",
				value: "contains"
			},
			{
				label: "Does not contain",
				value: "not_contains"
			},
			{
				label: "Matches",
				value: "matches"
			},
			{
				label: "Does not match",
				value: "not_matches"
			}
		]
	},
};
