import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Sun } from "react-feather";
import { toast } from "react-toastify";
import { ICON_SIZES } from "../../../constants/common";
import { iconSettings } from "../constants";
import {
	Header,
	TrafficTypeSelector,
	IconBar,
	ToolTip,
	Button,
	HeaderIconBar,
} from "../../../components";
import { updateCampaignTrafficType, duplicateCampaign } from "../../../redux/campaigns/thunks";

/**
 * TODO: there is an error that pops up in the console related to this
 * component (but really having to do with a lib used deeper down). It
 * doesn't seem like there is anything that can be done about this
 * (see https://github.com/tvkhoa/react-tippy/issues/158) but probably
 * should be silenced if that's possible.
 */
const ThreeInOneButton = ({ campaignId }) => {
	// Create a 7 day range for the Looker dashboard
	let startDate = new Date();
	startDate.setDate(startDate.getDate() - 6);
	startDate = startDate.toISOString().split("T")[0];

	let endDate = new Date();
	endDate.setDate(endDate.getDate() + 1);
	endDate = endDate.toISOString().split("T")[0];

	const lookerDashLink = campaignId
		? `https://looker.bounceexchange.com/dashboards/607?Parent%20Campaign%20ID=${campaignId}&Date%20Range=${startDate}%20to%20${endDate}`
		: undefined;

	return (
		<ToolTip content="3-in-1 Look DashBoard" position="bottom">
			<Button
				as="a"
				width="70px"
				url={lookerDashLink}
				target="_blank"
				data-qa="three-in-one-link"
			>
				3-in-1
			</Button>
		</ToolTip>
	);
};

const DuplicateVariationButton = () => {
	const duplicateVariation = () => {
		console.log("Duplicate button pressed");
	};

	return (
		<Button width="200px" onClick={duplicateVariation} dataQa="duplicate-variation-button">
			Duplicate Current Variation
		</Button>
	);
};

const CampaignEditHeader = ({
	parentCampaign,
	testCampaignUrl,
	parentTrafficType,
	setParentTrafficType,
	getTrafficType,
	setSelectedTrafficType,
	refreshCampaign,
}) => {
	const { selectedCampaigns } = useSelector((state) => state.campaigns);
	const selectedCampaign = selectedCampaigns.length === 1 ? selectedCampaigns[0] : {};
	const campaignId = selectedCampaign?.id;

	const campaignType = selectedCampaign.attributes?.["test-type"];
	const isParent = campaignType === "parent";
	const [dirtyStatus, setDirtyStatus] = useState(false);
	const [trafficType, setTrafficType] = useState(getTrafficType(selectedCampaign));

	const dispatch = useDispatch();
	const { selectedWebsite } = useSelector((state) => state.websites);
	const websiteId = selectedWebsite?.id;

	const isArchived = !selectedCampaign?.attributes?.active;

	const isParentArchived = !isParent ? !parentCampaign?.attributes?.active : undefined;
	const isArchivedOrIsParentArchived = isArchived || isParentArchived;
	const parentId = parentCampaign.id;

	const testCampaignLink = `https://${testCampaignUrl}#bxtest${campaignId}#wkndtest`;

	useEffect(() => {
		const selectedTrafficType = getTrafficType(selectedCampaign);
		setTrafficType(selectedTrafficType);
	}, [selectedCampaign, campaignId]);

	const changeTrafficTypeHandler = (value) => {
		dispatch(
			updateCampaignTrafficType({
				archived: isArchived ? 1 : 0,
				ids: [campaignId],
				status: value,
				website_id: websiteId,
			})
		);
		if (isParent) {
			setParentTrafficType(value);
		}
		setSelectedTrafficType(value);
		setTrafficType(value);
	};

	const duplicateVariation = async () => {
		const response = await dispatch(
			duplicateCampaign({
				campaignId,
			})
		);

		toast.info("Duplication in progress...", { autoClose: 500 });

		const { requestStatus } = response?.meta;

		if (requestStatus === "fulfilled") {
			refreshCampaign();
			toast.success("Successfully Duplicated", {
				autoClose: 1000,
			});
		} else {
			toast.error("An error occurred", { autoClose: 1000 });
		}
	};

	const saveCampaign = () => {
		console.log("save campaign pressed");
		// setDirtyStatus(!dirtyStatus);
	};

	const createNewVariation = () => {
		console.log("create new variation button pressed");
	};

	const openLog = () => {
		console.log("log button clicked");
	};

	const Icons = iconSettings(websiteId, parentId, campaignId);

	return (
		<Header
			leftComponent={
				<>
					<Button width="auto" dataQa="save-campaign-button" onClick={saveCampaign}>
						Save{" "}
						{dirtyStatus ? (
							<Sun
								aria-label="campaign has changed since last save"
								size={ICON_SIZES.small}
							/>
						) : null}
					</Button>

					{!isParent && (
						<Button
							width="auto"
							onClick={duplicateVariation}
							dataQa="duplicate-variation-button"
						>
							Duplicate Current Variation
						</Button>
					)}

					<Button
						width="100px"
						dataQa="new-variation-button"
						onClick={createNewVariation}
					>
						New Variation
					</Button>
				</>
			}
			rightComponent={
				<>
					<HeaderIconBar
						icons={Icons}
						flag={campaignType}
						parentCampaignId={parentId}
						childCampaignId={campaignId}
						showCampaignLink
						testCampaignLink={testCampaignLink}
						showLog
						openLog={openLog}
						showDashLink={isParent}
					/>

					<TrafficTypeSelector
						value={isArchivedOrIsParentArchived ? undefined : trafficType}
						parentTrafficType={parentTrafficType}
						onChange={changeTrafficTypeHandler}
					/>
				</>
			}
		/>
	);
};

CampaignEditHeader.propTypes = {
	parentCampaign: PropTypes.object,
	testCampaignUrl: PropTypes.string,
	selectedTrafficType: PropTypes.string.isRequired,
	setSelectedTrafficType: PropTypes.func.isRequired,
	parentTrafficType: PropTypes.string,
	setParentTrafficType: PropTypes.func.isRequired,
	getTrafficType: PropTypes.func.isRequired,
};

ThreeInOneButton.propTypes = {
	campaignId: PropTypes.string,
};

export default CampaignEditHeader;
