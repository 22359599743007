import { createAsyncThunk } from "@reduxjs/toolkit";
import fetch from "isomorphic-fetch";

import { endpoints } from "../../constants/common";
import { LocalStorageService } from "../../service/localStorage";

export const getUser = createAsyncThunk(
	"user/getUser",
	async () => {
		const token = LocalStorageService.getToken();
		const url = `${endpoints.coreApi}/roles`;
		const response = await fetch(url, {
			method: "GET",
			cache: "no-cache",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.json();
	}
);
