export const WEBSITES_URL = "/website_micros";

export const WEBSITE_URL_PARAMS = {
	contract_id: "",
	full_load: "",
	industry_id: "",
	limit: "",
	module: "",
	page: "",
	q: "",
	show: "",
	vertical_id: "",
};

export const WEBSITE_MODULES_FILTER_OPTIONS = [
	{ id: "", name: "All Modules" },
	{ id: "cms", name: "CMS" },
	{ id: "inbox", name: "Inbox" },
	{ id: "yield", name: "Publisher Yield" },
];
