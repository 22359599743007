import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Zap, Activity, Key, Download } from "react-feather";

import { useCreateLookerDashLink } from "../../hooks";
import ToolTip from "../ui/ToolTip";
import { ICON_SIZES } from "../../constants/common";

const HeaderIconBar = ({
	icons,
	flag,
	parentCampaignId,
	childCampaignId,
	showCampaignLink,
	testCampaignLink,
	showLog,
	openLog,
	showDashLink,
	showDownloadCampaigns,
	setShowDownloadCampaignPreview,
}) => {
	const { lookerDashLink } = useCreateLookerDashLink(childCampaignId);

	return (
		<div data-qa="header-icon-bar">
			{showDashLink && (
				<SettingsLink href={lookerDashLink} target="_blank">
					<ToolTip position="bottom" text="3-in-1 Looker Dashboard">
						<Key size={ICON_SIZES.medium} />
					</ToolTip>
				</SettingsLink>
			)}
			{showLog && (
				<SettingsLink as="button" onClick={openLog}>
					<ToolTip position="bottom" text="Activity Log">
						<Activity size={ICON_SIZES.medium} />
					</ToolTip>
				</SettingsLink>
			)}
			{showDownloadCampaigns && (
				<SettingsLink
					as="button"
					onClick={() => setShowDownloadCampaignPreview(true)}
					data-qa="download-campaigns-previews-button"
				>
					<ToolTip position="bottom" text="Download Campaigns Previews">
						<Download size={ICON_SIZES.medium} />
					</ToolTip>
				</SettingsLink>
			)}
			{icons.map(({ link, icon, name, dataQA, as }) => {
				const Icon = icon;
				const url = link(flag, parentCampaignId, childCampaignId);
				return (
					<SettingsLink
						as={as}
						target="_blank"
						rel="noreferrer"
						href={url}
						key={name}
						data-qa={dataQA}
					>
						<ToolTip position="bottom" text={name}>
							<Icon />
						</ToolTip>
					</SettingsLink>
				);
			})}
			{showCampaignLink && (
				<SettingsLink width="70px" href={testCampaignLink} as="a">
					<ToolTip position="bottom" text="Test Link">
						<Zap aria-label="test campaign link" size={ICON_SIZES.medium} />
						<span>{childCampaignId}</span>
					</ToolTip>
				</SettingsLink>
			)}
		</div>
	);
};

const SettingsLink = styled.a`
	margin: 0px 10px;
	padding: 0px;
	background: none;
	border: 0;
	width: ${(props) => (props.width ? props.width : "auto")};
	&:last-child {
		margin: 0 0 0 10px;
	}
	i,
	svg {
		color: #191919;
		&:hover {
			color: #fff;
		}
		&:hover + span {
			color: #fff;
		}
	}

	span {
		font-size: 11px;
		display: inline-block;
		margin: 0 0 0 10px;
		position: relative;
		top: -3px;
	}
`;

HeaderIconBar.propTypes = {
	icons: PropTypes.array.isRequired,
	flag: PropTypes.oneOf(["website", "parent", "variation", ""]).isRequired,
	parentCampaignId: PropTypes.string,
	childCampaignId: PropTypes.string,
	showCampaignLink: PropTypes.bool,
	testCampaignLink: PropTypes.string,
	showLog: PropTypes.bool,
	openLog: PropTypes.func,
	showDashLink: PropTypes.bool,
	showDownloadCampaigns: PropTypes.bool,
	setShowDownloadCampaignPreview: PropTypes.func,
};

export default HeaderIconBar;
