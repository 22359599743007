import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import { CAMPAIGNS_URL_TYPES } from "./constants";

export const getCampaignTypes = createAsyncThunk(
	"campaigns/getCampaignTypes",
	async (params = {}) => {
		const url = `${endpoints.coreApi}${CAMPAIGNS_URL_TYPES}`;
		const response = await ApiService.get(url, params);

		if (!response.ok) {
			throw new Error("Failed to load campaign types!");
		}

		return response.json();
	}
);
