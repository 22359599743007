import React from "react";
import styled from "styled-components";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { ContentContainer, Triangle, Content } from "./Design.styled";

const variableContent = (variable) => (
        <Variable key={variable} data-for={variable}>{variable}</Variable>
)

const NoVariablesConfigured = () => {
    return (
        <NoVariables>
            <NoVariablesText>
                No Variables Configured
            </NoVariablesText>
        </NoVariables>
    )
}

const LiquidVariablesContent = ({variables, open}) => {
    if(!open || !variables) { return null }

    const variableKeys = Object.keys(JSON.parse(variables))

    return (
        <ContentContainer left="145px">
            <Triangle>
                    {isEmpty(variableKeys) ?
                        <NoVariablesConfigured /> :
                    <Content>
                        <Container id="liquid-variables-tooltip-content">
                            {variableKeys.map((variable) => variableContent(variable))}
                        </Container>
                    </Content>
                }
            </Triangle>
        </ContentContainer>

    )
}

const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: #ffffff;
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
    font-weight: 100;
    font-size: 0.786rem;
    letter-spacing: .05em;
    line-height: 1.35;
`

const Variable = styled.span`
    margin: 0;
    font-size: 0.857rem;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 12px;
    width: 150px;
`

const NoVariables = styled.div`
    max-width: 760px;
    pointer-events: auto;
    z-index: 99;
    height: 66.8125px;
    width: 173.453px;
    animation-duration: 350ms;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #5b7489;
    position: absolute;
    margin-top: 10px;
    left: 290px;
`;

const NoVariablesText = styled.span`
    color: #ffffff;
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
    font-weight: 100;
    font-size: 0.786rem;
    letter-spacing: .05em;
    line-height: 1.35;
`


LiquidVariablesContent.propTypes = {
	variables: PropTypes.string,
	open: PropTypes.bool.isRequired,
};


export default LiquidVariablesContent;