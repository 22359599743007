//Anaytics 6 endpoint
import { createConversionSummaryData } from "../../businessLogic";
import reportsAdapter from "../reportsAdapter";
import { selectAllReports } from "../reportsAdapter";

export const loadConversionData = (state, action) => {
	const { payload } = action;
	const loadedReports = selectAllReports({report: state});
	const summary = createConversionSummaryData([...loadedReports, ...payload.data]);
	const csvFileName = `${payload.website.name}_${state.reportOptions.date_start}_${state.reportOptions.date_end}`;

	// update State
	state.title = payload?.website?.name;
	state.makeApiCall = false;
	state.summary = summary;
	state.csvFileName = csvFileName;
	state.next_cursor = payload.next_cursor;
	reportsAdapter.addMany(state.data, payload.data);
};
