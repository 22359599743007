import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { List, Edit, Copy, Trash2, ChevronDown, ChevronLeft } from "react-feather";
import { ICON_SIZES } from "../../../../constants/common";
import { ESPProfileCampaignsModal, ESPProfileForm, FormActions } from "../../components";
import { ToolTip, PopupModal, Button } from "../../../../components";
import { useEspProfileActions } from "../../../../hooks/esp-profiles/useEspProfileActions";
import { selectFormattedEspProfileCampaigns } from "../../../../redux/espProfiles/selectors";
import { prettyDate } from "../../../../components/utils/prettyDate";

const ESPSettingsRow = ({ formattedProfile }) => {
	const { dtEdited, id, name, websiteId, editedByName } = formattedProfile || {};

	const [formAction, setFormAction] = useState('');
	const [showESPProfileCampaigns, setShowESPProfileCampaigns] = useState(false);
	const { data: campaigns, isLoading } = useSelector(selectFormattedEspProfileCampaigns(id));
	const {
		deleteEspProfile,
		getEspProfiles,
		getEsps,
		updateEspProfile,
		cloneEspProfile,
		getCampaignsByProfile,
	} = useEspProfileActions(websiteId);

	useEffect(() => {
		getCampaignsByProfile(id);
	}, []);

	const handleDeleteProfile = async () => {
		deleteEspProfile(id, formattedProfile, {
			onFulfilled: () => {
				getEspProfiles();
				getEsps();
			},
		});
	};

	const handleEspProfileFormSubmit = (profileDto) => {
		const { id } = profileDto;

		if (formAction === FormActions.EDIT) {
			updateEspProfile(id, profileDto, {
				onFulfilled: () => {
					setFormAction('');
					getEspProfiles();
					getEsps();
				},
			});
		} else if (formAction === FormActions.CLONE) {
			cloneEspProfile(profileDto, {
				onFulfilled: () => {
					setFormAction('');
					getEspProfiles();
					getEsps();
				},
			});
		}
	};

	const showEspForm = formAction === FormActions.CLONE;
	const showEspDelete = formAction === FormActions.DELETE;

	return (
		<>
			<PopupModal
				id={id}
                showModal={showEspForm}
                toggleModal={() => setFormAction('')}>
					<ESPProfileForm
						formattedProfile={formattedProfile}
						closeForm={() => setFormAction('')}
						formAction={formAction}
						onSubmit={(profileDto) => {
							handleEspProfileFormSubmit(profileDto);
						}}
					/>
			</PopupModal>

			{/* delete modal */}
			<PopupModal
				showModal={showEspDelete}
				toggleModal={() => setFormAction('')}
			>
				<Centered>
					<DeletePrompt>
						Are you sure you want to delete this Onsite ESP Profile?
					</DeletePrompt>
					<ProfileToDelete>{name}</ProfileToDelete>
					<Button
						data-qa="delete-esp-button"
						onClick={handleDeleteProfile}
						theme="blue"
					>
						Delete Profile
					</Button>
				</Centered>
			</PopupModal>

			{/* associated campaign modal */}
			<PopupModal
				showModal={showESPProfileCampaigns}
				toggleModal={() => setShowESPProfileCampaigns(false)}
			>
				<ESPProfileCampaignsModal
					websiteId={websiteId}
					profileName={name}
					toggleModal={() => setShowESPProfileCampaigns(false)}
					id={id}
				/>
			</PopupModal>

			{/* Row Content */}
			<ESPSettingsRowContainer>
				<ProfileName>
					<ToolTip text="Profile Name" position="bottom">
						<span>{name}</span>
					</ToolTip>
				</ProfileName>

				<DateWrapper>
					<ToolTip text="Last Edit Date" position="bottom">
						Edited
						{editedByName && ` by ${editedByName}`}
						&nbsp;
						{prettyDate([dtEdited])}
					</ToolTip>
				</DateWrapper>

				<CountWrapper>
					<ToolTip text="# of associated campaigns" position="bottom">
						{(campaigns || []).filter((c) => c.parent).filter((campaign) => campaign.emailCaptureEspProfileID === id).length}
					</ToolTip>
				</CountWrapper>

				<IconWrapper>
					<ToolTip text="Campaigns" position="bottom">
						<List size={ICON_SIZES.medium} onClick={() => setShowESPProfileCampaigns(true)} />
					</ToolTip>
				</IconWrapper>

				<IconWrapper>
					<ToolTip text="Clone" position="bottom">
						<Copy
							onClick={() => setFormAction(FormActions.CLONE)}
							size={ICON_SIZES.medium}/>
					</ToolTip>
				</IconWrapper>

				<IconWrapper onClick={() => setFormAction(FormActions.DELETE)}>
					<ToolTip text="Delete" position="bottom" >
						<Trash2 size={ICON_SIZES.medium} />
					</ToolTip>
				</IconWrapper>

				<IconWrapper>
					<ToolTip text="Edit" position="bottom">
						{formAction === FormActions.EDIT ? (
							<ChevronDown
								onClick={() => setFormAction('')}
								size={ICON_SIZES.medium}/>
						): (
							<ChevronLeft
								onClick={() => setFormAction(FormActions.EDIT)}
								size={ICON_SIZES.medium}/>
						)}
					</ToolTip>
				</IconWrapper>

			</ESPSettingsRowContainer>

			{formAction === FormActions.EDIT && (
					<ESPProfileForm
						formattedProfile={formattedProfile}
						closeForm={() => setFormAction('')}
						formAction={formAction}
						onSubmit={(profileDto) => {
							handleEspProfileFormSubmit(profileDto);
						}}
					/>
			)}
		</>
	);
};

ESPSettingsRow.propTypes = {
	profile: PropTypes.shape({}),
	formattedProfile: PropTypes.shape({}),
};

const ESPSettingsRowContainer = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	min-height: 50px;
	line-height: 14px;
	padding: 0 11px;
	border: .5px solid;
	border-color: ${({ selected }) =>
		selected ? "rgb(48, 61, 120)" : "rgb(232, 232, 232)"};
	z-index: ${({ selected }) => (selected ? "2" : "unset")};
	background-color: white;
	margin: -1px 0 -1px 0;
	&:hover {
		border: .5px solid rgb(48, 61, 120);
		z-index: 2;
	}
`;

const ProfileName = styled.div`
	display: flex;
	align-items: center;
	width: 222px;
	margin: 0 11px;
	font-size: 12px;
	font-weight: 600;
`;

const IconWrapper = styled.div`
	margin: 0 6px;
	font-size: 12px;
	text-align: right;
	cursor: pointer;
`;

const CountWrapper = styled.div`
	margin: 0 25px;
	font-size: 12px;
	text-align: right;
`;

const DateWrapper = styled.div`
	min-width: 8vw;
	margin: 0 6px;
	font-size: 12px;
	font-weight: 400;
	text-align: right;
	margin-left: auto;
`;

const DeletePrompt = styled.p`
	margin-top: 45px;
	margin-bottom: 25px;
`;

const Centered = styled.div`
	text-align: center;
`;

const ProfileToDelete = styled.p`
	margin-bottom: 35px;
	font-size: 16px;
	color: red;
`;

export default ESPSettingsRow;
