export const CAMPAIGNS_URL = "/campaigns";

export const BLANK_CAMPAIGN_DATA = {
    "data": {
        "attributes": {
            "creative-timestamps": null,
            "osr-params-json": "",
            "push-optin-json": null,
            "trigger-offsite-json": null,
            "active": true,
            "campaign-series-id": 0,
            "clean-test": false,
            "client-editable": false,
            "dt-edited": "2018-09-28 19:45:40",
            "dt-enabled": "0000-00-00 00:00:00",
            "dt-inserted": "2013-11-11 17:28:39",
            "enabled": false,
            "notes-saved-at": null,
            "notes": "",
            "optimization-post-live-checked": 0,
            "order": 0,
            "product": "bouncex",
            "test-mode": false,
            "wip-mode": false,
            "test-traffic": "50",
            "test-type": "parent",
            "website-id": null,
            "tags": "\"null\"",
            "activation-delay": 0,
            "activation-offset": 0,
            "activation": "[]",
            "activation-realtime": "[]",
            "ad-auto-close-submission": 0,
            "ad-auto-close": 0,
            "ad-submission-redirect": "",
            "ad-submission-target": "",
            "alerts": 0,
            "ally-title": null,
            "background-color": "0",
            "background-opacity": 0,
            "bg-path": "0",
            "bg-type": "0",
            "blur-gate-enabled": 0,
            "blur-gate-exclusions": "",
            "blur-gate-inclusions": "",
            "callout": null,
            "callout-anchor-pos": "lm",
            "callout-genie-html": "",
            "callout-height": 100,
            "callout-height-unit": "%",
            "callout-hoffset": 0,
            "callout-placement-target": null,
            "callout-pos": "rm",
            "callout-target": "",
            "callout-type": "none",
            "callout-voffset": 0,
            "callout-width": 100,
            "callout-width-unit": "%",
            "campaign-series-stage-id": 0,
            "close-button-delay": 0,
            "close-button-style": "classic",
            "close-position": "0",
            "close-redirect-type": "0",
            "close-redirect-url": "0",
            "close-zoom": 0,
            "closed-no-show": 0,
            "compliance-json": "{\"gdpr\":0,\"gmp\":0,\"casl\":0}",
            "creative-json": "{\"steps\":[]}",
            "custom-css": "0",
            "custom-js-website": "0",
            "custom-js": "0",
            "custom-tab-title": "{\"title\":null,\"favicon_url\":null,\"favicon_type\":\"\",\"effect\":null}",
            "deployment": "{\"0\":{\"0\":{\"activation\":\"default\",\"prop\":\"\",\"prop2\":\"\",\"prop3\":\"\",\"val\":\"\"}}}",
            "device-matrix": "{\"platforms\":{\"desktop\":\"all\",\"phone\":\"none\",\"tablet\":\"none\",\"os\":\"all\",\"browser\":\"all\"},\"exceptions\":[]}",
            "dom-placement-method": "",
            "esp-data": null,
            "esp-dont-wait": 0,
            "esp-submit-uncompleted": 0,
            "event-js": "\"\"",
            "form-transition-effect": "",
            "ga-integration": null,
            "gbi-provider-website-integration-json": "",
            "header-bottom-alignment": "center",
            "header-bottom-genie-html": "",
            "header-bottom-height-unit": "px",
            "header-bottom-height": 90,
            "header-bottom-nano-animation": "anypage",
            "header-bottom-nano-bg": 0,
            "header-bottom-nano": 0,
            "header-bottom-type": "none",
            "header-bottom-valignment": "bottom",
            "header-bottom-width-unit": "%",
            "header-bottom-width": 100,
            "header-bottom": null,
            "header-top-alignment": "center",
            "header-top-genie-html": "",
            "header-top-height-unit": "px",
            "header-top-height": 90,
            "header-top-nano-animation": "none",
            "header-top-nano-bg": 1,
            "header-top-nano": 0,
            "header-top-type": "none",
            "header-top-valignment": "top",
            "header-top-width-unit": "%",
            "header-top-width": 100,
            "header-top": null,
            "header-type": null,
            "hide-powered": false,
            "ignore-activation-offset-page": "0",
            "ignore-activation-offset": 0,
            "ignore-previous-captures": 0,
            "max-activations-overall": 0,
            "max-activations-pageview": 0,
            "max-activations-session": 0,
            "new-genie-enabled": 1,
            "new-genie-json": "{\"steps\":[]}",
            "no-genie-style": 0,
            "not-in-global-control": 0,
            "overlay-click-to-close": 0,
            "overlay-custom-html": null,
            "overlay-email-html": null,
            "overlay-genie-html": "",
            "overlay-height-unit": "px",
            "overlay-height": 400,
            "overlay-iframe": 0,
            "overlay-recommendation-html": null,
            "overlay-teleport-html": "",
            "overlay-teleport-type": "_blank",
            "overlay-type": null,
            "overlay-width-unit": "px",
            "overlay-width": 800,
            "preview-url": "",
            "purchase-no-show": 0,
            "repress-bottom": 0,
            "repress-overlay": 0,
            "repress-top": 0,
            "right-activation-offset-page": "0",
            "right-activation-offset": 0,
            "session-length-unit": "0",
            "session-length": 0,
            "show-close-step": 0,
            "show-close": 0,
            "shroud-on-hover": 0,
            "strategy-json": "[]",
            "submission-redirect-delay": 2,
            "submission-redirect": null,
            "supress-bottom": 0,
            "supress-overlay": 0,
            "supress-top": 0,
            "top-vertical-activation-offset-page": "0",
            "top-vertical-activation-offset": "0",
            "transition-effect-speed": 0,
            "transition-effect-subsequent": "no_effect",
            "transition-effect": "0",
            "z-index": null,
            "clone-campaign-id": null,
            "master-campaign-id": null,
            "edited-by-name": null,
            "is-leader": false,
            "series-name": null,
            "test-mode-email": null
        },
        "relationships": {
            "brand-style": {
                "data": null
            },
            "creatives": {
                "data": []
            },
            "esp": {
                "data": null
            },
            "leader": {
                "data": null
            },
            "parent": {
                "data": null
            },
            "campaign-series": {
                "data": null
            },
            "gbi-series": {
                "data": null
            },
            "linked-campaigns": {
                "data": []
            },
            "campaign-subtype": {
                "data": null
            },
            "gbi-provider-website": {
                "data": null
            },
            "campaign-type": {
                "data": null
            },
        },
        "type": "campaigns"
    }
}
