import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value; //assign the value of ref to the argument
	}, [value]); //this code will run when the value of 'value' changes
	return ref.current; //in the end, return the current ref value.
};

usePrevious.propTypes = {
	value: PropTypes.any,
};
