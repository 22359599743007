import { createSlice } from "@reduxjs/toolkit";
import { getVerticals } from "./thunks";

const initialState = {
	isFetchError: false,
	response: {
		data: [],
	},
};

const verticalsSlice = createSlice({
	name: "verticals",
	initialState,
	reducers: {
		clearVerticalsApiError(state) {
			state.isFetchError = false;
		}
	},
	extraReducers: (builder) => {
		// getVerticals
		builder.addCase(getVerticals.fulfilled, (state, action) => {
			state.response = action.payload;
		});
		builder.addCase(getVerticals.rejected, (state) => {
			state.isFetchError = true;
		});
	},
});

export const {
	clearVerticalsApiError,
} = verticalsSlice.actions;

export default verticalsSlice.reducer;
