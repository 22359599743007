import { createSlice } from "@reduxjs/toolkit";
import { PAGE_MAP } from "./constants";

const initialState = {
	params: {},
	page: "",
	isLoading: true,
};

const pageSlice = createSlice({
	name: "page",
	initialState,
	reducers: {
		goToUsers(state, action) {
			state.page = PAGE_MAP.USERS;
			state.params = action.payload;
		},
		goToReport(state, action) {
			state.page = PAGE_MAP.REPORTS;
			state.params = action.payload;
		},
		removeReactApp(state) {
			state.page = PAGE_MAP.EXIT;
			state.params = {};
		},
		goToTools(state, action) {
			state.page = PAGE_MAP.TOOLS;
			state.params = action.payload;
		},
		goToEsps(state, action) {
			state.page = PAGE_MAP.ESPS;
			state.params = action.payload;
		},
		goToEsp(state, action) {
			state.page = PAGE_MAP.ESP;
			state.params = action.payload;
		},
		goToUploads(state, action) {
			state.page = PAGE_MAP.UPLOADS;
			state.params = action.payload;
		},
		goToTopStats(state, action) {
			state.page = PAGE_MAP.TOP_STATS;
			state.params = action.payload;
		},
		goToWebsites(state, action) {
			state.page = PAGE_MAP.WEBSITES;
			state.params = action.payload;
		},
		goToWebsitesSettings(state, action) {
			state.page = PAGE_MAP.WEBSITES_SETTINGS;
			state.params = action.payload;
		},
		goToInboxSeries(state, action) {
			state.page = PAGE_MAP.INBOX_SERIES;
			state.params = action.payload;
		},
		goToInboxSeriesEdit(state, action) {
			state.page = PAGE_MAP.INBOX_SERIES_EDIT;
			state.params = action.payload;
		},
		goToInboxCampaignEdit(state, action) {
			state.page = PAGE_MAP.INBOX_CAMPAIGN_EDIT;
			state.params = action.payload;
		},
		goToInboxCampaignDesign(state, action) {
			state.page = PAGE_MAP.INBOX_CAMPAIGN_DESIGN;
			state.params = action.payload;
		},
		goToCMSSeries(state, action) {
			state.page = PAGE_MAP.CMS_SERIES;
			state.params = action.payload;
		},
		goToCMSSeriesEdit(state, action) {
			state.page = PAGE_MAP.CMS_SERIES_EDIT;
			state.params = action.payload;
		},
		goToCMSCampaignEdit(state, action) {
			state.page = PAGE_MAP.CMS_CAMPAIGN_EDIT;
			state.params = action.payload;
		},
		goToCMSCampaignDesign(state, action) {
			state.page = PAGE_MAP.CMS_CAMPAIGN_DESIGN;
			state.params = action.payload;
		},
		goToCustomerRegistration(state, action) {
			state.page = PAGE_MAP.CUSTOMER_REGISTRATION;
			state.params = action.payload;
		},
		goToFonts(state, action) {
			state.page = PAGE_MAP.FONTS;
			state.params = action.payload;
		},
		loadingScreen(state, action) {
			state.isLoading = action.payload;
		},
		setPageParams(state, action) {
			state.params = action.payload;
		},
		goToSignOut(state, action) {
			state.page = PAGE_MAP.SIGNOUT;
			state.params = action.payload;
		},
		goToESPProfiles(state, action) {
			state.page = PAGE_MAP.ESP_PROFILES;
			state.params = action.payload;
		}
	},
});

export const {
	goToUsers,
	goToReport,
	removeReactApp,
	goToTools,
	goToEsps,
	goToEsp,
	goToUploads,
	goToTopStats,
	goToWebsites,
	loadingScreen,
	goToInboxSeries,
	goToInboxSeriesEdit,
	goToInboxCampaignEdit,
	goToInboxCampaignDesign,
	goToCustomerRegistration,
	goToCMSSeries,
	goToCMSSeriesEdit,
	goToCMSCampaignEdit,
	goToCMSCampaignDesign,
	goToFonts,
	goToWebsitesSettings,
	setPageParams,
	goToSignOut,
	goToESPProfiles,
} = pageSlice.actions;

export default pageSlice.reducer;
