function isBatch2(id) {
	const batch2WebsiteIDs = ["5429", "4736", "4997", "3895", "4501", "4868", "5218", "1986"];
	return batch2WebsiteIDs.includes(id)
}

/**
 * Available feature flags with their default value
 */
const AvailableFeatureFlags = {
	ShopifyPixelV2: false,
	ESPProfiles: true,
	InboxSeriesOnDemand: false,
	OnsiteIntegration: false,
};

let currentEnvironment = '';

/**
 * Verifies if the feature flag is enabled. An optional second environment
 * parameter allows toggling a feature flag in specific environments only.
 *
 * @param {string} name
 * @param {string} environment
 *
 * @returns {boolean}
 */
function isEnabled(name, environment = '') {
	if (
		window &&
		window.sessionStorage &&
		window.sessionStorage.getItem(name) === 'true'
	) {
		return true;
	}

	if (environment != '') {
		currentEnvironment = environment
	}

	switch (currentEnvironment) {
		case 'development':
			// Feature flags can be toggled per environment when
			// ENV.REACT_APP_ENVIRONMENT is passed to isEnabled, e.g.:
			// AvailableFeatureFlags['featureFlagIdentifier'] = true;
			break;
		case 'qa':
			break;
		case 'staging':
			break;
		case 'production':
			break;
	}

	return AvailableFeatureFlags[name];
}

export default {
	isEnabled,
	AvailableFeatureFlags,
	isBatch2
}
