import React from "react";
import styled from "styled-components";
import { ContentContainer, Triangle, Content } from "./Design.styled";
import PropTypes from "prop-types";

const EmailReference = ({open}) => {
	if(!open) { return null }

	return (
		<ContentContainer left="30px">
			<Triangle>
				<Content>
					<Container>
						<Container id="email-reference-tooltip-content">
							<Flex>
								<FullWidth>
									<TopSection>
										<Header>Email Reference</Header>
									</TopSection>
								</FullWidth>
								<div>
									<Section>
										<Row>
											<Header>Custom Variables</Header>
										</Row>
										<Row>
											<Code>&#123;&#123; .ToEmail &#125;&#125;</Code>
										</Row>
										<Row>
											<SubHeader>You can pipe into hash functions like this:</SubHeader>
										</Row>
										<IndentedRow>
											<Code>&#123;&#123; .ToEmail | md5 &#125;&#125;</Code>
										</IndentedRow>
										<IndentedRow>
											<Code>&#123;&#123; .ToEmail | sha1 &#125;&#125;</Code>
										</IndentedRow>
										<IndentedRow>
											<Code>&#123;&#123; .ToEmail | sha256 &#125;&#125;</Code>
										</IndentedRow>
										<Row>
											<Code>&#123;&#123; .CouponXyz &#125;&#125;</Code>
										</Row>
										<Row>
											<Code>&#123;&#123; .Timestamp &#125;&#125;</Code>
										</Row>
										<Row>
											<Code>&#123;&#123; .Subject &#125;&#125;</Code>
										</Row>
										<Row>
											<Code>&#123;&#123; .Preheader &#125;&#125;</Code>
										</Row>
									</Section>
									<Section>
										<Row>
											<Header>Trimming strings</Header>
										</Row>
										<Row>
											<SubHeader>Trim a string to exactly 20 characters:</SubHeader>
										</Row>
										<IndentedRow>
											<Code>&#123;&#123; substring .Preheader 0 20 &#125;&#125;</Code>
										</IndentedRow>
										<Row>
											<SubHeader>Trim a string to 20 characters or less, respecting word boundaries:</SubHeader>
										</Row>
										<IndentedRow>
											<Code>&#123;&#123; wordtrim .Preheader 0 20 &#125;&#125;</Code>
										</IndentedRow>
									</Section>
									<Section>
										<Row>
											<Header>Custom Links</Header>
										</Row>
										<Row>
											<Code>&#123;&#123; .IbxUnsubscribe &#125;&#125;</Code>
										</Row>
										<Row>
											<Code>&#123;&#123; .IbxUnsubscribeEsp &#125;&#125;</Code>
										</Row>
										<Row>
											<Code>&#123;&#123; .IbxWebview &#125;&#125;</Code>
										</Row>
										<Row>
											<SubHeader>Add the class <Code>ibx_no_webview</Code> to any HTML elements which you do not want displayed in the web view of this email.</SubHeader>
										</Row>
										<IndentedRow>
											<Code>&#123;&#123; .IbxOpen &#125;&#125;</Code>
										</IndentedRow>
									</Section>
									<Section>
										<Row>
											<Header>Comments</Header>
										</Row>
										<Row>
											<SubHeader>Standard HTML comments are removed when the email is rendered. Conditional comments are preserved.</SubHeader>
										</Row>
										<Row>
											<Code>&lt;!— i will be removed —&gt;</Code>
										</Row>
										<Row>
											<Code>&lt;!—[i will be preserved]—&gt;</Code>
										</Row>
									</Section>
								</div>
								<RightSideContainer>
									<RightSection>
										<Row>
											<Header>Iterating Through Items</Header>
										</Row>
										<Row>
											<Pre>
												<Code>
													&#123;&#123; range .Items &#125;&#125;<br/>
													<IndentedCode>
														&#9;&lt;a href="&#123;&#123; .url &#125;&#125;"&gt;&lt;img src="&#123;&#123; .creative &#125;&#125;"/&gt;&lt;/a&gt;<br/>
														&#9;&lt;a href="&#123;&#123; .url &#125;&#125;"&gt;&#123;&#123; .copy &#125;&#125;&lt;/a&gt;<br/>
														&#9;&lt;a href="&#123;&#123; .url &#125;&#125;"&gt;&#123;&#123; .subcopy &#125;&#125;&lt;/a&gt;<br/>
													</IndentedCode>
													&#123;&#123; end &#125;&#125;
												</Code>
											</Pre>
										</Row>
									</RightSection>
									<RightSection>
										<Row>
											<Header>Range Slice</Header>
										</Row>
										<Row>
											<SubHeader>Iterates over a subset of the items. This is zero-based, so the first item is 0. The second number is the item we stop before, just like in a for loop.</SubHeader>
										</Row>
										<Row>
											<Pre>
												<Code>
													&lt;!-- first three items--&gt;<br/>
													&#123;&#123; range slice .Items 0 3 &#125;&#125;<br/>
														<IndentedCode>
															&#123;&#123; .copy &#125;&#125;<br/>
														</IndentedCode>
													&#123;&#123; end &#125;&#125;<br/>
													&lt;!-- start with third item and go through to the end--&gt;<br/>
													&#123;&#123; range slice .Items 2 (len .Items) &#125;&#125;<br/>
														<IndentedCode>
															&#123;&#123; .copy &#125;&#125;<br/>
														</IndentedCode>
													&#123;&#123; end &#125;&#125;
												</Code>
											</Pre>
										</Row>
									</RightSection>
									<RightSection>
										<Row>
											<Header>Range Chunk</Header>
										</Row>
										<Row>
											<SubHeader>
												Divides items into chunks of a specified size.
												Example with ten items titled “0” through “9”:
											</SubHeader>
										</Row>
										<Row>
											<Pre>
												<Code>
													&lt;!— chunks of 3 (last one is discarded) —&gt;<br/>
													&#123;&#123; range chunk .Items 3 &#125;&#125;<br/>
														<IndentedCode>
															&#123;&#123; range . &#125;&#125;
															<IndentedCode>
																&#123;&#123; .copy &#125;&#125;
															</IndentedCode>
															&#123;&#123; end &#125;&#125;
														</IndentedCode>
													&#123;&#123; end &#125;&#125;<br/>
													&lt;!—<br/>
													Output: row: 012<br/>
															<IndentedCode>row: 345</IndentedCode>
															<IndentedCode>row: 678</IndentedCode>
													—&gt;
												</Code>
											</Pre>
										</Row>
									</RightSection>
								</RightSideContainer>
							</Flex>
						</Container>
					</Container>
				</Content>
			</Triangle>
		</ContentContainer>
	)

}

const Container = styled.div`
    display: block;
    color: #ffffff;
    font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
    font-weight: 100;
    font-size: 0.786rem;
    letter-spacing: .05em;
    line-height: 1.35;
`
const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
`
const FullWidth = styled.div`
    width: 100% !important;
`
const TopSection = styled.div`
    padding-bottom: 1.571rem;
`
const Header = styled.h1`
    margin: 0;
    font-size: 0.857rem;
    font-weight: 600;
`
const Section = styled.div`
    width: 17.857rem;
    margin-bottom: 1.571rem;
`
const RightSection = styled.div`
    width: 100%;
    margin-bottom: 1.571rem;
`

const RightSideContainer = styled.div`
    padding-left: 1.571rem;
	width: 444px;
`

const Row = styled.div`
    margin-bottom: 0.357rem;
`

const IndentedRow = styled.div`
	margin-bottom: 0.357rem;
    padding-left: 0.786rem;
`

const IndentedCode = styled.div`
    padding-left: 1.786rem;
`
const Code = styled.code`
    margin: 0;
    color: #ffffff;
    font-size: 0.786rem;
    font-family: monospace, serif;
    cursor: text;
`

const SubHeader = styled.h3`
    margin: 0;
    color: #7ba5ca;
    font-size: 0.786rem;
    font-weight: 400;
`

const Pre = styled.pre`
    margin: 0;
`

EmailReference.propTypes = {
	open: PropTypes.bool.isRequired,
};


export default EmailReference;