import { createSlice } from "@reduxjs/toolkit";
import { getWebsites } from "./thunks";
import websitesAdapter from "./websitesAdapter";

const initialState = {
	isLoading: false,
	isFetchError: false,
	isCreateWebsiteError: false,
	response: {
		data: websitesAdapter.getInitialState(),
		meta: {},
	},
	selectedWebsite: null,
};

const websitesSlice = createSlice({
	name: "websiteMicros",
	initialState,
	reducers: {
		clearWebsitesApiError(state) {
			state.isFetchError = false;
			state.isCreateWebsiteError = false;
		},
	},
	extraReducers: (builder) => {
		// getWebsites
		builder.addCase(getWebsites.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getWebsites.fulfilled, (state, action) => {
			const json = action.payload.json;
			state.isLoading = false;
			state.response.meta = json.meta;
			websitesAdapter.setAll(state.response.data, json.data);
		});
		builder.addCase(getWebsites.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			websitesAdapter.removeAll(state.response.data);
		});
	},
});

export const { clearWebsitesApiError } = websitesSlice.actions;

export default websitesSlice.reducer;
