import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ChevronLeft } from "react-feather";

import { ICON_SIZES } from "../../constants/common";

const SidebarButton = ({
	url = "/websites",
	dataQA = "back-button",
	text = "Websites",
}) => {
	return (
		<Button href={url} data-qa={dataQA}>
			<ChevronLeft
				aria-label="back button"
				size={ICON_SIZES.medium}
				fill={"#303d78"}
			/>
			{text}
		</Button>
	);
};

const Button = styled.a`
    //INFO: we have to increase the selector specificity to rewrite a some bx-core.css styles
	&& {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #f5ebe1;
		color: #303d78;
		font-weight: 700;
		font-size: 12px;
		height: 44px;
		cursor: pointer;
		text-decoration: none;
		font-weight: bold;
		& > svg {
			margin-right: 6px;
		}
	}
`;

SidebarButton.propTypes = {
    url: PropTypes.string,
    dataQA: PropTypes.string,
    text: PropTypes.string,
};

export default SidebarButton;
