import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Section } from "../../../../../../components/form";
import { CodeInput } from "../../../../components/code-input";

import { Spacer, TitleContainer } from "./utils/Sections.styled";

const EligibilityJS = () => {
	const selectedCampaign = useSelector(
		(state) => state.inboxCampaigns?.selectedInboxCampaign
	);

	const [code, setCode] = useState("");
	if (!selectedCampaign) {
		return null;
	}

	return (
		<Section title="Eligibility JS">
			<Spacer>
				<TitleContainer />
				<i
					onClick={() => {
						navigator.clipboard.writeText(code);
					}}
					className="icon-clipboard"
				/>
				<CodeInput
					text={code}
					onChange={(e) => setCode(e.currentTarget.value)}
				/>
			</Spacer>
		</Section>
	);
};

export default EligibilityJS;
