import React from "react";
import styled from "styled-components";
import {
	LeftColumnContainer,
	HeaderBar,
	Button,
	QuarterColumn,
	BlueButton,
} from "./styledComponents";
import {
	DAYS,
} from "./constants";

const FilterColumn = ({
	endDate,
	startDate,
	setStartDate,
	setEndDate,
	days,
	toggleDays,
}) => {
	const getButtonStyle = (selectedDays) => {
		let style = {
			backgroundColor: "#fff",
			color: "#5a5959",
		};

		if (days === selectedDays) {
			style = {
				backgroundColor: "#FBFCFD",
				color: "#535252",
			};
		}

		return style;
	};

	return (
		<LeftColumnContainer>
			<ModifiedHeaderBar>
				Top Level Stats
			</ModifiedHeaderBar>

			<Row>
				<Title>Behavioral Email Attribution</Title>
				<div>
					<Button
						onClick={() => toggleDays(DAYS.SEVEN)}
						style={getButtonStyle(DAYS.SEVEN)}
					>
						7 Day
					</Button>
					<Button
						onClick={() => toggleDays(DAYS.TWENTY_FOUR)}
						style={getButtonStyle(DAYS.TWENTY_FOUR)}
					>
						24 Day
					</Button>
				</div>
			</Row>

			<Row>
				<Title>Date Range</Title>
				<DateContainer>
					<QuarterColumn>Start&#58;</QuarterColumn>
					<input
						type="date"
						value={startDate}
						onChange={(event) => setStartDate(event.target.value)}
					/>
				</DateContainer>
				<DateContainer>
					<QuarterColumn>End&#58;</QuarterColumn>
					<input
						type="date"
						value={endDate}
						onChange={(event) => setEndDate(event.target.value)}
					/>
				</DateContainer>
			</Row>

		</LeftColumnContainer>
	);
};

const ExportCsvLink = styled.a`
	cursor: pointer;
	display: inline-block;
	margin-top: 20px;
	margin-bottom: 10px;
`;

const DateContainer = styled.div`
	display: flex;
	padding: 0.4em 0 0.4em 0;
`;

const ModifiedHeaderBar = styled(HeaderBar)`
	background-color: #F5EBE1;
	color: #214558;
	padding-left: 20px;
`;

const Row = styled.div`
	padding-left: 20px;
	padding-top: 14px;
	padding-bottom: 14px;
`;

const Title = styled.div`
	padding: 0.3em 0 0.4em 0;
	font-size: 14px;
`;

export default FilterColumn;
