import { createSlice } from "@reduxjs/toolkit";
import { getTemplateCampaigns } from "./thunks";

const initialState = {
	isFetchError: false,
	response: {
		data: [],
	},
};

const campaignTemplates = createSlice({
	name: "CampaignTemplates",
	initialState,
	reducers: {
		clearCampaignTemplatesApiError(state) {
			state.isFetchError = false;
		}
	},
	extraReducers: (builder) => {
		// getPurposes
		builder.addCase(getTemplateCampaigns.fulfilled, (state, action) => {
			state.response = action.payload;
		});
		builder.addCase(getTemplateCampaigns.rejected, (state) => {
			state.isFetchError = true;
		});
	},
});

export const {
	clearCampaignTemplatesApiError,
} = campaignTemplates.actions;

export default campaignTemplates.reducer;
