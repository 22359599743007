import { getCurrentDayString, formatDateString } from "../../../utils";
import { reportTypes } from "../../../constants/common";

export const updateReportOptions = (state, { payload: { options, type } }) => {
	const reportOptions = {
		...(state.reportOptions ||
			getDefaultReportOptions(type)),
		...options,
	};
	// update State
	state.reportOptions = reportOptions;
};

export const getDefaultReportOptions = (type, date_start, date_end) => {
	const last7 = 7;
	const startDate = new Date();
	startDate.setDate(startDate.getDate() - last7);
	const dateStart = date_start || formatDateString(startDate);
	const dateEnd = date_end || getCurrentDayString();
	const baseObj = {
		date_start: dateStart,
		date_end: dateEnd,
	};
	if (!type) {
		return baseObj;
	}
	const campaignOptions = {
		...baseObj,
		traffic_mode: "normal",
		date_interval: "day",
	};
	const comparisonOptions = {
		...baseObj,
		conv_goals: "all_goals",
		campaign_sorting: "campaign_conversion_rate",
		conv_view: "all_credit_30",
	};

	const options = {
		[reportTypes.websiteCampaign]: campaignOptions,
		[reportTypes.parentCampaign]: campaignOptions,
		[reportTypes.childCampaign]: campaignOptions,
		[reportTypes.websiteComparison]: comparisonOptions,
		[reportTypes.parentCampaignComparison]: comparisonOptions,
		[reportTypes.childCampaignComparison]: comparisonOptions,
		[reportTypes.websiteConversions]: baseObj,
		[reportTypes.parentConversions]: baseObj,
		[reportTypes.childConversions]: baseObj,
		[reportTypes.websiteEmailCapture]: baseObj,
		[reportTypes.parentEmailCapture]: baseObj,
		[reportTypes.childEmailCapture]: baseObj,
	}[type];
	return options;
};
