import { map, pick } from "lodash";

export const getOptions = (product, data) => {
	return map(
		data.filter(
			(datum) => datum.attributes.product === product || datum.attributes.product === "all"
		),
		(datum) => pick(datum.attributes, "id", "name")
	).map((option) => ({ name: option.name, value: option.id }));
};
