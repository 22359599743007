import { createSlice } from "@reduxjs/toolkit";
import { getInboxSeries, saveInboxSeries } from "./thunks";
import inboxSeriesAdapter from "./inboxSeriesAdapter";

const initialState = {
	isLoading: false,
	isFetchError: false,
	response: {
		data: inboxSeriesAdapter.getInitialState(),
		meta: {},
		included: [],
	},
	selectedInboxSeries: null,
};

const inboxSeriesSlice = createSlice({
	name: "inboxSeries",
	initialState,
	reducers: {
		clearInboxSeriesApiError(state) {
			state.isFetchError = false;
		},
	},
	extraReducers: (builder) => {
		// getInboxSeries
		builder.addCase(getInboxSeries.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getInboxSeries.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			state.response.included = action.payload.included;
			inboxSeriesAdapter.upsertOne(state.response.data, action.payload?.data);
		});
		builder.addCase(getInboxSeries.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxSeriesAdapter.removeAll(state.response.data);
		});
		// saveInboxSeries
		builder.addCase(saveInboxSeries.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(saveInboxSeries.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			inboxSeriesAdapter.setAll(state.response.data, action.payload.data);
		});
		builder.addCase(saveInboxSeries.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxSeriesAdapter.removeAll(state.response.data);
		});
	},
});

export const { clearInboxSeriesApiError } = inboxSeriesSlice.actions;

export default inboxSeriesSlice.reducer;
