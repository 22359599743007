import { numberFormat, toFixed, formatOutput } from "./utils";
import { reportTypes } from "../../constants/common";

const createComparisonData = (type, rawData) => {
	const convertObj = (obj) => {
		return formatData(type, obj);
	};
	return rawData.map(convertObj);
};
const formatData = (type, obj) => {
	const prefixSumbit = obj.submit_rate_confidence_check ? "positive" : "";
	const prefixConversion = obj.conversion_rate_confidence_check
		? "positive"
		: "";
	return {
		date: createDate(obj),
		mode: obj.mode,
		campaign_name: obj.campaign_name,
		campaign_id: obj.campaign_id,
		parent_campaign_id: obj.parent_campaign_id,
		parent_campaign_name: obj.parent_campaign_name,
		parent_campaign_purpose: obj.parent_campaign_purpose,
		date_start: obj.date_start.split("T")[0],
		date_end: obj.date_end.split("T")[0],
		website_visits: obj.website_visits,
		eligible_visits: formatOutput(
			obj.campaign_eligible_visits,
			obj.eligibility_rate
		),
		overall_impressions: formatOutput(
			obj.campaign_overall_impressions,
			obj.impression_rate
		),
		unique_impressions: formatOutput(
			obj.campaign_visit_impressions,
			obj.visit_impression_rate
		),
		closes: formatOutput(obj.campaign_closes, obj.close_rate),
		clicks: formatOutput(obj.campaign_clicks, obj.click_rate),
		form_submits: formatOutput(obj.campaign_submits, obj.submit_rate),
		submit_lift_conf: formatOutput(
			`${toFixed(100 * obj.submit_rate_lift)}%`,
			`${prefixSumbit} ${toFixed(100 * obj.submit_rate_confidence)}`
		),
		campaign_conversions: formatConversaion(type, obj),
		conversion_lift_conf: formatOutput(
			toFixed(100 * obj.conversion_rate_lift),
			`${prefixConversion} ${toFixed(
				100 * obj.conversion_rate_confidence
			)}`
		),
		aov: formatMoney(
			obj.website_conversions_aov,
			obj.website_conversions_overall_aov
		),
		revenue: formatRevenue(
			type,
			obj.campaign_conversions_revenue,
			obj.website_conversions_revenue
		),
	};
};
const createDate = ({ date_start, date_end }) => {
	const formatDateStart = date_start.split("T")[0];
	const formatDateEnd = date_end.split("T")[0];
	return {
		top: formatDateStart,
		bottom: formatDateEnd,
	};
};
const formatRevenue = (type, campaignRev, websiteRev) => {
	if (type === reportTypes.websiteComparison) {
		return `$${Number(websiteRev).toLocaleString(...numberFormat)}`;
	}
	return `$${Number(campaignRev).toLocaleString(...numberFormat)}`;
};
const formatMoney = (top, bottom) => {
	return {
		top: `$${Number(top).toLocaleString(...numberFormat)}`,
		bottom: `$${Number(bottom).toLocaleString(...numberFormat)}`,
	};
};
const formatConversaion = (
	type,
	{
		website_conversions,
		website_conversion_rate,
		website_conversions_overall,
		campaign_conversions,
		campaign_conversion_rate,
	}
) => {
	if (type === reportTypes.websiteComparison) {
		return {
			top: website_conversions,
			middle: `${website_conversion_rate}%`,
			bottom: website_conversions_overall,
		};
	}
	return {
		top: campaign_conversions,
		middle: campaign_conversion_rate,
		bottom: website_conversions_overall,
	};
};
export default createComparisonData;
