import styled from "styled-components";

export const TabsContainer = styled.div`
    display: flex;
    border-bottom: 1px solid;
    border-color: #c8d7e0;
`

export const Tab = styled.div`
    pointer-events: ${props => props.isSelected ? 'none' : null};
    color: #191919;
    text-transform: capitalize;
    border: 1px solid;
    border-bottom: 0;
    border-color: #c8d7e0;
    margin-top: ${props => props.marginTop ?? null};
    margin-right: ${props => props.marginRight ?? null};
    margin-left: ${props => props.marginLeft ?? null};
    margin: -1px 0 0 -1px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    min-width: 7.857rem;
    height: 3rem;
    padding: 0 0.524rem;
    background: ${props => props.isSelected ? `${props.selectColor ?? '#F5EBE1'}`: 'transparent'};
    cursor: pointer;
`

export const Container = styled.div`
    display: ${props => props.isSelected ? 'block' : 'none'};
    visibility: ${props => props.isSelected ? 'visible': 'hidden'};
    padding: 0.786rem;
    overflow-x: auto;
    white-space: nowrap;
`