import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getWebsite } from "../redux/websites/thunks";
import { getInboxCampaign } from "../redux/inboxCampaigns/thunks";



export const useGetWebsiteFromInboxCampaign = () => {
    const inboxCampaignId = useSelector((state) => state.page?.params?.campaignId);
    const [websiteId, setwebsiteId] = useState(null);

    const dispatch = useDispatch();

    const fetchWebsite = async (id) => {
        await dispatch(getWebsite(id))
    }
    const fetchInboxCampaign = async (campaignId) => {
        const { payload } = await dispatch(getInboxCampaign(campaignId))
        const webId = payload?.data?.relationships?.website?.data?.id
        setwebsiteId(webId)
    }

    useEffect(() => {
        if(websiteId){
            fetchWebsite(websiteId)
        }else if(!websiteId && inboxCampaignId){
            fetchInboxCampaign(inboxCampaignId)
        }
	}, [websiteId, inboxCampaignId]);

    const { selectedWebsite } = useSelector((state) => state.websites);

    return {
        ...selectedWebsite
    }
}