export const trafficOptions = [
    {
        name: "Variation",
        value: "variation",
    },
    {
        name: "Control",
        value: "control",
    }
];

export	const trafficInitialValues = (campaign) =>(
    {
        testType: campaign?.attributes["test-type"] ?? "Select Traffic Type",
        testTraffic: campaign?.attributes["test-traffic"] ?? 0,
    }
);
