import React from "react";
import styled from "styled-components";
import { Line } from "react-chartjs-2";
import {
	formatGraphData,
} from "./utils";

const AreaGraph = ({ label, rawData, yAxisFormatter, tooltipFormatter, xAxisFormatter }) => {
	const {
		labels,
		data,
	} = formatGraphData(rawData);

	const graphData = {
	  	labels,
	  	datasets: [{
	  		label,
	      	data,
	      	fill: true,
	      	backgroundColor: "#3a84c4",
	      	borderColor: "#3a84c4",
	      	pointRadius: 0,
	    }],
	};

	const options = {
		legend: {
			display: false,
		},
		tooltips: {
			mode: "index",
			intersect: false,
			callbacks: {
				label: (tooltipItem, data) => {
					let label = data.datasets[tooltipItem.datasetIndex].label;
                    return `${label}: ${tooltipFormatter(tooltipItem.yLabel)}`;
				},
				title: (tooltipItem) => {
					return xAxisFormatter(tooltipItem[0].xLabel);
				},
			},
		},
	  	scales: {
	    	yAxes: [{
	        	ticks: {
	          		beginAtZero: true,
	          		callback: yAxisFormatter,
	        	},
	    	}],
	    	xAxes: [{
	    		ticks: {
	    			maxTicksLimit: 5,
	    			callback: xAxisFormatter,
	    		},
	    	}],
	  	},
	};

	return (
		<Background>
			<Line
				data={graphData}
				options={options}
			/>
		</Background>
	);
};

const Background = styled.div`
	padding: 45px 0 20px 0px;
`;

export default AreaGraph;
