import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Circle } from "react-feather";
import { ICON_SIZES } from "../constants/common";

const COLORS_MAP = {
	on: "rgb(151, 212, 131)",
	wip: "rgb(155, 73, 118)",
	test: "rgb(219, 164, 127)",
	off: "rgb(160, 158, 156)",
	disabled: "rgb(48, 61, 120)",
};

const TrafficTypeSwitcher = ({ onChange, value }) => {
	const onOptionIsActive = value.includes("on");
	const offOptionIsActive = value.includes("off");
	const testOptionIsActive = value.includes("test");
	const wipOptionIsActive = value.includes("wip");

	const getIconColor = (iconType) => {
		switch (iconType) {
			case "on":
				return onOptionIsActive ? COLORS_MAP.on : COLORS_MAP.disabled;
			case "test":
				return testOptionIsActive ? COLORS_MAP.test : COLORS_MAP.disabled;
			case "off":
				return offOptionIsActive ? COLORS_MAP.off : COLORS_MAP.disabled;
			case "wip":
				return wipOptionIsActive ? COLORS_MAP.wip : COLORS_MAP.disabled;
			default:
				return COLORS_MAP.disabled;
		}
	};

	const clickItemHandler = (itemType) => {
		const optionsList = new Set(value);
		const hasItem = optionsList.has(itemType);

		if (hasItem && optionsList.size === 1) {
			return;
		}

		if (hasItem) {
			optionsList.delete(itemType);
			onChange([...optionsList]);
		} else {
			optionsList.add(itemType);
			onChange([...optionsList]);
		}
	};

	return (
		<TrafficTypeContainer>
			<Item
				data-qa={`filter-on-button${onOptionIsActive ? "-active" : ""}`}
				isActive={onOptionIsActive}
				onClick={() => clickItemHandler("on")}
			>
				<Circle
					aria-label="filter on button"
					size={ICON_SIZES.small}
					style={{ margin: "0 5px 0 0" }}
					color={getIconColor("on")}
					fill={getIconColor("on")}
				/>
				ON
			</Item>
			<Item
				data-qa={`filter-wip-button${wipOptionIsActive ? "-active" : ""}`}
				isActive={wipOptionIsActive}
				onClick={() => clickItemHandler("wip")}
			>
				<Circle
					aria-label="filter wip button"
					size={ICON_SIZES.small}
					style={{ margin: "0 5px 0 0" }}
					color={getIconColor("wip")}
					fill={getIconColor("wip")}
				/>
				WIP
			</Item>
			<Item
				data-qa={`filter-test-button${testOptionIsActive ? "-active" : ""}`}
				isActive={testOptionIsActive}
				onClick={() => clickItemHandler("test")}
			>
				<Circle
					aria-label="filter test button"
					size={ICON_SIZES.small}
					style={{ margin: "0 5px 0 0" }}
					color={getIconColor("test")}
					fill={getIconColor("test")}
				/>
				TEST
			</Item>
			<Item
				data-qa={`filter-off-button${offOptionIsActive ? "-active" : ""}`}
				isActive={offOptionIsActive}
				onClick={() => clickItemHandler("off")}
			>
				<Circle
					aria-label="filter off button"
					size={ICON_SIZES.small}
					style={{ margin: "0 5px 0 0" }}
					color={getIconColor("off")}
					fill={getIconColor("off")}
				/>
				OFF
			</Item>
		</TrafficTypeContainer>
	);
};
const TrafficTypeContainer = styled.div`
	display: flex;
	width: 100%;
	height: 42px;
	cursor: pointer;
`;

const Item = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1 1 auto;
	border: ${({ isActive }) =>
		isActive ? "1px solid rgb(48, 61, 120)" : "1px solid rgb(232, 232, 232)"};
	color: rgb(48, 61, 120);
	font-size: 10px;
	z-index: ${({ isActive }) => (isActive ? "2" : "1")};
	&:not(:first-child) {
		margin-left: -1px;
	}
	&:hover {
		border: 1px solid rgb(48, 61, 120);
		z-index: 2;
	}
`;

TrafficTypeSwitcher.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.arrayOf(PropTypes.oneOf(["on", "off", "test", "wip"])).isRequired,
};

export default TrafficTypeSwitcher;
