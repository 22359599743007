import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import {
	reportIdMap,
	API_LIMIT,
	reportTypes,
	endpoints,
} from "../../../../constants/common";
import { Title, Button } from "..";
import { Pagination } from "../controls/components";
import { PopupModal } from "../../../../components";
import { LocalStorageService } from "../../../../service/localStorage";

const HeaderComponent = ({
	title = "",
	type = "",
	id = -1,
	data = [],
	csvFileName,
	loadMore,
}) => {
	const [isLoadingCSV, setIsLoadingCSV] = useState(false);
	const [isExportCSVError, setIsExportCSVError] = useState(false);
	const { website_id, date_start, date_end } = useSelector(
		({ page = {} }) => page.params || {}
	);
	const total = useSelector(({ report = {} }) => report.total);
	const idType = reportIdMap[type];
	const enablePagination = type === reportTypes.websiteCampaign;

	const exportCSV = async () => {
		try {
			let link = document.createElement("a");
			link.download = csvFileName || "csvFile.csv";

			setIsLoadingCSV(true);
			const blob = await downloadCSV(website_id, date_start, date_end);
			link.href = URL.createObjectURL(blob);
			link.click();
			setIsLoadingCSV(false);
		} catch {
			setIsLoadingCSV(false);
			setIsExportCSVError(true);
		}
	};

	return (
		<div>
			<Link
				href={`/reports?type=${type}&${idType}=${id}&website_id=${website_id}`}
			>
				Back to Report
			</Link>
			<Title title={title}>
				<Padding />
				<Button onClick={exportCSV} isLoading={isLoadingCSV}>
					Export as CSV
				</Button>
				<PopupModal
					showModal={isExportCSVError}
					toggleModal={() => setIsExportCSVError(false)}
					closeOnOutClick
				>
					Failed to export CSV!
				</PopupModal>
			</Title>
			{enablePagination && (
				<Pagination
					limit={API_LIMIT}
					loadMore={loadMore}
					currentDataCount={data.length}
					total={total}
					style={{
						top: "85px",
						mediaWidth: "1500px",
						mediaTop: "23px",
					}}
				/>
			)}
		</div>
	);
};

const downloadCSV = async (websiteId, dateStart, dateEnd) => {
	const token = LocalStorageService.getToken();
	const params = `?date_start=${dateStart}&date_end=${dateEnd}`;
	const URL = `${endpoints.websiteReportURL}/${websiteId}/conversions${params}`;

	const response = await fetch(URL, {
		method: "GET",
		headers: {
			"Content-Type": "text/csv",
			Authorization: `Bearer ${token}`,
		},
	});

	if (!response.ok) {
		throw Error("Failed to load CSV!");
	}

	return response.blob();
};

HeaderComponent.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	data: PropTypes.array,
	csvFileName: PropTypes.string.isRequired,
	loadMore: PropTypes.func,
};

const Link = styled.a`
	margin-top: 10px;
	display: inline-block;
	font-weight: bold;
	color: cornflowerblue !important;
`;

const Padding = styled.span`
	display: inline-block;
	min-width: 20px;
`;

export default HeaderComponent;
