import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { handleAuthentication, initializeAuthentication, getTokenFromStorage } from "@frontend/authentication";
import { ErrorBoundary } from "react-error-boundary";
import { Client, Provider as URLQProvider, cacheExchange, fetchExchange } from "urql";

import { store } from "./redux/store";
import Router from "./router";
import { ErrorFallback, SiteBanner } from "./components";

const client = new Client({
	url: window.bx_vars.GRAPHQL_URL || process.env.GRAPHQL_URL,
	exchanges: [cacheExchange, fetchExchange],
	fetchOptions: () => {
		const token = getTokenFromStorage();
		return {
			headers: {
				Authorization: `Bearer ${token}`,
			}
		}
	}
});

import {
	goToUsers,
	goToEsp,
	goToEsps,
	goToReport,
	goToTools,
	goToTopStats,
	goToUploads,
	removeReactApp,
	goToWebsites,
	goToInboxSeries,
	goToInboxSeriesEdit,
	goToInboxCampaignEdit,
	goToInboxCampaignDesign,
	goToCMSSeries,
	goToCMSSeriesEdit,
	goToCMSCampaignDesign,
	goToCMSCampaignEdit,
	goToCustomerRegistration,
	goToFonts,
	goToWebsitesSettings,
	goToSignOut,
	goToESPProfiles,
} from "./redux/page/slice";

const appendBxCoreScript = () => {
	const bxCoreScriptWrapper = document.getElementById('bx-core-script-wrapper');
	if (bxCoreScriptWrapper?.innerHTML) {
		let bxCoreScript = bxCoreScriptWrapper.innerHTML;
		bxCoreScript = bxCoreScript.replace(/<!--|-->/g, '');

		const range = document.createRange();
		range.selectNode(bxCoreScriptWrapper);
		const documentFragment = range.createContextualFragment(bxCoreScript);
		document.body.appendChild(documentFragment);
	}
}

initializeAuthentication()
	.then(() => handleAuthentication())
	.then(() => {
		render();
		appendBxCoreScript();
	})
	.catch((e) => console.error(e));

const mountNode = document.getElementById("react-app");

const isDevelopment = process.env.REACT_APP_ENVIRONMENT !== "production";

const render = () => {
	ReactDOM.render(
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<React.StrictMode>
				<Provider store={store}>
					<URLQProvider value={client}>
					{isDevelopment && <SiteBanner name={process.env.REACT_APP_ENVIRONMENT} />}
					<Router />
					</URLQProvider>
				</Provider>
			</React.StrictMode>
		</ErrorBoundary>,
		mountNode
	);
};

//INFO this will give ember access to react via redux
window.emberHooks = {
	goToReport: (params = {}) => store.dispatch(goToReport(params)),
	removeReactApp: () => store.dispatch(removeReactApp()),
	goToTools: (params = {}) => store.dispatch(goToTools(params)),
	goToUsers: (params = {}) => store.dispatch(goToUsers(params)),
	goToEsps: (params = {}) => store.dispatch(goToEsps(params)),
	goToEsp: (params = {}) => store.dispatch(goToEsp(params)),
	goToUploads: (params = {}) => store.dispatch(goToUploads(params)),
	goToTopStats: (params = {}) => store.dispatch(goToTopStats(params)),
	goToWebsites: (params = {}) => store.dispatch(goToWebsites(params)),
	goToWebsitesSettings: (params = {}) => store.dispatch(goToWebsitesSettings(params)),
	goToInboxSeries: (params = {}) => store.dispatch(goToInboxSeries(params)),
	goToInboxSeriesEdit: (params = {}) => store.dispatch(goToInboxSeriesEdit(params)),
	goToInboxCampaignEdit: (params = {}) => store.dispatch(goToInboxCampaignEdit(params)),
	goToInboxCampaignDesign: (params = {}) => store.dispatch(goToInboxCampaignDesign(params)),
	goToCMSSeries: (params = {}) => store.dispatch(goToCMSSeries(params)),
	goToCMSSeriesEdit: (params = {}) => store.dispatch(goToCMSSeriesEdit(params)),
	goToCMSCampaignDesign: (params = {}) => store.dispatch(goToCMSCampaignDesign(params)),
	goToCMSCampaignEdit: (params = {}) => store.dispatch(goToCMSCampaignEdit(params)),
	goToCustomerRegistration: (params = {}) => store.dispatch(goToCustomerRegistration(params)),
	goToFonts: (params = {}) => store.dispatch(goToFonts(params)),
	goToSignOut: (params = {}) => store.dispatch(goToSignOut(params)),
	goToESPProfiles: (params = {}) => store.dispatch(goToESPProfiles(params)),
};
