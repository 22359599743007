import React from 'react';
import PropTypes from 'prop-types';
import { InputContainer, InputLabel, Input } from './styles'

const InputText = ({
    label,
    value,
    setValue,
    margin,
    width,
    flexBasis,
    disabled = false,
    type = "text",
}) => {
    return (
        <InputContainer margin={margin} width={width} flexBasis={flexBasis}>
            <InputLabel>{label}</InputLabel>
            <Input
                type={type}
                value={value}
                onChange={(e) => setValue(e)}
                disabled={disabled}
            />
        </InputContainer>
    )
}

InputText.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    setValue: PropTypes.func.isRequired,
    margin: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    flexBasis: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string,
}

export default InputText;
