import { createEntityAdapter } from "@reduxjs/toolkit";

const inboxCampaignsAdapter = createEntityAdapter({
	selectId: (inboxCampaigns) => inboxCampaigns.id,
});

export const {
	selectAll: selectAllInboxCampaigns,
} = inboxCampaignsAdapter.getSelectors((state) => state.inboxCampaigns.response.data);

export default inboxCampaignsAdapter;
