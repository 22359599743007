import { createEntityAdapter } from "@reduxjs/toolkit";

const childrenCampaignsAdapter = createEntityAdapter({
	selectId: (inbox) => inbox.id,
});

export const { selectAll: selectAllChildrenCampaigns } = childrenCampaignsAdapter.getSelectors(
	(state) => state.inbox.response.data
);

export default childrenCampaignsAdapter;
