import { parse as parseUtmValues } from "querystring";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";
import {
	PageSection,
	SectionTitle,
	SubsectionBreak,
	MinimalSubsection,
	Pill,
	NumberedList,
	SimpleText,
	Button,
	TabbedContainer,
	InputText,
} from "../../../../components";
import { getInboxSeries } from "../../../../redux/inboxSeries/thunks";
import { groupSeriesFamily } from "../../components/nav-pages/utils/main-page";
import { CodeInput } from "../../components/code-input";
import { getCreativeJson } from "../utils/paramsParsing";
import { Dropdown } from "../../../../components/form";
import { CadenceSettingStyle, HeaderText } from "./utils/SeriesEditPage.styled";
import { TIME } from "./constants";

const seriesDataInitialState = {
	family: null,
	seriesName: "",
	campaigns: [],
	defaultUtmValues: [],
	// Perhaps to be used for saving on the backend
	utmValues: [],
	leader: {},
	emailBlocksHeader: "",
	emailBlocksFooter: "",
};

const setState = (initialState) => {
	const [state, setState] = useState(initialState);

	const setStateValue = (obj = {}) => {
		setState({ ...state, ...obj });
	};

	return [state, setStateValue];
};

const SeriesEditPage = () => {
	const [seriesDataState, dispatchSeriesData] = setState(seriesDataInitialState);

	const { isFetchError: inboxSeriesIsFetchError } = useSelector((state) => state.inboxSeries);
	const { seriesId } = useSelector((state) => state.page.params);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!seriesDataState.seriesName) {
			dispatch(getInboxSeries(seriesId)).then((res) => {
				const sortedFamily = groupSeriesFamily(res.payload);
				dispatchSeriesData({
					family: sortedFamily,
					seriesName: sortedFamily.attributes.name,
					leader: sortedFamily.leader,
					campaigns: sortedFamily.children,
					defaultUtmValues: sortedFamily.children.map(({ attributes }) =>
						calculateDefaultUtmValues(attributes["click-params"])
					),
					utmValues: sortedFamily.children.map(
						({ attributes }) =>
							parseUtmValues(attributes["click-params"])["utm_campaign"]
					),
					emailBlocksFooter: getCreativeJson(sortedFamily, "footer"),
					emailBlocksHeader: getCreativeJson(sortedFamily, "header"),
				});
			});
		}
	}, [seriesDataState.seriesName]);

	const setLeaderInDropdown = (event) => {
		const campaignName = event.target.value;
		const newLeader = seriesDataState.campaigns.find(
			(campaign) => campaign.attributes.name === campaignName
		);
		dispatchSeriesData({ leader: newLeader });
	};

	if (inboxSeriesIsFetchError) {
		return <div>{`An Error occurred fetching data - ${inboxSeriesIsFetchError}`}</div>;
	}

	return (
		<>
			{seriesDataState?.family && (
				<>
					<PageSection display="flex" marginBottom="22px">
						<SectionTitle>Series Settings</SectionTitle>
						<InputText
							label="Name"
							value={seriesDataState.seriesName}
							setValue={(e) =>
								dispatchSeriesData({
									seriesName: e.target.value,
								})
							}
							width="100%"
						/>
						<Dropdown
							label="Leader"
							field="leader"
							value={seriesDataState.leader.attributes?.name ?? ""}
							onChange={setLeaderInDropdown}
							options={seriesDataState.campaigns.map((campaign) => ({
								name: campaign.attributes.name,
								value: campaign.attributes.name,
							}))}
						/>
					</PageSection>
					<PageSection marginBottom="22px" display="grid">
						<SectionTitle>Cadence</SectionTitle>
						<CampaignCadences
							family={seriesDataState.family}
							campaigns={seriesDataState.campaigns}
						/>
					</PageSection>
					<PageSection marginBottom="22px">
						<SectionTitle>UTM Campaign Values</SectionTitle>
						<UtmValues
							utmValues={seriesDataState.utmValues}
							defaultUtmValues={seriesDataState.defaultUtmValues}
						/>
						<MinimalSubsection display="flex">
							<Button
								disabled={isEqual(
									seriesDataState.utmValues,
									seriesDataState.defaultUtmValues
								)}
								onClick={() => {
									dispatchSeriesData({
										utmValues: seriesDataState.defaultUtmValues,
									});
								}}
								theme="gray"
							>
								Set to defaults
							</Button>
							<SimpleText style={{ display: "flex", alignSelf: "center" }}>
								Defaults will not be updated on variations that have been
								overridden.
							</SimpleText>
						</MinimalSubsection>
					</PageSection>
					<PageSection>
						<SectionTitle>Email Blocks</SectionTitle>
						<SubsectionBreak marginBottom="0" />
						<TabbedContainer
							tabsMap={{
								header: (
									<CodeInput
										code={seriesDataState.emailBlocksHeader}
										height="100%"
										onChange={(e) =>
											dispatchSeriesData({
												emailBlocksHeader: e,
											})
										}
									/>
								),
								footer: (
									<CodeInput
										code={seriesDataState.emailBlocksFooter}
										height="100%"
										onChange={(e) =>
											dispatchSeriesData({
												emailBlocksFooter: e,
											})
										}
									/>
								),
							}}
							defaultSelection="header"
							tabMargins={{
								marginLeft: "-1px",
								marginTop: "-1px",
							}}
						/>
					</PageSection>
				</>
			)}
		</>
	);
};

const CampaignCadences = ({ family, campaigns }) => {
	return (
		<NumberedList
			listElements={campaigns.map(({ attributes, children: variations }, i) => (
				<CadenceSetting
					key={attributes.name}
					name={family.attributes.name.substring(0, family.attributes.name.indexOf(" -"))}
					title={attributes.name}
					total={campaigns.length}
					position={i + 1}
					leader={attributes["is-leader"]}
					delay={attributes["v2-trigger-delay"]}
					units={attributes["v2-trigger-delay-unit"]}
					getVariationTraffic={() => {
						let traffic = 0;
						for (const variant of variations) {
							if (variant.attributes.enabled) {
								traffic += variant.attributes["test-traffic"];
							}
						}
						return traffic;
					}}
				/>
			))}
		/>
	);
};

CampaignCadences.propTypes = {
	family: PropTypes.object.isRequired,
	campaigns: PropTypes.array.isRequired,
};

const calculateDefaultUtmValues = (clickParams) => {
	const parsedParams = parseUtmValues(clickParams);
	const utmCampaign = parsedParams["utm_campaign"];
	if (!utmCampaign) {
		return null;
	}
	const splitUtmCampaign = utmCampaign?.split("_");

	const isNum = (value) => !isNaN(value);
	/**
	 * TODO: this needs to be explicitly provided rather than derived.
	 *
	 * The logic here is based on how the default utm values are derived in
	 * https://gitlab.bouncex.net/frontend/core-ui/-/blob/master/app/models/inbox-campaign.js#L554-L573
	 *
	 * Essentially, a default utm value will have the following characteristics:
	 * - a string with 5 parts delimited by '_';
	 * - the first section will be 'email';
	 * - the second section will be a code name (which is not delivered in any
	 * way that I could discern in the data);
	 * - the next three sections are of the form <NUMBER>_of_<NUMBER>.
	 *
	 * If there is any deviation from this it will not be considered the default.
	 *
	 * There are a lot of assumptions with the below code which is one of the reasons
	 * why how this value is provided needs to be refactored.
	 */
	if (splitUtmCampaign?.length === 5) {
		const [email, part, of, total] = splitUtmCampaign;
		if (email === "email" && isNum(part) && of === "of" && isNum(total)) {
			return utmCampaign;
		}
	}

	const defaultValue = [
		"email",
		splitUtmCampaign[1], // code name
	];

	for (let i = 2; i < splitUtmCampaign.length; i++) {
		if (splitUtmCampaign[i] === "of") {
			defaultValue.push(splitUtmCampaign[i - 1]);
			defaultValue.push("of");
			if (i + 1 < splitUtmCampaign.length) {
				defaultValue.push(splitUtmCampaign[i + 1]);
			}
		}
	}

	return defaultValue.join("_");
};

/**
 * TODO: make this more reliable.
 *
 * The below assumes that utmValues and defaultUtmValues are correlated
 * by the campaign they represent positionally (meaning the utmValue and
 * defaultUtmValue will be for the same campaign if they share the same
 * position in their respective lists). This is not the most stable solution.
 */
const UtmValues = ({ utmValues, defaultUtmValues }) => {
	return (
		<NumberedList
			listElements={utmValues.map((utmValue, i) => {
				if (utmValue === defaultUtmValues[i]) {
					return <SimpleText>{utmValue}</SimpleText>;
				}
				return (
					<>
						<SimpleText alert data-tip data-for={utmValue}>
							{utmValue}
						</SimpleText>
						<ReactTooltip
							id={utmValue}
							place="right"
							effect="solid"
							arrowColor="transparent"
						>
							Not using the default: {defaultUtmValues[i]}
						</ReactTooltip>
					</>
				);
			})}
		/>
	);
};

UtmValues.propTypes = {
	utmValues: PropTypes.array.isRequired,
	defaultUtmValues: PropTypes.array.isRequired,
};

const CadenceSetting = ({
	name,
	title,
	total,
	position,
	leader,
	delay,
	units,
	getVariationTraffic,
}) => {
	return (
		<CadenceSettingStyle>
			<HeaderText marginRight="6rem">{name}</HeaderText>
			<HeaderText marginRight="1.572rem">{`Part ${position} of ${total}`}</HeaderText>
			<HeaderText marginRight="1.572rem">{title}</HeaderText>
			{leader ? <Pill>leader</Pill> : null}
			<div style={{ marginLeft: "auto" }}>{`${getVariationTraffic()}%`}</div>
			<InputText
				label="Delay"
				value={delay}
				// TODO: add functionality for handling changes
				setValue={() => {}}
				width="7.143rem"
			/>
			<Dropdown
				label="Unit"
				internalLabel
				field="unit"
				value={units ?? ""}
				onChange={() => {
					return;
				}}
				options={TIME}
				width="12.143rem"
			/>
		</CadenceSettingStyle>
	);
};

CadenceSetting.propTypes = {
	total: PropTypes.number.isRequired,
	position: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	leader: PropTypes.bool,
	// v2-trigger-delay
	delay: PropTypes.number,
	// v2-trigger-delay-unit
	units: PropTypes.string,
	// Total of active variations test-traffic
	getVariationTraffic: PropTypes.func.isRequired,
};

SeriesEditPage.propTypes = {};

export default SeriesEditPage;
