import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import { safeJsonParse, toFloat } from "../../../../utils";

const relationshipConfig = {
	esp: {
		key: 'esp',
		type: 'esps',
	},
	espProfile: {
		key: 'source-email-capture-esp-profile',
		type: 'email-capture-esp-profiles',
	},
	website: {
		key: 'website',
		type: 'websites',
	},
};

export const toEspApiFieldsDto = (espProfileData, espFields) => {
	// @TODO Use a safe parse method
	const parsedEspProfileData = safeJsonParse(espProfileData);
	const parsedEspFields = safeJsonParse(espFields);
	return Object.entries(parsedEspFields).reduce((result, [key, field]) => {
		const value = parsedEspProfileData[key] || '';
		// We don't want to modify the key here since this is the required
		// format for the external API
		return {
			...result,
			[key]: { ...field, value },
		};
	}, {});
};


/**
 * @description Returns object with camelCased keys
 * ```
 * dtDeleted
 * dtEdited
 * dtInserted
 * espData
 * espDontWait
 * espId
 * espProfileId
 * espSubmitUncompleted
 * id
 * ignorePreviousCaptures
 * name
 * notes
 * submissionRedirect
 * submissionRedirectDelay
 * websiteId
 * ```
 * @param {*} profile 
 * @param {*} esp 
 * @returns 
 */

export const toEspProfileDto = (profile) => {
	const { attributes: profileAttrs = {}, relationships = {} } = profile || {};
	const profileAttrsDto = Object.entries(profileAttrs).reduce((result, [key, value]) => {
		return {
			...result,
			[camelCase(key)]: value,
		};
	}, {});

	/** Get formatted relationships */
	const profileRelationshipsDto = {
		espId: relationships[relationshipConfig.esp.key]?.data?.id,
		espProfileId: relationships[relationshipConfig.espProfile.key]?.data?.id,
		websiteId: relationships[relationshipConfig.website.key]?.data?.id,
	};

	return { ...profileAttrsDto, ...profileRelationshipsDto };
};

export const toEspApiPayload = (espProfileDto, overrides = {}) => {
	
	const {
		// We're simply removing the first line of vars from the payload
		// eslint-disable-next-line no-unused-vars
		dtDeleted, dtEdited, dtInserted, id, websiteId,
		espId,
		espProfileId,
		submissionRedirectDelay, // Transform to float below
		...espProfileAttrs // Contains the remaning props
	} = espProfileDto;

	const mergedEspAttributes = {
		...espProfileAttrs,
		...overrides,
		submissionRedirectDelay: toFloat(submissionRedirectDelay),
	};

	const attributes = Object.entries(mergedEspAttributes).reduce((result, [key, value]) => {
		return {
			...result,
			[kebabCase(key)]: value,
		}
	}, {});

	const { esp, espProfile } = relationshipConfig;

	/**  We only need the esp and esp profile relationships */
	const relationships = {
		[esp.key]: { data: { id: espId, type: esp.type } },
		[espProfile.key]: {
			data: espProfileId ? { id: espProfileId, type: espProfile.type } : null,
		},
	};

	return { data: { attributes, relationships } };
};

const CLONE_SUFFIX = ' - Copy';
/**
 * Supporting function to generateClonedProfileName
 * @param {*} name 
 * @returns 
 */
const getRootName = (name) => {
	const pattern = new RegExp('\\s-\\sCopy(\\s\\d+)?$');
	
	if (pattern.test(name)) {
		const parts = name.split(CLONE_SUFFIX);
		parts.pop();
		return parts.join();
	}

	return name;
}

export const generateClonedProfileName = (name, names = []) => {
	const rootName = getRootName(name);
	const cloneName = `${rootName}${CLONE_SUFFIX}`;
	const matchedNames = names.filter((n) => n.startsWith(cloneName));
	return matchedNames.length === 0 ? cloneName : `${cloneName} ${matchedNames.length + 1}`;
};
