import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import FontsRow from "./FontsRow";

const FontsBlock = ({ isLoading, data }) => {
	if (data.length === 0) {
		return <EmptyRow>No available Fonts.</EmptyRow>
	}

	return (
		<FontContainer>
			{/* {data.map((font) => <FontsRow key={font.id} font={font} /> )} */}
		</FontContainer>
	);
};

const FontContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	background-color: rgb(243, 243, 243);
`;

const EmptyRow = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 12px;
	width: 100%;
	background-color: #fff;
	color: #535252;
	height: 44px;
	border: 1px solid rgb(232, 232, 232);
`;

FontsBlock.propTypes = {
	data: PropTypes.array.isRequired,
};

export default FontsBlock;
