// eslint-disable-next-line no-unused-vars
import React from "react";
import styled from "styled-components";

const Link = styled.a`
	a:active,
	a:visited {
		color: cornflowerblue !important;
	}
	color: cornflowerblue !important;
	font-size: 14px;
	font-weight: 700;
`;

export default Link;
