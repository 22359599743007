import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import { Section, Dropdown } from "../../../../../../components/form";
import { Divider } from "../../../../../stats/styledComponents";
import { eligibilityThresholds } from "../../../../eligibility";
import { ICON_SIZES } from "../../../../../../constants/common";
import { Button } from "../../../../../../components";
import { AndStatementWrapper } from "./utils/Sections.styled";

const RenderedItems = () => {
	const [input, setInput] = useState("Choose...");
	const addRenderedItemsData = () => console.log("test");

	return (
		<AndStatementWrapper>
			<Section title="Rendered Items">
				<Divider />
				<Dropdown
					label="Item Source"
					field="event"
					onChange={(event) => setInput(event.value.target)}
					value={input}
					options={eligibilityThresholds["field"]["options"]}
				/>
			</Section>
			<Button theme="blue" onClick={() => addRenderedItemsData}>
				{/* add inputs here*/}
				<PlusCircle size={ICON_SIZES.medium} />
				AND Statement
			</Button>
		</AndStatementWrapper>
	);
};

export default RenderedItems;
