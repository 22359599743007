export const eligibilityTriggersOptions = [
	{
		name: "Choose...",
		value: "default",
	},
	{
		name: "Progression",
		value: "progression",
	},
	{
		name: "End of visit",
		value: "endvisit",
	},
	{
		name: "Inactive",
		value: "inactive",
	},
	{
		name: "Request Email",
		value: "requestemail",
	},
	{
		name: "Request Send",
		value: "requestsend",
	},
	{
		name: "SMS Subscribe",
		value: "subscribe",
	},
	{
		name: "Product Change",
		value: "product change",
	},
	{
		name: "SMS Transfer",
		value: "transfer",
	},
	{
		name: "Batch",
		value: "batch",
	},
	{
		// disable if not productChangeNewsEligible
		name: "Product Change News",
		value: "product change news",
	},
];

// if option type is progression show label Action
export const progression = [
	{
		name: "Choose...",
		value: "",
	},
	{
		name: "Been sent",
		value: "send",
	},
	{
		name: "Opened",
		value: "open",
	},
	{
		name: "Clicked",
		value: "click",
	},
	{
		name: "Sent App Push",
		value: "attempt_app_push",
	},
	{
		name: "Sent SMS",
		value: "attempt_sms",
	},
];
