import React, { useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { ToolTip, ReactLink } from "../../../components";
import { seriesPropTypes, seriesPagePropTypes } from "../propTypes";

const SeriesLSBContainer = ({ seriesData }) => {
	const { params } = useSelector((state) => state.page);
	const [errorTooltip, setShowErrorTooltip] = useState(false);

	const {
		attributes: { name, active, error },
	} = seriesData;

	return (
		<>
			<h1 className="nav-list_header">Series</h1>
			<ReactLink href={`/experiences/inbox-react/series/${seriesData.id}/edit`}>
				<LinkDiv selected={params?.seriesId === String(seriesData.id)}>
					<Circle className={`icon-circle ${active ? "u-color-on" : "u-color-off"}`} />
					<Text bold>{name}</Text>
					{error && (
						<div>
							<Triangle
								onMouseEnter={() => setShowErrorTooltip(true)}
								onMouseLeave={() => setShowErrorTooltip(false)}
							>
								triangle error
							</Triangle>
							{errorTooltip && <ToolTip content={error}>test</ToolTip>}
						</div>
					)}
				</LinkDiv>
			</ReactLink>
		</>
	);
};

const LinkDiv = styled.div`
	display: flex;
	font-weight: ${(props) => (props.selected ? "bold" : "")};
	text-align: center;
	flex-direction: row;
	background-color: ${(props) => (props.selected ? "#e8e8e8" : "")} !important;
	padding: 0.75rem;
`;

const Circle = styled.i`
	display: inline-block;
	margin-right: 2px;
	height: 100%;
	place-self: center;
	margin: 0 1rem 1rem 1rem;
`;

const Triangle = styled.div`
	width: 100px;
	height: 100px;
	position: relative;
	font-size: 20px;
	top: 200px;
	text-align: center;
	margin: auto;
`;

const Text = styled.div`
	color: #626161;
	font-size: 0.857rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
	font-weight: ${(props) => (props.bold ? "bold" : 600)};
	line-height: 1.45;
	text-align: left;
`;

SeriesLSBContainer.propTypes = {
	seriesData: PropTypes.oneOfType([seriesPropTypes, seriesPagePropTypes]),
};

export default SeriesLSBContainer;
