import React from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import PropTypes from "prop-types";
import 'react-toastify/dist/ReactToastify.css';

const SidebarWrapperContainer = styled.div`
	position: absolute;
	top: ${({ topHeight }) => topHeight};
    left: 270px;
    right: 0;
    z-index: 10;
`;

const SidebarWrapper = ({
	children,
}) => {
	const topHeight = process.env.REACT_APP_ENVIRONMENT === "production" ? "22px" : "45px";
	return (
		<>
			<SidebarWrapperContainer
				id="react-wrapper"
				topHeight={topHeight}
			>
				{children}
			</SidebarWrapperContainer>
			<ToastContainer
				position="top-center"
				limit={1}
				hideProgressBar
				autoClose={1500}
				theme="colored"
			/>
		</>
	);
};

SidebarWrapper.propTypes = {
	pageTitle: PropTypes.string.isRequired,
	children: PropTypes.node,
};

export default SidebarWrapper;
