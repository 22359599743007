export const groupCampaignFamily = (payload) => {
    const organizedSeries = [];
    let campaigns = [...payload.data];
    let series = [...payload.included];

    for(const {id, attributes} of campaigns) {
        const campaignId = id;
        const seriesId = attributes['campaign-series-id']

        const seriesMetadata = {
            id: seriesId,
            children: []
        }

        // Get series by id
        const currentSeries = series.find(({ id }) => id === seriesId)
        const seriesIndex = series.indexOf(currentSeries)
        // Remove the series because we don't need it again
        series.splice(seriesIndex, 1)

        // Get all campaigns in order from rest of campaigns
        let progenyIds = currentSeries.relationships.children.data.map(({ id }) => id)
        progenyIds = progenyIds.concat(currentSeries.relationships.grandchildren.data.map(({ id }) => id))
        for(const progenyId of progenyIds) {
            const progeny = campaigns.find(({ id }) => id === progenyId)
            const progenyIndex = campaigns.indexOf(progeny)
            seriesMetadata.children.push(progeny)

            if(progenyId !== campaignId) {
                campaigns.splice(progenyIndex, 1)
            }
        }

        organizedSeries.push(seriesMetadata)
    }

    return organizedSeries
}

export const groupSeriesFamily = ({ data, included: allMembers }) => {

    const groupedFamily = {
        attributes: data.attributes,
        children: [],
        grandchildren: [],
        leader: {},
        website: {},
        provider: {}
    }

    const keyedMembers = {}

    for(const member of allMembers) {
        keyedMembers[member.id] = member
    }


    const { leader, website, children, grandchildren } = data.relationships

    groupedFamily.leader = keyedMembers[leader.data.id]
    groupedFamily.website = keyedMembers[website.data.id]
    for(const child of children.data) {
        const member = keyedMembers[child.id]
        const fullChild = {
            attributes: member.attributes,
            children: []
        }
        const progenyIds = member.relationships.children.data
        for(const { id } of progenyIds) {
            fullChild.children.push(keyedMembers[id])
        }
        groupedFamily.children.push(fullChild)
    }
    for(const grandchild of grandchildren.data) {
        groupedFamily.grandchildren.push(keyedMembers[grandchild.id])
    }

    return groupedFamily
}
