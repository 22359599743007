import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
	WebsiteSwitcher,
	Search,
	Select,
} from "../../../components";

export const ArchiveButtons = [
	{
		title: "Active",
		value: 0,
		qa: "active-buttons-button"
	},
	{
		title: "Archived",
		value: 1,
		qa: "active-buttons-archived"
	},
];

const changeFilter = (params, value, resetFunction) => {
	const url = window.location.pathname;
	const urlParams = new URLSearchParams(window.location.search);

	if (value) {
		urlParams.set(params, value);
		urlParams.delete("page");
		resetFunction(value);
	} else {
		urlParams.delete(params);
		resetFunction(value);
	}

	const newUrl = `${url}?${urlParams.toString()}`;
	window.history.pushState({ path: newUrl }, "", newUrl);
};


const FontsFilter = ({ onChange, searchValue, sortValue, archivedValue, setArchived }) => {

	const options = [
		{ id: "created", name: "Most recently created" },
		{ id: "name", name: "Font Name" }
	];

	const updateFilter = (type, value, filterType) => changeFilter(type, value, filterType);

	return (
		<FiltersContainer>
			<WebsiteSwitcher />
			<Search
				dataQA="cms-search-input"
				value={searchValue}
				onChange={(value) => onChange({ type: "searchStr", value })}
				placeholder="Search..."
				label="Search"
			/>
			<Select
				dataQA="sort-value-dropdown"
				data={options}
				value={sortValue}
				onChange={(value) => onChange({ type: "sortBy", value })}
			/>
			<ButtonContainer>
				{ArchiveButtons.map(({ title, value, qa }) => (
					<Button
						data-qa={qa}
						key={title}
						isActive={archivedValue === value}
						onClick={() => updateFilter("archived", value, setArchived)}
					>
						{title}
					</Button>
				))}
			</ButtonContainer>
		</FiltersContainer>
	)
};

const FiltersContainer = styled.div`
	margin: 11px 0;
	box-sizing: border-box;
	& > div:not(:first-of-type) {
		margin: 11px 0;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Button = styled.button`
	flex: 1 1 auto;
	background: #ffffff;
	font-size: 10px;
	padding: 12px 0px;
	text-transform: uppercase;
	border-radius: 0;

	border: ${({ isActive }) => isActive
        ? "1px solid rgb(48, 61, 120)"
        : "1px solid rgb(232, 232, 232)"
    };

    color: ${({ isActive }) => isActive
        ? "rgb(48, 61, 120)"
        : "rgb(25, 25, 25)"
    };
`;

FontsFilter.propTypes = {
	onChange: PropTypes.func.isRequired,
	searchValue: PropTypes.string.isRequired,
	sortValue: PropTypes.string.isRequired,
	archivedValue: PropTypes.number.isRequired,
	setArchived: PropTypes.func.isRequired,
};

export default FontsFilter;
