import styled from 'styled-components'

export const ListElement = styled.div`
    display: flex;
`

export const Bullet = styled.div`
    margin: 0 0.786rem;
    font-weight: 600;
    font-size: 0.857rem;
    color: #191919;
    flex-shrink: 2;
    display: flex;
    align-items: center;
`

export const Content = styled.div`
    flex: 1;
`