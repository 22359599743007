import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import DraggableCampaignRow from "./DraggableCampaignRow"
import { Loader, EmptyRow } from "../../../components";
import { selectAllInbox } from "../../../redux/inbox/inboxAdapter";
import { campaignsSetOrder } from "../../../redux/inbox/thunks";
import { clearSelectedCampaigns } from "../../../redux/campaigns/slice";

import InboxBlock from "./InboxBlock";

const InboxTable = ({ isLoading, view }) => {
	const dispatch = useDispatch();
	const inboxCampaigns = useSelector(selectAllInbox);
	const [draggingCampaignIds, setDraggingCampaignIds] = useState([]);
	const websiteId = useSelector((state) => state?.page?.params?.websiteId);
	const campaignsOrder = inboxCampaigns.map((campaign) => campaign.id);

	let parentCampaigns = inboxCampaigns.filter(
		({ attributes = {} }) =>
			attributes["test-type"] === "parent" && attributes["is-leader"] === true
	);

	const changeOrderHandler = async (newOrder) => {
		const response = await dispatch(
			campaignsSetOrder({
				website_id: websiteId,
				campaign_ids: newOrder,
			})
		);

		const { requestStatus } = response?.meta || {};

		if (requestStatus === "fulfilled") {
			dispatch(clearSelectedCampaigns());

			toast.success("Order saved!", { autoClose: 1000 });
		} else {
			toast.error("An error occurred", { autoClose: 1000 });
		}
	};

	if (inboxCampaigns.length === 0 && !isLoading) {
		return <EmptyRow>No Experiences Found</EmptyRow>;
	}

	return (
		<TableContainer>
			{isLoading && <Loader isLoading={isLoading} />}
			{view === "list" && (
				<>
					{parentCampaigns.map((inbox) => (
						<InboxBlock key={inbox.id} campaign={inbox} />
					))}
				</>
			)}
			{view === "reorder" && (
				<>
					{parentCampaigns.map((campaign) => {
						return(
							<DraggableCampaignRow
							key={campaign.id}
							campaign={campaign}
							campaignsOrder={campaignsOrder}
							draggingCampaignIds={draggingCampaignIds}
							onChange={changeOrderHandler}
							onDragging={setDraggingCampaignIds}
							/>
						)}
					)}
				</>
			)}
		</TableContainer>
	);
};

const TableContainer = styled.div`
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	background-color: rgb(243, 243, 243);
`;

InboxTable.propTypes = {
	view: PropTypes.oneOf(["list", "reorder"]).isRequired,
	isLoading: PropTypes.bool.isRequired,
};

export default InboxTable;
