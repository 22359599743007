import { createSlice } from "@reduxjs/toolkit";
import { getTopStats } from "./thunks";

const initialState = {
  isLoading: false,
	response: {
		website: {},
		top_stats: {
			list_expansion: {},
			ibx_retargeting: {},
			email_acquisition: {},
			cms_modules: [],
			usage: {
				campaigns_run: {},
				variations_run: {},
			},
			chart_data: {
				submits: [],
				revenue: [],
				usage: [],
			},
		},
	},
};

const topStatsSlice = createSlice({
  name: "topStats",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getTopStats
    builder.addCase(getTopStats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getTopStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.response = action.payload;
    });
    builder.addCase(getTopStats.rejected, (state) => {
      state = initialState;
    });
  },
});

export default topStatsSlice.reducer;
