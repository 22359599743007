import { createEntityAdapter } from "@reduxjs/toolkit";

const websitesAdapter = createEntityAdapter({
	selectId: (website) => website.id || website.data?.id || Math.random(),
});

export const {
	selectAll: selectAllWebsites
} = websitesAdapter.getSelectors((state) => {
	return state.websiteMicros.response.data;
});

export default websitesAdapter;
