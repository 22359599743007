import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import {
	Navigation,
	PositionContainer,
	Left,
	Middle,
	Right,
	Link,
} from "./PageNavbar.styled";
import { ReactLink } from ".";

const PageNavbar = ({ left, middle, right }) => {
	return (
		<Navigation>
			<PositionContainer>
				{left &&
					left.map((element, i) => {
						return <Left key={`left-${i}`}>{element}</Left>;
					})}
			</PositionContainer>
			<PositionContainer>
				{middle &&
					middle.map((element, i) => {
						return <Middle key={`middle-${i}`}>{element}</Middle>;
					})}
			</PositionContainer>
			<PositionContainer>
				{right &&
					right.map((element, i) => {
						return <Right key={`right-${i}`}>{element}</Right>;
					})}
			</PositionContainer>
		</Navigation>
	);
};

export const PageNavbarLink = ({ name, callback, tip, appendTipIcon }) => {
	return (
		<>
			<Link data-tip data-for={name} onClick={callback}>
				<div>{name}</div>
			</Link>
			{tip && (
				<ReactTooltip id={name} place="bottom" effect="solid">
					{tip} {appendTipIcon && <i className={appendTipIcon} />}
				</ReactTooltip>
			)}
		</>
	);
};

export const PageNavbarIcon = ({
	name,
	callback,
	iconClass,
	appendTipIcon,
	disable,
}) => {
	return (
		<>
			<Link data-tip data-for={name} onClick={callback} disable={disable}>
				<i className={iconClass} />
			</Link>
			{!disable && (
				<ReactTooltip id={name} place="bottom" effect="solid">
					{name} {appendTipIcon && <i className={appendTipIcon} />}
				</ReactTooltip>
			)}
		</>
	);
};

export const PageNavbarView = ({ name, link, iconClass, highlight }) => {
	return (
		<ReactLink href={link}>
			<Link highlight={highlight} className="ViewPage">
				<i className={iconClass} />
				<div>{name}</div>
			</Link>
		</ReactLink>
	);
};

PageNavbarLink.propTypes = {
	callback: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	tip: PropTypes.string,
	appendTipIcon: PropTypes.string,
};

PageNavbarIcon.propTypes = {
	callback: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	iconClass: PropTypes.string.isRequired,
	appendTipIcon: PropTypes.string,
	disable: PropTypes.bool,
};

PageNavbarView.propTypes = {
	link: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	iconClass: PropTypes.string.isRequired,
	highlight: PropTypes.bool,
};

PageNavbar.propTypes = {
	left: PropTypes.array,
	middle: PropTypes.array,
	right: PropTypes.array,
};

export default PageNavbar;
