import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getReports } from "../../redux/report/utils";
import { reportTypes } from "../../constants/common";
import { formatDateString } from "../../utils";
import { getDefaultReportOptions } from "../../redux/report/businessLogic/updateReportOptions";
import CampaignReport from "./campaign";
import WebsiteComparison from "./websiteComparison";
import Conversations from "./conversation";
import EmailCapture from "./emailCapture";
import { clearReportData, updateReport } from "../../redux/report/slice";
import { selectAllReports } from "../../redux/report/reportsAdapter";

// Reports that use graphQL instead of the analytics API
const REPORTS_GRAPH_QL = [
	reportTypes.websiteComparison,
	reportTypes.parentCampaignComparison,
	reportTypes.childCampaignComparison,
	reportTypes.websiteCampaign,
	reportTypes.parentCampaign,
	reportTypes.childCampaign,
];

export default () => {
	// INFO: get Redux store info
	let {
		type,
		website_id,
		parent_campaign_id,
		campaign_id,
		date_start,
		date_end,
	} = useSelector(({ page: { params = {} } }) => params);
	website_id = Number(website_id);
	parent_campaign_id = Number(parent_campaign_id);
	campaign_id = Number(campaign_id);
	// INFO: don't render any reports if we don't know the report type
	if (!type) {
		return <span></span>;
	}
	// INFO: Get store values
	const {
		makeApiCall,
		summary,
		csvFileName,
		csvURL,
		title,
		total,
		reportOptions,
		totalNumOfData,
		next_date,
		next_cursor,
	} = useSelector((state) => {
		return state.report;
	});
	const isLoading = useSelector(({ page: { isLoading } }) => isLoading);
	const data = useSelector(selectAllReports);

	// INFO : Setup Redux store updates
	const dispatch = useDispatch();
	const onChangeReportOptions = (options) => {
		dispatch(updateReport({ options, type }));
	};
	// INFO: Handle all API calls
	const getNewReport = createAPICall(
		type,
		website_id,
		parent_campaign_id,
		campaign_id,
		dispatch,
		reportOptions,
		date_start,
		date_end
	);
	const loadMore = createLoadMore(
		next_date,
		next_cursor,
		type,
		website_id,
		parent_campaign_id,
		campaign_id,
		reportOptions,
		dispatch
	);

	// INFO: find the right react report component
	const Report = getReport(type);

	if (!Report) {
		return <span></span>;
	}

	// INFO: make API for data
	useEffect(() => {
		// Omits analytics API calls for reports that use graphQL
		const isNotGraphQL= !REPORTS_GRAPH_QL.includes(type);

		if (makeApiCall && isNotGraphQL) {
			getNewReport();
		}
	}, [makeApiCall]);

	//INFO: ONLY ON FIRST PAGE LOAD, clear reportOptions for each new page visit
	useEffect(() => {
		const defaultOptions = getDefaultReportOptions(type, date_start, date_end);
		dispatch(updateReport({
			options: defaultOptions,
			type,
		}));
	}, []);

	return (
		<Report
			website_id={website_id}
			title={title}
			data={data}
			summary={summary}
			csvFileName={csvFileName}
			csvURL={csvURL}
			type={type}
			parent_campaign_id={parent_campaign_id}
			campaign_id={campaign_id}
			reportOptions={reportOptions}
			onChangeReportOptions={onChangeReportOptions}
			getNewReport={getNewReport}
			isLoading={isLoading}
			totalNumOfData={totalNumOfData}
			loadMore={loadMore}
			date_start={date_start}
			date_end={date_end}
			total={total}
		/>
	);
};
const getReport = (type) => {
	// TODO: reduce if duplicate not needed.
	return {
		[reportTypes.websiteCampaign]: CampaignReport,
		[reportTypes.parentCampaign]: CampaignReport,
		[reportTypes.childCampaign]: CampaignReport,
		[reportTypes.websiteComparison]: WebsiteComparison,
		[reportTypes.parentCampaignComparison]: WebsiteComparison,
		[reportTypes.childCampaignComparison]: WebsiteComparison,
		[reportTypes.websiteConversions]: Conversations,
		[reportTypes.parentConversions]: Conversations,
		[reportTypes.childConversions]: Conversations,
		[reportTypes.websiteEmailCapture]: EmailCapture,
		[reportTypes.parentEmailCapture]: EmailCapture,
		[reportTypes.childEmailCapture]: EmailCapture,
	}[type];
};

export const createAPICall = (
	type,
	website_id,
	parent_campaign_id,
	campaign_id,
	dispatch,
	reportOptions,
	date_start,
	date_end
) => {
	let queryParams = getDefaultReportOptions(type, date_start, date_end);
	//INFO: We should always send queryPrams to the API, if none use the defaults
	if (Object.keys(reportOptions).length) {
		queryParams = reportOptions;
	}
	return () => {
		const promise = new Promise((resolve) => {
			resolve(dispatch(clearReportData()));
		});

		promise.then(() => {
			dispatch(
				getReports(
					type,
					website_id,
					parent_campaign_id,
					campaign_id,
					queryParams
				)
			);
		});
	};
};

const createLoadMore = (
	next_date,
	next_cursor,
	type,
	website_id,
	parent_campaign_id,
	campaign_id,
	reportOptions,
	dispatch
) => {
	if (!next_date && !next_cursor) {
		return;
	}
	return () => {
		const queryParams = {
			...reportOptions,
			//INFO Due to the change in API architecture email capture uses next_cursor
			// for pagination, but still requires an date_end to remain the same.
			date_end:
				(next_date && formatDateString(new Date(next_date))) ||
				reportOptions.date_end,
			next_cursor,
		};
		dispatch(
			getReports(
				type,
				website_id,
				parent_campaign_id,
				campaign_id,
				queryParams
			)
		);
	};
};
