/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Calendar } from "../components";
import { Dropdown } from "../../../../../components/form";
import { sharedDropDownValues } from "../../../../../constants/common";
import { Button } from "../../../../../components";

const DateRow = ({
	reportOptions = {},
	onChangeReportOptions = () => {},
}) => {
	const { date_start = "", date_end = "", date_interval = "" } = reportOptions;

	return (
		<Row>
			<div>
				<Calendar
					label="Start"
					field="date_start"
					value={date_start}
					onChange={onChangeReportOptions}
				/>
				<Calendar
					label="End"
					field="date_end"
					value={date_end}
					onChange={onChangeReportOptions}
				/>
				<Dropdown
					label="View by"
					field="date_interval"
					onChange={onChangeReportOptions}
					value={date_interval}
					options={viewOptions}
				/>
			</div>
		</Row>
	);
};
DateRow.propTypes = {
	reportOptions: PropTypes.object.isRequired,
	onChangeReportOptions: PropTypes.func.isRequired,
};
const viewOptions = [
	{
		name: "day",
		value: sharedDropDownValues.viewBy.day,
	},
	{
		name: "hour",
		value: sharedDropDownValues.viewBy.hour,
	},
	{
		name: "total",
		value: "total",
	},
];
const trafficOptions = [
	{
		name: "Normal",
		value: "normal",
	},
	{
		name: "Global Control",
		value: "global",
	},
];
const Row = styled.div`
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
	div + div {
		button {
			max-width: 100px;
		}
	}
`;

export default DateRow;
