import { merge } from "lodash";
import moment from "moment";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import {
	INBOX_SERIES_URL,
	BLANK_INBOX_SERIES_DATA,
	INBOX_CAMPAIGNS_URL,
	BLANK_INBOX_CAMPAIGNS_DATA,
} from "./constants";

export const duplicateCampaign = createAsyncThunk("campaigns/duplicate", async ({ campaignId }) => {
	const url = `${endpoints.coreApi}/campaigns/duplicate`;

	const response = await ApiService.postAsForm(url, { payload: `id=${campaignId}` });

	if (!response.ok) {
		throw new Error("Failed to duplicate campaign");
	}
	return response.json();
});

export const getInboxSeries = createAsyncThunk("inboxSeries/getInboxSeries", async (path = "") => {
	const url = `${endpoints.coreApi}${INBOX_SERIES_URL}`;
	const response = await ApiService.get(`${url}/${path}`);

	if (!response.ok) {
		throw new Error("Failed to load campaigns!");
	}

	return response.json();
});

export const saveInboxSeries = createAsyncThunk("inboxSeries/saveInboxSeries", async (series) => {
	const url = `${endpoints.coreApi}${INBOX_SERIES_URL}/${series.id}`;
	const response = await ApiService.patch(url, { payload: { data: series } });

	if (!response.ok) {
		throw new Error("Failed to load inbox campaigns!");
	}

	return response.json();
});

export const createNewInboxSeries = createAsyncThunk(
	"inboxSeries/createNewInboxSeries",
	async ({
		parentName,
		campaignPurposeId,
		product,
		websiteId,
		seriesInboxName,
		isChild,
		clickParams,
		variationName,
		emailType,
		testType,
	}) => {
		const urlInboxSeries = `${endpoints.coreApi}${INBOX_SERIES_URL}`;
		const urlInboxCampaigns = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}`;
		const currentDate = moment().format("YYYY-MM-DD HH:mm:ss");

		const formDataInboxSeries = {
			data: {
				attributes: {
					name: seriesInboxName,
					product: product,
				},
				relationships: {
					website: {
						data: {
							type: "websites",
							id: websiteId,
						},
					},
				},
				type: "inbox-series",
			},
		};

		const submitDataInboxSeries = merge(BLANK_INBOX_SERIES_DATA, formDataInboxSeries);
		const responseInboxSeries = await ApiService.post(urlInboxSeries, {
			payload: submitDataInboxSeries,
		});

		const responseInboxSeriesToJson = await responseInboxSeries.json();

		const formDataInboxCampaignsParent = {
			data: {
				attributes: {
					name: parentName,
					product: product,
					"click-params": clickParams,
					"interval-val": 30,
					"campaign-series-id": campaignPurposeId,
				},
				relationships: {
					"campaign-purpose": {
						data: {
							type: "campaign-purposes",
							id: campaignPurposeId,
						},
					},
					"inbox-series": {
						data: {
							type: "inbox-series",
							id: responseInboxSeriesToJson?.data?.id,
						},
					},
					website: {
						data: {
							type: "websites",
							id: websiteId,
						},
					},
				},
				type: "inbox-campaigns",
			},
		};

		const submitDataInboxCampaignsParent = merge(
			BLANK_INBOX_CAMPAIGNS_DATA,
			formDataInboxCampaignsParent
		);
		const responseInboxCampaigns = await ApiService.post(urlInboxCampaigns, {
			payload: submitDataInboxCampaignsParent,
		});

		const responseInboxCampaignsToJson = await responseInboxCampaigns.json();

		if (responseInboxSeriesToJson && responseInboxCampaignsToJson) {
			const patchInboxSeriesData = {
				data: {
					attributes: {
						name: seriesInboxName,
						product: product,
						pinned: 1,
						active: 1,
						"dt-inserted": currentDate,
					},
					relationships: {
						children: {
							data: [
								{
									type: "inbox-campaigns",
									id: responseInboxCampaignsToJson?.data?.id,
								},
							],
						},
						leader: {
							data: {
								type: "inbox-campaigns",
								id: responseInboxCampaignsToJson?.data?.id,
							},
						},
						website: {
							data: {
								type: "websites",
								id: websiteId,
							},
						},
					},
					type: "inbox-series",
					id: responseInboxSeriesToJson?.data?.attributes?.id,
				},
			};

			const patchInboxCampaignData = {
				data: {
					attributes: {
						"click-params": clickParams,
						"campaign-series-id": responseInboxSeriesToJson?.data?.attributes?.id,
						name: parentName,
						product: product,
						"series-name": seriesInboxName,
						"dt-edited": currentDate,
						"dt-inserted": currentDate,
						"interval-val": 30,
						tags: "null",
						"custom-tab-title":
							'{"title":null,"favicon_url":null,"favicon_type":"","effect":null}',
					},
					relationships: {
						leader: {
							data: {
								type: "inbox-campaigns",
								id: responseInboxCampaignsToJson?.data?.id,
							},
						},
						"inbox-series": {
							data: {
								type: "inbox-series",
								id: responseInboxSeriesToJson?.data?.attributes?.id.toString(),
							},
						},
						"campaign-purpose": {
							data: {
								type: "campaign-purposes",
								id: campaignPurposeId,
							},
						},
						website: {
							data: {
								type: "websites",
								id: websiteId,
							},
						},
					},
					type: "inbox-campaigns",
					id: responseInboxCampaignsToJson?.data?.id,
				},
			};
			const patchInboxSeriesLink = `${endpoints.coreApi}${INBOX_SERIES_URL}/${responseInboxSeriesToJson?.data?.attributes?.id}`;
			const patchDataInboxSeries = merge(BLANK_INBOX_SERIES_DATA, patchInboxSeriesData);
			await ApiService.patch(patchInboxSeriesLink, { payload: patchDataInboxSeries });

			const patchInboxCampaignLink = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}/${responseInboxCampaignsToJson?.data?.id}`;
			const patchDataInboxCampaings = merge(
				BLANK_INBOX_CAMPAIGNS_DATA,
				patchInboxCampaignData
			);
			await ApiService.patch(patchInboxCampaignLink, { payload: patchDataInboxCampaings });
		}

		if (isChild && responseInboxSeriesToJson && responseInboxCampaignsToJson) {
			console.log("responseInboxSeriesToJson", responseInboxSeriesToJson);
			console.log("responseInboxCampaignsToJson", responseInboxCampaignsToJson);
			const formDataInboxCampaignsChild = {
				data: {
					attributes: {
						"email-preheader": "",
						"email-subject": "Example Subject Line",
						"email-creative-json":
							'{"blocks":[{"order":1,"type":"header","template":""},{"order":2,"type":"main","template":""},{"order":3,"type":"footer","template":""}]}',
						"test-items-json":
							'[{"name":"Items","label":"Items","count":6},{"name":"SavedItems","label":"Saved","count":0},{"name":"UserItems","label":"User","count":0},{"name":"RecommendedItems","label":"Recommended","count":0},{"name":"CartItems","label":"Cart","count":0}]',
						name: variationName,
						"test-type": testType,
						"is-leader": false,
						product: product,
						"campaign-series-id": responseInboxSeriesToJson?.data?.attributes?.id,
					},
					relationships: {
						parent: {
							data: {
								type: "inbox-campaigns",
								id: responseInboxCampaignsToJson?.data?.id,
							},
						},
						"inbox-series": {
							data: {
								type: "inbox-series",
								id: responseInboxSeriesToJson?.data?.id,
							},
						},
						"campaign-type": {
							data: {
								type: "campaign-types",
								id: emailType,
							},
						},
						website: {
							data: {
								type: "websites",
								id: websiteId,
							},
						},
					},
					type: "inbox-campaigns",
				},
			};

			const submitDataInboxCampaignsChild = merge(
				BLANK_INBOX_CAMPAIGNS_DATA,
				formDataInboxCampaignsChild
			);
			await ApiService.post(urlInboxCampaigns, { payload: submitDataInboxCampaignsChild });
		}

		if (!responseInboxCampaigns.ok) {
			throw new Error("Failed to load inbox series!");
		}

		return responseInboxCampaignsToJson;
	}
);

export const crossSiteClonning = createAsyncThunk(
	"inboxSeries/cross_site_duplicate",
	async ({ template, cloneTo }) => {
		const url = `${endpoints.coreApi}/inbox_series/cross_site_duplicate`;
		const body = `targetSiteIds[]=${template}&websiteIds[]=${cloneTo}`

		const response = await ApiService.postAsForm(url, { payload: body });

		if (!response.ok) {
			throw new Error("Error duplicating.");
		}

		return response.json();

	}
);
