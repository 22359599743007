import { createEntityAdapter } from "@reduxjs/toolkit";

const reportsAdapter = createEntityAdapter({
	selectId: (report) => report.id || Math.random(),
});

export const {
	selectAll: selectAllReports,
} = reportsAdapter.getSelectors((state) => state.report.data);

export default reportsAdapter;
