const getPageCounterErrors = (formState, formErrors) => {
    if(formState.pageCount.interval === "") {
        formErrors["pageCount.interval"] = `Page Counter interval is required.`;
    }

    if (isNaN(formState.pageCount.interval)) {
        formErrors["pageCount.interval"] = `Page Counter interval must be numeric. Got "${formState.pageCount.interval}".`;
    } else if (formState.pageCount.interval < 0){
        formErrors["pageCount.interval"] = `Page Counter interval can not be negative. Got "${formState.pageCount.interval}".`;
    }
    return formErrors;
}

const getApiSettingsErrors = (formState, formErrors) => {
    formState.apiIntegrations.forEach((settings, index) => {
        const valueSettings = Object.values(settings);
        // It's ok to leave all blanks
        if (valueSettings.join("") === "") {
            return;
        }
        
        if (valueSettings.some(v => v === "")) {
            Object.entries(settings).forEach(([key, val]) => {
                if (val === "") {
                    formErrors[`apiIntegrations[${index}].${key}`] = "Enter all API settings or clear them all.";
                }
            });
            return;
        }

        if (settings.url) {
            try {
              new URL(settings.url);  
            } catch(e) {
                formErrors[`apiIntegrations[${index}].url`] = `"${settings.url}" is not a valid url.`;
            }
        }
    });
    return formErrors;
}

const getFeSettingsErrors = (formState, formErrors) => {
    Object.entries(formState.integrations).forEach(([intKey, settings]) => {
        const valueSettings = Object.values(settings);
        // It's ok to leave all blanks
        if (valueSettings.join("") === "") {
            return;
        }
        
        if (valueSettings.some(v => v === "")) {
            Object.entries(settings).forEach(([key, val]) => {
                if (val === "") {
                    formErrors[`integrations.${intKey}.${key}`] = `Enter all "${intKey}" settings or clear them all.`;
                }
            });
        }        
    });
    return formErrors;
}

export const getValidationErrors = (formState) => {
    const formErrors = {};
    return {
        ...getApiSettingsErrors(formState, formErrors),
        ...getFeSettingsErrors(formState, formErrors),
        ...getPageCounterErrors(formState, formErrors)
    };
}