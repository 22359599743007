import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { COLORS_MAP } from "../constants";

const BehavioralLogicBlock = ({ parentCampaign, changeCampaign, parentTrafficType }) => {
	const {
		response: { data: parentCampaignTypes = [] },
	} = useSelector((state) => state.parentCampaignTypes);

	const { selectedCampaigns } = useSelector((state) => state.campaigns);
	const selectedCampaign = selectedCampaigns.length === 1 ? selectedCampaigns[0] : {};

	const {
		response: { data: purposes = [] },
	} = useSelector((state) => state.purposes);

	const getPurpose = () => {
		// Match campaign purpose from campaignPurpose array and grab its name
		const campaignPurposeId = selectedCampaign?.relationships?.["campaign-purpose"]?.data?.id;
		const purpose = purposes.find((purpose) => purpose.id === campaignPurposeId);
		const purposeName = !purpose ? "Loading" : purpose?.attributes?.name;
		// Find parent campaign type (aka subpurpose) from the parentCampaignType array and grab its name
		const parentCampaignTypeId =
			selectedCampaign?.relationships?.["parent-campaign-type"]?.data?.id;
		const parentCampaign = parentCampaignTypes.find(
			(purpose) => purpose.id === parentCampaignTypeId
		);
		const parentCampaignName = !parentCampaign
			? "Select Subpurpose"
			: parentCampaign?.attributes?.name;
		return `${purposeName} (${parentCampaignName})`;
	};

	const getIconColor = (iconType) => {
		switch (iconType) {
			case "on":
				return COLORS_MAP.on;
			case "test":
				return COLORS_MAP.test;
			case "off":
				return COLORS_MAP.off;
			default:
				return COLORS_MAP.disabled;
		}
	};

	const isSelected = parentCampaign?.attributes?.id === selectedCampaign?.attributes?.id;

	const selectCampaign = () => {
		changeCampaign(parentCampaign);
	};

	const backgroundColor = isSelected ? "rgb(232, 232, 232)" : "transparent";
	const purpose = getPurpose();

	return (
		<BehavioralLogicRowContainer
			bgColor={backgroundColor}
			onClick={selectCampaign}
			isActive={isSelected}
		>
			<Icon className="icon-circle" color={getIconColor(parentTrafficType)} />

			<CampaignContainer data-qa="campaign-purpose">
				{purpose} -{" "}
				{parentCampaign?.id === selectedCampaign?.id
					? selectedCampaign.attributes?.name
					: parentCampaign?.attributes?.name}
			</CampaignContainer>
		</BehavioralLogicRowContainer>
	);
};

const BehavioralLogicRowContainer = styled.button`
	display: flex;
	align-items: flex-start;
	width: 100%;
	padding: 0.786rem 1.571rem;
	border: 0;
	background: transparent;
	color: #626161;
	font-size: 0.857rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
	font-weight: 600;
	line-height: 1.45;
	text-align: left;
	border-radius: 0;
	color: ${({ isActive }) => (isActive ? "rgb(48, 61, 120)" : "rgb(25, 25, 25)")};
	background-color: ${({ bgColor }) => bgColor || "transparent"};
`;

const Icon = styled.i`
	margin-right: 4px;
	padding-top: 3px;
	color: ${({ color }) => color};
`;

const CampaignContainer = styled.span`
	padding: 0 0 0 0.786rem;
	font-size: 0.857rem;
	font-weight: 600;
`;

BehavioralLogicBlock.propTypes = {
	parentCampaign: PropTypes.object.isRequired,
	changeCampaign: PropTypes.func.isRequired,
	getTrafficType: PropTypes.func.isRequired,
	selectedTrafficType: PropTypes.string.isRequired,
	parentTrafficType: PropTypes.string.isRequired,
};

export default BehavioralLogicBlock;
