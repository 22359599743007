import { createSlice } from "@reduxjs/toolkit";
import reportsAdapter from "./reportsAdapter";
import { loadCampaignData } from "./businessLogic/loadCampaign";
import { loadComparisonData } from "./businessLogic/loadComparison";
import { loadConversionData } from "./businessLogic/loadConversion";
import { updateReportOptions } from "./businessLogic/updateReportOptions";
import { loadEmailCapture } from "./businessLogic/loadEmailCapture";

const initialState = {
	data: reportsAdapter.getInitialState(),
	dataQl: null, // property for graphQl response
	//INFO : default true to load report on page load
	makeApiCall: true,
	reportOptions: {},
	totalNumOfData: 0,
	//INFO: next_date or next_cursor is used for pagination
	// if both are missing then there is no more data to load
	// email capture api only uses next_cursor and not next_date
	// like the rest of the of the endpoints.
	next_date: "",
	next_cursor: "",
	title: "",
	summary: [],
	csvFileName: "",
	csvURL: "",
};

const reportSlice = createSlice({
	name: "report",
	initialState,
	reducers: {
		clearReportData(state) {
			state.summary = [];
			state.csvFileName = "";
			reportsAdapter.removeAll(state.data);
		},
		loadCampaignReports(state, action) {
			loadCampaignData(state, action);
		},
		updateReport(state, action) {
			updateReportOptions(state, action);
		},
		loadConversion(state, action) {
			state = loadConversionData(state, action);
		},
		loadComparison(state, action) {
			state = loadComparisonData(state, action);
		},
		loadEmail(state, action) {
			loadEmailCapture(state, action);
		},
		setDataQl(state, action) {
			state.dataQl = action.payload;
		}
	},
});

export const {
	clearReportData,
	loadCampaignReports,
	updateReport,
	loadConversion,
	loadComparison,
	loadEmail,
	setDataQl,
} = reportSlice.actions;

export default reportSlice.reducer;
