import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAllWebsites } from "../redux/website-micros/websitesAdapter";
import { setPageParams } from "../redux/page/slice";
import { SearchableSelect } from ".";

const WebsiteSwitcher = () => {
	const {
		params: { websiteId },
	} = useSelector((state) => state.page);
	const websites = useSelector(selectAllWebsites);
	const dispatch = useDispatch();

	const options = websites.map((site) => {
		return {
			value: site.id,
			label: site.attributes.name,
		};
	});

	const changeWebsite = (websiteId) => {
		const url = window.location.pathname;
		const urlParams = new URLSearchParams(window.location.search);

		urlParams.set("website_id", websiteId);
		urlParams.delete("page");
		const newUrl = `${url}?${urlParams.toString()}`;

		window.history.pushState({ path: newUrl }, "", newUrl);
		dispatch(setPageParams({ websiteId }));
	};

	return (
		<SearchableSelect
			placeholder="Website"
			label="Website"
			options={options}
			value={websiteId}
			onChange={changeWebsite}
		/>
	);
};

export default WebsiteSwitcher;
