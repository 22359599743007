import numeral from "numeral";
import moment from "moment";

export const formatGraphData = (data) => {
	const separatedData = {
		labels: [],
		data: [],
	};

	data.forEach((datum) => {
		separatedData.labels.push(datum.date);
		separatedData.data.push(datum.value);
	});

	return separatedData;
};

export const formatDollars = (value) => {
	return numeral(value).format("$0,0");
};

export const formatNumber = (value) => {
	return numeral(value).format("0,0");
};

export const formatPercentage = (value) => {
	return numeral(value / 100).format("0%");
};

export const formatDate = (value) => {
	return moment(value).format("M/D/YYYY");
};

export const formatChartDate = (value) => {
	return moment(value).format("MMM DD, YYYY");
};

export const generateStartDate = () => {
	return moment()
		.subtract({
			days: 1,
			months: 1,
		})
		.format("YYYY-MM-DD");
};

export const generateEndDate = () => {
	return moment()
		.subtract("days", 1)
		.format("YYYY-MM-DD");
};
