//reactApp/redux/purposes/thunks.js
import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";

export const getPurposes = createAsyncThunk(
	"purposes/getPurposes",
	async () => {
		const url = `${endpoints.coreApi}/campaign_purposes`;
		const response = await ApiService.get(url);

		if (!response.ok) {
			throw new Error("Failed to load purposes!");
		}

		return response.json();
	}
);

export const getPurpose = createAsyncThunk(
	"purposes/getPurpose",
	async (id) => {
		const url = `${endpoints.coreApi}/campaign_purposes/${id}`;
		const response = await ApiService.get(url)

		if (!response.ok) {
			throw new Error("Failed to load purpose!");
		}

		return response.json();
	}
)
