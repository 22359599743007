import { useEffect } from "react";
import { toNumber, isArray, difference, intersection } from "lodash";

export const useBindValueToParam = (name, initValue, currentValue, callback) => {
	const protocol = window.location.protocol;
	const host = window.location.host;
	const pathname = window.location.pathname;
	const url = `${protocol}//${host}${pathname}`;

	const setParams = (params) => {
		const stringParams = params.toString();
		const newUrl = stringParams ? `${url}?${stringParams}` : url;
		window.history.pushState({ path: newUrl }, "", newUrl);
	};

	const updateComponentState = () => {
		const searchParams = new URLSearchParams(window.location.search);
		const params = searchParams.getAll(name);

		if (typeof callback === "function") {
			const initValueType = typeof initValue;

			if (initValueType === "string") {
				callback(params[0] || initValue);
			} else if (initValueType === "number") {
				callback(toNumber(params[0]) || initValue);
			} else if (isArray(initValue)) {
				const paramsForReturn = params.length ? params : initValue;
				callback(paramsForReturn);
			}
		}
	};

	const updateSearchParams = () => {
		const searchParams = new URLSearchParams(window.location.search);
		const param = searchParams.get(name);
		const isEqualValues = initValue === currentValue;

		if (param === currentValue.toString() || (!param && isEqualValues)) {
			return;
		}

		if (isEqualValues) {
			searchParams.delete(name);
		} else {
			searchParams.set(name, currentValue);
		}

		setParams(searchParams);
	};

	const updateArrayOfSearchParams = () => {
		const searchParams = new URLSearchParams(window.location.search);
		const remainParams = intersection(initValue, currentValue); // init params that remain in the array
		const addedParams = difference(currentValue, initValue); // params which have been added to array
		const allDiffParams = [...remainParams, ...addedParams];

		if (remainParams.length === initValue.length && !addedParams.length) {
			searchParams.delete(name);
			setParams(searchParams);

			return;
		}

		allDiffParams.forEach((param, index) => {
			if (!index) {
				searchParams.set(name, param);
			} else {
				searchParams.append(name, param);
			}
		});

		setParams(searchParams);
	};

	useEffect(() => {
		window.addEventListener("popstate", updateComponentState);
		updateComponentState();

		return () => {
			window.removeEventListener("popstate", updateComponentState);
		};
	}, []);

	useEffect(() => {
		if (isArray(initValue) && isArray(currentValue)) {
			updateArrayOfSearchParams();
		} else {
			updateSearchParams();
		}
	}, [currentValue]);
};
