import styled from 'styled-components'

export const ActivationContainer = styled.div`
    display: flex;
    margin-right: 0.786rem;
    margin-left: 0.786rem;

    // Kind of weird, helps avoid the seeming
    // double width of borders.
    border-left: 1px solid;
    border-color: ${props => props.selected === 0 ? "#97d483" : "rgba(42, 44, 49, 0.09)"};
`

export const ActivationButton = styled.div`
    padding: 0.214em 0.429em;
    min-width: 2em;
    pointer-events: ${props => props.selected ? "none" : null};
    background-color: ${props => props.selected ? props.color : "transparent"};
    border-style: solid;
    border-width: 1px 1px 1px 0px;
    border-color: ${props => props.selected ? props.color : "rgba(42, 44, 49, 0.09)"};
    display: flex;
    justify-content: center;
    & > i {
        color: ${props => props.selected ? "white" : "rgba(42, 44, 49, 0.09)"};
    }
    :hover {
        background-color: ${props => props.color};
        border-color: ${props => props.color};
        & > i {
            color: white;
        }
    }
`