import { capitalize } from 'lodash';
import { endpoints } from "../constants/common";
import { fontOptionGroups, webfontList } from "../constants/creatives/webfontList";

const getSiteFontIdsFromJson = (jsonObject = {}) => {
	let fontIds = jsonObject.siteFonts ? Object.keys(jsonObject.siteFonts) : [];

	// add brand style siteFonts
	if (jsonObject?.brand?.siteFonts) {
		fontIds = fontIds.concat(Object.keys(jsonObject.brand.siteFonts));
	}

	// an empty siteFont object sets ids=-1 so no fonts are loaded
	if (!fontIds.length && jsonObject.siteFonts) {
		fontIds = [-1];
	}

	return fontIds;
};

const init = () => {
	// init fallbacks for system fonts
	const systemFonts = fontOptionGroups[0];
		systemFonts.options.forEach(systemFont => {
			systemFont.value = `${systemFont.value}, ${systemFont.fallbacks}`;
		});

		const optionGroups = [systemFonts];
		const fontList = webfontList;
		const lookup = {};
		const familyLookup = {};

		Object.keys(fontList).forEach(foundry => {

			// create option group for select
			const newOptionGroup = {
				label: capitalize(foundry),
				options: []
			};

			fontList[foundry].forEach(family => {

				// add family values
				family.rawValue = fontFamilyValue(family);
				family.value = family.rawValue + (family.fallbacks ? ', ' + family.fallbacks : '');

				// add family to select option group
				newOptionGroup.options.push({
					label: family.family,
					value: family.value
				});

				// add family lookups
				if (!familyLookup.hasOwnProperty(family.rawValue)) {
					familyLookup[family.rawValue] = family;
					familyLookup[family.family] = family;
				}

				family.variations.forEach(font => {

					// add derived labels, values, folder, url
					font.family = family.family;
					font.familyValue = family.value;
					font.label = fontWeightLabel(font);
					font.folder = fontFamilyFolder(font);
					font.id = fontId(font);
					font.url = stylesheetUrl(font);

					// add to lookup
					lookup[font.folder] = font;
					lookup[font.id] = font;
				});
			});

			optionGroups.push(newOptionGroup);
		});

		return {
			fontList,
			lookup,
			familyLookup,
			optionGroups,
		};
};

const getSiteFontStylesheet = (websiteId, fontIds, websiteHash) => {
	const ids = fontIds.join(',');
	
	return `${endpoints.legacyApiDomain}/creative/fonts_css/stylesheet.css?website_id=${websiteId}&ids=${ids}&limit=200&_cache=${websiteHash}`;
};

const noSpace = (string) => {
	return string.replace(/\s/g, '');
}

const fontFamilyValue = (font) => {
	return 'bx_' + noSpace(font.family.toLowerCase());
};

const fontWeightLabel = (font) => {
	return capitalize(`${font.cssWeight} - ${font.designWeight} ${font.style}`);
};

const fontFamilyFolder = (font) => {
	return [noSpace(font.family), font.cssWeight, noSpace(font.designWeight), font.style].join('_').toLowerCase().replace(/\s/g, '_');
};

const fontId = (font) => {
	return ['bx', noSpace(font.family), font.cssWeight, font.style].join('_').toLowerCase().replace(/\s/g, '_');
};

const stylesheetUrl = (font) => {
	return '//assets.bounceexchange.com/fonts/' + fontFamilyFolder(font) + '/stylesheet.css';
};

export const WebfontsService = {
	getSiteFontIdsFromJson,
	getSiteFontStylesheet,
	init,
	fontFamilyValue,
	fontWeightLabel,
	fontFamilyFolder,
	fontId,
	stylesheetUrl,
};
