import CommonFields from './commonFields';

export const eligibilityThresholds = {
	label: "Event",
	name: 'eventName',
	nameKey: 'eventName',
	field: {
		isSelect: true,
		sizeString: "m",
		noDefault: true,
		options: [
			{
				name: "Sent Email",
				value: "send"
			},
			{
				name: "Sent App Push",
				value: "attempt_app_push"
			},
			{
				name: "Sent SMS",
				value: "attempt_sms"
			},
			{
				name: "Custom",
				value: "Custom",
				group: 2
			}
		]
	},
	properties: [
		{
			label: "Count",
			name: "count",
			nameKey: "count",
			field: CommonFields.genericNumber
		},
		{
			label: "Email Type",
			name: "and1",
			nameKey: "andKey",
			field: {
				isSelect: true,
				sizeString: "m",
				noDefault: true,
				options: [
					{
						name: "Series of Same Purpose",
						value: "IsSamePurpose",
						group: 1
					},
					{
						name: "Same Campaign",
						value: "HasSameParentCampaign",
						group: 1
					},
					{
						name: "Any Series",
						value: "IsAnySeries",
						group: 2
					},
					{
						name: "Series of Equal or Higher Priority",
						value: "IsEqualOrHigherPriority",
						group: 2
					},
					{
						name: "Series of Higher Priority",
						value: "IsHigherPriority",
						group: 2
					},
					{
						name: "Series of Low Intent",
						value: "IsLowIntent",
						group: 2
					},
					{
						name: "Series of Same Purpose Group",
						value: "IsSamePurposeGroup",
						group: 2
					},
					{
						name: "Any Email",
						value: "IsAnyEmail",
						group: 2
					},
					{
						name: "Any Email",
						value: "isAnyEmail",
						group: 3
					},
				]
			}
		},
		{
			label: "Per",
			name2: "During the Last",
			name: "when",
			nameKey: "n",
			placeholder: 'N/A',
			field: CommonFields.genericNumber,
			enableOnKey: 'period',
			enableOn: ['day', 'hour', 'minute']
		},
		{
			label: "When",
			name: "whenperiod",
			nameKey: "period",
			field: {
				isSelect: true,
				sizeString: "m",
				noDefault: true,
				options: [
					{
						name: "Days",
						value: "day"
					},
					{
						name: "Hours",
						value: "hour"
					},
					{
						name: "Minutes",
						value: "minute"
					},
					{
						name: "This Visit",
						value: "trigger visit"
					},
					{
						name: "Since Trigger Event",
						value: "since trigger event"
					}
				]
			}
		}
	]
};
