/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PopupModal } from "..";
const SubmitEPSModal = ({
	data,
	showModal = true,
	toggleModal = () => { },
}) => {
	const {
		email = "",
		url = "",
		utm_source = "",
		campaign_id = "",
		error,
		extra_data,
		// remove extra_data_csv from modal
		// eslint-disable-next-line no-unused-vars
		extra_data_csv,
		...rest } = data;
	return (
		<PopupModal showModal={showModal} toggleModal={toggleModal}>
			<h3>{email}</h3>
			<table>
				<tbody>
					<tr>
						<LABEL>url</LABEL>
						<INFO>{url}</INFO>
					</tr>
					<tr>
						<LABEL>Campaign Id</LABEL>
						<INFO>{campaign_id}</INFO>
					</tr>
					<tr>
						<LABEL>utm_source</LABEL>
						<INFO>{utm_source}</INFO>
					</tr>
					{!!rest && <ListObjectEntries data={rest} />}
					{!!extra_data && <ListObjectEntries data={extra_data} />}
					{!!error && (
						<tr >
							<LABEL>Submission error</LABEL>
							<INFO>{error}</INFO>
						</tr>
					)}
				</tbody>
			</table>
		</PopupModal>
	);
};

SubmitEPSModal.propTypes = {
	showModal: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired,
	data: PropTypes.shape({
		email: PropTypes.string,
		url: PropTypes.string,
		utm_source: PropTypes.string,
		pageView: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		zip: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		campaign_id: PropTypes.number,
		error: PropTypes.string,
		extra_data_csv: PropTypes.shape({}),
		extra_data: PropTypes.shape({
			phone_number: PropTypes.string,
			'sms-optin': PropTypes.string,
			pageview: PropTypes.string,
			zip_code: PropTypes.string,
		}),
	}),
};

const ListObjectEntries = ({ data }) => {
	const keys = Object
		.keys(data)
		.filter((key) => !!data[key])

	return keys.map((key) => 
		typeof data[key] === 'object' 
		? <ListObjectEntries data={data[key]} />
		: <tr key={key}>
			<LABEL>{key}</LABEL>
			<INFO>{data[key]}</INFO>
		</tr>
	);
}

const LABEL = styled.td`
	min-width: 150px;
	font-weight: bolder;
	padding: 10px 0;
`;
const INFO = styled.td`
	word-wrap: break-word;
	max-width: 50vw;
`;
export default SubmitEPSModal;
