import PropTypes from "prop-types";

export const seriesPagePropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  product: PropTypes.string,
  pinned: PropTypes.bool,
  name: PropTypes.string,
  "email-creative-json": PropTypes.string,
  active: PropTypes.bool,
  "graph-mail-enabled": PropTypes.bool,
  "dt-inserted": PropTypes.string,
  "dt-edited": PropTypes.string
});

export const seriesPropTypes = PropTypes.shape({
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  attributes: seriesPagePropTypes,
  type: PropTypes.string,
  relationship: PropTypes.shape({
    website: PropTypes.shape({
      data: PropTypes.shape({
        type: PropTypes.string,
        id:  PropTypes.string
      })
    }),
    leader: PropTypes.shape({
      data: PropTypes.shape({
        type: PropTypes.string,
        id:  PropTypes.string
      })
    }),
    children: PropTypes.shape({
      data: PropTypes.shape([
        {
          type: PropTypes.string,
          id:  PropTypes.string
        }
      ])
    }),
    grandchildren: PropTypes.shape({
      data: PropTypes.shape([
        {
          type: PropTypes.string,
          id:  PropTypes.string
        }
      ])
    })
  })
});

export const parentPropTypes = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.string,
  attributes: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.string,
    name: PropTypes.string,
  }),
  relationships: PropTypes.shape({
    leader: PropTypes.shape({
      data: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string
      })
    }),
    children: PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string
      }))
    })
  })
});

export const variationPropTypes = PropTypes.shape({
  type: PropTypes.string,
  id: PropTypes.string,
  attributes: PropTypes.shape({
    id: PropTypes.number,
    product: PropTypes.string,
    name: PropTypes.string,
  }),
  relationships: PropTypes.shape({
    leader: PropTypes.shape({
      data: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string
      })
    }),
    parent: PropTypes.shape({
      data: PropTypes.shape({
        type: PropTypes.string,
        id: PropTypes.string
      })
    })
  })
});
