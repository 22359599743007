import React from "react";
import styled from "styled-components";

export const columnArray = [
	{
		id: "date",
		name: "Date Range",
	},
	{
		id: "campaign_name",
		name: "Campaign",
	},
	{
		id: "campaign_report_link",
		name: "Report",
	},
	{
		id: "website_visits",
		name: (
			<span>
				Website
				<br />
				Visits
			</span>
		),
	},
	{
		id: "eligible_visits",
		name: (
			<span>
				Eligible
				<br />
				Visits
			</span>
		),
	},
	{
		id: "overall_impressions",
		name: (
			<span>
				Overall
				<br />
				Impressions
			</span>
		),
	},
	{
		id: "unique_impressions",
		name: (
			<span>
				Unique
				<br />
				Impressions
			</span>
		),
	},
	{
		id: "closes",
		name: "Closes",
	},
	{
		id: "clicks",
		name: "Clicks",
	},
	{
		id: "form_submits",
		name: "Submits",
	},
	{
		id: "submit_lift_conf",
		name: (
			<span>
				Submits
				<br />
				Lift/Conf
			</span>
		),
	},
	{
		id: "campaign_conversions",
		name: (
			<span>
				Campaign
				<br />
				Conversions
			</span>
		),
	},
	{
		id: "conversion_lift_conf",
		name: (
			<span>
				Conversions
				<br />
				Lift/Conf
			</span>
		),
	},
	{
		id: "aov",
		name: "AOV",
	},
	{
		id: "revenue",
		name: "Revenue",
	},
];

export const columnArrayQL = [
	{
		id: "date",
		name: "Date Range",
	},
	{
		id: "campaign_name",
		name: "Campaign",
	},
	{
		id: "campaign_report_link",
		name: "Report",
	},
	{
		id: "website_visits",
		name: (
			<span>
				Website
				<br />
				Visits
			</span>
		),
	},
	{
		id: "eligible_visits",
		name: (
			<span>
				Eligible
				<br />
				Visits
			</span>
		),
	},
	{
		id: "overall_impressions",
		name: (
			<span>
				Overall
				<br />
				Impressions
			</span>
		),
	},
	{
		id: "unique_impressions",
		name: (
			<span>
				Unique
				<br />
				Impressions
			</span>
		),
	},
	{
		id: "closes",
		name: "Closes",
	},
	{
		id: "clicks",
		name: "Clicks",
	},
	{
		id: "form_submits",
		name: "Submits",
	},
	{
		id: "campaign_conversions",
		name: (
			<span>
				Campaign
				<br />
				Conversions
			</span>
		),
	},
	{
		id: "conversion_lift_conf",
		name: (
			<span>
				Conversions
				<br />
				Lift/Conf
			</span>
		),
	},
	{
		id: "aov",
		name: "AOV",
	},
	{
		id: "revenue",
		name: "Revenue",
	},
];

export const Cell = styled.td`
	text-align: center;
	min-width: 90px;
`;

export const CampaignName = styled.div`
	width: 250px;
`;

export const ActiveIcon = styled.span`
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	margin-right: 5px;
	background-color: ${({ active }) => {
		return {
			"ON": "#97d483",
			"TEST_MODE": "#dba47f",
			"OFF": "#191919",
			0: "#97d483",
			1: "#dba47f",
			2: "#191919",
		}[active];
	}};
`;

export const formatCurrency = (value) => (
	Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(value)
);

export const formatPercentages = (value, minimumFractionDigits = 0) => (
	Intl.NumberFormat("en-US", { style: "percent", minimumFractionDigits }).format(value)
);

export const formatNumber = (value) => Intl.NumberFormat().format(value);
