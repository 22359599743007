import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Section, Dropdown } from "../../../../../../components/form";
import { InputNumber } from "../../../../../../components";
import { trafficOptions, trafficInitialValues } from "./utils/traffic";
import { Spacer } from "./utils/Sections.styled";

const Traffic = () => {
	const selectedCampaign = useSelector((state) => state.inboxCampaigns?.selectedInboxCampaign);

	const [trafficFormData, setTrafficFormData] = useState(trafficInitialValues(selectedCampaign));
	if (!selectedCampaign) {
		return null;
	}
	return (
		<Section title="Traffic">
			<Spacer flexBasis="10%">
				<Dropdown
					field="traffic_type"
					label="Traffic Type"
					onChange={(e) =>
						setTrafficFormData({
							...trafficFormData,
							testType: e.target.value,
						})
					}
					value={trafficFormData.testType}
					options={trafficOptions}
				/>
				<InputNumber
					label="Traffic %"
					value={trafficFormData.testTraffic}
					setValue={(e) =>
						setTrafficFormData({
							...trafficFormData,
							testTraffic: e.target.value,
						})
					}
					min="0"
					max="20"
					margin="0 0.785rem"
					placeholder={0}
				/>
			</Spacer>
		</Section>
	);
};

export default Traffic;
