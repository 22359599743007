import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "../../../components";
import { FormInput } from "../../../components/form";
/**
 *     @font-face {
        font-family: "OpenSans";
        src: url("/examples/fonts/OpenSans-Regular.eot");
        src: url("/examples/fonts/OpenSans-Regular.ttf") format("truetype");
        font-stretch: normal;
        font-weight: normal;
        font-style: normal;
    }
 *
*/

const fontFacePlaceholder = `@font-face {
	font-family: "OpenSans";
	src: url("/examples/fonts/OpenSans-Regular.eot");
	src: url("/examples/fonts/OpenSans-Regular.ttf") format("truetype");
	font-stretch: normal;
	font-weight: normal;
	font-style: normal;
}
`;

const NewFontForm = ({ closeForm }) => {
	const [fontFaceValue, setFontFaceValue] = useState("");
	const [url, setUrl] = useState("");
	const [fontStack, setfontStack] = useState("");
	const [base64, setBase64] = useState("");

	const [font, setfont] = useState({
		family: "",
		weight: "",
		style: "",
	});

	const findValues = () => {
		// Removes leading or trailing whitespace.
		const obj = fontFaceValue
			.replace("@font-face", "")
			.replace("{", "")
			.replace("}", "")
			.replace(/^\s*|\s*$/g, "");
		const lines = obj.split(/\r|\r\n|\n/);

		const values = lines
			.map((x) => x.split(":").map((y) => y.trim().replace(";", "").replace(/["']/g, "")))
			.reduce((a, x) => {
				a[x[0]] = x[1];
				return a;
			}, {});

		setfont({
			family: values["font-family"],
			weight: values["font-weight"],
			style: values["font-style"],
		});
	};

	const checkDisabled = () => {
		if (
			fontFaceValue.length > 1 &&
			url.length > 1 &&
			fontStack.length > 1 &&
			base64.length > 1
		) {
			return false;
		}
		return true;
	};

	useEffect(() => {
		findValues();
	}, [fontFaceValue]);

	const submitForm = () => {
		console.log(fontFaceValue);
		// closeForm();
	};

	return (
		<div>
			<Label>Create a new Font</Label>
			<FormInput
				dataQA="behavioral-experience-name"
				value={fontFaceValue}
				onChange={setFontFaceValue}
				label="Font Face Declaration"
				placeholder={fontFacePlaceholder}
				type="textarea"
			/>

			{font.family && (
				<FontDescription>
					<p>
						<strong>Font Family:</strong> {font.family}
					</p>
					<p>
						<strong>Font Style:</strong> {font.style}
					</p>
					<p>
						<strong>Font Weight:</strong> {font.weight}
					</p>
				</FontDescription>
			)}

			<FormInput
				dataQA="behavioral-experience-name"
				value={fontStack}
				onChange={setfontStack}
				label="Stack"
				placeholder="OpenSans, Arial, Sans Serif"
			/>

			<FormInput
				dataQA="reference-url"
				value={url}
				onChange={setUrl}
				label="Reference URL"
				placeholder="https://typekit.com/some/example"
			/>

			<Label>
				<a href="https://base64.guru/converter/encode" rel="noreferrer" target="_blank">
					Base64 Encode a font
				</a>
			</Label>

			<FormInput
				dataQA="behavioral-experience-name"
				value={base64}
				onChange={setBase64}
				label="Font (base64 encoded)"
				placeholder="example.com"
				type="textarea"
			/>

			<Label>Search for a website</Label>

			<ButtonContainer>
				<Button theme="blue" disabled={checkDisabled()} onClick={() => submitForm()}>
					Create Font
				</Button>
			</ButtonContainer>
		</div>
	);
};

const FontDescription = styled.div`
	margin: 10px 0;
	display: flex;
	flex-direction: column;

	p {
		font-size: 14px;
		margin: 0 0 5px 0;
	}
`;

const ButtonContainer = styled.div`
	width: 250px;
	margin: 20px auto;
`;

const Label = styled.label`
	display: block;
	margin: 5px 0;
	padding: 0;
	font-size: 12px;
	font-weight: 600;
	width: 100%;
	text-align: left;
	color: #535252;
`;

export default NewFontForm;
