import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { selectAllInbox } from "../../../redux/inbox/inboxAdapter";
import InboxRow from "./InboxRow";

const bgColorMap = {
	on: "rgb(255, 255, 255)",
	off: "rgb(232, 232, 232)",
	test: "rgb(245, 235, 225)",
};

const InboxBlock = ({ campaign }) => {
	const includedCampaigns = useSelector(selectAllInbox);
	const campaignSeriesID = campaign?.relationships?.["inbox-series"]?.data?.id;

	const campaignSeries = includedCampaigns.filter(
		(campaign) => campaign?.attributes?.["campaign-series-id"] === campaignSeriesID
	);

	return (
		<InboxBlockContainer data-qa="inbox-parent">
			{/* @todo show warning on what mode the inbox is in test mode, or off
				grab the data from websites.v2-test-mode,
			*/}

			<InboxRow
				campaign={campaign}
				isParent={true}
				// bgColor={bgColorMap[parentTrafficType]}
				// parentTrafficType={parentTrafficType}
			/>

			{campaignSeries.map((campaign) => (
				<InboxRow key={campaign.id} campaign={campaign} isParentArchived={false} />
			))}
		</InboxBlockContainer>
	);
};

InboxBlock.propTypes = {
	campaign: PropTypes.object.isRequired,
};

const InboxBlockContainer = styled.div`
	width: 100%;
	border-left: 8px solid #bcccd9;
	margin-bottom: 22px;
`;

export default InboxBlock;
