import { createSlice } from "@reduxjs/toolkit";
import { getContracts } from "./thunks";

const initialState = {
	isFetchError: false,
	response: {
		data: [],
	},
};

const contractsSlice = createSlice({
	name: "contracts",
	initialState,
	reducers: {
		clearContractsApiError(state) {
			state.isFetchError = false;
		}
	},
	extraReducers: (builder) => {
		// getContracts
		builder.addCase(getContracts.fulfilled, (state, action) => {
			state.response = action.payload;
		});
		builder.addCase(getContracts.rejected, (state) => {
			state.isFetchError = true;
		});
	},
});

export const {
	clearContractsApiError,
} = contractsSlice.actions;

export default contractsSlice.reducer;
