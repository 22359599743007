import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Image, XCircle } from "react-feather";
import { selectAllCampaigns } from "../../../redux/campaigns/campaignsAdapter";
import { getCreativeChildrenCampaigns } from "../../../redux/campaigns/thunks";
import { ICON_SIZES } from "../../../constants/common";
import { clearCreativeCampaigns } from "../../../redux/campaigns/slice";


import { PopupModal, Select, Button, ToolTip } from "../../../components";
import CMSCreativePreview from "./CMSCreativePreview";

const initParams = {
	value: {
		id: "",
		name: "Select a campaign",
		children: {}
	}
};

const UpdateMultipleCampaignCreativesButton = () => {
	const dispatch = useDispatch();
	let defaultValues = [{ id: "", name: "Select a campaign" }];
	const campaigns = useSelector(selectAllCampaigns);
	const [name, setName] = useState(initParams.value.name);
	const creativeCampaigns = useSelector((state) => state.campaigns.selectedCreativeChildrenCampaigns ?? []);
	const [showForm, setShowForm] = useState(false);
	const toggleModal = () => {
		setShowForm(false);
		clearRow();
	}

	const handleChange = (params) => {
		setName(params);
		dispatch(getCreativeChildrenCampaigns(params));
	}

	const clearRow = () => {
		setName(initParams.value.name);
		dispatch(clearCreativeCampaigns());
	}

	const dropdown = campaigns.map((campaign) => ({
		id: campaign?.attributes?.id, name: campaign?.attributes?.name
	}));

	defaultValues.push(...dropdown);
	return (
		<>
			<PopupModal showModal={showForm} toggleModal={() => toggleModal()}>
				<Header>Update Campaign Creatives</Header>

				<SelectArea>
					<ToolTip position="bottom" text="Clear Row">
						<Button width="44px" onClick={() => clearRow()}>
							<XCircle size={ICON_SIZES.medium} title="Clear Row" />
						</Button>
					</ToolTip>

					<Select
						dataQA="purpose-dropdown"
						data={defaultValues}
						value={name}
						onChange={(name) => handleChange(name)}
					/>
				</SelectArea>

				{creativeCampaigns.map((campaign, id) => {
					return <CMSCreativePreview key={id} campaign={campaign} showStepNumber />
				})}

				<Button theme="blue">
					Update Multiple Creatives
				</Button>
			</PopupModal>
			<SettingsLink data-qa="new-experience-button" onClick={() => setShowForm(true)}>
				<Image size={ICON_SIZES.medium} />
			</SettingsLink>
		</>
	);
};

const Header = styled.h1`
	font-size: 14px;
	font-weight: bold;
	text-align: center;
`;

const SelectArea = styled.div`
	display: flex;
	margin: 40px 0;
	flex-direction: row;
`;

const SettingsLink = styled.button`
	margin: 0px 10px;
	background: none;
	border: 0;
	width: ${(props) => (props.width ? props.width : "auto")};
	&:last-child {
		margin: 0 0 0 10px;
	}
	i,
	svg {
		color: #191919;
		&:hover {
			color: #fff;
		}
		&:hover + span {
			color: #fff;
		}
	}

	span {
		font-size: 11px;
		display: inline-block;
		margin: 0 0 0 10px;
		position: relative;
		top: -3px;
	}
`;

export default UpdateMultipleCampaignCreativesButton;
