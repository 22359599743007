// ESP
export const TYPE_OPTIONS = [
	{
		value: "api",
		label: "API",
	},
	{
		value: "form",
		label: "Form",
	},
];

export const FIELD_TYPE_OPTIONS = [
	{
		value: "input",
		label: "Input",
	},
	{
		value: "password",
		label: "Password Input",
	},
	{
		value: "text",
		label: "Text",
	},
	{
		value: "dd",
		label: "Dropdown",
	},
];

export const FIELD = {
	NAME: "name",
	REQUIRED: "required",
	TYPE: "type",
	CHOICES: "choices",
};

// ESPs
export const PER_PAGE = 31;
