import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ButtonRow as Buttons } from "./components";

const ComparisonControls = ({
	reportOptions,
	onChangeReportOptions,
}) => {
	return (
		<Controls>
			<ButtonRow
				onClick={onChangeReportOptions}
			/>
			<ReportSetting
				reportOptions={reportOptions}
				onChange={onChangeReportOptions}
			/>
			<ReportDetails
				reportOptions={reportOptions}
				onChange={onChangeReportOptions}
			/>
		</Controls>
	);
};

ComparisonControls.propTypes = {
	reportOptions: PropTypes.object.isRequired,
	onChangeReportOptions: PropTypes.func.isRequired,
};

const ButtonRow = ({ onClick }) => {
	return (
		<Row justify-content="flex-start">
			<Buttons
				onClick={onClick}
			/>
		</Row>
	);
};
ButtonRow.propTypes = {
	onClick: PropTypes.func.isRequired,
};
const ReportSetting = ({ reportOptions, onChange }) => {
	const update = ({ target }) => {
		const { value, name } = target;
		onChange({
			...reportOptions,
			[name]: value,
		});
	};
	return (
		<Row>
			<Label htmlFor="date_start">
				<span>Start</span>
				<input
					id="date_start"
					type="date"
					name="date_start"
					value={reportOptions.date_start || ""}
					onChange={update}
				/>
			</Label>
			<Label htmlFor="date_end">
				<span>End</span>
				<input
					id="date_end"
					type="date"
					name="date_end"
					value={reportOptions.date_end || ""}
					onChange={update}
				/>
			</Label>
			<Label htmlFor="conv_goals">
				<span>Conversion Goals</span>
				<select
					id="conv_goals"
					value={reportOptions.conv_goals || ""}
					name="conv_goals"
					onChange={update}
				>
					<option value="all_goals">All Goals</option>
					<option value="purchase">purchase</option>
				</select>
			</Label>
			<Label htmlFor="campaign_sorting">
				<span>Sorting</span>
				<select
					id="campaign_sorting"
					name="campaign_sorting"
					value={reportOptions.campaign_sorting || ""}
					onChange={update}
				>
					<option value="campaign_conversion_rate">
						Conversion Rate
					</option>
					<option value="submit_rate">Submit Rate</option>
				</select>
			</Label>
			<Label htmlFor="conv_view">
				<span>Conversion Credit</span>
				<select
					id="conv_view"
					name="conv_view"
					value={reportOptions.conv_view || ""}
					onChange={update}
				>
					<option value="all_credit_30">
						Impression Conversion 30 Day (All Credit)
					</option>
					<option value="all_credit">
						Impression Conversion (All Credit)
					</option>
					<option value="all_credit_7">
						Impression Conversion 7 Day (All Credit)
					</option>
					<option value="visit_only">
						Impression Conversion (Same Visit)
					</option>
					<option value="click_only">Click Conversions</option>
					<option value="submit_only">Submit Conversions</option>
					<option value="submit_30">Submit Conversions 30 Day</option>
					<option value="submit_7">Submit Conversions 7 Day</option>
				</select>
			</Label>
		</Row>
	);
};
ReportSetting.propTypes = {
	reportOptions: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};

const ReportDetails = ({ reportOptions, onChange }) => {
	const update = ({ target }) => {
		const { name, value, checked, type } = target;
		onChange({
			...reportOptions,
			[name]: type === "checkbox" ? checked : value,
		});
	};
	return (
		<Row>
			<Group>
				<label htmlFor="only_on_campaigns">
					<input
						id="only_on_campaigns"
						name="only_on_campaigns"
						checked={reportOptions.only_on_campaigns === true}
						type="checkbox"
						onChange={update}
					/>
					<span>On Campaigns Only</span>
				</label>
			</Group>
			<Group>
				<label htmlFor="campaign_group_controls">
					<input
						id="campaign_group_controls"
						name="campaign_group_controls"
						type="checkbox"
						checked={reportOptions.campaign_group_controls === true}
						onChange={update}
					/>
					<span>Combine Control</span>
				</label>
			</Group>
			<Group>
				<label htmlFor="retroactive">
					<input
						id="retroactive"
						name="retroactive"
						type="checkbox"
						checked={reportOptions.retroactive === true}
						onChange={update}
					/>
					<span>Retroactive Attribution</span>
				</label>
			</Group>

		</Row>
	);
};
ReportDetails.propTypes = {
	reportOptions: PropTypes.object.isRequired,
	onChange: PropTypes.func.isRequired,
};
const Controls = styled.div`
	background-color: #fff;
	padding: 1em;
	min-width: 535px;
`;
const Row = styled.ul`
	display: flex;
	padding: 0;
	margin: 0;
	flex-flow: row wrap;
	justify-content: ${(props) => props["justify-content"] || ""};
	align-items: flex-start;
	& > li {
		display: inline-block;
		list-style: none;
	}
`;

const Label = styled.label`
	margin: 0 0.5em 0 0;

	span {
		display: inline-block;
		margin: 0 .75em 0 0.5em;
		vertical-align: top;
	}
	select {
		padding: 0;
	}
`;
const Group = styled.div`
	margin: 0 0.5em 0 0;
	display: inline-block;
	input[type="checkbox"] {
		border-radius: 3px;
	}
	span {
		margin: 0 .75em 0 0.5em;
	}
`;

export default ComparisonControls;
