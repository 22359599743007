import { createSlice } from "@reduxjs/toolkit";
import { getFonts } from "./thunks";
import fontsAdapter from "./fontsAdapter";
import { WebfontsService } from "../../service/webfonts";

const initialState = {
	isFetchError: false,
	response: {
		data: fontsAdapter.getInitialState(),
		meta: {},
		included: [],
	},
	webfonts: {
		siteFontsHash: [],
		siteFontsStylesheet: "",
		fontList: [],
		lookup: {},
		familyLookup: {},
		optionGroups: [],
	},
};

const fontsSlice = createSlice({
	name: "fonts",
	initialState,
	reducers: {
		clearFontsApiError(state) {
			state.isFetchError = false;
		},
		removeAllFonts(state) {
			fontsAdapter.removeAll(state.response.data);
		},
		setFontsStylesheet(state, action) {
			const { json, websiteId } = action.payload;
			const siteFontIds = WebfontsService.getSiteFontIdsFromJson(json);
			const stylesheet = WebfontsService.getSiteFontStylesheet(
				websiteId,
				siteFontIds,
				state.webfonts.siteFontsHash
			);

			state.webfonts.siteFontsStylesheet = stylesheet;
		},
		initWebfonts(state) {
			const initData = WebfontsService.init();

			state.webfonts.fontList = initData.fontList;
			state.webfonts.lookup = initData.lookup;
			state.webfonts.familyLookup = initData.familyLookup;
			state.webfonts.optionGroups = initData.optionGroups;
		},
	},
	extraReducers: (builder) => {
		// getFonts
		builder.addCase(getFonts.fulfilled, (state, action) => {
			const fonts = action?.payload?.data || [];
			const fontsHash = fonts.map((font) => font.id).join("");

			state.webfonts.siteFontsHash = fontsHash;
			state.webfonts.siteFontsStylesheet = "";
			fontsAdapter.setAll(state.response.data, fonts);
		});
		builder.addCase(getFonts.rejected, (state) => {
			state.isFetchError = true;
		});
	},
});

export const {
	clearFontsApiError,
	removeAllFonts,
	setFontsStylesheet,
	initWebfonts,
} = fontsSlice.actions;

export default fontsSlice.reducer;
