import React from 'react';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import PropTypes from 'prop-types';

const CodeInput = ({ code, onChange, height }) => {

    // TODO extracting this out for now to preserve console
    // logging, but we will ultimately need to refactor this
    // for all situations
    const consoleLogChange = (value, viewUpdate) => {
        console.log('value:', value);
        console.log('viewUpdate:', viewUpdate);
    }

    const handleChange = onChange ? onChange : consoleLogChange
    return (
        <CodeMirror
            value={code}
            height={height ?? '6rem'}
            style={{width: "100%"}}
            extensions={[javascript({ jsx: true })]}
            onChange={handleChange}
        />
    )
};

CodeInput.propTypes = {
    code: PropTypes.string,
    onChange: PropTypes.func,
    height: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
}

export default CodeInput;