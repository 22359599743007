import styled from "styled-components";

export const SimpleText = styled.div`
    color: ${props => props.alert ? '#b64b25 !important' : null};
    font-size: 0.857rem;
    font-weight: 400;
    width: fit-content;
    margin: ${props => props.margin ?? null}
`

export default SimpleText;