import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Star } from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { TrafficPercentageSetter, TrafficTypeSelector } from "../../../components";
import { updateCampaignTraffic, updateCampaignTrafficType } from "../../../redux/campaigns/thunks";
import { setSelectedCampaign, addSelectedCampaign } from "../../../redux/inbox/slice";

const InboxRow = ({
	campaign,
	isParent = false,
	bgColor,
	parentTrafficValue,
	parentTrafficType,
	isParentArchived,
}) => {
	const dispatch = useDispatch();
	const {
		purposes: {
			response: { data: purposeTypes = [] },
		},
		campaignTypes: {
			response: { data: campaignTypes = [] },
		},
	} = useSelector((state) => state);
	const { selectedCampaigns } = useSelector((state) => state.inbox);
	const isArchived = !campaign?.attributes?.active;
	const isArchivedOrIsParentArchived = isArchived || isParentArchived;

	const selectCampaignHandler = (event) => {
		if (event.ctrlKey || event.shiftKey || event.metaKey) {
			dispatch(addSelectedCampaign(campaign));
			return;
		}
		dispatch(setSelectedCampaign(campaign));
	};

	const campaigSeriesName = campaign?.attributes?.["series-name"];
	const campaignName = campaign?.attributes?.name;

	const campaignTypeId = campaign?.relationships?.["campaign-type"]?.data?.id;
	const purposeTypeId = campaign?.relationships?.["campaign-purpose"]?.data?.id;
	const dateEdited = campaign?.attributes['dt-edited'].substring(0,  campaign?.attributes['dt-edited'].indexOf(' ')) || '';

	let options = [];

	let onlyCampaignTypes = campaignTypes.filter(
		({ attributes = {}, type = "" }) =>
			(attributes.product === "inbox" || attributes.product === "all") &&
			type === "campaign_types"
	);

	let newArray = onlyCampaignTypes.map((el) => {
		let val = {};
		val.id = el.id;
		val.name = el.attributes.name;
		return val;
	});

	let onlyPurposeTypes = purposeTypes.filter(
		({ attributes = {}, type = "" }) =>
			(attributes.product === "inbox" || attributes.product === "all") &&
			type === "campaign_purposes"
	);

	let newPurposeTypes = onlyPurposeTypes.map((el) => {
		let val = {};
		val.id = el.id;
		val.name = el.attributes.name;
		return val;
	});

	options.push(...newArray);
	/* @TODO: probably a better way to do this? maybe a hook for campaignTypeId,
    if campaign-type is set to 0 look at the campaign purpose */
	const campaignidName = options.filter((option) => option.id === campaignTypeId);
	let purposeidName = [];
	const purposeName = campaignidName[0]?.name === "Not Set" ? " " : campaignidName[0]?.name;
	let part = false;
	if (purposeName === " ") {
		part = true;
		purposeidName = newPurposeTypes.filter((option) => option.id === purposeTypeId)[0]?.name;
	}

	const campaignId = campaign?.attributes?.["id"];
	const campaignSeriesId = campaign?.attributes?.["campaign-series-id"];

	const id = isParent ? campaignSeriesId : campaignId;
	const name = isParent ? campaigSeriesName : campaignName;
	const isLeader = campaign?.attributes?.["is-leader"];

	const linkToEdit = `/experiences/inbox-legacy/${id}/edit`;
	let label = part && !isParent ? purposeidName : purposeName;
	const isSelected = selectedCampaigns?.some((campaign) => campaign.id === campaignId);

	const backgroundColor = isSelected
		? "rgb(146, 152, 182)"
		: isArchivedOrIsParentArchived
		? "rgb(172, 171, 169)"
		: bgColor;
	return (
		<InboxRowContainer
			part={purposeName === " " && part ? true : false}
			onClick={selectCampaignHandler}
			isSelected={isSelected}
			bgColor={backgroundColor}
			isParent={isParent}
			data-qa="campaign-row"
		>
			<NameContainer isParent={isParent} part={part} data-qa="campaign-name">
				{isParent ? (
					<Star />
				) : (
					<PurposeContainer part={part} data-qa="campaign-purpose">
						<a href={linkToEdit}>{label}</a>
					</PurposeContainer>
				)}
				<a href={linkToEdit}>{name}</a>
				{isLeader && !isParent && <LeaderPill>leader</LeaderPill>}
			</NameContainer>

			{/*
			<NameContainer isParent={isParent} data-qa="campaign-name">
				<a href={linkToEdit}>{campaignName}</a>
			</NameContainer>
			<TrafficPercentageSetter
				value={campaignTraffic}
				onChange={changeTrafficHandler}
				disabled={isParent || isArchivedOrIsParentArchived}
				errorIfNotFull={isParent}
			/>
			*/}
			<CampaignIdContainer>{id}</CampaignIdContainer>
		</InboxRowContainer>
	);
};

InboxRow.propTypes = {
	campaign: PropTypes.object,
	bgColor: PropTypes.string,
	isParent: PropTypes.bool,
	parentTrafficValue: PropTypes.number,
	parentTrafficType: PropTypes.oneOf(["on", "off", "test"]),
	isParentArchived: PropTypes.bool,
};

const InboxRowContainer = styled.div`
	margin-top: ${({ part }) => (part ? "5px" : 0)};
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 11px;
	width: 100%;
	background-color: ${({ bgColor, isParent }) =>
		isParent ? "rgb(255,255,255)" : bgColor || "transparent"};
	height: ${({ isParent }) => (isParent ? "53px" : "42px")};
	border: 1px solid;
	border-color: ${({ isSelected, isArchived }) =>
		isSelected ? "rgb(48, 61, 120)" : isArchived ? "rgb(160, 158, 156)" : "rgb(232, 232, 232)"};
	z-index: ${({ isSelected }) => (isSelected ? "5" : "1")};
	/* @TODO Bold text if a campaign is a parent, spacing */
	&:first-child: {
		margin-top: 0;
	}
	&:hover {
		border-color: rgb(48, 61, 120);
		z-index: 5;
		filter: brightness(95%);
	}
`;

const PurposeContainer = styled.div`
	width: 180px;
	margin: 0 11px 0 5px;
	font-size: 12px;
	font-weight: ${({ isParent, part }) => (isParent || (part && !isParent) ? "600" : "400")};
`;

const LeaderPill = styled.span`
	font-weight: 400;
	border-radius: 10px;
	background-color: #c8d7e0;
	padding: 0 5px;
	margin-left: 5px;
`;

const NameContainer = styled(PurposeContainer)`
	flex-grow: 1;
	align-items: center;
	font-size: 12px;
	display: flex;
	font-weight: ${({ isParent, part }) => (isParent || (part && !isParent) ? "600" : "400")};
	svg {
		margin: 0 20px 0 0;
		width: 16px;
	}
`;

const CampaignIdContainer = styled.span`
	margin-left: auto;
	font-size: 10px;
	color: #9d9a9a;
`;

export default InboxRow;
