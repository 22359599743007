import {
	methodInput,
	customerTypeInput,
	conditionInput,
	eventInput,
	eventTypeInput,
	duringTheLastInput,
	whenInput,
	lastEventInput,
	whenEventTypeInput,
	whereInput,
	operatorInput,
	valueInput,
	stateInput,
} from "./inputHelper";

const MethodInputValues = Object.freeze({
	CUSTOM: "Custom",
	CUSTOMER_BEHAVIOR: "CheckForEvents",
	CUSTOMER_STATE: "CustomerState",
	CUSTOMER_TYPE: "CheckCustomerStatus",
});

export const methodDecision = Object.freeze({
	[MethodInputValues.CUSTOMER_TYPE]: [customerTypeInput],
	[MethodInputValues.CUSTOMER_STATE]: [stateInput],
	[MethodInputValues.CUSTOMER_BEHAVIOR]: [conditionInput, eventInput],
});

const EventInputValues = Object.freeze({
	ADD_TO_CART: "add to cart",
	CONVERSION: "conversion",
	CUSTOM: "custom",
	HOVER_ITEM: "hover item",
	PRODUCT_CHANGE: "product change",
	PRODUCT_CHANGE_NEWS: "product change news",
	SUBMIT_EMAIL: "requestemail",
	VIEW_CATEGORY: "view category",
	VIEW_ITEM: "view item",
	VIEW_SEARCH: "view search",
});

export const eventNameDecision = Object.freeze({
	[EventInputValues.ADD_TO_CART]: [whenInput, whereInput, operatorInput, valueInput],
	[EventInputValues.CONVERSION]: [whenInput, whereInput, operatorInput, valueInput],
	[EventInputValues.CUSTOM]: [whenInput, whereInput, operatorInput, valueInput],
	[EventInputValues.HOVER_ITEM]: [whenInput, whereInput, operatorInput, valueInput],
	[EventInputValues.PRODUCT_CHANGE]: [eventTypeInput],
	[EventInputValues.PRODUCT_CHANGE_NEWS]: [],
	[EventInputValues.SUBMIT_EMAIL]: [whenInput, whereInput, operatorInput, valueInput],
	[EventInputValues.VIEW_CATEGORY]: [whenInput, whereInput, operatorInput, valueInput],
	[EventInputValues.VIEW_ITEM]: [whenInput, whereInput, operatorInput, valueInput],
	[EventInputValues.VIEW_SEARCH]: [whenInput, whereInput, operatorInput, valueInput],
});

const WhenInputValues = Object.freeze({
	DAYS: "day",
	HOURS: "hour",
	MINUTES: "minute",
	SINCE_LAST_EVENT: "since last event",
	SINCE_TRIGGER_EVENT: "since trigger event",
	THIS_VISIT: "trigger visit",
});

export const whenDecision = Object.freeze({
	[WhenInputValues.DAYS]: [duringTheLastInput],
	[WhenInputValues.HOURS]: [duringTheLastInput],
	[WhenInputValues.MINUTES]: [duringTheLastInput],
	[WhenInputValues.THIS_VISIT]: [],
	[WhenInputValues.SINCE_TRIGGER_EVENT]: [],
	[WhenInputValues.SINCE_LAST_EVENT]: [lastEventInput],
});

const methodDecisionLogic = (method) => {
	if (!method) {
		return [];
	}
	return methodDecision[method];
};

const eventNameDecisionLogic = (eventName) => {
	if (!eventName) {
		return [];
	}
	return eventNameDecision[eventName];
};

const whenDecisionLogic = (period, whenevent) => {
	if (!period) {
		return [];
	}
	if (
		whenevent === EventInputValues.PRODUCT_CHANGE &&
		period === WhenInputValues.SINCE_LAST_EVENT
	) {
		return whenDecision[period].concat([whenEventTypeInput]);
	}
	return whenDecision[period];
};

export const eligibilityCriteriaInputs = ({
	method,
	eventName,
	when: { period: whenperiod, event: whenevent } = {},
}) => {
	return [methodInput]
		.concat(methodDecisionLogic(method))
		.concat(eventNameDecisionLogic(eventName))
		.concat(whenDecisionLogic(whenperiod, whenevent));
};
