import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";

export const getParentCampaignTypes = createAsyncThunk(
	"parentCampaigns/getParentCampaigns",
	async (params = {}) => {
		const url = `${endpoints.coreApi}/parent_campaign_types`;
		const response = await ApiService.get(url, params);

		if (!response.ok) {
			throw new Error("Failed to load parent campaign types!");
		}

		return response.json();
	}
);
