import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Divider } from "../../pages/stats/styledComponents";
import { Title } from "./Title.styled";

const Section = ({ title, children }) => {
	return (
		<SectionBlock>
			{title && (
				<>
					<Title>{title}</Title>
					<Divider />
				</>
			)}
			{children}
		</SectionBlock>
	);
};

const SectionBlock = styled.div`
	border: 1px solid #e8e8e8;
	background: #ffffff;
	margin-top: 1.75rem;
`;

Section.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

export default Section;
