import { createSelector } from "@reduxjs/toolkit";

const inboxCampaigns = (state) => state?.inboxCampaigns?.response?.data?.entities;
const unSavedChanges = (state) => state?.inboxCampaigns?.unSavedChanges;

export const useSelectInboxCampaigns = createSelector(
	inboxCampaigns,
	unSavedChanges,
	(campaigns, changes) => {
		const modifiedCampaigns = Object.assign({}, campaigns);
		Object.keys(modifiedCampaigns).forEach((id) => {
			if (id && changes[id]) {
				modifiedCampaigns[id] = {
					...modifiedCampaigns[id],
					attributes: {
						...modifiedCampaigns[id].attributes,
						...changes[id]?.attributes,
					},
					relationships: {
						...modifiedCampaigns[id].relationships,
						...changes[id]?.relationships,
					},
				};
			}
		});
		return modifiedCampaigns;
	}
);
