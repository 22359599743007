import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ToolTip } from ".";

const TrafficPercentageSetter = ({ value, onChange, disabled, errorIfNotFull }) => {
	const [newValue, setNewValue] = useState(value);
	const [isError, setIsError] = useState(false);
	const noActionOnBlur = useRef(false);
	const isEqualValues = value === newValue;

	useEffect(() => {
		if (value > 100) {
			setIsError(true);
		} else if (errorIfNotFull && value < 100) {
			setIsError(true);
		} else {
			setIsError(false);
		}
	});

	useEffect(() => {
		setNewValue(value);
	}, [value]);

	const changeHandler = (e) => {
		const value = parseInt(e.target.value);

		if (value) {
			setNewValue(value);
		} else {
			setNewValue(0);
		}
	};

	const pressEnterHandler = (event) => {
		if (event.key === "Enter" && !isEqualValues) {
			noActionOnBlur.current = true;
			onChange(newValue);
			event.target.blur();
		}
	};

	const blurHandler = () => {
		if (noActionOnBlur.current || isEqualValues) {
			noActionOnBlur.current = false;
			return;
		}

		onChange(newValue);
	};

	return (
		<SetterContainer>
			<ToolTip
				position="bottom"
				text="Live experience traffic percentages should add up to 100%"
				disabled={!isError}
			>
				{isError && <ErrorIcon className="icon-exclamation-triangle" />}
				<PercentageInput
					type="text"
					value={newValue}
					onChange={changeHandler}
					disabled={disabled}
					isError={isError}
					pattern="\d{1,3}"
					onBlur={blurHandler}
					onKeyDown={pressEnterHandler}
					onClick={(event) => event.stopPropagation()}
				/>
				<Label isError={isError}>%</Label>
			</ToolTip>
		</SetterContainer>
	);
};

const SetterContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: right;
	width: 55px;
	margin: 0 11px;
`;

const PercentageInput = styled.input`
	width: 27px;
	height: 18px;
	padding: 0 2px;
	text-align: right;
	border: 1px solid transparent;
	background-color: inherit;
	cursor: pointer;
	color: ${({ isError }) => (isError ? "rgb(182, 75, 37)" : "black")};
	-moz-appearance: textfield;
	&::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
	&:focus {
		outline: none;
		border-color: ${({ isError }) => (isError ? "rgb(182, 75, 37)" : "rgb(195, 193, 193)")};
		background-color: white;
		color: ${({ isError }) => (isError ? "rgb(182, 75, 37)" : "black")};
	}
`;

const Label = styled.span`
	color: ${({ isError }) => (isError ? "rgb(182, 75, 37)" : "black")};
`;

const ErrorIcon = styled.i`
	font-size: 12px;
	margin-right: 3px;
	color: rgb(182, 75, 37);
`;

TrafficPercentageSetter.propTypes = {
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	errorIfNotFull: PropTypes.bool,
};

export default TrafficPercentageSetter;
