import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";

export const getIndustries = createAsyncThunk(
	"industries/getIndustries",
	async () => {
		const url = `${endpoints.coreApi}/industries`;
		const response = await ApiService.getWithSearchParams(url);

		if (!response.ok) {
			throw new Error("Failed to load industries!");
		}

		return response.json();
	}
);
