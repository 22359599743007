import React from "react";
import PropTypes from "prop-types";
import { Cell, columnArray, CampaignName, ActiveIcon } from "./components/utils";

import { Header, Body, Table, TBody, Link } from "./components";

const TableComponent = ({ data = [] }) => {
	const dataMap = sortMap(data);
	const bodies = createTableBodies(dataMap);

	return (
		<Table>
			<Header headers={columnArray} />
			{bodies}
		</Table>
	);
};
TableComponent.propTypes = {
	data: PropTypes.array.isRequired,
};
const createTableBodies = (dataMap = {}) => {
	if (!Object.keys(dataMap)) {
		return [];
	}
	const rows = [];
	// eslint-disable-next-line no-unused-vars
	for (const [key, array] of Object.entries(dataMap)) {
		const {
			parent_campaign_id,
			parent_campaign_name,
			parent_campaign_purpose,
			date_start,
			date_end,
		} = array[0];
		const formatDateStart = date_start.split("T")[0];
		const formatDateEnd = date_end.split("T")[0];
		const parentHeader = (
			<TBody cellPadding="0" key={`header-${key}`}>
				<tr>
					<td colSpan="15">
						<h4>
							{`${parent_campaign_purpose} ${parent_campaign_name}`}
							&nbsp;&nbsp;&nbsp;
							<Link
								href={`/reports?type=parentEmailCapture&parent_campaign_id=${parent_campaign_id}&date_start=${formatDateStart}&date_end=${formatDateEnd}`}
							>
								Report Conversions Captures
							</Link>
						</h4>
					</td>
				</tr>
			</TBody>
		);
		rows.push(parentHeader);
		rows.push(
			<Body
				data={array}
				createCells={createCustomCell}
				cellPadding="0"
				key={`body-${key}-${rows.length}`}
			/>
		);
	}
	return rows;
};
const sortMap = (data = []) => {
	if (!data.length) {
		return {};
	}

	// INFO: Map of Parent campaign id to an array of child campaigns
	const parentMap = {
		// parentCampaignid: [child campaign,...]
	};
	data.forEach((campaignObj) => {
		if (parentMap[campaignObj.parent_campaign_id]) {
			parentMap[campaignObj.parent_campaign_id].push(campaignObj);
		} else {
			parentMap[campaignObj.parent_campaign_id] = [campaignObj];
		}
	});
	return parentMap;
};
const createCustomCell = (cellObj = {}) => {
	const keys = columnArray
		.map(({ id }) => id)
		.filter((id) => id !== "campaign_report_link");

	return keys.map((key, index) => {
		if (key === "campaign_name") {
			return campaignCell(key, cellObj);
		}
		//INFO: An object can have several rows of data, printing each one with a line break
		if (typeof cellObj[key] === "object") {
			const output = Object.values(cellObj[key]);

			const data = output.reduce((container, item, index) => {
				container.push(item);
				if (index !== output.length - 1) {
					container.push(<br key={index} />);
				}
				return container;
			}, [])

			if (key ==='form_submits') {
				const params = window.location.search.slice(1).split('&')
				const dataParams = {}
				const { top, bottom } = cellObj.date
				let url = `/reports?type=`
				const datePart = `&date_start=${top}&date_end=${bottom}`

				for (const param of params) {
					const [key, value] = param.split('=')

					dataParams[key] = value
				}

				const websiteIdPart = `&website_id=${dataParams.website_id}`

				if (dataParams.type === 'websiteComparison') {
					url += `websiteEmailCapture${websiteIdPart}${datePart}`
				}

				if (dataParams.type === 'parentCampaignComparison') {
					url += `parentEmailCapture${websiteIdPart}&parent_campaign_id=${dataParams.parent_campaign_id}${datePart}`
				}

				if (dataParams.type === 'childCampaignComparison') {
					url += `childEmailCapture${websiteIdPart}&campaign_id=${dataParams.campaign_id}${datePart}`
				}

				return (
					<Cell key={index}>
						<span>
							<Link href={url}>
								{data}
							</Link>
						</span>
					</Cell>
				)
			}

			return (
				<Cell key={index}>
					<span>
						{data}
					</span>
				</Cell>
			);
		}
		return <Cell key={index}>{cellObj[key]}</Cell>;
	});
};

const campaignCell = (
	key,
	{ mode, campaign_name, campaign_id, date_start, date_end }
) => {
	return (
		<React.Fragment key={campaign_id}>
			<Cell key={key}>
				<CampaignName>
					<ActiveIcon active={mode} />
					<Link href={`experiences/cms/${campaign_id}/edit`}>
						{campaign_name}
					</Link>
				</CampaignName>
			</Cell>
			<Cell key={`${key}-report-link`}>
				<Link
					href={`reports?type=childCampaign&campaign_id=${campaign_id}&date_start=${date_start}&date_end=${date_end}`}
				>
					&nbsp;&nbsp;&nbsp;
					<span className="icon-bar-chart"></span>
				</Link>
			</Cell>
		</React.Fragment>
	);
};



export default TableComponent;
