import { createSlice } from "@reduxjs/toolkit";
import { getPurposes, getPurpose } from "./thunks";

const initialState = {
	isFetchError: false,
    isLoading: false,
	response: {
		data: [],
	},
};

const purposesSlice = createSlice({
	name: "purposes",
	initialState,
	reducers: {
		clearPurposesApiError(state) {
			state.isFetchError = false;
		}
	},
	extraReducers: (builder) => {
		// getPurposes
        builder.addCase(getPurposes.pending, (state) => {
            state.isLoading = true
        });
		builder.addCase(getPurposes.fulfilled, (state, action) => {
            state.isLoading = false;
			state.response = action.payload;
		});
		builder.addCase(getPurposes.rejected, (state) => {
            state.isLoading = false;
			state.isFetchError = true;
		});
	},
});

export const {
	clearPurposesApiError,
} = purposesSlice.actions;

export default purposesSlice.reducer;
