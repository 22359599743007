import { endpoints } from "../constants/common";

// Possible options:
// campaign - campaign that contain the creative;
// website - website that contain the campaign;
// brandStyleId - ???;
// brandStyleFonts - ???;
// effect - ???;
const preparePayload = (jsonString, options) => {
	const campaign = options?.campaign || {};
	const website = options?.website || {};
	const campaignId = campaign?.id || 0;
	const brandStyleId = options?.brandStyleId || 0;
	const brandStyleFonts = options?.brandStyleFonts || [];
	const websiteId = website?.id || 0;
	const showPoweredBy = false;
		// website?.attributes?.["show-bouncex-pwer"] && !campaign?.attributes?.["hide-powered"];
	const poweredByMessage = website?.attributes?.["bouncex-pwer-message"];
	const effect = options?.effect ? options?.effect : "";
	const jsonObject = JSON.parse(jsonString);

	const creativeFonts = jsonObject.fonts || [];
	const brandFonts = jsonObject?.brand?.fonts?.length ? jsonObject.brand.fonts : brandStyleFonts;
	const fonts = [...new Set([...creativeFonts, ...brandFonts])];

	const stepsList = Array.isArray(jsonObject?.steps)
		? jsonObject.steps.map((__, i) => i + 1)
		: [];

	const fixedPlacement = getFixedPlacementObject(campaign, website);

	// Insert liquid variable values into JSON for rendering step
	// using the same regex as in comprehender_helper.php for liquid variables
	let newJson = jsonString;
	const matches = newJson.match(/\{(\w+)([|+#*)])?(\w+)?\}/g);
	const customLiquidVar = website?.attributes?.["custom-liquid-variable"];
	const isLiquidVars = customLiquidVar && customLiquidVar !== "[]";
	const liquidVars = isLiquidVars ? JSON.parse(customLiquidVar) : false;

	if (matches) {
		matches.forEach((match) => {
			let rawMatch = match.slice(1, -1);
			if (liquidVars[rawMatch.toLowerCase().replace("*", "")]) {
				if (match.includes("*")) {
					// if match includes * keep source casing
					newJson = newJson.replace(
						match,
						liquidVars[rawMatch.toLowerCase().slice(0, -1)].val
					);
				} else if (rawMatch.toUpperCase() === rawMatch) {
					// if match is all uppercase replace with uppercase value
					newJson = newJson.replace(
						match,
						liquidVars[rawMatch.toLowerCase()].val.toUpperCase()
					);
				} else if (rawMatch.toLowerCase() === rawMatch) {
					// if match is all lowercase replace with lowercase value
					newJson = newJson.replace(match, liquidVars[rawMatch].val.toLowerCase());
				} else if (stringIsTitleized(rawMatch)) {
					// if match is titleized replace with titleized value
					newJson = newJson.replace(
						match,
						titleizeString(liquidVars[rawMatch.toLowerCase()].val)
					);
				}
			}
		});
	}

	const postData = {
		json: newJson,
		brand_style_id: parseInt(brandStyleId),
		show_bouncex_pwer: showPoweredBy ? 1 : 0,
		bouncex_pwer_message: poweredByMessage,
		effect,
		campaign_id: campaignId,
		website_id: websiteId,
	};

	return {
		postData,
		jsonObject,
		fonts,
		stepsList,
		fixedPlacement,
	};
};

const writeIframe = (doc, data, fonts, websiteStylesheet, webfontsLookup) => {
	let head = "<!DOCTYPE html>" + getStylesheetLink(endpoints.cssUri, "bx-base-styles");

	fonts.forEach((font) => {
		head += getStylesheetLink(webfontsLookup[font].url, font);
	});

	if (websiteStylesheet) {
		head += getStylesheetLink(websiteStylesheet, "site-fonts");
	}

	doc.write(head + data);
	doc.close();
};

const getStylesheetLink = (href, id = "") => {
	return `<link id="${id}" href="${href}" rel="stylesheet" media="all" type="text/css">`;
};

const getFixedPlacementObject = (campaign, website) => {
	if (!campaign.attributes || !website.attributes) {
		return {};
	}

	return {
		callout_pos: campaign.attributes["callout-pos"],
		callout_hoffset: campaign.attributes["callout-hoffset"],
		callout_voffset: campaign.attributes["callout-offset"],
		header_bottom_alignment: campaign.attributes["header-bottom-alignment"],
		content_width: website.attributes["content-width"],
	};
};

const setActiveStep = (
	doc,
	fixedPlacement,
	currentStepNumber,
	renderWithEffect = false,
	allowScrolling = false
) => {
	// set body styles
	const body = doc.body;
	body.style.margin = "0";
	if (!allowScrolling) {
		body.style.overflow = "hidden";
	}

	// make the pusher first in the dom so that inspecting elements will give you the original
	const bxPusher = doc.querySelector(".bx-pusher");
	bxPusher && body.prepend(bxPusher);

	// remove old active step
	const oldStep = doc.querySelector(".bx-active-step");
	oldStep && oldStep.classList.remove("bx-active-step");

	// set new active step
	const newStep = doc.querySelector(`.bx-step-${currentStepNumber}`);
	newStep && newStep.classList.add("bx-active-step");
	const closePlacement = newStep ? newStep.getAttribute("data-close-placement") : "";

	const bxc = doc.getElementsByClassName("bxc");

	iterateHtmlCollection(bxc, (el) => {
		// set top level classes
		const newTopLevelClasses = el
			.getAttribute("class")
			.replace(/bx-active-step-(\d+)/, `bx-active-step-${currentStepNumber}`);

		el.setAttribute("class", newTopLevelClasses);
		el.classList.remove("bx-has-close-outside", "bx-has-close-inside");
		el.classList.add(`bx-has-close-${closePlacement ? closePlacement : "none"})`, "bx-impress");

		const bxAlign = el.getElementsByClassName("bx-align");

		// align fixed types
		const isNanotab = el.classList.contains("bx-type-nanotab");
		const isConversionCorner = el.classList.contains("bx-type-conversioncorner");
		const isDynamicWallpaper = el.classList.contains("bx-type-dynamicwallpaper");
		const isRailBar = el.classList.contains("bx-type-railbar");

		if (isNanotab || isConversionCorner || isDynamicWallpaper || isRailBar) {
			if (fixedPlacement.callout_pos) {
				el.classList.add(`bx-fixed-${fixedPlacement.callout_pos}`);
			}
			if (fixedPlacement.callout_hoffset) {
				const marginValue = `${parseInt(fixedPlacement.callout_hoffset)}px`;
				const bxSlab = el.getElementsByClassName("bx-slab");
				iterateHtmlCollection(bxSlab, (el) => (el.style["margin-left"] = marginValue));
			}
			if (fixedPlacement.callout_voffset) {
				if (fixedPlacement.callout_pos.includes("b")) {
					const marginValue = `${-parseInt(fixedPlacement.callout_voffset)}px`;
					iterateHtmlCollection(
						bxAlign,
						(el) => (el.style["margin-bottom"] = marginValue)
					);
				} else {
					const marginValue = `${parseInt(fixedPlacement.callout_voffset)}px`;
					iterateHtmlCollection(bxAlign, (el) => (el.style["margin-top"] = marginValue));
				}
			}

			if (isDynamicWallpaper) {
				if (fixedPlacement.header_bottom_alignment === "content_left") {
					const marginValue = `${parseInt(fixedPlacement.content_width / 2)}px`;
					iterateHtmlCollection(
						bxAlign,
						(el) => (el.style["margin-right"] = marginValue)
					);
				} else if (fixedPlacement.header_bottom_alignment === "content_right") {
					const marginValue = `${parseInt(fixedPlacement.content_width / 2)}px`;
					iterateHtmlCollection(bxAlign, (el) => (el.style["margin-left"] = marginValue));
				}
			}
		}

		// centered creatives
		const isAnnotation = el.classList.contains("bx-type-annotation");
		const isAgilityZone = el.classList.contains("bx-type-agilityzone");

		if (isAnnotation || isAgilityZone) {
			// center non-positioned children of body
			doc.documentElement.style.height = "100%";
			body.style.display = "flex";
			body.style.alignItems = "center";
			body.style.justifyContent = "center";
			body.style.height = "100%";
			body.style.margin = "0";
			body.style.overflow = "hidden";

			// remove positioning on annotations so they can be centered by body
			if (isAnnotation) {
				iterateHtmlCollection(bxAlign, (el) => (el.style.perspective = "none"));
				const bxCreative = el.getElementsByClassName("bx-creative");
				iterateHtmlCollection(bxCreative, (el) => {
					el.style.position = "static";
					el.style.bottom = "auto";
					el.style.left = "auto";
				});
			}
		}

		// toggle effects
		if (!renderWithEffect) {
			const bxCreative = el.getElementsByClassName("bx-creative");
			iterateHtmlCollection(bxCreative, (el) => (el.style.animation = "none"));
		} else {
			el.classList.add("bx-impress-in");
		}
	});

	// set dynamic input classes
	initStepInputJs(doc);
};

const initStepInputJs = (doc) => {
	const inputs = doc.querySelectorAll(".bxc .bx-input, .bxc .bx-textarea");
	inputs.forEach((input) => {
		input.addEventListener("focus", handleInputEvents);
		input.addEventListener("blur", handleInputEvents);
		input.addEventListener("keydown", handleInputEvents);
		input.addEventListener("keyup", handleInputEvents);
		input.addEventListener("change", handleInputEvents);
		input.addEventListener("paste", handleInputEvents);
	});

	function handleInputEvents(event) {
		const input = event.target;
		const parent = input.closest(".bx-row");
		switch (event.type) {
			case "focus":
				//parent.addClass('bx-has-focus');
				parent.classList.add("bx-has-focus");
				break;
			case "blur":
				//parent.removeClass('bx-has-focus');
				parent.classList.remove("bx-has-focus");
				break;
			case "keydown":
				{
					input.style["min-height"] = input.getBoundingClientRect().height;
					const ignoredKeys = {
						8: null,
						9: null,
						13: null,
						16: null,
						17: null,
						18: null,
						20: null,
						37: null,
						38: null,
						39: null,
						40: null,
						91: null,
						93: null,
						224: null,
					};

					if (!ignoredKeys.hasOwnProperty(event.keyCode)) {
						parent.classList.add("bx-has-text");
					}
				}
				break;
			case "change":
			case "paste":
			case "keyup":
				if (input.value.length > 0) {
					parent.classList.add("bx-has-text");
				} else {
					parent.classList.remove("bx-has-text");
				}
				break;
		}
	}
};

const iterateHtmlCollection = (collection, callback) => {
	for (const el of collection) {
		callback(el);
	}
};

export const CreativeRenderService = {
	preparePayload,
	writeIframe,
	setActiveStep,
	initStepInputJs,
};
