const createConversionSummaryData = (rawData) => {
	const count = {
		order_id: rawData.length,
		amount: 0,
		email: {},
		bx_credit: 0,
		visit_credit: 0,
		cookie_credit: 0,
		email_credit: 0,
		impressions: 0,
		clicks: 0,
		submits: 0,
		control_impressions: 0,
	};
	rawData.forEach((obj) => {
		count.amount += obj.amount;
		count.email[obj.email] = obj.email;
		count.bx_credit += obj.bx_credit;
		count.visit_credit += obj.visit_credit;
		count.cookie_credit += obj.cookie_credit;
		count.email_credit += obj.email_credit;
		count.impressions += obj.impressions;
		count.clicks += obj.clicks;
		count.submits += obj.submits;
		count.control_impressions += obj.control_impressions;
	});
	return [
		{
			...count,
			email: Object.keys(count.email).length,
			amount: Number(count.amount).toFixed(2),
		},
	];
};

export default createConversionSummaryData;
