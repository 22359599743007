import { createEntityAdapter } from "@reduxjs/toolkit";

const campaignsAdapter = createEntityAdapter({
	selectId: (campaign) => campaign.id,
});

export const {
	selectAll: selectAllCampaigns,
} = campaignsAdapter.getSelectors((state) => state.campaigns.response.data);

export default campaignsAdapter;
