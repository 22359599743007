import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";

export const getContracts = createAsyncThunk(
	"contracts/getContracts",
	async () => {
		const url = `${endpoints.coreApi}/contracts`;
		const response = await ApiService.getWithSearchParams(url);

		if (!response.ok) {
			throw new Error("Failed to load contracts!");
		}

		return response.json();
	}
);
