import CommonFields from "./commonFields";

export const methodInput = {
	label: "Method",
	name: "method",
	nameKey: "method",
	options: [
		{
			label: "Customer Type",
			value: "CheckCustomerStatus",
		},
		{
			label: "Customer Behavior",
			value: "CheckForEvents",
		},
		{
			label: "Customer State",
			value: "CustomerState",
		},
		{
			label: "Custom",
			value: "Custom",
		},
	],
};

export const customerTypeInput = {
	label: "Customer Type",
	name: "status",
	nameKey: "status",
	includes: ["CheckCustomerStatus"],
	isSelect: true,
	noDefault: true,
	options: [
		{
			label: "Customer",
			value: "previous customer",
		},
		{
			label: "Prospect",
			value: "prospect",
		},
	],
};

export const conditionInput = {
	label: "Condition",
	name: "mustNot",
	nameKey: "mustNot",
	includes: ["CheckForEvents"],
	excludeOnKey: "method",
	excludeOn: ["Custom"],
	isSelect: true,
	sizeString: "m",
	options: [
		{
			label: "Has Triggered",
			value: "",
		},
		{
			label: "Has Not Triggered",
			value: true,
		},
	],
};

export const eventInput = {
	label: "Event",
	name: "eventName",
	nameKey: "eventName",
	includes: ["CheckForEvents"],
	isSelect: true,
	sizeString: "m",
	noDefault: true,
	options: [
		{
			label: "Add to Cart",
			value: "add to cart",
		},
		{
			label: "Conversion",
			value: "conversion",
		},
		{
			label: "Custom",
			value: "custom",
		},
		{
			label: "Hover Item",
			value: "hover item",
		},
		{
			label: "Product Change",
			value: "product change",
		},
		{
			label: "Submit Email",
			value: "requestemail",
		},
		{
			label: "View Category",
			value: "view category",
		},
		{
			label: "View Item",
			value: "view item",
		},
		{
			label: "View Search",
			value: "view search",
		},
		{
			label: "Product Change News",
			value: "product change news",
		},
	],
};

export const eventTypeInput = {
	label: "Event Type",
	name: "and",
	nameKey: "andProductChange",
	includes: ["CheckForEvents"],
	placeholder: "N/A",
	enableOnKey: "eventName",
	enableOn: ["product change"],
	isSelect: true,
	sizeString: "m",
	noDefault: true,
	options: [
		{
			label: "Back In Stock",
			value: "IsBackInStock",
		},
		{
			label: "Low Stock",
			value: "IsLowStock",
		},
		{
			label: "Price Drop",
			value: "IsPriceDrop",
		},
		{
			label: "Out Of Stock",
			value: "IsOutOfStock",
		}
	],
};

export const duringTheLastInput = {
	label: "During the last",
	name: "when",
	nameKey: "n",
	includes: ["CheckForEvents"],
	placeholder: "N/A",
	field: CommonFields.genericNumber,
	enableOnKey: "period",
	enableOn: ["day", "hour", "minute"],
	excludeOnKey: "eventName",
	excludeOn: ["product change"],
};

export const whenInput = {
	label: "When",
	name: "whenperiod",
	nameKey: "period",
	includes: ["CheckForEvents"],
	enableOnKey: "eventName",
	enableOn: [
		"add to cart",
		"conversion",
		"custom",
		"hover item",
		"requestemail",
		"view category",
		"view item",
		"view search",
	],
	excludeOnKey: "eventName",
	excludeOn: ["product change"],
	isSelect: true,
	sizeString: "m",
	noDefault: true,
	options: [
		{
			label: "Days",
			value: "day",
		},
		{
			label: "Hours",
			value: "hour",
		},
		{
			label: "Minutes",
			value: "minute",
		},
		{
			label: "This Visit",
			value: "trigger visit",
		},
		{
			label: "Since Trigger Event",
			value: "since trigger event",
		},
		{
			label: "Since Last Event",
			value: "since last event",
		},
	],
};

export const lastEventInput = {
	label: "Last Event",
	name: "whenevent",
	nameKey: "event",
	includes: ["CheckForEvents"],
	enableOnKey: "period",
	enableOn: ["since last event"],
	excludeOnKey: "eventName",
	excludeOn: ["product change"],
	isSelect: true,
	sizeString: "m",
	noDefault: true,
	options: [
		{
			label: "Add to Cart",
			value: "add to cart",
		},
		{
			label: "Product Change",
			value: "product change",
		},
		{
			label: "View Item",
			value: "view item",
		},
	],
};

export const whenEventTypeInput = {
	label: "Event Type",
	name: "wheneventtype",
	nameKey: "eventType",
	includes: ["CheckForEvents"],
	enableOnKey: "event",
	enableOn: ["product change"],
	excludeOnKey: "eventName",
	excludeOn: ["product change"],
	enableIf: function (criterion) {
		return (
			criterion.get("period") === "since last event" &&
			criterion.get("event") === "product change"
		);
	},
	isSelect: true,
	sizeString: "m",
	noDefault: true,
	options: [
		{
			label: "Back In Stock",
			value: "back in stock",
		},
		{
			label: "Low Stock",
			value: "low stock",
		},
		{
			label: "Price Drop",
			value: "price drop",
		},
		{
			label: "Out Of Stock",
			value: "out of stock",
		}
	],
};

export const whereInput = {
	label: "Where (optional)",
	name: "property",
	nameKey: "property",
	includes: ["CheckForEvents"],
	placeholder: "None",
	excludeOnKey: "eventName",
	excludeOn: ["product change"],
	isInput: true,
	type: "text",
	allowEmpty: true,
	isSmall: true,
};

export const operatorInput = {
	label: "Operator",
	name: "operator",
	nameKey: "operator",
	includes: ["CheckForEvents"],
	enableOnKey: "property",
	enableOn: /([\d\w_]+)/,
	isRegex: true,
	options: CommonFields.lessGreaterEqualMatches.options,
	excludeOnKey: "eventName",
	excludeOn: ["product change"],
	isSmall: true,
};

export const valueInput = {
	label: "Value",
	name: "propertyValue",
	nameKey: "propertyValue",
	includes: ["CheckForEvents"],
	enableOnKey: "property",
	enableOn: /([\d\w_]+)/,
	isRegex: true,
	excludeOnKey: "eventName",
	excludeOn: ["product change"],
	isInput: true,
	type: "text",
	allowEmpty: true,
	isSmall: true,
};
export const stateInput = {
	label: "States",
	name: "state",
	nameKey: "state",
	includes: ["CustomerState"],
	isSelect: true,
	noDefault: true,
	options: [
		{
			label: "User has empty cart",
			value: "EmptyCart",
		},
	],
};
