import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Settings } from "react-feather";
import PropTypes from "prop-types";
import { ICON_SIZES } from "../../../constants/common";
import { ToolTip, StatusMark } from "../../../components";
import { selectWebsite } from "../../../redux/websites/slice";
import { NoInfoIconLink } from ".";

const WebsiteRow = ({ website, contracts, industries, verticals }) => {
	const { name, enabled, id } = website?.attributes;
	const managerName = website.attributes["manager-name"];
	const urlToSettings = `/websites-legacy/${id}/edit`;
	const contractId = website?.relationships?.contract?.data?.id;
	const industryId = website?.relationships?.industry?.data?.id;
	const verticalId = website?.relationships?.vertical?.data?.id;
	const { selectedWebsite } = useSelector((state) => state.websites);
	const isSelected = parseInt(selectedWebsite?.id) === id;
	const dispatch = useDispatch();

	const createNoInfo = (content) => (
		<NoInfoIconLink url={urlToSettings} tipText={content} />
	);

	const contractType = contractId
		? contracts.find((el) => el.id === contractId)?.attributes?.name
		: createNoInfo("Select a contract type from the settings page");
	const industryType = industryId
		? industries.find((el) => el.id === industryId)?.attributes?.name
		: createNoInfo("Select an industry from the settings page");
	const verticalType = verticalId
		? verticals.find((el) => el.id === verticalId)?.attributes?.name
		: createNoInfo("Select a verticals from the settings page");

	const checkADA = () => {
		try {
			const json = JSON.parse(
				website.attributes["enable-ally-settings-json"]
			);

			return Object.keys(json).some((el) => !!json[el]);
		} catch {
			return false;
		}
	};

	const selectWebsiteHandler = () => {
		dispatch(selectWebsite(website));
	};

	return (
		<WebsiteRowContainer
			selected={isSelected}
			onClick={selectWebsiteHandler}
		>
			<StatusMark enabled={enabled} styles={{ marginLeft: "11px" }} />
			<WebsiteName>
				<a
					className="website-name"
					data-websiteid={id}
					href={`/experiences/cms?website_id=${id}`}
				>
					<ToolTip text="Experiences" position="bottom">
						{name}
					</ToolTip>
				</a>
				{checkADA() && (
					<AbbrADA title="Americans with Disabilities Act">
						ADA
					</AbbrADA>
				)}
			</WebsiteName>
			<InfoWrapper>{contractType}</InfoWrapper>
			<InfoWrapper>{industryType}</InfoWrapper>
			<InfoWrapper>{verticalType}</InfoWrapper>
			<InfoWrapper>{managerName}</InfoWrapper>
			<IconSettingsLink href={urlToSettings} data-qa="settings-button">
				<ToolTip text="Settings" position="bottom">
					<Settings aria-label="settings" size={ICON_SIZES.medium} />
				</ToolTip>
			</IconSettingsLink>
		</WebsiteRowContainer>
	);
};

WebsiteRow.propTypes = {
	website: PropTypes.object.isRequired,
	contracts: PropTypes.array.isRequired,
	industries: PropTypes.array.isRequired,
	verticals: PropTypes.array.isRequired,
};

const WebsiteRowContainer = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	min-height: 44px;
	line-height: 14px;
	padding: 0 11px;
	border: 1px solid;
	border-color: ${({ selected }) =>
		selected ? "rgb(48, 61, 120)" : "rgb(232, 232, 232)"};
	z-index: ${({ selected }) => (selected ? "2" : "unset")};
	background-color: white;
	margin: -1px 0 -1px 0;
	&:hover {
		border: 1px solid rgb(48, 61, 120);
		z-index: 2;
	}
`;

const WebsiteName = styled.div`
	display: flex;
	align-items: center;
	width: 222px;
	margin: 0 11px;
	font-size: 12px;
	font-weight: 600;
`;

const AbbrADA = styled.abbr`
	margin: 0 5px;
	padding: 0 8px;
	height: 18px;
	background-color: rgb(245, 235, 225);
	border-radius: 5px;
	font-size: 10px;
`;

const InfoWrapper = styled.div`
	width: 100px;
	margin: 0 11px;
	font-size: 12px;
`;

const IconSettingsLink = styled.a`
	margin: 0 11px;
	font-size: 12px;
	font-weight: 600;
	text-align: right;
	margin-left: auto;
`;

export default WebsiteRow;
