import { createEntityAdapter } from "@reduxjs/toolkit";

const fontsAdapter = createEntityAdapter({
	selectId: (font) => font.id,
});

export const {
	selectAll: selectAllFonts,
} = fontsAdapter.getSelectors((state) => state.fonts.response.data);

export default fontsAdapter;
