import React, { useState, useEffect, useReducer } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
	CMSCreativePreview,
	BehavioralLogicBlock,
	VariationListBlock,
	NotesBlock,
	CampaignEditMain,
	CampaignEditHeader,
} from "../components";
import { getWebsite, getWebsiteId } from "../../../redux/website/thunks";
import { selectWebsite } from "../../../redux/websites/slice";
import { getCampaign } from "../../../redux/campaigns/thunks";
import { setSelectedCampaign } from "../../../redux/campaigns/slice";
import { getCampaignTypes } from "../../../redux/campaignTypes/thunks";
import { getParentCampaignTypes } from "../../../redux/parentCampaignTypes/thunks";
import { getIndustries } from "../../../redux/industries/thunks";
import { getPurposes } from "../../../redux/purposes/thunks";

import { SidebarButton, Wrapper } from "../../../components";

import { getTrafficType } from "../../../utils";

const CMSCampaignEdit = () => {
	const [parentCampaign, setParentCampaign] = useState({});
	const [parentTrafficType, setParentTrafficType] = useState("on");
	const [websiteUrl, setWebsiteUrl] = useState("");
	const [testCampaignUrl, setTestCampaignUrl] = useState("");
	const [websiteId, setWebsiteId] = useState("");
	const [pageTitle, setPageTitle] = useState("Loading...");
	const [selectedTrafficType, setSelectedTrafficType] = useState("");
	const dispatch = useDispatch();

	// Get campaign param from url
	//There has to be a better way than this...
	const url = window.location.pathname;
	const splitUrl = url.split("/").reverse("");
	const campaignId = splitUrl[1];

	useEffect(() => {
		const fetchWebsiteId = async () => {
			const response = await dispatch(getWebsiteId(campaignId));
			const websiteResponseId = response?.payload?.data?.relationships?.website?.data?.id;

			//this slice sets the website to the state. can later be used by useSelect on child components and then used as a regular object
			const websiteDataResponse = await dispatch(getWebsite(websiteResponseId));
			dispatch(selectWebsite(websiteDataResponse?.payload?.data));
			const testUrl = websiteDataResponse?.payload?.data?.attributes?.website;
			setWebsiteId(websiteResponseId);
			const websiteCampaignsUrl = `/experiences/cms?website_id=${websiteResponseId}`;
			setTestCampaignUrl(testUrl);
			setWebsiteUrl(websiteCampaignsUrl);
		};

		fetchWebsiteId();
	}, [campaignId]);

	const fetchCampaignData = async () => {
		const response = await dispatch(getCampaign(campaignId));
		const campaignData = response?.payload?.data;
		dispatch(setSelectedCampaign(campaignData));
		setParentCampaign(campaignData);
		setParentTrafficType(getTrafficType(campaignData));
		const title = campaignData?.attributes?.name;
		setPageTitle(title);

		if (campaignData?.attributes?.["test-type"] !== "parent") {
			const campaignParentId = campaignData?.relationships?.parent?.data?.id;
			const parentResponse = await dispatch(getCampaign(campaignParentId));
			const parentResponseData = parentResponse?.payload?.data;
			//If the selected campaign is not the main campaign on reload, make sure that the parent of the selected campaign remains as the main campaign
			setParentCampaign(parentResponseData);
			changeCampaign(campaignData);
			setParentTrafficType(getTrafficType(parentResponseData));
			setSelectedTrafficType(getTrafficType(campaignData));
		}
	};

	useEffect(() => {
		fetchCampaignData();
		dispatch(getParentCampaignTypes());
		dispatch(getPurposes());
		dispatch(getCampaignTypes());
		dispatch(getIndustries());
	}, [campaignId]);

	const changeCampaign = (campaign) => {
		dispatch(setSelectedCampaign(campaign));
		splitUrl.splice(1, 1, campaign.id);
		const url = splitUrl.join("/").split("/").reverse("").join("/");
		window.history.pushState({ path: url }, "", url);
	};

	const children = parentCampaign?.relationships?.children;
	const { selectedWebsite } = useSelector((state) => state.websites);
	const websiteName = selectedWebsite?.attributes?.name || "Back";

	return (
		<Wrapper
			left="0"
			maxWidth="100%"
			style={{
				display: "flex",
				justifyContent: "space-between",
				bottom: "0",
			}}
			title={pageTitle}
		>
			<Aside>
				<SidebarButton url={websiteUrl} dataQa={"website-button"} text={websiteName} />
				<BlockLabel>Behavioral Logic</BlockLabel>
				<BehavioralLogicBlock
					parentCampaign={parentCampaign}
					changeCampaign={changeCampaign}
					getTrafficType={getTrafficType}
					selectedTrafficType={selectedTrafficType}
					parentTrafficType={parentTrafficType}
				/>

				<BlockLabel>Variations</BlockLabel>
				{children?.data.map((child, i) => {
					return (
						<VariationListBlock
							variationId={child?.id}
							changeCampaign={changeCampaign}
							selectedTrafficType={selectedTrafficType}
							getTrafficType={getTrafficType}
							setSelectedTrafficType={setSelectedTrafficType}
							parentCampaign={parentCampaign}
							key={i}
						/>
					);
				})}

				<NotesWrapper>
					<BlockLabel>Notes</BlockLabel>
					<NotesBlock parentCampaign={parentCampaign} />
				</NotesWrapper>
				<CMSCreativePreview showLabel />
			</Aside>
			<Main>
				<CampaignEditHeader
					testCampaignUrl={testCampaignUrl}
					parentCampaign={parentCampaign}
					parentTrafficType={parentTrafficType}
					setParentTrafficType={setParentTrafficType}
					selectedTrafficType={selectedTrafficType}
					setSelectedTrafficType={setSelectedTrafficType}
					getTrafficType={getTrafficType}
					refreshCampaign={fetchCampaignData}
				/>
				<CampaignEditMain />
			</Main>
		</Wrapper>
	);
};

const Aside = styled.aside`
	width: 270px;
	background-color: #ffffff;
`;

const BlockLabel = styled.h2`
	display: block;
	margin: 0;
	padding: 0.786rem 1.571rem;
	color: #626161;
	font-size: 0.857rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
	font-weight: 400;
`;

const NotesWrapper = styled.div`
	border-top: 1px solid #e8e8e8;
	border-bottom: 1px solid #e8e8e8;
	margin: 15px 0;
`;

const Main = styled.main`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

export default CMSCampaignEdit;
