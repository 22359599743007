import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import fetch from "isomorphic-fetch";
import { toast } from "react-toastify";
import { Button } from "../../../components";
import { endpoints } from "../../../constants/common";

const DownloadCampaignsPreviewModal = ({ pageTitle, websiteId, inbox, toggleModal }) => {

    const campaignSeries = inbox.filter(
		(campaign) => campaign?.attributes?.["campaign-series-id"] === campaign?.relationships?.["inbox-series"]?.data?.id
	);

	const token = window.retrieveTokenFromStorage();

	const [checked, setChecked] = useState([]);
	const [allSelected, setAllSelected] = useState(false);

	// Add/Remove checked item from list
	const handleCheck = (event) => {
		var updatedList = [...checked];
		if (event.target.checked) {
			updatedList = [...checked, event.target.value];
		} else {
			updatedList.splice(checked.indexOf(event.target.value), 1);
		}
		setChecked(updatedList);
		if (updatedList.length === campaignSeries.length) {
			setAllSelected(true);
		} else {
			setAllSelected(false);
		}
	};

	const downloadAPICall = async () => {
		const url = `${endpoints.campaignUrl}/v1/websites/${websiteId}/campaigns/creatives`;
        const response = await fetch(url, {
			cache: "no-cache",
			method: "POST",
			body: JSON.stringify({ campaignIds: [], seriesIds: checked, format: "PNG" }),
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return await response.json();
	};

	let isChecked = (item) => (checked.includes(item) ? "checked-item" : "not-checked-item");
	return (
		<Modal>
			<>
				<Header>
					Download Campaign Creatives
					<br />
					for{" "}
					{`${pageTitle || ""} ${
						websiteId || ""
					}`}
				</Header>
				<div style={{ marginBottom: "5px" }}>
					<Button
						theme="gray"
						width="100px"
						onClick={() => {
							if (allSelected) {
								setChecked([]);
								setAllSelected(false);
							} else {
								setChecked(campaignSeries.map((item) => item.attributes["campaign-series-id"]));
								setAllSelected(true);
							}
						}}
					>
						{allSelected ? "Deselect All" : "Select All"}
					</Button>
				</div>
			</>
			<div className="checkList">
				<div className="list-container">
					{campaignSeries.map((item) => (
						<Row key={item.attributes["campaign-series-id"]}>
							<input
								checked={checked.includes(item.attributes["campaign-series-id"])}
								value={item.attributes["campaign-series-id"]}
								type="checkbox"
								onChange={handleCheck}
							/>
							<span style={{ paddingLeft: "5px" }} className={isChecked(item.attributes["campaign-series-id"])}>
								{item.attributes["series-name"]}
							</span>
							<span style={{ marginLeft: "auto" }}>{item.attributes["campaign-series-id"]}</span>
						</Row>
					))}
				</div>
			</div>
			<ButtonDiv>
				<Button
					theme="gray"
					onClick={async () => {
						if (checked.length === 0) {
							return;
						}
						let response = await downloadAPICall();
						if (response?.status === "OK") {
							toggleModal();
							toast.success(
								"Download request successful. Please check your email for the link",
								{
									autoClose: 2500,
								}
							);
						} else {
							toast.error("An error has occurred. Please try again", {
								autoClose: 2000,
							});
						}
					}}
				>
					Download Campaigns
				</Button>
			</ButtonDiv>
		</Modal>
	);
};

const Modal = styled.div`
	overflow-y: auto;
	max-height: 80vh;
	min-height: 80vh;
	max-width: 80vw;
	min-width: 60vw;
`;

const ButtonDiv = styled.div`
	text-align: center;
	margin-top: 10px;
`;

const Row = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 11px;
	width: 100%;
	overflow-y: auto;
	background-color: ${({ bgColor }) => bgColor || "transparent"};
	height: ${({ isParent }) => (isParent ? "53px" : "42px")};
	border: 1px solid;
	border-color: ${({ isSelected, isArchived }) =>
		isSelected ? "rgb(48, 61, 120)" : isArchived ? "rgb(160, 158, 156)" : "rgb(232, 232, 232)"};
	z-index: ${({ isSelected }) => (isSelected ? "5" : "1")};
	&:hover {
		border-color: rgb(48, 61, 120);
		z-index: 5;
		filter: brightness(95%);
		background: #cdcdcd;
	}
`;

const Header = styled.div`
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	padding: 0;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	text-align: center;
	color: #535252;
`;

DownloadCampaignsPreviewModal.propTypes = {
	inbox: PropTypes.array.isRequired,
	toggleModal: PropTypes.func.isRequired,
    pageTitle: PropTypes.string,
    websiteId: PropTypes.string,
};

export default DownloadCampaignsPreviewModal;
