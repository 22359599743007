import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { SidebarButton } from "../../../components";
import { Divider } from "../../stats/styledComponents";
import {
	seriesPropTypes,
	parentPropTypes,
	variationPropTypes,
	seriesPagePropTypes,
} from "../propTypes";
import SeriesLSBContainer from "./SeriesLSBContainer";
import ParentsLSBContainer from "./ParentsLSBContainer";

// LSB = Left Side Bar
const LSBContainer = ({ series, parents, variations }) => {
	const { selectedWebsite } = useSelector((state) => state.websites);

	const websiteName = selectedWebsite?.attributes?.name || "Back";
	const websiteUrl = selectedWebsite?.attributes?.id
		? `/experiences/inbox?website_id=${selectedWebsite?.attributes?.id}`
		: `/experiences/inbox`;

	if (!series) {
		return null;
	}

	return (
		<>
			<SidebarButton url={websiteUrl} dataQa={"website-button"} text={websiteName} />
			<div>
				<SeriesLSBContainer seriesData={series} />
				<Divider />
				<ParentsLSBContainer parents={parents} variations={variations} />
			</div>
		</>
	);
};

LSBContainer.propTypes = {
	parents: PropTypes.arrayOf(parentPropTypes),
	variations: PropTypes.arrayOf(variationPropTypes),
	series: PropTypes.oneOfType([seriesPropTypes, seriesPagePropTypes]),
};

export default LSBContainer;
