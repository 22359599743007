import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

import { clearRenderedCreative } from "../redux/creatives/slice";
import { renderCreative } from "../redux/creatives/thunks";
import { setFontsStylesheet } from "../redux/fonts/slice";
import { CreativeRenderService } from "../service/creativeRender";

const selectedCampaignsSelector = ({ campaigns }) => campaigns.selectedCampaigns;
const selectedWebsiteSelector = ({ websites }) => websites.selectedWebsite;

const selectedCampaignIdSelector = createSelector(
	[selectedCampaignsSelector],
	(selectedCampaigns = []) => {
		return selectedCampaigns.length === 1 ? selectedCampaigns[0].id : null;
	}
);

const selectedCampaignSelector = createSelector(
	[selectedCampaignsSelector],
	(selectedCampaigns = []) => {
		return selectedCampaigns.length === 1 ? selectedCampaigns[0] : selectedCampaigns[selectedCampaigns.length - 1];
	}
);

function getCreativePreviewAttributes(campaign, website) {
	const creativeRawData = campaign?.attributes?.["new-genie-json"];
	const isBouncex = campaign?.attributes?.product === "bouncex";
	const isVariation = campaign?.attributes?.["test-type"] === "variation";
	
	const creativePayload = (!creativeRawData)
	? {}
	: CreativeRenderService.preparePayload(creativeRawData, {
		website: website,
		campaign: campaign,
	});
	
	return {
		isBouncex,
		isVariation,
		creativePayload,
	};
}

export const useCreativePreview = (campaign) => {
	const dispatch = useDispatch();
	/** Using this as a way to memoize the updateCreative callback with a primitive */
	const campaignIdSelected = useSelector(selectedCampaignIdSelector);
	const campaignId = campaign?.data?.id ?? campaignIdSelected;
	const selectedCampaign = useSelector(selectedCampaignSelector);
	
	const selectedWebsite = useSelector(selectedWebsiteSelector);
	const creativeCampaign = campaign?.data ?? selectedCampaign;
	const { isBouncex, isVariation, creativePayload } = getCreativePreviewAttributes(creativeCampaign, selectedWebsite);

	const {
		postData,
		jsonObject,
		stepsList = [],
		fonts = [],
		fixedPlacement = {},
	} = creativePayload;

	const updateCreative = useCallback(() => {
		dispatch(clearRenderedCreative());

		if (postData && jsonObject) {
			dispatch(renderCreative(postData));
			dispatch(
				setFontsStylesheet({
					json: jsonObject,
					websiteId: postData.website_id,
				})
			);
		}
	}, [campaignId]);

	return {
		isBouncex,
		isVariation,
		stepsList,
		fonts,
		fixedPlacement,
		updateCreative,
	};
};
