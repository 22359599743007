import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const TitleComponent = ({ title = "", children }) => {
	return (
		<Title>
			{title}
			{children}
		</Title>
	);
};
TitleComponent.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node,
};
const Title = styled.div`
	margin: 10px 0;
	padding: 20px;
	background: #fff;
	font-weight: bolder;
	min-width: 535px;
	button {
		margin-left: 10px;
	}
`;

export default TitleComponent;
