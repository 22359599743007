import React from "react";
import PropTypes from "prop-types";
import { Circle } from "react-feather";
import { ICON_SIZES } from "../../constants/common";

const COLORS_MAP = {
	enabled: "rgb(151, 212, 131)",
	disabled: "rgb(160, 158, 156)",
};

const getIconColor = (status) => {
	return status ? COLORS_MAP.enabled : COLORS_MAP.disabled;
};

/**
 * Status Mark Component -- renders the green / gray circle if a campaign is active
 * @param {bool} enabled - renders the green / gray circle
 * @param {object} styles - CSS specific overides / adjustments for the circle
 * @returns <StatusMark enabled={enabled} />
 */

const StatusMark = ({ enabled, styles }) => (
	<Circle
		aria-label="status mark"
		size={ICON_SIZES.small}
		color={getIconColor(enabled)}
		fill={getIconColor(enabled)}
		style={styles}
	/>
);


StatusMark.propTypes = {
	enabled: PropTypes.bool.isRequired,
	styles: PropTypes.object,
};

export default StatusMark;
