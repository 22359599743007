import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { toast } from "react-toastify";
import { Button, SearchableSelect } from "../../../components";
import { selectAllWebsites } from "../../../redux/website-micros/websitesAdapter";
import { crossSiteClonning } from "../../../redux/inboxSeries/thunks";

const CampaignTemplateCloningModal = ({ toggleModal }) => {
    const dispatch = useDispatch();

    const websites = useSelector(selectAllWebsites);
    const [template, setTemplate] = useState("")
    const [cloneTo, setCloneTo] = useState("")
    const [submitting, setSubmitting] = useState(false)

    const options = websites.map((site) => {
        return {
            value: site.attributes.id,
            label: site.attributes.name,
        };
    });

    const crossSiteDuplicate = async () => {
        setSubmitting(true);
        const response = await dispatch(
            crossSiteClonning({
                template: template,
                cloneTo: cloneTo
            })
        )

        const { requestStatus } = response?.meta;

        if (requestStatus === "fulfilled") {
            toggleModal();
            toast.success("Successfully duplicated.", { autoClose: 1000 });
            setSubmitting(false);
        } else {
            toast.error("Error duplicating.", { autoClose: 1000 });
            setSubmitting(false);
        }
    };

    const isActive = !!template && !!cloneTo

    return (
        <Modal>
            <>
                <Header>
                    Campaign Template Cloning Tool
                </Header>
                <SearchableSelect
                    placeholder="Select a template to clone"
                    options={options}
                    onChange={(id) => setTemplate(id)}
                    showId
                    label="Clone source:"
                    value={template}
                    showValue
                />
                <SearchableSelect
                    placeholder="Select a website to clone to"
                    options={options}
                    onChange={(id) => setCloneTo(id)}
                    showId
                    label="Clone destination:"
                    value={cloneTo}
                    showValue
                />

                <ButtonDiv>
                    <Button
                        theme={(isActive && !submitting) ? "blue" : "gray"}
                        disabled={!isActive || submitting}
                        onClick={crossSiteDuplicate}
                        isLoading={submitting}
                    >
                        {submitting ? "" : "Submit"}
                    </Button>
                </ButtonDiv>
            </>
        </Modal>
    );
};

const Modal = styled.div`
    padding: 10px;
`;

const ButtonDiv = styled.div`
	text-align: center;
	margin-top: 20px;
`;

const Header = styled.div`
	display: block;
	margin-top: 5px;
	margin-bottom: 10px;
	padding: 0;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	text-align: center;
	color: #535252;
`;

CampaignTemplateCloningModal.propTypes = {
    toggleModal: PropTypes.func.isRequired,
};

export default CampaignTemplateCloningModal;
