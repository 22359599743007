import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getPreview } from "../../../../../../redux/inbox/thunks";
import {
	IFrameContainer,
	IFrame,
	PreviewContainer,
	PreviewAside,
	PreviewHeader,
	HeaderLabel,
	ViewSizes,
	Size,
} from "../../design/Design.styled";

const Preview = () => {
	const [zoomValue, setZoomValue] = useState(75);
	const [previewType, setPreviewType] = useState("desktop");
	const [preview, setPreview] = useState(null);
	const campaign = useSelector((state) => state.inboxCampaigns?.selectedInboxCampaign);
	const dispatch = useDispatch();

	useEffect(() => {
		if (campaign) {
			dispatch(
				getPreview({
					campaignId: campaign.id,
					nodeEts: "1",
					testItems: encodeURIComponent(campaign?.attributes["test-items-json"]),
				})
			).then((response) => {
				setPreview(response.payload);
			});
		}
	}, [campaign]);

	return (
		<div style={{ background: "white" }}>
			<PreviewHeader>
				<HeaderLabel>Preview</HeaderLabel>
				<PreviewAside>
					<input
						type="range"
						min="20"
						max="75"
						value={zoomValue}
						className="slider"
						onChange={(e) => setZoomValue(e.currentTarget.value)}
					/>
					<ViewSizes>
						<Size
							onClick={() => setPreviewType("mobilexs")}
							selected={previewType === "mobilexs"}
						>
							Mobile XS
						</Size>
						<Size
							onClick={() => setPreviewType("mobile")}
							selected={previewType === "mobile"}
						>
							Mobile
						</Size>
						<Size
							onClick={() => setPreviewType("desktop")}
							selected={previewType === "desktop"}
						>
							Desktop
						</Size>
					</ViewSizes>
				</PreviewAside>
			</PreviewHeader>
			<PreviewContainer>
				<IFrameContainer zoomValue={zoomValue} previewType={previewType}>
					<IFrame srcDoc={preview} zoomValue={zoomValue} previewType={previewType} />
				</IFrameContainer>
			</PreviewContainer>
		</div>
	);
};

export default Preview;
