import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { X } from "react-feather";

import { ICON_SIZES } from "../constants/common";

const PopupModal = ({ toggleModal, children, closeOnOutClick, showModal, style }) => {
	const closeModal = () => {
		toggleModal(false);
	};

	if (!showModal) {
		return null;
	}

	return (
		<Wrapper>
			<Overlay onClick={closeOnOutClick && closeModal} />
			<Modal style={style}>
				<CloseButton data-qa="close-modal-button" onClick={closeModal}>
					<X size={ICON_SIZES.extra_large} aria-label="close icon" />
				</CloseButton>
				<ChildrenWrapper>
					{children}
				</ChildrenWrapper>
			</Modal>
		</Wrapper>
	);
};

PopupModal.propTypes = {
	toggleModal: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
	showModal: PropTypes.bool.isRequired,
	closeOnOutClick: PropTypes.bool,
	style: PropTypes.any,
};

PopupModal.defaultProps = {
	closeOnOutClick: true,
	style: {},
};

const Overlay = styled.div`
	z-index: 9997;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: #aaaaaa;
	opacity: 0.3;
`;

const Modal = styled.div`
	z-index: 9998;
	position: fixed;
	left: 50%;
	top: 45%;
	transform: translate(-50%, -50%);
	background: #ffffff;
	border: 1px solid #aaaaaa;
	border-radius: 4px;
	padding: 20px;
	max-width: 90vw;
	max-height: 90vh;
	overflow-y: auto;
`;

const Wrapper = styled.div`
	position: relative;
`;

const CloseButton = styled.div`
	position: absolute;
	right: 20px;
	top: 10px;
	display: inline-block;
	width: 24px;
	height: 24px;
	text-align: center;
	z-index: 10003;
	color: #535252;
	font-weight: bolder;
	padding: 0px 4px;
	cursor: pointer;
	svg {
		position: relative;
		left: -1px;
		top: 3px;
	}
`;

const ChildrenWrapper = styled.div`
	margin-top: 25px;
`

export default PopupModal;
