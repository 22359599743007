import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ToolTip } from "../../../components";

const DiffWrap = ({ diff, callback, children }) => {
	if (!diff) {
		return children;
	}
	return (
		<>
			<ToolTip text="Copy to siblings" position="right">
				<Delta onClick={() => callback(children)}>
					<i className="icon-delta" />
				</Delta>
			</ToolTip>
			<BorderWrapper>{children}</BorderWrapper>
		</>
	);
};

const Delta = styled.div`
	cursor: pointer;
	color: #ffffff;
	width: 1.001rem;
	height: 1.001rem;
	font-size: 0.786rem;
	border-radius: 0.5rem;
	line-height: 1.001rem;
	text-align-last: center;
	background-color: #dba47f;
`;

const BorderWrapper = styled.div`
	box-shadow: 0 0 0 2px #ffe0ca;
	line-height: 0;
`;

DiffWrap.propTypes = {
	diff: PropTypes.bool,
	callback: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

export default DiffWrap;
