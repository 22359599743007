import styled from 'styled-components'

export const Link = styled.div`
    background: ${props => props.highlight ? "#F5EBE1" : null};
    font-size: 0.857rem;
    font-weight: 600;
    white-space: nowrap;
    padding: 0.786rem;
    opacity: ${props => props.disable ? "0.2" : null};
    pointer-events: ${props => props.highlight || props.disable ? "none" : null};
    & > * {
        display: flex;
        justify-content: center;
    };
    &:hover * {
        filter: invert(100%);
        cursor: pointer;
    };
    &.ViewPage {
        height: 44px;
        width: 110px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    };
`

export const PositionContainer = styled.div`
    display: flex;
`

export const Left = styled.div`
    display: flex;
    align-items: center;
`

export const Middle = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
`

export const Right = styled.div`
    display: flex;
    align-items: center;
`

export const Navigation = styled.div`
    background: #25B89D;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border-top: 0;
    border: none;
    z-index: 3;
    min-height: 3.143rem;
    color: #191919;
    fill: #191919;
`
