import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { InputContainer, InputLabel, Select } from "../styles";

const SimpleList = ({ options }) => {
	return options.map(({ name = "", value = "" }, index) => {
		return (
			<option key={index} value={value}>
				{name}
			</option>
		);
	});
};

const DynamicOptions = ({ options, defaultOption }) => {
	if (Array.isArray(options)) {
		return (
			<>
				{defaultOption && <option value="none">{defaultOption}</option>}
				<SimpleList options={options} />
			</>
		);
	} else {
		return (
			<>
				{defaultOption && <option value="none">{defaultOption}</option>}
				{Object.keys(options).map((groupName, index) => {
					return (
						<optgroup key={index} label={groupName}>
							<SimpleList options={options[groupName]} />
						</optgroup>
					);
				})}
				;
			</>
		);
	}
};

DynamicOptions.propTypes = {
	options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

const Dropdown = ({
	width,
	minWidth = "",
	label = "",
	field = "",
	value = "",
	options = [],
	internalLabel,
	onChange = () => {},
	dataQA = "",
	margin = "",
	defaultOption = null,
}) => {
	const setChange = ({ target }) => {
		onChange({
			[field]: target.value,
		});
	};
	return (
		<>
			{internalLabel ? (
				<SelectWrapper>
					<InputLabel>{label}</InputLabel>
					<Select value={value ?? "none"} onChange={setChange}>
						<DynamicOptions options={options} defaultOption={defaultOption} />
					</Select>
					<i className="icon-angle-down input-icon_icon" />
				</SelectWrapper>
			) : (
				<InputContainer data-qa={dataQA} margin={margin} width={width}>
					<InputLabel>{label}</InputLabel>
					<Select value={value ?? "none"} onChange={setChange}>
						<DynamicOptions options={options} defaultOption={defaultOption} />
					</Select>
				</InputContainer>
			)}
		</>
	);
};

const SelectWrapper = styled.div`
	text-align: left;
	align-items: center;
	display: flex;
	margin: 0 0.786rem;
	position: relative;
`;

Dropdown.propTypes = {
	width: PropTypes.string,
	minWidth: PropTypes.string,
	internalLabel: PropTypes.bool,
	label: PropTypes.string.isRequired,
	field: PropTypes.string.isRequired,
	dataQA: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	options: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.shape({
				name: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
			})
		),
		PropTypes.shape({
			[PropTypes.string]: PropTypes.shape({
				name: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
			}),
		}),
	]).isRequired,
	defaultOption: PropTypes.string,
	margin: PropTypes.string,
};
export default Dropdown;
