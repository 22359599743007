import { createEntityAdapter } from "@reduxjs/toolkit";

const espsAdapter = createEntityAdapter({
	sortComparer: (a, b) => a.attributes.name.localeCompare(b.attributes.name),
});

export const {
	selectAll: selectAllESPs,
} = espsAdapter.getSelectors((state) => state.esps.response.data);

export default espsAdapter;
