import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { groupBy } from 'lodash';

const createGroupedDropdown = (optionsArray, types) => {
	return Object.keys(types)
		.sort()
		.forEach((key) => {
			if (key !== "undefined") {
				optionsArray.push({
					groupName: key,
					options: types[key].map((el) => {
						return {
							attributes: el.attributes,
							relationships: el.relationships,
						}
					}),
				});
			}
		});
};

const getPurposeMethods = () => {
	const {
		purposes: {
			response: { data: purposes = [] },
		},
		industries: {
			response: { data: industries = [] },
		},
	} = useSelector((state) => state);

	const getPurposeOptions = ({ productType, eCommerceIndustryId = null }) => {
		const websiteRelationships = useSelector((state) => state.websites?.selectedWebsite?.relationships);
		const INDUSTRY_ID = websiteRelationships?.industry.data.id;

		const getOptions = () => {
			let allPurposes = null
			if(purposes.length) {
				allPurposes = purposes.reduce((allSoFar, current) => {
					return {...allSoFar, [current.id]: current}
				})
			}
			let options = [
				allPurposes ? { ...allPurposes } : { id: "", name: "All Purposes" }
			];

			let filteredPurposes = purposes.filter(
				({ attributes = {} }) =>
					attributes.product === "all" || attributes.product ===  productType
			);

			options.push(filteredPurposes.reduce((allSoFar, current) => {
				return { ...allSoFar, [current.id]: current}
			}, {}))

			/**
			 * TODO: remove?
			 *
			 * This is from the code that Rob wrote. I'm not sure it actually
			 * is needed, but I don't want to remove it give my lack of
			 * understanding. For now I'm making the ecomm industry id optional.
			 */
			if (eCommerceIndustryId && INDUSTRY_ID !== eCommerceIndustryId) {
				filteredPurposes = filteredPurposes.filter(
					({ attributes = {} }) =>
						attributes["industry-id"] === eCommerceIndustryId
				);
			}

			const groupedPurposes = groupBy(
				filteredPurposes,
				({ attributes = {} }) => {
					const industryId = attributes["industry-id"];
					const industry = industries.find((el) => el.id === industryId);
					return industry?.attributes?.name;
				}
			);

			/**
			 * TODO: remove?
			 *
			 * This is left over from Rob's code. I'm not sure why we need
			 * "options", but again because I don't fully understand the
			 * environment and all use cases I'm leaving it in here.
			 */
			createGroupedDropdown(options, groupedPurposes);
			return options;
		};


		return useMemo(
			() => getOptions(),
			[purposes, industries, INDUSTRY_ID]
		);
	}

	return {
		getPurposeOptions
	}
}

// TODO: make this usable for different types of pages.

export default getPurposeMethods;