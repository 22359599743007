/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Icon } from "../../../../../stats/styledComponents";
import { Section, FormInput } from "../../../../../../components/form";
import { useSelectInboxCampaigns } from "../../../../../../hooks/selectors/useSelectInboxCampaigns";
import { updateInboxCampaign } from "../../../../../../redux/inboxCampaigns/thunks";
import { useGetDefaultUTMValues } from "../../../utils/useGetDefaultUTMValues";

const ClickThroughParameters = () => {
	const dispatch = useDispatch();
	const {
		params: { campaignId },
	} = useSelector((state) => state.page);
	const campaign = useSelector(useSelectInboxCampaigns)?.[campaignId];

	const {
		utmData,
		utmMediumDefault,
		utmSourceDefault,
		utmCampaignDefault,
		utmTermDefault,
		joinParams,
		clickParams,
	} = useGetDefaultUTMValues(campaign);

	const [utmState, setUtmState] = useState(utmData);

	const [campaignUtmOverride, setCampaignUtmOverride] = useState(
		utmData?.["utm_campaign"] !== utmCampaignDefault
	);
	const [sourceUtmOverride, setSourceUtmOverride] = useState(
		campaign?.attributes?.["utm-override"] ?? false
	);
	const [mediumUtmOverride, setMediumUtmOverride] = useState(
		utmData?.["utm_medium"] !== utmMediumDefault
	);
	const [termUtmOverride, setTermUtmOverride] = useState(
		utmData?.["utm_term"] !== utmTermDefault
	);

	const removeFromUtmData = (type) => {
		delete utmState[type];
		setUtmState(utmState);
	};

	const setOverride = (newValue, overrideFunction, defaultValue, attribute) => {
		overrideFunction(newValue);
		setUtmState({
			...utmState,
			[attribute]: defaultValue,
		});
	};

	useEffect(() => {
		if (!utmState) {
			setUtmState(utmData);
		}
	}, [utmData]);

	useEffect(() => {
		if (utmState) {
			dispatch(
				updateInboxCampaign({
					id: campaignId,
					attributes: {
						"click-params": joinParams(utmState),
						"utm-override": utmState?.["utm-override"],
					},
				})
			);
		}
	}, [utmState]);

	if (!clickParams) {
		return null;
	}

	return (
		<Section title="Click Through Parameters">
			<Preview>
				<span>Preview</span>
				<PreviewSection>
					<IconSection>
						<Icon
							onClick={() => {
								navigator.clipboard.writeText(clickParams);
							}}
							className="icon-clipboard"
						/>
					</IconSection>
					<InputPreviewSection>
						<CodeInput value={clickParams} readOnly />
					</InputPreviewSection>
				</PreviewSection>
			</Preview>
			<Divider />
			<UTM>
				<TextSection>Campaign</TextSection>
				<InputSection>
					<LeftAlign>
						<Input
							type="checkbox"
							checked={campaignUtmOverride}
							onChange={(event) =>
								setOverride(
									event.target.checked,
									setCampaignUtmOverride,
									utmCampaignDefault,
									"utm_campaign"
								)
							}
							name="campaign_override"
						/>
						<Label htmlFor="utm_campaign">Override</Label>
					</LeftAlign>
					<FormInput
						disabled={!campaignUtmOverride}
						value={utmState?.["utm_campaign"] ?? utmCampaignDefault}
						onChange={(e) =>
							setUtmState(...utmState, (utmState["utm_campaign"] = e.target.value))
						}
						label="utm_campaign"
						placeholder="campaign"
						width="100%"
					/>
				</InputSection>
				<RemoveSection>
					<RemoveButton onClick={() => removeFromUtmData("utm_campaign")}>
						Remove
					</RemoveButton>
				</RemoveSection>
			</UTM>
			<Divider />
			<SureMessage>
				Make sure purpose, parts and total parts are consistent. If they are not, uncheck
				override to get the default.
			</SureMessage>
			<Divider />
			<UTM>
				<TextSection>Source</TextSection>
				<InputSection>
					<LeftAlign>
						<Input
							type="checkbox"
							checked={sourceUtmOverride}
							onChange={(event) =>
								setOverride(
									event.target.checked,
									setSourceUtmOverride,
									utmSourceDefault,
									"utm_source"
								)
							}
							name="source_override"
						/>
						<Label htmlFor="utm_source">Override</Label>
					</LeftAlign>
					<FormInput
						disabled={!sourceUtmOverride}
						value={utmState?.["utm_source"] ?? utmSourceDefault}
						onChange={(e) =>
							setUtmState(...utmState, (utmState["utm_source"] = e.target.value))
						}
						label="utm_source"
						placeholder="source"
						width="100%"
					/>
				</InputSection>
			</UTM>
			<Divider />
			<UTM>
				<TextSection>Medium</TextSection>
				<InputSection>
					<LeftAlign>
						<Input
							type="checkbox"
							checked={mediumUtmOverride}
							onChange={(event) =>
								setOverride(
									event.target.checked,
									setMediumUtmOverride,
									utmMediumDefault,
									"utm_medium"
								)
							}
							name="medium_override"
						/>
						<Label htmlFor="utm_medium">Override</Label>
					</LeftAlign>
					<FormInput
						disabled={!mediumUtmOverride}
						value={utmState?.["utm_medium"] ?? utmMediumDefault}
						onChange={(e) =>
							setUtmState(...utmState, (utmState["utm_medium"] = e.target.value))
						}
						label="utm_medium"
						placeholder="medium"
						width="100%"
					/>
				</InputSection>
			</UTM>
			<Divider />
			<UTM>
				<TextSection>Term</TextSection>
				<InputSection>
					<LeftAlign>
						<Input
							type="checkbox"
							checked={termUtmOverride}
							onChange={(event) =>
								setOverride(
									event.target.checked,
									setTermUtmOverride,
									utmTermDefault,
									"utm_term"
								)
							}
							name="term_override"
						/>
						<Label htmlFor="utm_term">Override</Label>
					</LeftAlign>
					<FormInput
						disabled={!termUtmOverride}
						value={utmState?.["utm_term"] ?? utmTermDefault}
						onChange={(e) =>
							setUtmState(...utmState, (utmState["utm_term"] = e.target.value))
						}
						label="utm_term"
						placeholder="term"
						width="100%"
					/>
				</InputSection>
			</UTM>
			<Divider />
			<UTM>
				<TextSection>Additional</TextSection>
				<InputSectionColumn>
					<AlignLabel>
						<Amp>&amp;</Amp>
						<FormInput
							value={utmState?.["utm_additional"] ?? ""}
							onChange={(e) =>
								setUtmState(...utmData, (utmData["utm_content"] = e.target.value))
							}
							label="utm_content"
							placeholder="content"
							width="100%"
						/>
					</AlignLabel>
					<span>{`Format: &param1=val1&param2=val2&param3=var3...`}</span>
				</InputSectionColumn>
			</UTM>
		</Section>
	);
};

const Input = styled.input`
	padding: 5px;
`;

const AlignLabel = styled.div`
	display: inline-flex;
	align-items: center;
	width: 100%;
`;

const IconSection = styled.div`
	max-width: 1rem;
	font-size: 87.5%;
	line-height: normal;
	font-weight: 400;
	text-align: left;
	text-rendering: optimizeLegibility;
	box-sizing: inherit;
	margin: 0 0.786rem;
	color: #191919;
	-webkit-box-flex: 1;
	flex: 1;
`;

const InputPreviewSection = styled.div`
	font-size: 87.5%;
	line-height: normal;
	font-weight: 400;
	text-align: left;
	text-rendering: optimizeLegibility;
	box-sizing: inherit;
	margin: 0 0.786rem;
	color: #191919;
	-webkit-box-flex: 1;
	flex: 1;
`;

const Preview = styled.div`
	width: 100%;
	padding: 1rem;
	display: flex;
	align-self: center;
	flex-direction: column;
	align-items: flex-start;
`;

const UTM = styled.div`
	font-size: 87.5%;
	line-height: normal;
	color: #535252;
	font-weight: 400;
	text-rendering: optimizeLegibility;
	box-sizing: inherit;
	display: flex;
	/* flex-wrap: wrap; */
	align-items: center;
	position: relative;
	min-height: 3.143rem;
	padding: 0 0.786rem;
	border: 1px solid #e8e8e8;
	background: #ffffff;
	z-index: 1;
	text-align: left;
	padding-top: 0.786rem;
	padding-bottom: 0.786rem;
	margin-top: -1px;
	align-items: center;
`;

const LeftAlign = styled.div`
	display: flex;
	margin-bottom: 0.5rem;
`;

const CodeInput = styled.input`
	width: 100%;
	align-items: flex-start;
	line-height: normal;
	text-align: left;
	text-rendering: optimizeLegibility;
	box-sizing: inherit;
	padding: 0.786rem;
	border: 1px solid #c3c1c1;
	border-radius: 0;
	color: inherit;
	font-size: 0.857rem;
	font-weight: 400;
	font-family: monospace, serif;
	background: #f8f8f8;
	word-break: break-all;
`;

const Label = styled.label`
	font-size: 12px;
	vertical-align: top;
	display: inline-block;
	margin: 0 3rem 0 0.5rem;
`;

const SureMessage = styled.span`
	color: #9d9a9a;
`;

const TextSection = styled.div`
	line-height: normal;
	text-align: left;
	text-rendering: optimizeLegibility;
	box-sizing: inherit;
	margin: 0 0.786rem;
	color: #191919;
	font-size: 1rem;
	font-weight: 400;
	flex-basis: 12rem;
`;

const InputSection = styled.div`
	font-size: 87.5%;
	line-height: normal;
	color: #535252;
	font-weight: 400;
	text-align: left;
	text-rendering: optimizeLegibility;
	box-sizing: inherit;
	-webkit-box-flex: 1;
	flex: 1;
	display: flex;
	align-items: center;
	flex-direction: row;
`;

const InputSectionColumn = styled.div`
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	color: #535252;
	text-align: left;
	flex-direction: column;
	align-items: flex-start;
`;

const RemoveSection = styled.div`
	text-align: left;
	-webkit-box-flex: 1;
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: row;
	flex-basis: 1rem;
`;

const RemoveButton = styled.div`
	text-rendering: optimizeLegibility;
	box-sizing: inherit;
	vertical-align: baseline;
	line-height: normal;
	text-transform: none;
	-webkit-appearance: button;
	display: inline-block;
	position: relative;
	border-radius: 0;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;
	z-index: 1;
	border: 0;
	background: transparent;
	fill: #626161;
	font-size: 0.857rem;
	font-weight: 600;
	padding: 0.143em 0.286em;
	margin: 0 0.786rem;
	color: #191919;
`;

const PreviewSection = styled.div`
	width: 100%;
	font-size: 87.5%;
	line-height: normal;
	color: #535252;
	font-weight: 400;
	text-align: left;
	text-rendering: optimizeLegibility;
	box-sizing: inherit;
	padding-top: 0.786rem;
	flex-basis: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
`;

const Amp = styled.div`
	margin-right: 1rem;
	font-size: 16px;
`;
export default ClickThroughParameters;
