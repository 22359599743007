import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useGetInboxData } from "../../../../../../hooks";
import { useSelectInboxCampaigns } from "../../../../../../hooks/selectors/useSelectInboxCampaigns";
import { updateInboxCampaign } from "../../../../../../redux/inboxCampaigns/thunks";
import { Divider } from "../../../../../stats/styledComponents";
import { DiffWrap } from "../../../../../../components/form/diff-wrap";
import { Section, Dropdown, FormInput } from "../../../../../../components/form";
import { getOptions } from "./utils/classification";

const Classification = () => {
	const dispatch = useDispatch();
	const {
		params: { campaignId },
	} = useSelector((state) => state.page);
	const {
		campaignTypes: {
			response: { data: campaignTypesData },
		},
		purposes: {
			response: { data: purposesData },
		},
	} = useSelector((state) => state);
	const campaign = useSelector(useSelectInboxCampaigns)?.[campaignId];
	const { parents } = useGetInboxData();
	const currentIndex = parents.findIndex((parent) => parent.id === campaignId);

	const isParent = campaign?.attributes?.["test-type"] === "parent";

	const initialValues = {
		attributes: {
			name: campaign?.attributes?.name,
			"optimization-post-live-checked":
				campaign?.attributes?.["optimization-post-live-checked"],
			"clean-test": campaign?.attributes?.["clean-test"],
		},
		relationships: {
			"campaign-purpose": {
				data: isParent
					? {
							type: "campaign-purposes",
							id: campaign?.relationships?.["campaign-purpose"]?.data?.id,
					  }
					: null,
			},
			"campaign-type": {
				data: isParent
					? null
					: {
							type: "campaign-types",
							id: campaign?.relationships?.["campaign-type"]?.data?.id,
					  },
			},
		},
	};

	const dataRef = useRef(initialValues);

	const [classificationFormData, setClassificationFormData] = useState(initialValues);

	useEffect(() => {
		dispatch(
			updateInboxCampaign({
				...classificationFormData,
				id: campaignId,
			})
		);
	}, [classificationFormData]);

	const handleCopyToSiblings = (e) => {
		//TODO: work on copying data to same level sibling campaigns (other parents)
		console.log(e.props);
	};

	return (
		<Section title="Classification">
			<Spacer>
				<Grow by={9}>
					<FormInput
						value={classificationFormData?.attributes.name ?? ""}
						onChange={(value) =>
							setClassificationFormData({
								...classificationFormData,
								attributes: {
									...classificationFormData.attributes,
									name: value,
								},
							})
						}
						label="Name"
						placeholder="New website"
						width="100%"
					/>
				</Grow>
				{isParent ? (
					<Grow by={1}>
						<Dropdown
							label="Purpose"
							field="purpose"
							onChange={(option) =>
								setClassificationFormData({
									...classificationFormData,
									relationships: {
										...classificationFormData.relationships,
										"campaign-purpose": {
											data: { type: "campaign-purposes", id: option.purpose },
										},
									},
								})
							}
							value={
								classificationFormData?.relationships["campaign-purpose"]?.data
									?.id ?? ""
							}
							options={getOptions("inbox", purposesData)}
							internalLabel
						/>
					</Grow>
				) : (
					<Grow by={1}>
						<Dropdown
							label="Campaign Type"
							field="campaignType"
							onChange={(option) =>
								setClassificationFormData({
									...classificationFormData,
									relationships: {
										...classificationFormData.relationships,
										"campaign-type": {
											data: {
												type: "campaign-types",
												id: option.campaignType,
											},
										},
									},
								})
							}
							value={
								classificationFormData?.relationships["campaign-type"]?.data?.id ??
								""
							}
							options={getOptions("inbox", campaignTypesData)}
							internalLabel
						/>
					</Grow>
				)}
				{!currentIndex && (
					<Grow by={1}>
						<Part>{`Part ${currentIndex + 1} of ${parents.length}`}</Part>
					</Grow>
				)}
			</Spacer>
			<Divider />
			<Spacer>
				<DiffWrap
					diff={
						dataRef?.current?.attributes["clean-test"] !==
						classificationFormData.attributes["clean-test"]
					}
					callback={handleCopyToSiblings}
				>
					<Input
						type="checkbox"
						checked={classificationFormData.attributes["clean-test"]}
						onChange={() =>
							setClassificationFormData({
								...classificationFormData,
								attributes: {
									...classificationFormData.attributes,
									"clean-test": !classificationFormData.attributes["clean-test"],
								},
							})
						}
						name="opt_test"
					/>
				</DiffWrap>
				{classificationFormData.attributes["clean-test"] && <i className="icon-delta" />}
				<Label htmlFor="opt_test">Optimization Test</Label>
				<DiffWrap
					diff={
						dataRef?.current?.attributes["optimization-post-live-checked"] !==
						classificationFormData.attributes["optimization-post-live-checked"]
					}
					callback={handleCopyToSiblings}
				>
					<Input
						type="checkbox"
						checked={
							classificationFormData.attributes["optimization-post-live-checked"]
						}
						onChange={() =>
							setClassificationFormData({
								...classificationFormData,
								attributes: {
									...classificationFormData.attributes,
									"optimization-post-live-checked":
										!classificationFormData.attributes[
											"optimization-post-live-checked"
										],
								},
							})
						}
						name="opt_post"
					/>
				</DiffWrap>
				<Label htmlFor="opt_post">Optimization Post Live Checked</Label>
			</Spacer>
		</Section>
	);
};

const Grow = styled.div`
	flex-grow: ${(props) => props.by ?? ""};
`;

const Part = styled.div`
	color: #214558;
	font-weight: 600;
`;

const Input = styled.input`
	padding: 5px;
`;

const Label = styled.label`
	font-size: 12px;
	cursor: "pointer";
	vertical-align: top;
	display: inline-block;
	margin: 0 3rem 0 0.5rem;
`;

const Spacer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	margin: 1rem;
	> * {
		margin: 0 1rem 0 0;
	}
`;

export default Classification;
