import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { Button, PopupModal } from "..";
import {
	backFillEmailCaptures,
	enableIncompleteFormsEmailCapture,
} from "../../../../redux/report/utils";

const BackFillModal = ({
	showModal = true,
	toggleModal = () => {},
	website_id,
	date_start,
	date_end,
}) => {
	const dispatch = useDispatch();
	const isLoading = useSelector(({ isLoading }) => isLoading);
	const [dateStart, setDateStart] = useState(date_start);
	const [dateEnd, setDateEnd] = useState(date_end);
	const [message, setMessage] = useState("");

	const onChangeDateStart = (e) => {
		setDateStart(e.target.value);
	};
	const onChangeDateEnd = (e) => {
		setDateEnd(e.target.value);
	};
	const disableSubmit = notValidDates(dateStart, dateEnd, isLoading);
	const updateMessage = (text) => {
		setMessage(text);
	};
	const backFill = () => {
		setMessage("Submitting");

		backFillEmailCaptures(
			website_id,
			dateStart,
			dateEnd,
			updateMessage,
			dispatch
		);
	};
	const enableIncomplete = () => {
		setMessage("Submitting");
		enableIncompleteFormsEmailCapture(website_id, updateMessage, dispatch);
	};
	return (
		<PopupModal showModal={showModal} toggleModal={toggleModal}>
			<Title>Back fill Email Captures</Title>
			<div>
				<Label htmlFor="dateStart">
					Start Date:{" "}
					<input
						id="dateStart"
						type="date"
						value={dateStart}
						onChange={onChangeDateStart}
						disabled={isLoading}
					/>
				</Label>
				<Label htmlFor="dateEnd">
					End Date:{" "}
					<input
						id="dateEnd"
						type="date"
						value={dateEnd}
						onChange={onChangeDateEnd}
						disabled={isLoading}
					/>
				</Label>
			</div>
			<Wrapper>
				<Button disabled={disableSubmit} onClick={backFill}>
					Back Fill
				</Button>
				<Button onClick={enableIncomplete} disabled={isLoading}>
					Enable Submit Uncompleted Forms
				</Button>
			</Wrapper>

			<Message>{message}</Message>
		</PopupModal>
	);
};
const notValidDates = (dateStart, dateEnd, isLoading) => {
	if (!dateStart || !dateEnd || isLoading) {
		return true;
	}
	if (dateStart === dateEnd) {
		return false;
	}
	const start = new Date(dateStart);
	const end = new Date(dateEnd);
	return start > end;
};
BackFillModal.propTypes = {
	showModal: PropTypes.bool.isRequired,
	toggleModal: PropTypes.func.isRequired,
	website_id: PropTypes.number.isRequired,
	date_start: PropTypes.string,
	date_end: PropTypes.string,
};
const Title = styled.h2`
	font-weight: bolder;
`;
const Label = styled.label`
	display: inline-block;
	margin: 10px;
`;
const Wrapper = styled.div`
	margin: 10px 0;
	display: flex;
	justify-content: space-between;
`;
const Message = styled.div`
	text-align: center;
	font-weight: bold;
`;
export default BackFillModal;
