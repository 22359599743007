import React from "react";
import PropTypes from "prop-types";
import { InputContainer, InputLabel, Select, Input } from "../../../../../../components/styles";
import { eligibilityCriteriaInputs } from "../../../../eligibility";
import { ToolTip } from "../../../../../../components";
import {
	EligibilityRow,
	EligibilityInput,
	EligibilityInputsContainer,
	CloseBox,
	Divider,
} from "./utils/Sections.styled";

const EligibilityCriteriaForm = ({ criteria, index, setEligibilityCriteria, deleteCriteria }) => {
	if (!criteria.length) {
		return null;
	}

	const data = criteria[0];

	/* eslint-disable no-unused-vars */
	// These values are used dynamically via eval(input.value)
	const {
		method,
		status,
		mustNot,
		eventName,
		when: { n: when, period: whenperiod, event: whenevent, eventType: wheneventtype } = {},
		where: { property, operator, propertyValue } = {},
		state,
	} = data;

	const and = data?.and?.[0];
	/* eslint-enable no-unused-vars */

	const handleChange = (e, inputName) => {
		setEligibilityCriteria(
			[
				{
					...data,
					[inputName]: e.target.value,
				},
			],
			index
		);
	};

	return (
		<>
			<EligibilityRow>
				<EligibilityInputsContainer>
					{eligibilityCriteriaInputs(data).map((input) => {
						if (!input) {
							return null;
						}
						return (
							<EligibilityInput
								key={`${input?.name}-${index}`}
								isSmall={input?.isSmall}
							>
								<InputContainer>
									<InputLabel>{input.label}</InputLabel>
									{input.isInput ? (
										<Input
											// eslint-disable-next-line no-eval
											value={eval(input?.name)}
											onChange={(e) => handleChange(e, input.name)}
										/>
									) : (
										<Select
											// eslint-disable-next-line no-eval
											value={eval(input?.name)}
											onChange={(e) => handleChange(e, input.name)}
										>
											{input.options?.map((option) => (
												<option key={option?.label} value={option?.value}>
													{option?.label}
												</option>
											))}
										</Select>
									)}
								</InputContainer>
							</EligibilityInput>
						);
					})}
				</EligibilityInputsContainer>
				<CloseBox>
					<ToolTip position="left" text="Remove this criterion">
						<i
							onClick={() => deleteCriteria(index)}
							className="icon-close-thin"
							style={{ padding: "11px", cursor: "pointer" }}
						/>
					</ToolTip>
				</CloseBox>
			</EligibilityRow>
			<Divider />
		</>
	);
};

const criteriaPropType = PropTypes.shape({
	method: PropTypes.string,
	status: PropTypes.string,
	mustNot: PropTypes.bool,
	eventName: PropTypes.string,
	and: PropTypes.arrayOf(PropTypes.string),
	when: PropTypes.shape({
		n: PropTypes.number,
		period: PropTypes.string,
		event: PropTypes.string,
		eventType: PropTypes.string,
	}),
	where: PropTypes.shape({
		property: PropTypes.string,
		operator: PropTypes.string,
		propertyValue: PropTypes.string,
	}),
	state: PropTypes.string,
});

EligibilityCriteriaForm.propTypes = {
	criteria: PropTypes.arrayOf(criteriaPropType),
	index: PropTypes.number.isRequired,
	setEligibilityCriteria: PropTypes.func.isRequired,
	deleteCriteria: PropTypes.func.isRequired,
};

export default EligibilityCriteriaForm;
