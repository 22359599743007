import { createSlice } from "@reduxjs/toolkit";
import { getUpdatedResponse } from "./utils";
import { getESP, createESP, updateESP } from "./thunks";

const initialState = {
	isLoading: false,
	response: {
		data: {},
	},
};

const espSlice = createSlice({
	name: "esp",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// getESP
		builder.addCase(getESP.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getESP.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response = getUpdatedResponse(action.payload);
		});
		builder.addCase(getESP.rejected, (state) => {
			state.isLoading = false;
			state.response = { data: {} };
		});
		// createESP
		builder.addCase(createESP.fulfilled, (state, action) => {
			state.response = getUpdatedResponse(action.payload);
		});
		builder.addCase(createESP.rejected, (state) => {
			state.response = { data: {} };
		});
		// updateESP
		builder.addCase(updateESP.fulfilled, (state, action) => {
			state.response = getUpdatedResponse(action.payload);
		});
		builder.addCase(updateESP.rejected, (state) => {
			state.response = { data: {} };
		});
	},
});

export default espSlice.reducer;
