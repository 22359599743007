import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { getPreview } from "../../../../../redux/inbox/thunks";
import { IFrameContainer, IFrame, PreviewContainer } from "./Design.styled";

const Preview = ({ isDesignExpanded, zoomValue, previewType, campaign }) => {
	if (isDesignExpanded) {
		return null;
	}
	const [preview, setPreview] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		if (campaign) {
			dispatch(
				getPreview({
					campaignId: campaign.id,
					nodeEts: "1",
					testItems: encodeURIComponent(campaign?.attributes["test-items-json"]),
				})
			).then((response) => {
				setPreview(response?.payload);
			});
		}
	}, [campaign]);

	return (
		<PreviewContainer>
			<IFrameContainer zoomValue={zoomValue} previewType={previewType}>
				<IFrame srcDoc={preview} zoomValue={zoomValue} previewType={previewType} />
			</IFrameContainer>
		</PreviewContainer>
	);
};

Preview.propTypes = {
	isDesignExpanded: PropTypes.bool.isRequired,
	zoomValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	previewType: PropTypes.string.isRequired,
	campaign: PropTypes.object.isRequired,
};

export default Preview;
