import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Wrapper } from "../../../components";
import InboxNavBar from "../components/nav-pages/InboxNavBar";
import { LSBContainer } from "../leftSideBar";
import { useGetInboxData } from "../../../hooks";
import { InboxSeriesBody } from "./series";
import { EditContainer, DesignContainer } from "./campaign";
import { getCampaignTypes } from "../../../redux/campaignTypes/thunks";

const InboxEntry = () => {
	const dispatch = useDispatch();
	const { series, parents, variations } = useGetInboxData();
	const { params } = useSelector((state) => state.page);
	const isDesign = location.pathname.includes("design");
	const isSeriesPage = params?.seriesId;
	const isCampaignEdit = !isSeriesPage && !isDesign;

	useEffect(() => {
		dispatch(getCampaignTypes());
	}, []);

	return (
		<Wrapper
			left="0"
			maxWidth="100%"
			style={{
				display: "flex",
				justifyContent: "space-between",
				bottom: "0",
			}}
		>
			<Aside>
				<LSBContainer series={series} parents={parents} variations={variations} />
			</Aside>
			<Main>
				<InboxNavBar series={series} parents={parents} variations={variations} />
				{isSeriesPage && <InboxSeriesBody />}
				{isDesign && <DesignContainer />}
				{isCampaignEdit && <EditContainer />}
			</Main>
		</Wrapper>
	);
};

const Aside = styled.aside`
	width: 270px;
	min-width: 270px;
	height: 100%;
	background-color: #ffffff;
	overflow-y: auto;
	opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
	&::-webkit-scrollbar {
		display: none;
	}
`;

const Main = styled.main`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	height: 100%;
	overflow-y: auto;
`;

export default InboxEntry;
