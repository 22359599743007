import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Banner = styled.div`
  position: absolute;
  height: 23px;
  width: 100%;
  padding: 5px 0;
  background: #9b4976;
  border-bottom: 1px solid darken(#9b4976, 10%);
  text-align: center;
  font-family: "Larsseit";
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;

  span {
    background: #FFFFFF;
    color: #9b4976;
    font-size: 10px;
    padding: 4px 8px 2px;
    border-radius: 4px;
  }
`;

const SiteBanner = ({ name }) => (
  <Banner>
    <span>
      {name}
    </span>
  </Banner>
);

SiteBanner.propTypes = {
  name: PropTypes.string.isRequired,
};

export default SiteBanner;
