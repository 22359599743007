import { createAsyncThunk } from "@reduxjs/toolkit";
import fetch from "isomorphic-fetch";

import { endpoints } from "../../constants/common";
import { appendQueryParams } from "../businessLogic/utils";
import { LocalStorageService } from "../../service/localStorage";



export const getTopStats = createAsyncThunk(
	"topStats/getTopStats",
	async ({ websiteId, queryParamsObj }) => {
		const url = appendQueryParams(
			`${endpoints.websiteReportURL}/${websiteId}/top-stats`,
			queryParamsObj
		);

		const token = LocalStorageService.getToken();

		const response = await fetch(url, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		const json = await response.json();

		return {
			...json,
			top_stats: {
				...json.top_stats,
				chart_data: {
					submits: json.top_stats.chart_data.submits || [],
					revenue: json.top_stats.chart_data.revenue || [],
					usage: json.top_stats.chart_data.usage || [],
				},
			},
		};
	}
);
