import React, { useReducer, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../../../components"

import {
	InputContainer,
	InputLabel,
	Input,
	Select,
} from "../../../../components/styles";
import getPurposeMethods from "../../../../redux/purposes/getPurposeMethods";

const initialNewBehaviorState = {
	data: null,
	options: null,
	partName: "New welcome email",
	variationName: "New welcome variation",
	emailType: "",
	testType: "",
	selectedOption: "",
};

const NewBehaviorStateType = Object.freeze({
	OPTIONS: "options",
	PART_NAME: "part_name",
	TEST_TYPE: "test_type",
	EMAIL_TYPE: "email_type",
	VARIATION_NAME: "variation_name",
	SELECTED_OPTION: "selected_option",
});

const newBehaviorReducer = (state, { type, data }) => {
	switch (type) {
		case NewBehaviorStateType.OPTIONS:
			return {
				...state,
				options: data,
			};
		case NewBehaviorStateType.PART_NAME:
			return {
				...state,
				partName: data,
			};
		case NewBehaviorStateType.VARIATION_NAME:
			return {
				...state,
				variationName: data,
			};
		case NewBehaviorStateType.TEST_TYPE:
			return {
				...state,
				testType: data,
			};
		case NewBehaviorStateType.EMAIL_TYPE:
			return {
				...state,
				emailType: data,
			};
		case NewBehaviorStateType.SELECTED_OPTION:
			return {
				...state,
				selectedOption: data,
			};
		case "data":
			return {
				...state,
				data,
			};
	}
};

const NewBehavioralLogicModal = ({ closeModal }) => {
	const [isNewVariant, setIsNewVariant] = useState(true);
	const [newBehaviorState, dispatchNewBehavior] = useReducer(
		newBehaviorReducer,
		initialNewBehaviorState
	);
	const { getPurposeOptions } = getPurposeMethods();

	// TODO: figure out how to derive argument
	const {
		[2]: { options },
	} = getPurposeOptions({ productType: "inbox" });
	useEffect(() => {
		dispatchNewBehavior({
			type: NewBehaviorStateType.OPTIONS,
			data: options,
		});
	}, [options]);

	const isCreateButtonDisabled = () => {
		const { emailType, selectedOption, testType } = newBehaviorState;
		/**
		 * Explanation:
		 *  if selectedOption is not selected, disable button (return true)
		 *  if selectedOption is selected
		 *      if new variation checkbox is checked and emailType and
		 *      testType are not selected, disable button (return true)
		 *
		 * As of right now "testType" can't be falsey, but it does need to be set
		 * so it should remain in this check in case there is ever a change that
		 * would allow it to be falsey.
		 */
		return selectedOption ? isNewVariant && !emailType && !testType : true;
	};

	return (
		<ModalContainer>
			<InputContainer width="100%">
				<InputLabel>Part Name</InputLabel>
				<Input
					type="text"
					value={newBehaviorState.partName}
					onChange={(e) => {
						dispatchNewBehavior({
							type: NewBehaviorStateType.PART_NAME,
							data: e.target.value,
						});
					}}
				/>
			</InputContainer>
			<InputContainer width="100%">
				<InputLabel>Purpose</InputLabel>
				<Select
					onChange={(e) =>
						dispatchNewBehavior({
							type: NewBehaviorStateType.SELECTED_OPTION,
							data: e.target.value,
						})
					}
				>
					<option value="">Select purpose...</option>
					{newBehaviorState.options &&
						newBehaviorState.options.map(({ attributes: { id, name } }) => (
							<option key={`${id}-${name}`} value={id}>
								{name}
							</option>
						))}
				</Select>
			</InputContainer>
			<Checkbox
				defaultChecked={isNewVariant}
				onClick={() => setIsNewVariant(!isNewVariant)}
			/>
			{isNewVariant && (
				<>
					<InputContainer width="100%">
						<InputLabel>
							{/** TODO: remove hardcoded values */}
							{`${
								newBehaviorState.testType === "control"
									? "Control"
									: "Variation"
							} Name`}
						</InputLabel>
						<Input
							type="text"
							value={newBehaviorState.variationName}
							onChange={(e) => {
								dispatchNewBehavior({
									type: NewBehaviorStateType.VARIATION_NAME,
									data: e.target.value,
								});
							}}
						/>
					</InputContainer>
					<InputContainer width="100%">
						<InputLabel>Email Type</InputLabel>
						<Select
							onChange={(e) =>
								dispatchNewBehavior({
									type: NewBehaviorStateType.EMAIL_TYPE,
									data: e.target.value,
								})
							}
						>
							{/* TODO: this should be programmatically derived */}
							<option value="">Select type...</option>
							<option value="0">Not Set</option>
							<option value="5">Control</option>
							<option value="9">Dynamic</option>
							<option value="10">Static</option>
							<option value="25">Behavioral SMS</option>
						</Select>
					</InputContainer>
					<InputContainer width="100%">
						<InputLabel>Test Type</InputLabel>
						<Select
							defaultChecked={newBehaviorState.testType}
							onChange={(e) =>
								dispatchNewBehavior({
									type: NewBehaviorStateType.TEST_TYPE,
									data: e.target.value,
								})
							}
						>
							{/** TODO: could this be programmatically derived? */}
							<option value="variation">Variation</option>
							<option value="control">Control</option>
						</Select>
					</InputContainer>
				</>
			)}
			<Button theme="blue" onClick={closeModal} disabled={isCreateButtonDisabled()}>
				Create
			</Button>
		</ModalContainer>
	);
};

const Checkbox = styled.input`
	padding: 5px;
`;

const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

Checkbox.defaultProps = {
	type: "checkbox",
};

NewBehavioralLogicModal.propTypes = {
	closeModal: PropTypes.func.isRequired,
};

export default NewBehavioralLogicModal;
