import React from "react";
import PropTypes from "prop-types";
import { Tooltip as ReactTippy } from "react-tippy";
import "react-tippy/dist/tippy.css";

/** Based off of react-tippy https://github.com/tvkhoa/react-tippy */
const Tooltip = ({ text, position, trigger, children, dataQa, disabled, ...rest }) => (
	<ReactTippy
		title={text}
		position={position}
		trigger={trigger}
		arrow="true"
		theme="transparent"
		dataQa={dataQa}
		disabled={disabled}
		{...rest}
	>
		{children}
	</ReactTippy>
);

Tooltip.defaultProps = {
	text: null,
	position: "top",
	trigger: "mouseenter",
	dataQa: "tooltip",
	disabled: false,
};

Tooltip.propTypes = {
	text: PropTypes.string,
	children: PropTypes.node,
	disabled: PropTypes.bool,
	position: PropTypes.oneOf(["top", "bottom", "left", "right"]),
	trigger: PropTypes.oneOf(["mouseenter", "focus", "click", "manual"]),
	/** String for an automation testing purpose */
	dataQa: PropTypes.string,
};

export default Tooltip;
