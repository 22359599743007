export const INBOX_SERIES_URL = "/inbox_series";
export const INBOX_CAMPAIGNS_URL = "/inbox_campaigns";

export const BLANK_INBOX_SERIES_DATA = {
	data: {
		attributes: {
			"email-creative-json":
				'{"blocks":[{"order":1,"type":"header","template":""},{"order":2,"type":"footer","template":""}]}',
			"graph-mail-enabled": false,
			active: null,
			"dt-edited": null,
			"dt-inserted": null,
			"dt-start": null,
			name: "",
			pinned: null,
			product: "",
			variables: null,
			"clone-series-id": null,
		},
		relationships: {
			children: {
				data: [],
			},
			leader: {
				data: null,
			},
			website: {
				data: null,
			},
		},
		type: null,
	},
};

export const BLANK_INBOX_CAMPAIGNS_DATA = {
	data: {
		attributes: {
			"click-params": "",
			"criteria-json": null,
			"render-json": null,
			"thresholds-json": null,
			"eligibility-js": null,
			"eligibility-triggers": null,
			"email-from-address": "inserttemplate@bounceexchange.com",
			"email-from-name": "inserttemplate@bounceexchange.com",
			"email-preheader": null,
			"email-reply-to-address": "",
			"email-subject": null,
			"esp-extra-fields": null,
			"interval-unit": "minute",
			"interval-val": null,
			"push-url": null,
			"push-image": null,
			"subscriber-esp-extra-fields": null,
			"test-mode-email": "",
			"utm-override": false,
			"email-creative-json": null,
			"v2-body": null,
			"v2-renderer": 0,
			"v2-trigger-delay": null,
			"v2-trigger-delay-unit": null,
			"saved-test-fixture": null,
			"test-items-json": null,
			"bpush-fields-json": null,
			"recommender-strategy": "",
			"msg-settings-json": null,
			"msg-body-json": null,
			active: true,
			"campaign-series-id": null,
			"clean-test": false,
			"client-editable": false,
			"dt-edited": "2018-09-28 19:45:40",
			"dt-enabled": "0000-00-00 00:00:00",
			"dt-inserted": "2015-06-11 14:32:19",
			enabled: false,
			name: "",
			"notes-saved-at": null,
			notes: "",
			"optimization-post-live-checked": 0,
			order: 1,
			product: "",
			"wip-mode": false,
			"test-mode": false,
			"test-traffic": "50",
			"test-type": "parent",
			"website-id": null,
			tags: '"null"',
			"activation-delay": 5,
			"activation-offset": 0,
			activation: null,
			"activation-realtime": "[]",
			"ad-auto-close-submission": 0,
			"ad-auto-close": 0,
			"ad-submission-redirect": "",
			"ad-submission-target": "",
			alerts: 0,
			"ally-title": null,
			"background-color": "#000000",
			"background-opacity": 90,
			"bg-path": "",
			"bg-type": "color",
			"blur-gate-enabled": 0,
			"blur-gate-exclusions": "",
			"blur-gate-inclusions": "",
			callout: null,
			"callout-anchor-pos": "lm",
			"callout-genie-html": "",
			"callout-height": 100,
			"callout-height-unit": "%",
			"callout-hoffset": 0,
			"callout-placement-target": null,
			"callout-pos": "rm",
			"callout-target": "",
			"callout-type": "none",
			"callout-voffset": 0,
			"callout-width": 100,
			"callout-width-unit": "%",
			"campaign-series-stage-id": 0,
			"close-button-delay": 0,
			"close-button-style": "classic",
			"close-position": "top_right",
			"close-redirect-type": "_self",
			"close-redirect-url": null,
			"close-zoom": 0.75,
			"closed-no-show": 0,
			"compliance-json": '{"gdpr":0,"gmp":0,"casl":0}',
			"creative-json": null,
			"custom-css": null,
			"custom-js-website": null,
			"custom-js": null,
			deployment: null,
			"device-matrix": null,
			"dom-placement-method": "",
			"esp-data": null,
			"esp-dont-wait": 0,
			"esp-submit-uncompleted": 0,
			"event-js": "",
			"form-transition-effect": "",
			"ga-integration": null,
			"gbi-provider-website-integration-json": "",
			"header-bottom-alignment": "center",
			"header-bottom-genie-html": "",
			"header-bottom-height-unit": "px",
			"header-bottom-height": 90,
			"header-bottom-nano-animation": "anypage",
			"header-bottom-nano-bg": 0,
			"header-bottom-nano": 0,
			"header-bottom-type": "none",
			"header-bottom-valignment": "bottom",
			"header-bottom-width-unit": "%",
			"header-bottom-width": 100,
			"header-bottom": null,
			"header-top-alignment": "center",
			"header-top-genie-html": "",
			"header-top-height-unit": "px",
			"header-top-height": 90,
			"header-top-nano-animation": "none",
			"header-top-nano-bg": 1,
			"header-top-nano": 0,
			"header-top-type": "none",
			"header-top-valignment": "top",
			"header-top-width-unit": "%",
			"header-top-width": 100,
			"header-top": null,
			"header-type": null,
			"hide-powered": false,
			"ignore-activation-offset-page": "no_landing",
			"ignore-activation-offset": 0,
			"ignore-previous-captures": 0,
			"max-activations-overall": 4,
			"max-activations-pageview": 1,
			"max-activations-session": 1,
			"new-genie-enabled": 0,
			"new-genie-json": null,
			"no-genie-style": 0,
			"not-in-global-control": 0,
			"overlay-click-to-close": 0,
			"overlay-custom-html": null,
			"overlay-email-html": null,
			"overlay-genie-html": "",
			"overlay-height-unit": "px",
			"overlay-height": 400,
			"overlay-iframe": 1,
			"overlay-recommendation-html": null,
			"overlay-teleport-html": "",
			"overlay-teleport-type": "_blank",
			"overlay-type": null,
			"overlay-width-unit": "px",
			"overlay-width": 800,
			"preview-url": "",
			"purchase-no-show": 0,
			"repress-bottom": 0,
			"repress-overlay": 0,
			"repress-top": 0,
			"right-activation-offset-page": "no_landing",
			"right-activation-offset": 0,
			"session-length-unit": "hours",
			"session-length": 12,
			"show-close-step": 0,
			"show-close": 1,
			"shroud-on-hover": 0,
			"strategy-json": null,
			"submission-redirect-delay": 2,
			"submission-redirect": null,
			"supress-bottom": 0,
			"supress-overlay": 0,
			"supress-top": 0,
			"top-vertical-activation-offset-page": "no_landing",
			"top-vertical-activation-offset": "0",
			"transition-effect-speed": 1,
			"transition-effect-subsequent": "no_effect",
			"transition-effect": "no_effect",
			"z-index": null,
			"clone-campaign-id": null,
			"master-campaign-id": null,
			"edited-by-name": null,
			"is-leader": true,
			"series-name": "Master Template",
		},

		relationships: {
			leader: {
				data: null,
			},
			parent: {
				data: null,
			},
			"inbox-series": {
				data: {
					type: "",
					id: "",
				},
			},
			"linked-campaigns": {
				data: [],
			},
			"brand-style": {
				data: null,
			},
			"gbi-provider-website": {
				data: null,
			},
			"campaign-purpose": {
				data: {
					type: "",
					id: "",
				},
			},
			"campaign-type": {
				data: null,
			},
			"parent-campaign-type": {
				data: null,
			},
			website: {
				data: {
					type: "",
					id: "",
				},
			},
		},
		type: "inbox-campaigns",
	},
};
