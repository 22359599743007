import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ToolTip, ReactLink } from "../../../components";
import { Divider } from "../../stats/styledComponents";
import { variationPropTypes, parentPropTypes } from "../propTypes";

const VariationsLSBContainer = ({ variations, parent }) => {
	const { error } = variations;
	const [errorTooltip, setShowErrorTooltip] = useState(false);
	const { params } = useSelector((state) => state.page);

	return (
		<>
			<h1 className="nav-list_header">Variations</h1>
			{variations
				?.filter((variation) => variation?.relationships?.parent?.data?.id === parent?.id)
				.map((variation) => {
					return (
						variation.attributes.active && (
							<div key={variation.attributes.id}>
								<ReactLink href={`/experiences/inbox-react/${variation.id}/edit`}>
									<LinkDiv
										selected={params?.campaignId === String(variation?.id)}
										bold={false}
									>
										<Circle
											className={`icon-circle ${
												variation.attributes.active
													? "u-color-on"
													: "u-color-off"
											}`}
										/>
										<Text>{variation?.attributes?.name}</Text>
										{error && (
											<div>
												<Triangle
													onMouseEnter={() => setShowErrorTooltip(true)}
													onMouseLeave={() => setShowErrorTooltip(false)}
												>
													triangle error
												</Triangle>
												{errorTooltip && (
													<ToolTip content={error}>test</ToolTip>
												)}
											</div>
										)}
									</LinkDiv>
								</ReactLink>
							</div>
						)
					);
				})}
			<Divider />
		</>
	);
};

const LinkDiv = styled.div`
	display: flex;
	font-weight: ${(props) => (props.selected ? "bold" : "")};
	text-align: center;
	flex-direction: row;
	background-color: ${(props) => (props.selected ? "#e8e8e8" : "")} !important;
	padding: 0.75rem;
`;

const Circle = styled.i`
	display: inline-block;
	margin-right: 2px;
	height: 100%;
	place-self: center;
	margin: 0 1rem 1rem 1rem;
`;

const Triangle = styled.div`
	width: 100px;
	height: 100px;
	position: relative;
	font-size: 20px;
	top: 200px;
	text-align: center;
	margin: auto;
`;

const Text = styled.div`
	color: #626161;
	font-size: 0.857rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
	font-weight: ${(props) => (props.bold ? "bold" : 600)};
	line-height: 1.45;
	text-align: left;
`;

VariationsLSBContainer.propTypes = {
	parent: parentPropTypes,
	variations: PropTypes.arrayOf(variationPropTypes),
};

export default VariationsLSBContainer;
