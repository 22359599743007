import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import { INBOX_CAMPAIGN_DEFAULTS_URL } from "./constants";


export const getInboxCampaignDefaults = createAsyncThunk(
	"inboxCampaignDefaults/getInboxCampaignDefaults",
	async ({testType, emailType}) => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGN_DEFAULTS_URL}?test_type=${testType}?type_id${emailType}`;
		const response = await ApiService.get(url);

		if (!response.ok) {
			throw new Error("Failed to load inbox campaign defaults!");
		}

		return response.json();
	}
);
export const clearInboxCampaignDefaults = createAction("inboxCampaignDefaults/clearInboxCampaignDefaults");