import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Image, Book, Info } from "react-feather";
import { ToolTip } from "../../../components";
import { ICON_SIZES } from "../../../constants/common";

const CampaignInfoLink = ({ isParent, campaignId, dateEdited }) => {
	const {
		params: { websiteId },
	} = useSelector((state) => state.page);

	const { selectedWebsite: website } = useSelector((state) => state.websites);

	const siteLink = website?.attributes?.website;

	const link = isParent
		? `/clients/stats?website_id=${websiteId}&campaign_ids=${campaignId}`
		: `http://${siteLink}?qbxtest${campaignId}`;

	const tooltipContent = isParent ? "Campaign stats" : "Force Preview";

	return (
		<>
		<LinkContainer>
					<ToolTip position="bottom" text={`Last edited on: ${dateEdited}`}>
				<a
					data-qa="campaign-info-link-button"
					target="_blank"
					rel="noreferrer"
				>
					<Info
							aria-label="campaign stats button"
							size={ICON_SIZES.medium}
						/>
				</a>
			</ToolTip>
			</LinkContainer>
			<LinkContainer>
			<ToolTip position="bottom" text={tooltipContent}>
				<a
					data-qa="campaign-info-link-button"
					target="_blank"
					href={link}
					rel="noreferrer"
				>
					{isParent ? (
						<Book
							aria-label="campaign stats button"
							size={ICON_SIZES.medium}
						/>
					) : (
						<Image
							aria-label="force preview button"
							size={ICON_SIZES.medium}
						/>
					)}
				</a>
			</ToolTip>
		</LinkContainer>
		</>
	);
};

const LinkContainer = styled.div`
	margin: 0 11px;
`;

CampaignInfoLink.propTypes = {
	campaignId: PropTypes.string.isRequired,
	isParent: PropTypes.bool,
	dateEdited: PropTypes.string,
};

export default CampaignInfoLink;
