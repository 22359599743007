import { createEntityAdapter } from "@reduxjs/toolkit";

const inboxTypesAdapter = createEntityAdapter({
	selectId: (inbox) => inbox.id,
});

export const { selectAll: selectAllInbox } = inboxTypesAdapter.getSelectors(
	(state) => state.inbox.response.data
);

export default inboxTypesAdapter;
