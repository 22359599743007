const fallbacks = {
	serif: '"Times New Roman", Times, serif',
	sansSerif: 'Helvetica, sans-serif',
	monospace: '"Courier New", monospace'
};

const fontOptionGroups = [
	{
		label: 'System',
		value: '',
		options: [
			{
				label: 'Arial',
				value: 'Arial',
				fallbacks: fallbacks.sansSerif
			},
			{
				label: 'Courier New',
				value: '"Courier New"',
				fallbacks: 'monospace'
			},
			{
				label: 'Georgia',
				value: 'Georgia',
				fallbacks: fallbacks.serif
			},
			{
				label: 'Helvetica',
				value: 'Helvetica',
				fallbacks: 'Arial, sans-serif'
			},
			{
				label: 'Helvetica Neue',
				value: '"Helvetica Neue"',
				fallbacks: 'Helvetica, Arial, sans-serif'
			},
			{
				label: 'Tahoma',
				value: 'Tahoma',
				fallbacks: fallbacks.sansSerif
			},
			{
				label: 'Times New Roman',
				value: '"Times New Roman"',
				fallbacks: 'Times, serif'
			},
			{
				label: 'Trebuchet MS',
				value: '"Trebuchet MS"',
				fallbacks: fallbacks.sansSerif
			},
			{
				label: 'Verdana',
				value: 'Verdana',
				fallbacks: fallbacks.sansSerif
			}
		]
	}
];

const webfontList = {
	google: [
		{
			family: 'Alegreya',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
				style: 'italic'
				}
			]
		},
		{
			family: 'Alegreya SC',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Allura',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Arvo',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Asap',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Bitter',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Cabin',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Cabin Condensed',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Cinzel',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'EB Garamond',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				}
			]
		},
		{
			family: 'Fredoka One',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				}
			]
		},
		{
			family: 'Inconsolata',
			fallbacks: fallbacks.monospace,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Josefin Sans',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Lato',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Libre Baskerville',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Merriweather',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Montserrat',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Open Sans',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Extra Bold',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Extra Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Open Sans Condensed',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Oswald',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Quicksand',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Raleway',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 200,
					designWeight: 'Extra Light',
					style: 'normal'
				},
				{
					cssWeight: 200,
					designWeight: 'Extra Light',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Extra Bold',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Extra Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Roboto',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Ultra Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Roboto Condensed',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Roboto Mono',
			fallbacks: fallbacks.monospace,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Roboto Slab',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Rock Salt',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				}
			]
		},
		{
			family: 'Slabo 13px',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				}
			]
		},
		{
			family: 'Special Elite',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				}
			]
		},
		{
			family: 'VT323',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				}
			]
		},
		{
			family: 'Yanone Kaffeesatz',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Extra Light',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		}
	],
	fontspring: [
		{
			family: 'Avalon',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Extra Light',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Extra Light',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 600,
					designWeight: 'Demi',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Demi',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Avalon Condensed',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Demi',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Black',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps Condensed',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps Condensed Inline',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps Condensed Inline Fill',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps Engraved',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps Engraved Fill',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps Inline',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps Inline Fill',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Caps Western',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Dingbats',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Inline',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Inline Fill',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Ornaments',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Blend Script',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Bodoni Display FS',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				},
				{
					cssWeight: 600,
					designWeight: 'Demibold',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Demibold',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'Bodoni Display FS Caps',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Demibold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Core Sans D',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'italic'
				}
			]
		},
		{
			family: 'Core Sans D Condensed',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'italic'
				}
			]
		},
		{
			family: 'Cultura New',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Black',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Black',
					style: 'italic'
				}
			]
		},
		{
			family: 'Garden Catchwords',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Garden Dingbats',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Garden Essential',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Garden Essential Alt',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Garden Ornaments',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Garden Pro',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Garden Slab',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Garden Swash',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Garden Swash Alt',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Joystix Monospace',
			fallbacks: fallbacks.monospace,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Joystix Proportional',
			fallbacks: fallbacks.monospace,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo One',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Two',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Three',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Four',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Clean One',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Clean Two',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Clean Three',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Clean Four',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Clean Outline',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Lulo Outline',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Mastadoni G1',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Mastadoni G2',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Mastadoni G3',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Mastadoni G4',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Mastadoni G5',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				}
			]
		},
		{
			family: 'Museo',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'italic'
				}
			]
		},
		{
			family: 'Museo Sans',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'italic'
				}
			]
		},
		{
			family: 'Museo Slab',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'normal'
				},
				{
					cssWeight: 300,
					designWeight: 'Book',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'italic'
				}
			]
		},
		{
			family: 'Nexa Script',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Demi',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'normal'
				}
			]
		},
		{
			family: 'Olivier',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Populaire',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Proxima Nova',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Thin',
					style: 'italic'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Semibold',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Extra Bold',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Extra Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Black',
					style: 'italic'
				}
			]
		},
		{
			family: 'Report School',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Heavy',
					style: 'italic'
				}
			]
		},
		{
			family: 'Riccione Serial',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 100,
					designWeight: 'Extra Light',
					style: 'normal'
				},
				{
					cssWeight: 100,
					designWeight: 'Extra Light',
					style: 'italic'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Normal',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Extra Bold',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Extra Bold',
					style: 'italic'
				},
				{
					cssWeight: 900,
					designWeight: 'Heavy',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Heavy',
					style: 'italic'
				}
			]
		},
		{
			family: 'Rockingham',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 600,
					designWeight: 'Demibold',
					style: 'normal'
				},
				{
					cssWeight: 600,
					designWeight: 'Demibold',
					style: 'italic'
				},
				{
					cssWeight: 800,
					designWeight: 'Extrabold',
					style: 'normal'
				}
			]
		},
		{
			family: 'Rockingham Condensed',
			fallbacks: fallbacks.serif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Sofia Pro Soft',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'italic'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		},
		{
			family: 'The Hand',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 200,
					designWeight: 'Light',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 800,
					designWeight: 'Black',
					style: 'normal'
				},
				{
					cssWeight: 900,
					designWeight: 'Extrablack',
					style: 'normal'
				}
			]
		},
		{
			family: 'The Hand Dotted',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				}
			]
		},
		{
			family: 'Trump Gothic Pro',
			fallbacks: fallbacks.sansSerif,
			variations: [
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'normal'
				},
				{
					cssWeight: 400,
					designWeight: 'Regular',
					style: 'italic'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'normal'
				},
				{
					cssWeight: 500,
					designWeight: 'Medium',
					style: 'italic'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'normal'
				},
				{
					cssWeight: 700,
					designWeight: 'Bold',
					style: 'italic'
				}
			]
		}
	]
};

export { webfontList, fontOptionGroups };
