import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";

export const downloadCreative = createAsyncThunk(
	"creatives/downloadCreative",
	async (id) => {
		const url = `${endpoints.coreApi}/creatives/${id}`;
		const response = await ApiService.get(url);

		if (!response.ok) {
			throw new Error("Failed to download creative!");
		}

		return response.json();
	}
);

export const renderMultiCreative = createAsyncThunk(
	"creatives/renderMultiCreative",
	async ({
		json,
		brand_style_id,
		show_bouncex_pwer,
		bouncex_pwer_message,
		effect,
		campaign_id,
		website_id,
	}) => {
		const url = `${endpoints.legacyApiDomain}/creative/render`;
		const jsonParam = `json=${encodeURIComponent(json)}&`;
		const brandParam = `brand_style_id=${brand_style_id}&`;
		const poweredParam = `show_bouncex_pwer=${show_bouncex_pwer}&`;
		const messageParam = `bouncex_pwer_message=${bouncex_pwer_message}&`;
		const effectParam = `effect=${effect}&`;
		const campaignIdParam = `campaign_id=${campaign_id}&`;
		const websiteIdParam = `website_id=${website_id}`;
		const body =
			jsonParam +
			brandParam +
			poweredParam +
			messageParam +
			effectParam +
			campaignIdParam +
			websiteIdParam;
		const response = await ApiService.postAsForm(url, { payload: body });
		if (!response.ok) {
			throw new Error("Failed to render creative!");
		}

		return response.text();
	}
);

export const renderCreative = createAsyncThunk(
	"creatives/renderCreative",
	async ({
		json,
		brand_style_id,
		show_bouncex_pwer,
		bouncex_pwer_message,
		effect,
		campaign_id,
		website_id,
	}) => {
		const url = `${endpoints.legacyApiDomain}/creative/render`;
		const jsonParam = `json=${encodeURIComponent(json)}&`;
		const brandParam = `brand_style_id=${brand_style_id}&`;
		const poweredParam = `show_bouncex_pwer=${show_bouncex_pwer}&`;
		const messageParam = `bouncex_pwer_message=${bouncex_pwer_message}&`;
		const effectParam = `effect=${effect}&`;
		const campaignIdParam = `campaign_id=${campaign_id}&`;
		const websiteIdParam = `website_id=${website_id}`;
		const body =
			jsonParam +
			brandParam +
			poweredParam +
			messageParam +
			effectParam +
			campaignIdParam +
			websiteIdParam;
		const response = await ApiService.postAsForm(url, { payload: body });
		if (!response.ok) {
			throw new Error("Failed to render creative!");
		}

		return response.text();
	}
);
