import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
	openNewTabWithUrl,
	UrlTemplate,
} from "../../../components/utils/open-page";
import { PageNavbarIcon } from "../../../components";

const BasicOptions = ({ disabledOptions }) => {
	const { page, inboxSeries, inboxCampaigns } = useSelector((state) => state);
	let websiteId;

	if (page.params.seriesId) {
		websiteId = inboxSeries?.response?.included.find(
			(seriesElement) => seriesElement.type === "websites"
		)?.id;
	} else if (page.params.campaignId) {
		websiteId = inboxCampaigns?.response?.included?.find(
			(campaignElement) => campaignElement.type === "websites"
		)?.id;
	}
	// TODO: add logic for other pages where website settings might be opened

	const pageNavbarIconPropsList = [
		{
			key: "Website Settings",
			name: "Website Settings",
			callback: () =>
				openNewTabWithUrl(UrlTemplate.WEBSITE_SETTINGS, { websiteId }),
			iconClass: "icon-gear",
			disable:
				!websiteId ||
				(disabledOptions &&
					disabledOptions.includes(UrlTemplate.WEBSITE_SETTINGS)),
		},
		{
			key: "Legacy Inbox",
			name: "Legacy Inbox",
			callback: () =>
				openNewTabWithUrl(UrlTemplate.LEGACY_INBOX, { websiteId }),
			iconClass: "icon-list-alt",
			disable:
				!websiteId ||
				(disabledOptions &&
					disabledOptions.includes(UrlTemplate.LEGACY_INBOX)),
		},
		{
			key: "Series Report",
			name: "Series Report",
			callback: () =>
				openNewTabWithUrl(UrlTemplate.REPORT, { websiteId }),
			iconClass: "icon-bar-chart",
			disable:
				!websiteId ||
				(disabledOptions &&
					disabledOptions.includes(UrlTemplate.REPORT)),
		},
		{
			key: "Comparison",
			name: "Comparison",
			callback: () =>
				openNewTabWithUrl(UrlTemplate.COMPARISON, { websiteId }),
			iconClass: "icon-trophy",
			disable:
				!websiteId ||
				(disabledOptions &&
					disabledOptions.includes(UrlTemplate.COMPARISON)),
		},
		{
			key: "Top Stats",
			name: "Top Stats",
			callback: () =>
				openNewTabWithUrl(UrlTemplate.TOP_STATS, { websiteId }),
			iconClass: "icon-book",
			disable:
				!websiteId ||
				(disabledOptions &&
					disabledOptions.includes(UrlTemplate.TOP_STATS)),
		},
		/**
		 * Removing this because it goes to the came place as LEGACY_INBOX;
		 * this is true even in the ember version. I want to leave this as
		 * a comment, however, as it should probably be included, but the
		 * functionality is fundamentally broken.
		 */
		//{
		//    key: 'Inbox Performance Report',
		//    name: 'Inbox Performance Report',
		//    callback: () => openNewTabWithUrl(
		//        UrlTemplate.PERFORMANCE_REPORT,
		//        { websiteId }
		//    ),
		//    iconClass: 'icon-envelope-o',
		//    disable: !websiteId || (disabledOptions && disabledOptions.includes(UrlTemplate.PERFORMANCE_REPORT)),
		//},
	];

	return pageNavbarIconPropsList.map((navbarIconProps) => (
		// eslint-disable-next-line
		<PageNavbarIcon {...navbarIconProps} />
	));
};

BasicOptions.propTypes = {
	disabledOptions: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.number])
	),
};

export default BasicOptions;
