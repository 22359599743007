import styled from 'styled-components'

const Pill = styled.span`
    display: inline-flex;
    align-items: center;
    font-size: 0.714rem;
    border-radius: 0.714rem;
    background: #bccdda;
    padding: 0.25rem 0.714rem;
    height: fit-content;
`

export default Pill;
