import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { BarChart } from "react-feather";

import { ICON_SIZES } from "../../../../constants/common";
import {
	Cell, columnArrayQL, CampaignName, ActiveIcon, formatPercentages, formatCurrency, formatNumber,
} from "./components/utils";
import { selectComparisonTableData, selectReportOptions } from "../../../../redux/report/selectors";

import { Header, Table, TBody, Link, ParentHeader } from "./components";


const ComparisonTableQL = ({ fetching }) => {
	const {
		date_start: startDate,
		date_end: endDate,
	} = useSelector(selectReportOptions);
	const { website_id: websiteId } = useSelector((state) => state.page.params);
	const campaigns = useSelector(selectComparisonTableData);

	if (fetching) {
		return <div>Loading ...</div>
	}

	return (
		<Table>
			<Header headers={columnArrayQL} />
			{campaigns.map((campaign) => (
				<React.Fragment key={campaign?.common?.id}>
					<ParentHeader
						name={campaign?.common?.name}
						purposeName={campaign?.common?.purposeName}
						id={campaign?.common?.id}
						startDate={startDate}
						endDate={endDate}
					/>
					{createVariations(campaign?.variations, websiteId, startDate, endDate, campaign?.common?.id)}
				</React.Fragment>
			))}
		</Table>
	);
};

ComparisonTableQL.propTypes = {
	fetching: PropTypes.bool.isRequired,
};

const createVariations = (variations, websiteId, startDate, endDate, parentId) => {
	const formatDateStart = startDate.split("T")[0];
	const formatDateEnd = endDate.split("T")[0];

	return variations.map((variation, id) => {
		const EligibilityRate = formatPercentages(variation?.stats?.EligibilityRate);
		const TotalImpressionRate = formatPercentages(variation?.stats?.TotalImpressionRate);
		const UniqueImpressionRate = formatPercentages(variation?.stats?.UniqueImpressionRate);
		const CloseRate = formatPercentages(variation?.stats?.CloseRate);
		const EmailOptInRate = formatPercentages(variation?.stats?.EmailOptInRate);
		const ClickRate = formatPercentages(variation?.stats?.ClickRate);
		const AverageOrderValue = formatCurrency(variation?.stats?.AverageOrderValue);
		const TotalRevenue = formatCurrency(variation?.stats?.ConversionStats?.TotalRevenue);
		const ConversionLift = formatNumber(variation?.stats?.LiftStats.ControlLiftBreakdown.Combined.ConversionLift);
		const LiftConfidence = formatPercentages(variation?.stats?.LiftStats.ControlLiftBreakdown.Combined.ConfidenceScore);
		const ConversionRate = formatPercentages(variation?.stats?.ConversionStats?.ConversionRate);

		return (
			<TBody cellPadding="0" key={id}>
				<tr key={id}>
					<Cell>
						<span>
							{formatDateStart} <br/>
							{formatDateEnd}
						</span>
					</Cell>
					<CampaignCell
						name={variation.common.name}
						id={variation.common.id}
						parentId={parentId}
						websiteId={websiteId}
						dateStart={formatDateStart}
						dateEnd={formatDateEnd}
						mode={variation.common.status}
					/>
					<Cell>
						{variation?.stats?.Visits}
					</Cell>
					<Cell>
						<span>{variation?.stats?.Eligibility} <br />
						{EligibilityRate}</span>
					</Cell>
					<Cell>
						<span>{variation?.stats?.TotalImpressions} <br />
						{TotalImpressionRate}</span>
					</Cell>
					<Cell>
						<span>{variation?.stats?.UniqueImpressions} <br/>
						{UniqueImpressionRate}</span>
					</Cell>
					<Cell>
						<span>{variation?.stats?.Closes} <br/>
						{CloseRate}</span>
					</Cell>
					<Cell>
						<span>{variation?.stats?.UniqueClicks} <br/>
						{ClickRate}</span>
					</Cell>
					<Cell>
						<Link href={`reports?type=websiteEmailCapture&website_id=${websiteId}&date_start=${formatDateStart}&date_end=${formatDateEnd}`}>
							<span>{variation?.stats?.EmailOptIns} <br/>
							{EmailOptInRate}</span>
						</Link>
					</Cell>
					<Cell>
						<span>
							{variation?.stats?.ConversionStats?.Conversions} <br/>
							{ConversionRate} <br />
							{variation?.overallStats?.ConversionStats?.Conversions}
						</span>
					</Cell>
					<Cell>
						<span>{ConversionLift}<br/>
						{LiftConfidence}</span>
					</Cell>
					<Cell>
						<span>{AverageOrderValue}</span>
					</Cell>
					<Cell>
						<span>{TotalRevenue}</span>
					</Cell>
				</tr>
			</TBody>
		);
	});
}


const CampaignCell = ({ name, id, dateStart, dateEnd, mode, parentId, websiteId }) => (
	<React.Fragment key={id}>
		<Cell key={id}>
			<CampaignName>
				<ActiveIcon active={mode} />
				<Link href={`experiences/cms/${id}/edit`}>
					{name}
				</Link>
			</CampaignName>
		</Cell>
		<Cell>
		<Link
			href={`reports?type=childCampaign&campaign_id=${id}&parent_campaign_id=${parentId}&website_id=${websiteId}&date_start=${dateStart}&date_end=${dateEnd}`}
		>
			<BarChart aria-label="bar chart" size={ICON_SIZES.large} />
		</Link>
		</Cell>
	</React.Fragment>
);

CampaignCell.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]).isRequired,
	dateStart: PropTypes.string,
	dateEnd: PropTypes.string,
	mode: PropTypes.oneOf(["ON", "TEST_MODE", "OFF"]).isRequired,
	parentId: PropTypes.string.isRequired,
	websiteId: PropTypes.string.isRequired,
};

export default ComparisonTableQL;
