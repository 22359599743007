import styled from "styled-components";

export const SectionTitle = styled.h2`
	font-size: 1rem;
	font-weight: 600;
	margin: 0 0.786rem;
	z-index: 0;
	align-self: center;
	white-space: nowrap;
`;

export const SubsectionBreak = styled.div`
	width: 100%;
	height: 1px;
	border-top: ${(props) => (props.empty ? null : "1px solid #e8e8e8")};
	margin-top: ${(props) => props.marginTop ?? "0.7rem"};
	margin-bottom: ${(props) => props.marginBottom ?? "0.6rem"};
`;

export const PlusMinusToggleStyled = styled.button`
	display: flex;
	font-size: 0.857rem;
	font-weight: 600;
	color: #626161;
	fill: #626161;
	padding: 0.786rem;
	background: transparent;
	border: 0;
	margin-left: ${(props) => props.marginLeft ?? null};
	margin-right: ${(props) => props.marginRight ?? null};
`;

export const SubsectionContainer = styled.div`
	margin-left: ${(props) => props.marginLeft ?? null};
	display: ${(props) => props.display ?? null};
	align-items: center;
`;

const PageSection = styled.div`
	margin-bottom: ${(props) => props.marginBottom ?? null};
	background-color: #ffffff;
	border: 1px solid #e5e1e1;
	padding-top: ${(props) => props.paddingTop ?? "0.786rem"};
	padding-bottom: ${(props) => props.paddingBottom ?? "0.786rem"};
	padding-left: ${(props) => props.paddingLeft ?? null};
	padding-right: ${(props) => props.paddingRight ?? null};
	z-index: 2;
	display: ${(props) => props.display ?? null};
`;

export default PageSection;
