export const TIME = [
	{
		name: "Choose...",
		value: "default",
	},
	{
		name: "Minutes",
		value: "minutes",
	},
	{
		name: "Hours",
		value: "hours",
	},
	{
		name: "Days",
		value: "days",
	},
];
