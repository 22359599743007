import React, { useEffect, useState } from "react";
import { isEmpty, get } from "lodash";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray } from "react-hook-form";

import { Loader, Button, Wrapper } from "../../components";
import { getESP, createESP, updateESP } from "../../redux/esp/thunks";
import {
	Container,
	ListContainer,
	Row,
	HalfColumn,
	FillColumn,
	TinyColumn,
	Label,
	Select,
	Input,
	Icon,
	SubNav,
	SubNavItem,
	ErrorMessage,
} from "./styledComponents";
import { TYPE_OPTIONS, FIELD_TYPE_OPTIONS, FIELD } from "./constants";

const Esp = () => {
	const {
		page: { params },
		esp: { isLoading, response },
	} = useSelector((state) => state);

	const dispatch = useDispatch();
	const [ignore, forcedUpdate] = useState(false);
	const [initiallyLoaded, setInitiallyLoaded] = useState(false);
	const [message, setMessage] = useState("");

	const { register, errors, handleSubmit, setValue, getValues, control, watch } = useForm({
		defaultValues: {
			name: get(response, "data.attributes.name", ""),
			type: TYPE_OPTIONS[0].value,
			active: true,
			fields: [],
		},
	});

	const name = getValues("name");
	const type = getValues("type");
	const active = getValues("active");
	const { fields, remove, append } = useFieldArray({
		control,
		name: "fields",
	});

	const deleteFieldRow = (indexToDelete) => {
		remove(indexToDelete);
	};

	const addFieldRow = () => {
		const newField = {
			name: "",
			required: "1",
			type: FIELD_TYPE_OPTIONS[0].value,
			choices: "",
		};

		append(newField);
	};

	const getColumnStyle = (hasError) => {
		return {
			border: hasError ? "1px solid #E59999" : "none",
			backgroundColor: hasError ? "#f2dede" : "white",
		};
	};

	const setField = (key, index, value) => {
		fields[index][key] = value;
		forcedUpdate(!ignore);
	};

	const handleSetMessage = (msg) => () => {
		setMessage(msg);

		setTimeout(() => setMessage(""), 3000);
	};

	const onSubmit = (submitData) => {
		const isNewEsp = isEmpty(response.data);
		if (isNewEsp) {
			dispatch(
				createESP({
					submitData,
					onSuccess: handleSetMessage("Created!"),
				})
			);
		} else {
			dispatch(
				updateESP({
					submitData,
					response,
					onSuccess: handleSetMessage("Saved!"),
				})
			);
		}
	};

	useEffect(() => {
		if (params.espId) {
			dispatch(getESP(params.espId));
		}
	}, []);

	useEffect(() => {
		if (response.data.attributes) {
			setValue("name", response.data.attributes.name);
			setValue("type", response.data.attributes.type);
			setValue("active", response.data.attributes.active);
			response.data.attributes.fields.forEach((field) => {
				if (initiallyLoaded) {
					remove(0);
				}
				append(field);
			});
			setInitiallyLoaded(true);
		}
	}, [response.data]);

	return (
		<Wrapper>
			<Loader isLoading={isLoading} />

			<form onSubmit={handleSubmit(onSubmit)}>
				<SubNav>
					<SubNavItem href="/tools/esps">◀ ESPs</SubNavItem>
				</SubNav>

				{get(response, "data.attributes.name", null) && (
					<Container>{response.data.attributes.name}</Container>
				)}

				<ListContainer>
					<ModifiedRow>
						<ModifiedHalfColumn>Name</ModifiedHalfColumn>
						<ModifiedHalfColumn style={getColumnStyle(errors.name)}>
							<Input
								name="name"
								type="text"
								style={{ width: "45%" }}
								ref={register({ required: true })}
							/>
							{errors.name && <ErrorMessage>Please enter field name</ErrorMessage>}
						</ModifiedHalfColumn>
					</ModifiedRow>
					<ModifiedRow>
						<ModifiedHalfColumn>Type</ModifiedHalfColumn>
						<ModifiedHalfColumn>
							<Select name="type" ref={register}>
								{TYPE_OPTIONS.map((option, index) => (
									<option key={index} value={option.value}>
										{option.label}
									</option>
								))}
							</Select>
						</ModifiedHalfColumn>
					</ModifiedRow>
					<ModifiedRow>
						<ModifiedHalfColumn>
							Status&#58; {active ? "Active" : "Inactive"}
						</ModifiedHalfColumn>
						<ModifiedHalfColumn>
							<Input name="active" type="checkbox" ref={register} />
						</ModifiedHalfColumn>
					</ModifiedRow>
				</ListContainer>

				<ListContainer>
					<ModifiedRow>
						<ModifiedHalfColumn>Field Name</ModifiedHalfColumn>
						<ModifiedHalfColumn>Requirement</ModifiedHalfColumn>
						<FillColumn>Field Type</FillColumn>
						<ModifiedTinyColumn />
					</ModifiedRow>
					{fields.length === 0 ? (
						<EmptyStatement>
							Currently&#44; there are no fields assigned to this ESP
						</EmptyStatement>
					) : (
						fields.map((field, index) => (
							<ModifiedRow key={field.id}>
								<ModifiedHalfColumn>
									<Input
										name={`fields[${index}].name`}
										ref={register()}
										style={{ width: "80%" }}
										type="text"
										defaultValue={field.name}
									/>
								</ModifiedHalfColumn>
								<ModifiedHalfColumn>
									<Label>
										<Input
											name={`fields[${index}].required`}
											type="radio"
											ref={register()}
											value="1"
											defaultChecked={field.required === "1"}
										/>{" "}
										Required
									</Label>
									<Label style={{ marginLeft: "16px" }}>
										<Input
											name={`fields[${index}].required`}
											type="radio"
											ref={register()}
											value="0"
											defaultChecked={field.required === "0"}
										/>{" "}
										Optional
									</Label>
								</ModifiedHalfColumn>
								<FillColumn>
									<Select
										name={`fields[${index}].type`}
										ref={register()}
										style={{ width: "30%" }}
										onChange={(event) =>
											setField(FIELD.TYPE, index, event.target.value)
										}
										value={field.type}
									>
										{FIELD_TYPE_OPTIONS.map((fieldType, index) => (
											<option key={index} value={fieldType.value}>
												{fieldType.label}
											</option>
										))}
									</Select>

									{field.type === "dd" && (
										<Input
											name={`fields[${index}].choices`}
											ref={register()}
											style={{
												marginLeft: "8px",
												width: "60%",
												height: "31px",
											}}
											type="text"
											defaultValue={field.choices}
										/>
									)}
								</FillColumn>
								<ModifiedTinyColumn>
									<Icon
										onClick={() => deleteFieldRow(index)}
										className="icon-remove"
									/>
								</ModifiedTinyColumn>
							</ModifiedRow>
						))
					)}
				</ListContainer>

				<ButtonsWrapper>
					<Button onClick={addFieldRow} type="button" theme="blue">
						Add new field
					</Button>

					<Button theme="blue" type="submit">
						Add ESP
					</Button>
					{message && <Message>{message}</Message>}
				</ButtonsWrapper>
			</form>
		</Wrapper>
	);
};

const EmptyStatement = styled(Row)`
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

const ModifiedRow = styled(Row)`
	padding-left: 0;
	padding-right: 0;
`;

const ButtonsWrapper = styled(Container)`
	display: flex;
	align-items: center;
	button {
		margin-right: 6px;
	}
`;

const ModifiedHalfColumn = styled(HalfColumn)`
	padding-left: 20px;
	padding-right: 20px;
`;

const ModifiedTinyColumn = styled(TinyColumn)`
	margin-right: 20px;
`;

const Message = styled.div`
	text-align: center;
	font-weight: bold;
	margin-left: 15px;
`;

export default Esp;
