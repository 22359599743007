import fetch from "isomorphic-fetch";
import { toast } from "react-toastify";

const sendRequest = ({
    url,
    method,
    cache,
    headers,
    successToast,
    failureToast,
}) => {
    if(!url) {
        throw new Error('No provided url. Please set the "url" argument.')
    }
    if(!method) {
        throw new Error('No http method provided. Please set the "method" argument.')
    }
    fetch(
        url,
		{
			method,
			cache: cache ?? null,
			headers: headers ?? null,
		}
    ).then(result => {
        if(result) {
            toast.success(successToast ?? 'Request sent successfully!')
        }
    }).catch(err => {
        toast.error(failureToast ?? 'Request failed. Please see logs.')
        console.error(err)
    })
}

export default sendRequest;