import React from "react";
import { NewESPProfileButton } from "../../components";

const ESPSettingsNav = () => {
	/* The view would relate to the modal view */
	return (
		<nav style={{zIndex: "1"}}className="primary-layout_main-nav nav-bar nav-bar__responsive mint-theme">
			<div className="nav-bar_left nav-bar_left__stretch-items bare-btn-bar bare-btn-bar__no-borders">
			</div>

			<NewESPProfileButton />
		</nav>
	);
};

export default ESPSettingsNav;
