import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const FormInput = ({
	width,
	label,
	value,
	onChange,
	placeholder,
	dataQA = "input",
	showCopyToSibling,
	disabled = false,
}) => {
	const changeHandler = (event) => {
		onChange(event.target.value);
	};

	const [showCopy, setShowCopy] = useState(false);

	return (
		<Label width={width}>
			{label && <LabelText>{label}</LabelText>}
			<Input
				data-qa={dataQA}
				hasLabel={label}
				value={value}
				showCopy={showCopy}
				onChange={changeHandler}
				type="text"
				width={width}
				placeholder={placeholder}
				onMouseEnter={showCopyToSibling && setShowCopy}
				disabled={disabled}
			/>
		</Label>
	);
};

const Label = styled.label`
	position: relative;
	box-sizing: border-box;
	width: ${(props) => props.width ?? ""};
`;

const LabelText = styled.div`
	position: absolute;
	left: 11px;
	top: 9px;
	font-size: 10px;
	font-weight: 400;
	color: rgb(25, 25, 25);
	pointer-events: none;
`;

const Input = styled.input`
	box-sizing: content-box;
	height: 18px;
	border: 1px solid rgb(195, 193, 193);
	font-size: 12px;
	color: rgb(25, 25, 25);
	padding: ${({ hasLabel }) => (hasLabel ? "25px 11px 11px" : "11px")};
	width: ${(props) => (props.width ? "-webkit-fill-available" : "416px")};
	&:disabled {
		opacity: 0.5;
	}
`;

FormInput.propTypes = {
	width: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	defaultValue: PropTypes.string,
	showCopyToSibling: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	dataQA: PropTypes.string,
	disabled: PropTypes.bool,
	value: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
			})
		),
	]).isRequired,
};

export default FormInput;
