import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInboxCampaign } from "../redux/inboxCampaigns/thunks";
import { getInboxSeries } from "../redux/inboxSeries/thunks";

export const useGetInboxData = () => {
	const { params } = useSelector((state) => state.page);
	const dispatch = useDispatch();

	let series,
		parents,
		variations,
		campaigns,
		inboxCampaigns,
		seriesRelatedData,
		campaignsRelatedData;

	seriesRelatedData = useSelector((state) => state.inboxSeries.response.included);
	campaigns = useSelector((state) => state.inboxCampaigns.response);
	campaignsRelatedData = useSelector((state) => state.inboxCampaigns.response.included);
	const seriesEntities = useSelector((state) => state.inboxSeries?.response?.data?.entities);

	const refreshIndexCampaign = async () => {
		if (params?.seriesId && !seriesEntities[params.seriesId]) {
			dispatch(getInboxSeries(params?.seriesId));
		}
		if (params?.campaignId && !campaigns[params.campaignId]) {
			dispatch(getInboxCampaign(params.campaignId));
		}
	};

	useEffect(() => {
		refreshIndexCampaign();
	}, [params]);

	if (params?.seriesId) {
		series = seriesEntities[params.seriesId];
		inboxCampaigns = seriesRelatedData?.filter(
			(campaign) => campaign?.type === "inbox_campaigns"
		);
	}
	if (params?.campaignId) {
		series = campaignsRelatedData?.filter(
			(campaign) =>
				campaign?.type === "inbox_series" &&
				(campaign?.relationships.children.data.find(
					(child) => String(child.id) === String(params?.campaignId)
				) ||
					campaign?.relationships.grandchildren.data.find(
						(child) => String(child.id) === String(params?.campaignId)
					))
		)[0];
		inboxCampaigns = campaignsRelatedData?.filter(
			(campaign) => campaign?.type === "inbox_campaigns"
		);
		if (campaigns?.data?.entities) {
			const originalCampaign = {
				type: "inbox_campaigns",
				id: params?.campaignId,
				attributes: campaigns?.data?.entities[params?.campaignId]?.attributes,
			};
			inboxCampaigns?.push(originalCampaign);
		}
	}
	parents =
		inboxCampaigns &&
		inboxCampaigns
			?.filter(
				(campaign) => campaign.attributes && campaign?.attributes["test-type"] === "parent"
			)
			.sort((a, b) => a.id - b.id);
	variations =
		inboxCampaigns &&
		inboxCampaigns?.filter(
			(campaign) => campaign.attributes && campaign?.attributes["test-type"] === "variation"
		);

	return {
		series,
		parents,
		variations,
		campaigns,
		inboxCampaigns,
		seriesRelatedData,
		campaignsRelatedData,
	};
};
