import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getInboxCampaigns } from "../../redux/inbox/thunks";
import { selectAllWebsites } from "../../redux/website-micros/websitesAdapter";
import { useBindValueToParam, useDebouncedValue } from "../../hooks";
import { getPurposes } from "../../redux/purposes/thunks";
import { getCampaignTypes } from "../../redux/campaignTypes/thunks";
import { getWebsites } from "../../redux/websites/thunks";
import { clearInboxApiError } from "../../redux/inbox/slice"
import { clearPurposesApiError } from "../../redux/purposes/slice";
import { clearCampaignTypesApiError } from "../../redux/campaignTypes/slice";

import {
	ButtonsGroup,
	SidebarButton,
	CmsInboxSwitcher,
	WebsiteSwitcher,
	Wrapper,
	Button,
	HeaderIconBar,
	HeaderSortButtons,
	Header,
	PopupModal,
} from "../../components";
import { selectAllInbox } from "../../redux/inbox/inboxAdapter";

import {
	InboxTable,
	InboxFilters,
	NewInboxSeriesButton,
	DownloadCampaignsPreviewModal,
	RightInboxButtonsBlock,
	RightInboxInfoBlock,
	CampaignTemplateCloningModal,
	ArchiveCampaignForm,
} from "./components";
import { iconSettings } from "./constants";

const initParams = {
	website_id: "",
	v: 0,
	q: "",
	hideVariations: 0,
	archived: 0,
	campaign_purpose_id: "",
	campaign_type_id: "",
	"status[]": ["on", "off", "test", "wip"],
};

const InboxSeriesPage = () => {
	const {
		isLoading: inboxIsLoading,
		isFetchError: inboxIsFetchError,
		response: {
			meta: { total_rows: totalInboxCampaigns = 0 },
		},
		selectedCampaigns,
	} = useSelector((state) => state.inbox);

	const websites = useSelector(selectAllWebsites);
	const inboxCampaigns = useSelector(selectAllInbox);

	let parentCamapaigns = inboxCampaigns.filter(
		({ attributes = {} }) =>
			attributes["test-type"] === "parent" && attributes["is-leader"] === true
	);

	const {
		purposes: { isFetchError: purposesIsFetchError },
		campaignTypes: { isFetchError: campaignTypesIsFetchError },
	} = useSelector((state) => state);

	const {
		page,
		params: { websiteId },
	} = useSelector((state) => state.page);

	const dispatch = useDispatch();

	const [pageTitle, setPageTitle] = useState("Loading...");
	const [trafficType, setTrafficType] = useState(initParams["status[]"]);
	const [searchStr, setSearchStr] = useState(initParams.q);
	const [purposeId, setPurposeId] = useState(initParams.campaign_purpose_id);
	const [typeId, setTypeId] = useState(initParams.campaign_type_id);
	const [archived, setArchived] = useState(initParams.archived);
	const [hideVariations, setHideVariations] = useState(initParams.hideVariations);
	const [view, setView] = useState("list");
	const [showDownloadCampaignPreview, setShowDownloadCampaignPreview] = useState(false);
	const [showCloneCampaignCloningPreview, setShowCloneCampaignCloningPreview] = useState(false);
	const [showArchiveModal, setShowArchiveModal] = useState(false);

	useBindValueToParam("status[]", initParams["status[]"], trafficType, setTrafficType);
	useBindValueToParam("archived", initParams.archived, archived, setArchived);
	useBindValueToParam(
		"hideVariations",
		initParams.hideVariations,
		hideVariations,
		setHideVariations
	);
	useBindValueToParam(
		"campaign_purpose_id",
		initParams.campaign_purpose_id,
		purposeId,
		setPurposeId
	);

	useBindValueToParam("campaign_type_id", initParams.campaign_type_id, typeId, setTypeId);
	const debouncedSearchStr = useDebouncedValue(searchStr, 500, { isAwait: inboxIsLoading });

	const hasApiError =
		inboxIsFetchError ||
		campaignTypesIsFetchError ||
		purposesIsFetchError;

	const clearApiErrors = () => {
		dispatch(clearInboxApiError());
		dispatch(clearPurposesApiError());
		dispatch(clearCampaignTypesApiError());
	};

	const resetFilters = () => {
		setTrafficType(initParams["status[]"]);
		setSearchStr(initParams.q);
		setPurposeId(initParams.campaign_purpose_id);
		setTypeId(initParams.campaign_type_id);
		setHideVariations(initParams.hideVariations);
		setArchived(initParams.archived);
	};

	const refreshInboxCampaigns = async () => {
		await dispatch(
			getInboxCampaigns({
				...initParams,
				website_id: websiteId,
				"status[]": trafficType,
				hideVariations: hideVariations,
				q: debouncedSearchStr,
				include: "none",
				"fields[inbox_campaigns]":
					"active, test_type, is_leader, id, campaign_series_id, name, series_name, enabled, dt_edited, test_mode, test_traffic, test_type",
			})
		);
	};

	// useEffect(() => {
	// }, [websiteId, trafficType, debouncedSearchStr, archived, hideVariations]);

	useEffect(() => {
		refreshInboxCampaigns();
	}, [websiteId, trafficType, debouncedSearchStr, archived, hideVariations]);

	useEffect(() => {
		dispatch(getWebsites({}));
		dispatch(getPurposes());
		dispatch(getCampaignTypes());
	}, []);

	useEffect(() => {
		websites.filter((website) => {
			if (website.id === websiteId) {
				setPageTitle(website?.attributes?.name);
			}
		});
	}, [websites, websiteId]);

	const filterOnChange = (event) => {
		switch (event.type) {
			case "trafficType":
				setTrafficType(event.value);
				break;
			case "searchStr":
				setSearchStr(event.value);
				break;
			case "purpose":
				setPurposeId(event.value);
				break;
			case "type":
				setTypeId(event.value);
				break;
			case "archived":
				setArchived(event.value);
				break;
			case "hideVariations":
				setHideVariations(event.value);
		}
	};

	return (
		<Wrapper
			left="0"
			maxWidth="100%"
			style={{
				display: "flex",
				justifyContent: "space-between",
				bottom: "0",
			}}
			title={pageTitle}
		>
			<Aside>
				<NoPaddingContainer>
					<SidebarButton />
				</NoPaddingContainer>
				<CmsInboxSwitcher page={page} websiteId={websiteId} />
				<WebsiteSwitcher />
				<InboxFilters
					onChange={filterOnChange}
					trafficTypeValue={trafficType}
					searchValue={searchStr}
					purposeValue={purposeId}
					typeValue={typeId}
				/>
				<ButtonsGroup type="archived" data={archived} setData={setArchived} />
				<ButtonsGroup
					type="hideVariations"
					data={hideVariations}
					setData={setHideVariations}
				/>
				<Button dataQA="reset-button" onClick={resetFilters} fullWidth theme="blue">
					Reset Filters
				</Button>
			</Aside>
			<Main>
				{!inboxIsLoading && (
					<PopupModal showModal={hasApiError} toggleModal={clearApiErrors}>
						Something went wrong!
					</PopupModal>
				)}
				<Header
					leftComponent={<NewInboxSeriesButton websiteId={websiteId} />}
					middleComponent={<HeaderSortButtons view={view} changeView={setView} />}
					rightComponent={
						<HeaderIconBar
							showDownloadCampaigns
							setShowDownloadCampaignPreview={setShowDownloadCampaignPreview}
							icons={iconSettings(websiteId)}
							flag="websites"
						/>
					}
				/>
				<InboxTable isLoading={inboxIsLoading} view={view} />
				<PopupModal
					showModal={showDownloadCampaignPreview}
					toggleModal={() => setShowDownloadCampaignPreview(false)}
				>
					<DownloadCampaignsPreviewModal
						pageTitle={pageTitle}
						websiteId={websiteId}
						inbox={parentCamapaigns}
						toggleModal={() => setShowDownloadCampaignPreview(false)}
					/>
				</PopupModal>
				<PopupModal
					showModal={showCloneCampaignCloningPreview}
					toggleModal={() => setShowCloneCampaignCloningPreview(false)}
				>
					<CampaignTemplateCloningModal
						toggleModal={() => setShowCloneCampaignCloningPreview(false)}
					/>
				</PopupModal>
				<PopupModal
					showModal={showArchiveModal}
					toggleModal={() => setShowArchiveModal(false)}
				>
					<ArchiveCampaignForm
						selectedCampaigns={selectedCampaigns}
						refreshCampaigns={refreshInboxCampaigns}
						toggleArchiveModal={() => setShowArchiveModal(false)}
					/>
				</PopupModal>
			</Main>
			<Aside>
				<RightInboxInfoBlock />
				<RightInboxButtonsBlock
					toggleCampaignsCloneModal={() => setShowCloneCampaignCloningPreview(true)}
					toggleArchiveModal={() => setShowArchiveModal(true)}
					refreshCampaigns={refreshInboxCampaigns}
				/>
			</Aside>
		</Wrapper>
	);
};

const Aside = styled.aside`
	width: 270px;
	background-color: #ffffff;
	overflow-y: auto;
	padding: 22px;
	opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
	pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
	&::-webkit-scrollbar {
		display: none;
	}
`;

const Main = styled.main`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

const NoPaddingContainer = styled.div`
	margin: -22px -22px 0;
`;

export default InboxSeriesPage;
