import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getESPs } from "../../redux/esps/thunks";
import { Loader, Pagination, Wrapper, Button } from "../../components";
import { selectAllESPs } from "../../redux/esps/espsAdapter";
import {
	Header,
	Container,
	ListContainer,
	Row,
	LargeColumn,
	SmallColumn,
	SubNav,
	SubNavItem,
} from "./styledComponents";
import { PER_PAGE } from "./constants";

const Esps = () => {
	const {
		page: { params },
		esps: { isLoading },
	} = useSelector((state) => state);
	const esps = useSelector(selectAllESPs);
	const dispatch = useDispatch();

	const getModifiedPageNumber = (page) => (page === null ? "1" : page);
	const [page, setPage] = useState(getModifiedPageNumber(params.page));

	const modifiedSetPage = (pageNum) => {
		const urlWithoutParams = window.location.href.split("?")[0];
		window.history.pushState("", "", `${urlWithoutParams}?page=${pageNum}`);
		setPage(pageNum);
	};

	const handleBackButton = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const pageParam = urlParams.get("page");
		setPage(getModifiedPageNumber(pageParam));
	};

	useEffect(() => {
		let queryParamsObj = {
			page,
			perPage: PER_PAGE,
		};

		if (page === "all") {
			queryParamsObj = {
				page: "1",
				perPage: 1000,
			};
		}

		dispatch(getESPs());
	}, [page]);

	useEffect(() => {
		window.addEventListener("popstate", handleBackButton);
		return () => window.removeEventListener("popstate", handleBackButton);
	}, [handleBackButton]);

	return (
		<Wrapper>
			<Loader isLoading={isLoading} />

			<SubNav>
				<SubNavItem href="/tools">Tools</SubNavItem>
			</SubNav>

			<Container>
				<Button theme="blue" url="/tools/esp" as="a">
					Add ESP
				</Button>
			</Container>

			<Container>
				<Header>Integrated Email Service Providers List</Header>
			</Container>

			<ListContainer>
				{esps
					.filter((esp, index) => {
						// Fake Pagination
						if (page === "all") {
							return true;
						}

						const pageInteger = parseInt(page);
						const minIndex = PER_PAGE * (pageInteger - 1);
						const maxIndex = PER_PAGE * pageInteger - 1;
						return index >= minIndex && index <= maxIndex;
					})
					.map((esp, index) => (
						<a key={esp.id} href={`/tools/esp?esp_id=${esp.id}`}>
							<Row>
								<LargeColumn>
									<Header>{esp.attributes.name}</Header>
								</LargeColumn>
								<ModifiedSmallColumn>
									{esp.attributes.type.toUpperCase()}
								</ModifiedSmallColumn>
								<ModifiedSmallColumn>
									{esp.attributes.active ? "Active" : "Inactive"}
								</ModifiedSmallColumn>
							</Row>
						</a>
					))}
				<Row>
					<Pagination
						currentPage={page}
						totalPages={Math.ceil(esps.length / PER_PAGE)}
						setPage={modifiedSetPage}
					/>
				</Row>
			</ListContainer>
		</Wrapper>
	);
};

const ModifiedSmallColumn = styled(SmallColumn)`
	font-weight: 600;
`;

export default Esps;
