import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const HeaderComponent = ({ headers = [] }) => {
	const cells = headers.map(({ name }, index) => <th key={index}>{name}</th>);
	return (
		<THead>
			<tr>{cells}</tr>
		</THead>
	);
};
HeaderComponent.propTypes = {
	headers: PropTypes.array.isRequired,
};
const THead = styled.thead`
	background-color: #f3f3f2;
	height: 50px;
	th {
		&:first-child {
			padding-left: 20px;
		}
		&:nth-child(2) {
			width: 180px;
		}
		&:last-child {
			padding-right: 20px;
		}
		padding: 20px 0;
		text-align: center;
		@media (max-width: 768px) {
			font-size: 11px;
		}
	}
`;
export default HeaderComponent;
