import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { CreativePreviewStep } from "../../../components";
import { useCreativePreview } from "../../../hooks";

const CMSCreativePreview = ({
	showStepNumber,
	showLabel,
	campaign,
}) => {
	const {
		isBouncex,
		isVariation,
		stepsList,
		fonts,
		fixedPlacement,
		updateCreative,
	} = useCreativePreview(campaign);

	useEffect(() => {
		updateCreative();
	}, [updateCreative]);


	return (
		<PreviewContainer showLabel>
			{isBouncex && isVariation && (
				<>
					{showLabel && (
						<BlockLabel>
							Stats Preview Image
						</BlockLabel>
					)}
					<Container>
						
						{stepsList.map((step, id) =>
							<CreativePreviewStep
							key={id}
							stepNumber={step}
							showStepNumber={showStepNumber}
							fonts={fonts}
							fixedPlacement={fixedPlacement}
							/>
							)}
					</Container>
				</>
			)}
		</PreviewContainer>
	);
};

const PreviewContainer = styled.div`
	margin: ${({ showLabel }) => (showLabel ? "0" : "40px -22px 0")};
`;

const BlockLabel = styled.h2`
	display: block;
	margin: 0;
	padding: 0.786rem 1.571rem;
	color: #626161;
	font-size: 0.857rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
	font-weight: 400;
`;

const Container = styled.div`
	margin: 0 0 20px;
`

CMSCreativePreview.propTypes = {
	showStepNumber: PropTypes.bool,
	showLabel: PropTypes.bool,
	campaign: PropTypes.shape({}),
};

export default CMSCreativePreview;
