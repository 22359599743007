import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Buttons = [
	{
		type: "archived",
		buttons: [
			{
				title: "Active",
				value: 0,
				qa: "active-buttons-active",
			},
			{
				title: "All",
				value: 2,
				qa: "active-buttons-all",
			},
		],
	},
	{
		type: "hideVariations",
		buttons: [
			{
				title: "Show Variations",
				value: 0,
				qa: "active-buttons-show-variations",
			},
			{
				title: "Hide variations",
				value: 1,
				qa: "active-buttons-hide-variations",
			},
		],
	},
];

const changeFilter = (params, value, resetFunction) => {
	const url = window.location.pathname;
	const urlParams = new URLSearchParams(window.location.search);

	if (value) {
		urlParams.set(params, value);
		urlParams.delete("page");
		resetFunction(value);
	} else {
		urlParams.delete(params);
		resetFunction(value);
	}

	const newUrl = `${url}?${urlParams.toString()}`;
	window.history.pushState({ path: newUrl }, "", newUrl);
};

const ButtonsGroup = ({ type, data, setData }) => {
	const updateFilter = (type, value, filterType) => changeFilter(type, value, filterType);

	const renderButtonsGroup = Buttons.find((obj) => {
		return obj.type === type;
	});

	return (
		<ButtonContainer>
			{renderButtonsGroup.buttons.map(({ title, value, qa }) => (
				<Button
					data-qa={qa}
					key={title}
					isActive={data === value}
					onClick={() => updateFilter(renderButtonsGroup.type, value, setData)}
				>
					{title}
				</Button>
			))}
		</ButtonContainer>
	);
};

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 11px;
`;

const Button = styled.button`
	flex: 1;
	background: #ffffff;
	font-size: 10px;
	padding: 12px 0px;
	text-transform: uppercase;
	border-radius: 0;

	border: ${({ isActive }) =>
		isActive ? "1px solid rgb(48, 61, 120)" : "1px solid rgb(232, 232, 232)"};

	color: ${({ isActive }) => (isActive ? "rgb(48, 61, 120)" : "rgb(25, 25, 25)")};
`;

ButtonsGroup.propTypes = {
	data: PropTypes.number.isRequired,
	setData: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};

export default ButtonsGroup;
