import { sharedDropDownValues, reportTypes } from "../../constants/common";
import { formatPercentage, getPercentage, numberFormat, toFixed } from "./utils";

const createWebsiteCampaignData = (type, rawData, date_interval) => {
	const convertObj = (obj) => {
		return formatData(type, obj, date_interval);
	};
	return rawData.map(convertObj);
};
const formatData = (
	type,
	{
		date = "",
		overall_visits = 0,
		eligible_visits = 0,
		visit_impressions = 0,
		overall_impressions = 0,
		clicks = 0,
		closes = 0,
		form_submits = 0,
		website_conversions = 0,
		campaign_conversions = 0,
		overall_conversions = 0,
	},
	date_interval = ""
) => {
	return {
		date: formatDate(date, date_interval),
		overall_visits: Number(overall_visits).toLocaleString(...numberFormat),
		eligible_visits: formatPercentage(eligible_visits, overall_visits),
		overall_impressions: formatOverall(
			toFixed(visit_impressions),
			overall_impressions,
			eligible_visits
		),
		clicks: formatPercentage(clicks, toFixed(overall_impressions)),
		closes: formatPercentage(closes, overall_impressions),
		form_submits: formatPercentage(form_submits, overall_impressions),
		overall_conversions: getOverallConversions(
			type,
			website_conversions,
			campaign_conversions,
			overall_conversions,
			visit_impressions
		),
	};
};
const getOverallConversions = (
	type = "",
	website_conversions = 0,
	campaign_conversions = 0,
	overall_conversions = 0,
	visit_impressions = 0
) => {
	if (type === reportTypes.websiteCampaign) {
		return {
			top: `${website_conversions} / ${overall_conversions}`,
			bottom: `${getPercentage(website_conversions, visit_impressions)}%`,
		};
	} else if (type === reportTypes.parentCampaign || type === reportTypes.childCampaign) {
		return {
			top: `${campaign_conversions} / ${overall_conversions}`,
			bottom: `${getPercentage(campaign_conversions, visit_impressions)}%`,
		};
	}
	return { top: "", bottom: "" };
};

const formatOverall = (first = 0, second = 0, third = 0) => {
	return {
		top: `${Number(first).toLocaleString(...numberFormat)} / ${Number(second).toLocaleString(
			...numberFormat
		)}`,
		bottom: getPercentage(second, third),
	};
};

const formatDate = (dateString = "", dateInterval = "") => {
	if (!dateString) {
		return {};
	}
	const dateObj = new Date(dateString);
	const bottomOptions =
		dateInterval === sharedDropDownValues.viewBy.hour
			? //formats time by local time.
			  { hour: "2-digit", minute: "2-digit" }
			: // viewBy.day returns each date in UTC format, so it needs to be formatted as UTC
			  { timeZone: "UTC" };

	const topOptions =
		dateInterval === sharedDropDownValues.viewBy.hour
			? { weekday: "long" }
			: // viewBy.day returns each date in UTC format, so it needs to be formatted as UTC
			  { weekday: "long", timeZone: "UTC" };

	return {
		top: dateObj.toLocaleDateString("en-us", topOptions),
		// use sv-SE timezone format yyy-mm-dd
		// https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
		bottom: dateObj.toLocaleDateString("sv-SE", bottomOptions),
	};
};

export default createWebsiteCampaignData;
