import React from "react";
import { Section } from "../../../../../../components/form";
import { CodeInput } from "../../../../components/code-input";

const SendESPExtraFields = () => {
	const code = "console.log('SendESPExtraFields');";

	return (
		<Section title="Send ESP Extra Fields">
			<CodeInput code={code} />
		</Section>
	);
};

export default SendESPExtraFields;
