export const WEBSITES_URL = "/websites";

export const WEBSITE_URL_PARAMS = {
	contract_id: "",
	full_load: "",
	industry_id: "",
	limit: "",
	module: "",
	page: "",
	q: "",
	show: "",
	vertical_id: "",
};

export const NEW_WEBSITE_DEFAULTS = {
	// website non-null columns
	active: 1,
	advanced_cookie_options: '{"first_party_limit":"3500","local_storage":"1"}',
	alerts: 1,
	analytics: 0,
	beta: 0,
	block_urls: '',
	bouncex_pwer_message: '',
	calculated_mrr: 0,
	compliance_json: '{"gdpr":0,"gmp":0,"whitelist_check":0}',
	content_width: 900,
	cookie_type: 'bind_to_domain',
	credentials_json: '',
	currency: 'USD',
	custom_campaign_js: '',
	custom_liquid_variable: '[]',
	custom_variable: '[]',
	page_type_json: '',
	deployment_presets: '',
	device_graph_enabled: 0,
	device_graph_settings_json:'{ "trafficPercentage": 100, "tagData": { "id": "c.js", "async": "true", "src": "//pixel.cdnwidget.com/cdn/c.min.js", "data-gm": "0", "data-fire": "0" } }',
	enabled: 0,
	enable_advanced_cookie: 0,
	enable_ally: 0,
	use_button_tag_for_close: true,
	use_verbose_label_for_close: true,
	enable_bpush: 0,
	enable_ga_integration: 1,
	enable_iframe: 0,
	enable_waypoints: 0,
	encrypt_email_two_way: 0,
	event_stream_enabled: 1,
	event_tracking_enabled: 2,
	event_tracking_test_mode: 1,
	event_tracking_js: '',
	event_ui_json: '',
	fastboot_enabled: 1,
	features: '',
	feed_config_json: '',
	force_https: 0,
	gbi_enabled: 0,
	global_control_size: 0,
	gmp_url_exceptions: 'gclid',
	gs_url_identifiers: '',
	inbox_reporting_enabled: 0,
	lang: 'english',
	load_jquery: 'auto',
	master: false,
	max_activations_pageview: 1,
	max_activations_session: 2,
	no_control_ips: '',
	order_email_js: '',
	order_id_js: '',
	order_val_js: '',
	perf_logging_enabled: 0,
	pick_random_campaigns: 0,
	polling_interval: 1000,
	product_feed_settings_json: '',
	script_delay: 0,
	script_tags_json: '',
	show_bouncex_pwer: 0,
	site_element_json: '{}',
	template_site: 0,
	test_child_id: 0,
	test_enabled: 0,
	test_traffic: 50,
	third_party_cookie: 0,
	top_stats_disabled: 0,
	user_history_enabled: 0,
	use_website_cookie: 0,
	wrike_folder_id: '',
	wrike_permalink: '',

	// website metadata
	single_page_app_on: 0,
	single_page_app_test_mode: 1,
	max_activations_timeperiod: 0,
	max_activations_timeperiod_hours: 0,
	enable_cms_dfp_suppression: 0,
	enable_ally_settings_json: '{"enable_ally_aria_labels": 1,"enable_ally_focus": 1,"enable_ally_number_to_string": 1}',
	wknd_web_sdk_enabled: 0,

	// ir_website non-null columns
	accelerated_test_mode: 0,
	auto_add_utm_parameters: 1,
	cart_as_hash_param: 0,
	enable_deterministic_child_campaign: 0,
	enable_unidentified_devices: 0,
	monitor_inputs_bcx: 1,
	monitor_inputs_website: 0,
	number_of_workers: 1,
	tracking_enabled: 0,
	validate_events: 1,
	v2_enabled: 0,
	v2_sender: 0,
	v2_test_mode: 0,
	graph_mail_enabled: 0,
	suppressed_domains: '',
	eligibility_strategy: 'criteriamet',
	test_email_injector: 0
};

export const WEBSITE_RELATIONSHIPS = {
	client: {
		data: null,
	},
	contract: {
		data: null,
	},
	"gbi-provider": {
		data: null,
	},
	"gbi-series": {
		data: [],
	},
	"inbox-series": {
		data: [],
	},
	industry: {
		data: null,
	},
	users: {
		data: [],
	},
	vertical: {
		data: null,
	}
};

export const WEBSITE_MODULES_FILTER_OPTIONS = [
	{ id: "", name: "All Modules" },
	{ id: "cms", name: "CMS" },
	{ id: "inbox", name: "Inbox" },
	{ id: "yield", name: "Publisher Yield" },
];
