import { createSlice } from "@reduxjs/toolkit";
import { getWebsite, getWebsiteId, saveWebsite } from "./thunks";

const initialState = {
	isLoading: false,
	isFetchError: false,
	isUpdating: false,
	hasUpdateErrors: false,
	response: {
		data: [],
	},
};

const websiteSlice = createSlice({
	name: "website",
	initialState,
	reducers: {
		clearWebsite(state) {
			state.isFetchError = false;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getWebsite.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getWebsite.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.data = action.payload.data;
		});
		builder.addCase(getWebsite.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
		});
		builder.addCase(saveWebsite.pending, (state) => {
			state.isUpdating = true;
			state.hasUpdateErrors = false;
		});
		builder.addCase(saveWebsite.fulfilled, (state, action) => {
			state.isUpdating = false;
			state.response.data = action.payload.data;
		});
		builder.addCase(saveWebsite.rejected, (state) => {
			state.isUpdating = false;
			state.hasUpdateErrors = true;
		});
		builder.addCase(getWebsiteId.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getWebsiteId.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.data = action.payload.data;
		});
		builder.addCase(getWebsiteId.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
		});
	},
});

export const { clearWebsite } = websiteSlice.actions;

export default websiteSlice.reducer;
