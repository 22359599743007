import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Header = ({ leftComponent, middleComponent, rightComponent }) => (
	<HeaderContainer>
		<LeftComponent>{leftComponent}</LeftComponent>
		<MiddleComponent>{middleComponent}</MiddleComponent>
		<RightComponent>{rightComponent}</RightComponent>
	</HeaderContainer>
);

const HeaderContainer = styled.header`
	padding: 0 20px;
	align-items: center;
	width: 100%;
	min-height: 44px;
	background-color: rgb(37, 184, 157);
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
`;

const LeftComponent = styled.div`
	display: flex;
	align-items: center;
`;

const MiddleComponent = styled.div`
	justify-content: center;
	display: flex;
`;

const RightComponent = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

Header.propTypes = {
	leftComponent: PropTypes.node,
	middleComponent: PropTypes.node,
	rightComponent: PropTypes.node,
};

export default Header;
