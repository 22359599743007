import { gql } from "urql";
import { COMMON_VALUES, CAMPAIGN_STATS } from "./fragments";

const parentCampaignQuery = gql`
    ${CAMPAIGN_STATS}
    ${COMMON_VALUES}
    query($websiteId: Int!, $parentId: Int!, $start: DateTime!, $end: DateTime!, $statsGranularity: StatsGranularity) {
        website (id: $websiteId, start: $start, end: $end) {
            id
            name
            onsiteProduct(websiteAttribution: ALL_CREDIT_30, statsGranularity: $statsGranularity) {
                campaign(id: $parentId) {
                    common {
                        ...commonValues
                    }
                    stats {
                        ...campaignStats
                    }
                    timeSeries {
                        date
                        stats {
                            ...campaignStats
                        }
                    }
                }
            }
        }
        overall: website(id: $websiteId, start: $start, end: $end) {
            id
            onsiteProduct(websiteAttribution: ALL_CONVERSION, statsGranularity: $statsGranularity) {
                campaign(id: $parentId) {
                    common {
                        ...commonValues
                    }
                    stats {
                        ConversionStats {
                            Conversions
                        }
                    }
                    timeSeries {
                        date
                        stats {
                            ConversionStats {
                                Conversions
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default parentCampaignQuery;
