import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { StatusMark } from "../../../components";
import { WEBSITE_MODULES_FILTER_OPTIONS } from "../../../redux/websites/constants";
import { NoInfoIconLink } from ".";

const RightInfoBlock = () => {
	const { selectedWebsite } = useSelector((state) => state.websites);
	const contracts = useSelector(
		(state) => state?.contracts?.response?.data || []
	);
	const industries = useSelector(
		(state) => state?.industries?.response?.data || []
	);
	const verticals = useSelector(
		(state) => state?.verticals?.response?.data || []
	);
	const {
		name,
		id,
		enabled,
		"gbi-enabled": gbiEnabled,
		"tracking-enabled": trackingEnabled,
		"v2-enabled": v2Enabled,
		"v2-test-mode": v2TestMode,
		"dt-golive": goLiveDate,
		website,
	} = selectedWebsite?.attributes || {};

	const urlToSettings = `/websites-legacy/${id}/edit`;
	const contractId = selectedWebsite?.relationships?.contract?.data?.id;
	const industryId = selectedWebsite?.relationships?.industry?.data?.id;
	const verticalId = selectedWebsite?.relationships?.vertical?.data?.id;

	const createNoInfo = (content) => (
		<NoInfoIconLink url={urlToSettings} tipText={content} />
	);

	const contractType = contractId
		? contracts.find((el) => el.id === contractId)?.attributes?.name
		: createNoInfo("Select a contract type from the settings page");
	const industryType = industryId
		? industries.find((el) => el.id === industryId)?.attributes?.name
		: createNoInfo("Select an industry from the settings page");
	const verticalType = verticalId
		? verticals.find((el) => el.id === verticalId)?.attributes?.name
		: createNoInfo("Select a verticals from the settings page");

	const isInboxEnabled = trackingEnabled || v2Enabled || v2TestMode;

	return (
		<>
			{selectedWebsite && (
				<InfoContainer>
					<Title data-qa="header-brand">{name}</Title>
					<Element>
						<a
							data-qa="link-cms"
							href={`/websites-legacy/${id}/edit?tab=cms`}
						>
							<StatusMark
								styles={{ marginRight: "10px" }}
								enabled={enabled}
							/>
							{WEBSITE_MODULES_FILTER_OPTIONS[1].name}
						</a>
					</Element>
					<Element>
						<a
							data-qa="link-inbox"
							href={`/websites-legacy/${id}/edit?tab=inbox`}
						>
							<StatusMark
								styles={{ marginRight: "10px" }}
								enabled={isInboxEnabled}
							/>
							{WEBSITE_MODULES_FILTER_OPTIONS[2].name}
						</a>
					</Element>
					<Element>
						<a
							data-qa="link-yield"
							href={`/websites-legacy/${id}/edit?tab=yield`}
						>
							<StatusMark
								styles={{ marginRight: "10px" }}
								enabled={gbiEnabled}
							/>
							{WEBSITE_MODULES_FILTER_OPTIONS[3].name}
						</a>
					</Element>

					<Title data-qa="header-website">Website</Title>
					<Element data-qa="website-link">
						<a
							href={`https://${website}`}
							target="_blank"
							rel="noreferrer"
						>
							{website}
						</a>
					</Element>

					<Title data-qa="header-client-type">Client Type</Title>
					<Element data-qa="contract-type">{contractType}</Element>
					<Element data-qa="industry-type">{industryType}</Element>
					<Element data-qa="vertical-type">{verticalType}</Element>

					{goLiveDate && (
						<>
							<Title data-qa="header-go-live">Go live date</Title>
							<Element data-qa="go-live-date">
								{new Date(
									goLiveDate.toString().replace(/-/g, "/")
								).toDateString()}
							</Element>
						</>
					)}

					<Title data-qa="header-site-id">Site ID</Title>
					<Element data-qa="site-id">{id}</Element>
				</InfoContainer>
			)}
		</>
	);
};

const InfoContainer = styled.div`
	margin-top: 44px;
`;

const Title = styled.h1`
	font-size: 14px;
	font-weight: 600;
	margin: 22px 0 5px;
`;

const Element = styled.div`
	margin: 5px 0;
	font-size: 12px;
`;

export default RightInfoBlock;
