import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import {
	SidebarButton,
	PopupModal,
	SidebarPagination,
	Button,
	ResultsNumber,
	Header,
	Wrapper,
} from "../../components";

import { useBindValueToParam, useDebouncedValue } from "../../hooks";
import { getFonts } from "../../redux/fonts/thunks";
import { clearFontsApiError } from "../../redux/fonts/slice";
import { setPageParams } from "../../redux/page/slice";

import { FontsFilters, FontsBlock, NewFontButton } from "./components";

const initParams = {
	archived: 0,
	limit: 20,
	page: 1,
	q: "",
	sort: "edited",
	website_id: "",
};

const FontsPage = () => {
	const dispatch = useDispatch();

	const { isLoading, isFetchError, response } = useSelector((state) => state.fonts);

	const {
		params: { websiteId },
	} = useSelector((state) => state.page);

	const id = websiteId ? websiteId : initParams.website_id;
	const { data } = response;

	const totalRows = response?.meta?.total_rows ? response?.meta?.total_rows : 0;
	const [pageNumber, setPageNumber] = useState(initParams.page);
	const [searchStr, setSearchStr] = useState(initParams.q);
	const [sort, setSort] = useState(initParams.sort);
	const [archived, setArchived] = useState(initParams.archived);
	const [resultsOnPage, setResultsOnPage] = useState(initParams.limit);

	const refreshFonts = async () => {
		await dispatch(
			getFonts({
				...initParams,
				website_id: id,
				sort: sort,
				archived: archived,
				page: pageNumber,
				q: searchStr,
				limit: resultsOnPage,
			})
		);
	};

	useEffect(() => {
		refreshFonts();
	}, [pageNumber, searchStr, sort, archived, resultsOnPage, websiteId]);

	const hasApiError = isFetchError;

	const filterOnChange = (event) => {
		switch (event.type) {
			case "sortBy":
				setSort(event.value);
				break;
			case "searchStr":
				setSearchStr(event.value);
				break;
			case "archived":
				setArchived(event.value);
				break;
		}
	};

	const clearApiErrors = () => {
		dispatch(clearFontsApiError());
	};

	const deleteWebsiteId = () => {
		const url = window.location.pathname;
		const urlParams = new URLSearchParams(window.location.search);
		urlParams.delete("website_id");
		const newUrl = `${url}?${urlParams.toString()}`;
		window.history.pushState({ path: newUrl }, "", newUrl);
		dispatch(setPageParams({ websiteId: "" }));
	};

	const resetFilters = () => {
		setPageNumber(initParams.page);
		setSearchStr(initParams.q);
		setArchived(initParams.archived);
		setResultsOnPage(initParams.limit);
		setSort(initParams.sort);
		deleteWebsiteId();
	};

	useBindValueToParam("q", initParams.q, searchStr, setSearchStr);
	useBindValueToParam("page", initParams.page, pageNumber, setPageNumber);
	useBindValueToParam("archived", initParams.archived, archived, setArchived);
	useBindValueToParam("sort", initParams.sort, sort, setSort);
	useBindValueToParam("limit", initParams.limit, resultsOnPage, setResultsOnPage);

	return (
		<Wrapper
			left="0"
			maxWidth="100%"
			style={{
				display: "flex",
				justifyContent: "space-between",
				bottom: "0",
			}}
			title="Fonts"
			pageTitle="Fonts"
		>
			<Aside disabled={isLoading}>
				<NoPaddingContainer>
					<SidebarButton />
					<SidebarPagination
						pageNumber={pageNumber}
						resultsOnPage={resultsOnPage}
						setPageNumber={setPageNumber}
						totalRows={totalRows}
					/>
				</NoPaddingContainer>
				<FontsFilters
					onChange={filterOnChange}
					searchValue={searchStr}
					sortValue={sort}
					archivedValue={archived}
					setArchived={setArchived}
				/>

				<ResultsNumber
					totalRows={totalRows}
					onChange={setResultsOnPage}
					resultsNumber={resultsOnPage}
				/>
				<Button fullWidth theme="blue" dataQA="reset-button" onClick={resetFilters}>
					Reset
				</Button>
			</Aside>
			<Main>
				<PopupModal showModal={hasApiError} toggleModal={clearApiErrors}>
					Something went wrong!
				</PopupModal>
				<Header leftComponent={<NewFontButton />} />
				<FontsBlock isLoading={isLoading} data={data} />
			</Main>
		</Wrapper>
	);
};

const Aside = styled.aside`
	width: 270px;
	background-color: #ffffff;
	overflow-y: auto;
	padding: 22px;
	opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
	&::-webkit-scrollbar {
		display: none;
	}
`;

const Main = styled.main`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

const NoPaddingContainer = styled.div`
	margin: -22px -22px 0;
`;

export default FontsPage;
