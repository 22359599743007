import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Section, FormInput } from "../../../../../../components/form";
import { useSelectInboxCampaigns } from "../../../../../../hooks/selectors/useSelectInboxCampaigns";
import { updateInboxCampaign } from "../../../../../../redux/inboxCampaigns/thunks";
import { Spacer } from "./utils/Sections.styled";

const Masthead = () => {
	const dispatch = useDispatch();
	const {
		params: { campaignId },
	} = useSelector((state) => state.page);

	const campaign = useSelector(useSelectInboxCampaigns)?.[campaignId];

	const initialState = {
		attributes: {
			"email-from-address": campaign?.attributes?.["email-from-address"],
			"email-from-name": campaign?.attributes?.["email-from-name"],
			"email-reply-to-address": campaign?.attributes?.["email-reply-to-address"],
		},
	};
	const [mastHeadData, setMastHeadData] = useState(initialState);

	const handleChange = (value, attribute) => {
		setMastHeadData({
			...mastHeadData,
			attributes: {
				...mastHeadData.attributes,
				[attribute]: value,
			},
		});
	};

	useEffect(() => {
		dispatch(
			updateInboxCampaign({
				...mastHeadData,
				id: campaignId,
			})
		);
	}, [mastHeadData]);

	// TODO: Create//Add DiffWrapper for Inputs and add them to the below inputs
	const dataRef = useRef(initialState);

	return (
		<Section title="Masthead">
			<Spacer>
				<FormInput
					value={mastHeadData?.attributes?.["email-from-name"]}
					onChange={(value) => handleChange(value, "email-from-name")}
					label="From Name"
					placeholder=""
					width="100%"
				/>
				<FormInput
					value={mastHeadData?.attributes?.["email-from-address"]}
					onChange={(value) => handleChange(value, "email-from-address")}
					label="From Email"
					placeholder=""
					width="100%"
				/>
				<FormInput
					value={mastHeadData?.attributes?.["email-reply-to-address"]}
					onChange={(value) => handleChange(value, "email-reply-to-address")}
					label="Reply-To Email"
					placeholder=""
					width="100%"
				/>
			</Spacer>
		</Section>
	);
};

export default Masthead;
