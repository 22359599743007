export const PAGE_MAP = {
	REPORTS: "REPORTS",
	STATS: "STATS",
	EXIT: "EXIT",
	TOOLS: "TOOLS",
	USERS: "USERS",
	ESPS: "ESPS",
	ESP: "ESP",
	UPLOADS: "UPLOADS",
	TOP_STATS: "TOP_STATS",
	WEBSITES: "WEBSITES",
	INBOX_SERIES: "INBOX_SERIES",
	INBOX_SERIES_EDIT: "INBOX_SERIES_EDIT",
	INBOX_CAMPAIGN_EDIT: "INBOX_CAMPAIGN_EDIT",
	INBOX_CAMPAIGN_DESIGN: "INBOX_CAMPAIGN_DESIGN",
	CMS_SERIES: "CMS_SERIES",
	CMS_SERIES_EDIT: "CMS_SERIES_EDIT",
	CMS_CAMPAIGN_EDIT: "CMS_CAMPAIGN_EDIT",
	CMS_CAMPAIGN_DESIGN: "CMS_CAMPAIGN_DESIGN",
	FONTS: "FONTS",
	WEBSITES_SETTINGS: "WEBSITES_SETTINGS",
	CUSTOMER_REGISTRATION: "CUSTOMER_REGISTRATION",
	LOGIN: "LOGIN",
	SIGNOUT: "SIGNOUT",
	ESP_PROFILES: "ESP_PROFILES",
};
