import { createSlice } from "@reduxjs/toolkit";
import espsAdapter from "./espsAdapter";
import { getESPs } from "./thunks";

const initialState = {
	isLoading: false,
	response: {
		data: espsAdapter.getInitialState(),
	},
};

const espsSlice = createSlice({
	name: "esps",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// getESPs
		builder.addCase(getESPs.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getESPs.fulfilled, (state, action) => {
			state.isLoading = false;
			espsAdapter.setAll(state.response.data, action.payload.data);
		});
		builder.addCase(getESPs.rejected, (state) => {
			state.isLoading = false;
			espsAdapter.removeAll(state);
		});
	},
});

export default espsSlice.reducer;
