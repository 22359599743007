import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ResultsNumber = ({ onChange, resultsNumber, totalRows }) => {
	const changeHandler = ({ target }) => {
		if (!target.value) {
			// if target.value is empty default to 1
			onChange(1);
		} else {
			onChange(parseInt(target.value));
		}
	};

	return (
		<ResultsNumberContainer data-qa="results-input-container">
			<label>Results Per Page</label>
			<Input
				type="number"
				min="1"
				max={totalRows}
				onChange={changeHandler}
				placeholder="20"
				value={resultsNumber}
				data-qa="results-input"
			/>
		</ResultsNumberContainer>
	)
};

ResultsNumber.propTypes = {
	onChange: PropTypes.func.isRequired,
	resultsNumber: PropTypes.number.isRequired,
	totalRows: PropTypes.string,
};

const ResultsNumberContainer = styled.div`
	margin: 11px 0;
`;

const Input = styled.input`
	width: 100%;
	&:focus {
		border-color: #303D78;
		color: #191919;
		outline: none;
		background: none;
		&::placeholder {
			color: inherit;
		}
	}
`;

export default ResultsNumber;
