import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import { INBOX_URL, INBOX_CAMPAIGNS_URL, INBOX_PURPOSE_URL } from "./constants";

export const getPreview = createAsyncThunk(
	"inbox/getPreview",
	async ({ campaignId, nodeEts, testItems }) => {
		const url = `${endpoints.legacyApiDomain}${INBOX_URL}/preview_email?campaign_id=${campaignId}&nodeets=${nodeEts}&test_items=${testItems}`;
		const response = await ApiService.get(url);

		if (!response.ok) {
			throw new Error("Failed to load preview!");
		}

		return response.text();
	}
);

export const getInboxCampaigns = createAsyncThunk(
	"inbox/getInboxCampaigns",
	async (params = {}) => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}`;
		const response = await ApiService.getWithSearchParams(url, params);

		if (!response.ok) {
			throw new Error("Failed to load inbox campaigns!");
		}

		return response.json();
	}
);

export const updateInboxActivestatus = createAsyncThunk(
	"campaigns/updateInboxActivestatus",
	async ({ archived, ids, status, website_id }) => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}/update_status`;
		const valueIds = ids.join("&ids[]=");
		const body = `archived=${archived}&ids[]=${valueIds}&status=${status}&website_id=${website_id}`;
		const response = await ApiService.postAsForm(url, { payload: body });

		if (!response.ok) {
			throw new Error(`Failed to ${status} campaign!`);
		}

		return response.json();
	}
);

export const campaignsSetOrder = createAsyncThunk(
	"inbox/campaignsSetOrder",
	async ({ website_id, campaign_ids }) => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}/set_order`;
		const paramsIds = campaign_ids.reduce((params, value) => {
			return `${params}&campaign_ids[]=${value}`;
		}, "");
		const body = `website_id=${website_id}${paramsIds}`;
		const response = await ApiService.postAsForm(url, { payload: body });

		if (!response.ok) {
			throw new Error("Failed to set inbox campaigns order!");
		}

		return response.json();
	}
);

export const getInboxPurpose = createAsyncThunk("inbox/getInboxPurpose", async (params = {}) => {
	const url = `${endpoints.coreApi}${INBOX_PURPOSE_URL}`;
	const response = await ApiService.get(url, params);

	if (!response.ok) {
		throw new Error("Failed to load inbox purposes!");
	}

	return response.json();
});
