//Anaytics 6 endpoint
import { createComparisonData } from "../../businessLogic";
import reportsAdapter from "../reportsAdapter";

export const loadComparisonData = (state, action) => {
	const { payload } = action;
	const reportType = payload.type;
	const title = "";
	const data = createComparisonData(reportType, payload.data);
	const totalNumOfData = 0;

	// update State
	state.title = title;
	state.makeApiCall = false;
	state.totalNumOfData = totalNumOfData;
	state.next_date = payload.next_date;
	reportsAdapter.addMany(state.data, data);
};
