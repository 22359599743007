import { createSelector } from "@reduxjs/toolkit";
import { sortVariations } from "./utils";
import { reportTypes } from "../../constants/common";
import { formatPercentages, formatNumber } from "../../pages/reports/components/tables/components/utils";

export const selectReportOptions = (state) => state.report.reportOptions;

export const selectComparisonTableData = createSelector(
	(state) => state.report.dataQl,
	(state) => state.report.reportOptions.only_on_campaigns,
	(state) => state.report.reportOptions.campaign_sorting,
	(state) => state.report.reportOptions.campaign_group_controls,
	(state) => state.report.reportOptions.conv_goals,
	(state) => state.page.params.type,
	(data, only_on_campaigns, campaign_sorting, campaign_group_controls, conv_goals, reportType) => {
		let campaigns = [];
		let overallCampaigns = [];

		// Prepare data based on report type
		if (reportType === reportTypes.websiteComparison) {
			campaigns = data?.website?.onsiteProduct?.campaigns || [];
			overallCampaigns = data?.overall?.onsiteProduct?.campaigns || [];
		}

		if (reportType === reportTypes.parentCampaignComparison) {
			const { campaign } = data?.website?.onsiteProduct || {};
			const overallCampaign = data?.overall?.onsiteProduct?.campaign;
			campaigns = campaign ? [campaign] : [];
			overallCampaigns = overallCampaign ? [overallCampaign] : [];
		}

		if (reportType === reportTypes.childCampaignComparison) {
			const { campaign, variation } = data?.website?.onsiteProduct || {};
			const overallCampaign = data?.overall?.onsiteProduct?.campaign;
			const overallVariation = data?.overall?.onsiteProduct?.variation;
			campaigns = campaign && variation ? [{ ...campaign, variations: [variation] }] : [];
			overallCampaigns = overallCampaign && overallVariation
				? [{ ...overallCampaign, variations: [overallVariation] }]
				: [];
		}

		return campaigns
			.filter((campaign) => !only_on_campaigns || campaign.common.status === "ON") // on/off campaigns filter
			.map((campaign) => {
				const overallCampaign = overallCampaigns.find((camp) => campaign.common.id === camp.common.id);
				// Injects overall stats to each variation
				let variations = campaign.variations.map((variation) => ({
					...variation,
					overallStats: overallCampaign.variations.find((overallVar) =>
						overallVar.common.id === variation.common.id).stats,
				}));

				// Combine controls logic
				if (campaign_group_controls) {
					const controlsName = `${campaign.common.purposeName}: Controls`;
					const control = variations.filter((variation) => variation.common.testType === "control");
					variations = variations.filter((variation) => variation.common.testType !== "control");

					if (control.length) {
						variations.push({
							...control[0],
							stats: campaign.combinedControl.stats,
							common: { ...control[0].common, name: controlsName },
							overallStats: overallCampaign.combinedControl.stats,
						});
					}
				}

				// Goals filter logic
				if (conv_goals && conv_goals !== "all_goals") {
					variations = variations.map((variation) => {
						const conversionByGoal = variation.stats.ConversionsByGoal
							.find((conversion) => conversion.Goal === conv_goals);
						const overallConversionByGoal = variation.overallStats.ConversionsByGoal
							.find((conversion) => conversion.Goal === conv_goals);
						return {
							...variation,
							stats: {
								...variation.stats,
								ConversionStats: { ...variation.stats.ConversionStats, ...conversionByGoal?.Stats },
							},
							overallStats: {
								...variation.overallStats,
								ConversionStats: { ...variation.overallStats.ConversionStats, ...overallConversionByGoal?.Stats },
							},
						};
					});
				}

				return { ...campaign, variations: sortVariations(variations, campaign_sorting) };
			});
	},
);

export const selectCampaignTableData = createSelector(
	(state) => state.report.dataQl,
	(state) => state.page.params.type,
	(state) => state.report.reportOptions.date_interval,
	(state) => state.report.reportOptions.date_start,
	(state) => state.report.reportOptions.date_end,
	(data, reportType, interval, startDate, endDate) => {
		const isTotal = interval === "total";
		let series = [];
		let overallSeries = [];

		// Prepare data based on report type
		if (reportType === reportTypes.websiteCampaign) {
			const timeSeries =  data?.website?.onsiteProduct?.timeSeries || [];
			const overallSeies =  data?.overall?.onsiteProduct?.timeSeries || [];
			series = isTotal ? [{ stats: data?.website?.onsiteProduct?.stats }] : timeSeries;
			overallSeries = isTotal ? [{ stats: data?.overall?.onsiteProduct?.stats }] : overallSeies;
		}

		if (reportType === reportTypes.parentCampaign) {
			const timeSeries =  data?.website?.onsiteProduct?.campaign?.timeSeries || [];
			const overallSeies =  data?.overall?.onsiteProduct?.campaign?.timeSeries || [];
			series = isTotal ? [{ stats: data?.website?.onsiteProduct?.campaign?.stats }] : timeSeries;
			overallSeries = isTotal ? [{ stats: data?.overall?.onsiteProduct?.campaign?.stats }] : overallSeies;
		}

		if (reportType === reportTypes.childCampaign) {
			const timeSeries =  data?.website?.onsiteProduct?.variation?.timeSeries || [];
			const overallSeies =  data?.overall?.onsiteProduct?.variation?.timeSeries || [];
			series = isTotal ? [{ stats: data?.website?.onsiteProduct?.variation?.stats }] : timeSeries;
			overallSeries = isTotal ? [{ stats: data?.overall?.onsiteProduct?.variation?.stats }] : overallSeies;
		}


		return [...series].reverse().map((serie) => {
			const overallSerie = overallSeries.find((overallSerie) => overallSerie.date === serie.date);
			const dayName = isTotal ? "Range" : new Date(serie?.date).toLocaleDateString("en-US", { weekday: "long" });
			const serieConversions = serie?.stats?.ConversionStats?.Conversions;
			const overallConversions = overallSerie?.stats?.ConversionStats?.Conversions;
			let dayRange = `${startDate} - ${endDate}`;

			if (!isTotal) {
				const dateParts = serie?.date?.split('T');
				dayRange = interval === "hour" ? `${dateParts[0]} ${dateParts[1]}` : dateParts[0];
			}

			return {
				overall_visits: formatNumber(serie?.stats?.Visits),
				eligible_visits: {
					top: formatNumber(serie?.stats?.Eligibility),
					bottom: formatPercentages(serie?.stats?.EligibilityRate, 2),
				},
				overall_impressions: {
					top: `${formatNumber(serie?.stats?.UniqueImpressions)} / ${formatNumber(serie?.stats?.TotalImpressions)}`,
					bottom: formatPercentages(serie?.stats?.TotalImpressionRate, 2),
				},
				clicks: {
					top: formatNumber(serie?.stats?.TotalClicks),
					bottom: formatPercentages(serie?.stats?.ClickRate, 2),
				},
				closes: {
					top: formatNumber(serie?.stats?.Closes),
					bottom: formatPercentages(serie?.stats?.CloseRate, 2),
				},
				form_submits: {
					top: formatNumber(serie?.stats?.EmailOptIns),
					bottom: formatPercentages(serie?.stats?.EmailOptInRate, 2),
				},
				overall_conversions: {
					top: `${formatNumber(serieConversions)} / ${formatNumber(overallConversions)}`,
					bottom: formatPercentages(serie?.stats?.ConversionStats?.ConversionRate, 2),
				},
				date: { top: dayName, bottom: dayRange },
			};
		});
	},
);

export const selectCampaignTableTitle = createSelector(
	(state) => state.report.dataQl,
	(state) => state.page.params.type,
	(data, reportType) => {
		switch (reportType) {
			case reportTypes.websiteCampaign:
				return data?.website?.name ? `${data?.website?.name} All Campaigns Analytics` : "";

			case reportTypes.parentCampaign:
				return data?.website?.onsiteProduct?.campaign?.common?.name || "";

			case reportTypes.childCampaign:
				return data?.website?.onsiteProduct?.variation?.common?.name || "";

			default:
				return "";
		}
	},
);
