import { createSlice } from "@reduxjs/toolkit";
import { getPreview, getInboxCampaigns, campaignsSetOrder } from "./thunks";
import inboxAdapter from "./inboxAdapter";
import childrenCampaignsAdapter from "./childrenCampaignsAdapter";

const initialState = {
	isLoading: false,
	isFetchError: false,
	response: {
		data: inboxAdapter.getInitialState(),
		meta: {},
		included: childrenCampaignsAdapter.getInitialState(),
	},
	selectedCampaign: [],
};

const inboxSlice = createSlice({
	name: "inbox",
	initialState,
	reducers: {
		clearInboxApiError(state) {
			state.isFetchError = false;
		},
		setSelectedCampaign(state, action) {
			state.selectedCampaigns = [action.payload];
		},
		addSelectedCampaign(state, action) {
			const newCampaign = action.payload;
			const isInSelected = state.selectedCampaigns.some((campaign) => {
				return campaign.id === newCampaign.id;
			});

			if (!isInSelected) {
				state.selectedCampaigns.push(newCampaign);
			}
		},
		clearCampaignsApiError(state) {
			state.isFetchError = false;
		},
	},
	extraReducers: (builder) => {
		// getInboxCampaigns
		builder.addCase(getInboxCampaigns.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getInboxCampaigns.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			inboxAdapter.setAll(state.response.data, action.payload.data);
			childrenCampaignsAdapter.setAll(state.response.included, action.payload.included);
		});
		builder.addCase(getInboxCampaigns.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxAdapter.removeAll(state.response.data);
			childrenCampaignsAdapter.removeAll(state.response.included);
		});
		// getPreview
		builder.addCase(getPreview.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getPreview.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			state.response.included = action.payload.included;
			inboxAdapter.setMany(state.response.data, action.payload);
		});
		builder.addCase(getPreview.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxAdapter.removeAll(state.response.data);
		});
		// campaignsSetOrder
		builder.addCase(campaignsSetOrder.fulfilled, (state, action) => {
			const parentCampaigns = [...action.payload.data];
			parentCampaigns.sort((a, b) => {
				return parseInt(a.attributes.order) - parseInt(b.attributes.order);
			});

			inboxAdapter.setAll(state.response.data, parentCampaigns);
			childrenCampaignsAdapter.setAll(state.response.included, action.payload.included);
		});
	},
});

export const {
	clearCampaignsApiError,
	clearInboxApiError,
	setSelectedCampaign,
	addSelectedCampaign,
} = inboxSlice.actions;

export default inboxSlice.reducer;
