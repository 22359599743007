import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { TrafficTypeSwitcher, Search, Select } from "../../../components";

const InboxFilters = ({ onChange, trafficTypeValue, searchValue, purposeValue, typeValue }) => {
	const {
		purposes: {
			response: { data: purposes = [] },
		},
		campaignTypes: {
			response: { data: campaignTypes = [] },
		},
	} = useSelector((state) => state);

	const getPurposeOptions = () => {
		let options = [{ id: "", name: "All Purposes" }];
		let onlyInboxPurposes = purposes.filter(
			({ attributes = {}, type = "" }) =>
				(attributes.product === "inbox" || attributes.product === "all") &&
				type === "campaign_purposes"
		);

		let newArray = onlyInboxPurposes.map((el) => {
			let val = {};
			val.id = el.id;
			val.name = el.attributes.name;
			return val;
		});

		options.push(...newArray);
		return options;
	};

	const getTypeOptions = () => {
		let options = [{ id: "", name: "All Campaign Types" }];

		let onlyCampaignTypes = campaignTypes.filter(
			({ attributes = {}, type = "" }) =>
				(attributes.product === "inbox" || attributes.product === "all") &&
				type === "campaign_types"
		);

		let newArray = onlyCampaignTypes.map((el) => {
			let val = {};
			val.id = el.id;
			val.name = el.attributes.name;
			return val;
		});

		options.push(...newArray);
		return options;
	};

	const memoizedTypeOptions = useMemo(() => getTypeOptions(), [campaignTypes]);

	const memoizedPurposeOptions = useMemo(() => getPurposeOptions(), [purposes]);

	return (
		<FiltersContainer>
			<label>Filters</label>
			<TrafficTypeSwitcher
				onChange={(value) => onChange({ type: "trafficType", value })}
				value={trafficTypeValue}
			/>
			<Search
				value={searchValue}
				onChange={(value) => onChange({ type: "searchStr", value })}
				placeholder="Search..."
			/>
			<Select
				dataQA="purpose-dropdown"
				data={memoizedPurposeOptions}
				value={purposeValue}
				onChange={(value) => onChange({ type: "purpose", value })}
			/>

			<Select
				dataQA="type-dropdown"
				data={memoizedTypeOptions}
				value={typeValue}
				onChange={(value) => onChange({ type: "type", value })}
			/>
		</FiltersContainer>
	);
};

const FiltersContainer = styled.div`
	margin: 11px 0;
	box-sizing: border-box;
	& > div:not(:first-of-type) {
		margin: 11px 0;
	}
`;

InboxFilters.propTypes = {
	onChange: PropTypes.func.isRequired,
	trafficTypeValue: PropTypes.arrayOf(PropTypes.oneOf(["on", "off", "test", "wip"])).isRequired,
	purposeValue: PropTypes.string.isRequired,
	searchValue: PropTypes.string.isRequired,
};

export default InboxFilters;
