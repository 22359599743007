const safeJSONParse = (stringToParse) => {
	let parsed;

	try {
		parsed = JSON.parse(stringToParse);
	} catch (e) {
		console.error(e);
		parsed = {};
	}

	return parsed;
};

const convertFieldsObjectToArray = (fieldsObject) => {
	return Object.keys(fieldsObject)
		.map((key) => {
			const field = fieldsObject[key];
			const datum = {
				name: key,
				type: field.type,
				required: field.required,
			};

			if (field.choices) {
				datum.choices = field.choices.join(", ");
			}

			return datum;
		});
};

export const getUpdatedResponse = (response) => {
	const updatedResponse = { ...response };
	const parsedFields = safeJSONParse(updatedResponse.data.attributes.fields);
	updatedResponse.data.attributes.fields = convertFieldsObjectToArray(parsedFields);
	return updatedResponse;
};

export const convertFieldsArrayToObject = (fields) => {
	return fields
		.filter((field) => field.name)
		.reduce((dictionary, field) => {
			dictionary[field.name] = {
				type: field.type,
				required: field.required,
			};

			if (field.choices) {
				dictionary[field.name].choices = field.choices.split(", ");
			}

			return dictionary;
		}, {});
};

export const getUpdatedEsp = (submitData, esp) => {
	const updatedEsp = { ...esp };
	const fieldsObject = convertFieldsArrayToObject(submitData.fields || []);

	updatedEsp.attributes = {
		...updatedEsp.attributes,
		name: submitData.name,
		type: submitData.type,
		active: submitData.active,
		fields: JSON.stringify(fieldsObject),
	};

	return updatedEsp;
};
