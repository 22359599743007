import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import { kebabize } from "../../utils";
import {
	WEBSITES_URL,
	WEBSITE_URL_PARAMS,
	NEW_WEBSITE_DEFAULTS,
	WEBSITE_RELATIONSHIPS,
} from "./constants";

import { createInboxCSVItems, buildDownloads, createOnsiteCSVItems } from "./utils";

export const getWebsites = createAsyncThunk(
	"websites/getWebsites",
	async ({ params = {}, firstAsSelected }) => {
		const url = `${endpoints.coreApi}${WEBSITES_URL}`;
		const searchParams = { ...WEBSITE_URL_PARAMS, ...params };
		const response = await ApiService.getWithSearchParams(url, searchParams);

		if (response.status === 200) {
			const json = await response.json();

			return { json, firstAsSelected };
		}

		throw new Error("Failed to load websites!");
	}
);

export const getWebsite = createAsyncThunk("websites/getWebsite", async (websiteId) => {
	const url = `${endpoints.coreApi}${WEBSITES_URL}/${websiteId}`;
	const response = await ApiService.get(url);

	if (response.status === 200) {
		const json = await response.json();

		return json;
	}

	throw new Error("Failed to load website!");
});

export const createNewWebsite = createAsyncThunk(
	"websites/createNewWebsite",
	async ({ name, website, industryId, notSaveInStore }) => {
		const url = `${endpoints.coreApi}${WEBSITES_URL}`;
		const industry = { data: { id: industryId, type: "industries" } };
		//INFO: we have to kebabize payload according to core-api
		const payload = kebabize({
			data: {
				attributes: { ...NEW_WEBSITE_DEFAULTS, name, website },
				relationships: { ...WEBSITE_RELATIONSHIPS, industry },
			},
		});
		const response = await ApiService.post(url, { payload });

		if (!response.ok) {
			throw new Error("Failed to create website!");
		}

		const json = await response.json();

		return { json, notSaveInStore };
	}
);

export const downloadInboxCampaigns = createAsyncThunk("websites/downloadInboxCampaigns", async(website) => {
	const url = `${endpoints.coreApi}/inbox_campaigns?`;
	const body = `${url}website_id=${website.id}&campaign_type_id[]=9&campaign_type_id[]=10&&status[]=on&status[]=test&status[]=wip&fields[inbox_campaigns]=id,name,test_type,wip_mode,test_mode,enabled`;

	const response = await ApiService.get(body);
	const json = await response.json();

	const dataIds = json.data.filter((data) => data.attributes["test-type"] === "variation").map((data) => (data.id));
	const dataMap = {};

	json.data.forEach((item) => {
		dataMap[item.id] = item;
	});

	const chunkSize = 10;
	let chunkedCalls = [];

	for (let i = 0; i < dataIds.length; i += chunkSize) {
		chunkedCalls.push(dataIds.slice(i, i + chunkSize));
	}

	let allResults = [];

	for (let j = 0; j < chunkedCalls.length; j++) {
		const chunkedArrays = chunkedCalls[j];
		const chunkedResults = chunkedArrays.map((id) => {
			const legacyAPI = `${endpoints.legacyApiDomain}`;
			const legacyURL = `${legacyAPI}/inbox/preview_email?campaign_id=${id}&json=1`;
			return ApiService.get(legacyURL);
		});

		const results = await Promise.allSettled(chunkedResults);
		allResults = [...allResults, ...results];
	}

	const csvItems = await createInboxCSVItems(allResults, dataMap);
	await buildDownloads(csvItems, website.attributes.name, "Inbox");
});

export const downloadOnsiteCampaigns = createAsyncThunk("websites/downloadOnsiteCampaigns", async(website) => {
	const dataurl = `${endpoints.coreApi}/campaigns?website_id=${website.id}&parents_only=0&status[]=on&status[]=test&status[]=wip&fields[campaigns]=id,name,test_type,wip_mode,test_mode,enabled`;

	const response = await ApiService.get(dataurl);
	const json = await response.json();

	const { website: websiteUrl, name } = website.attributes;

	const websiteName = `https://${websiteUrl}`;

	const dataIds = json.data.filter((data) => data.attributes["test-type"] === "variation").map((data) => (data.id));
	const dataMap = {};

	json.data.forEach((item) => {
		dataMap[item.id] = item;
	});

	const chunkSize = 10;
	let chunkedCalls = [];
	let allResults = [];

	for (let i = 0; i < dataIds.length; i += chunkSize) {
		chunkedCalls.push(dataIds.slice(i, i + chunkSize));
	}

	for (let j = 0; j < chunkedCalls.length; j++) {
		const chunkedArrays = chunkedCalls[j];
		const chunkedResults = chunkedArrays.map((id) => {
			const url = `${websiteName}?bxtest${id}#wkndtest`;
			return {
				id,
				url
			}
		});

		allResults = [...chunkedResults];
	}

	const csvItems = await createOnsiteCSVItems(allResults, dataMap);
	await buildDownloads(csvItems, name, "Onsite");
});
