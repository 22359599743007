import { createAsyncThunk } from "@reduxjs/toolkit";
import fetch from "isomorphic-fetch";

import { endpoints } from "../../constants/common";
import { LocalStorageService } from "../../service/localStorage";

export const getESPs = createAsyncThunk(
	"esps/getESPs",
	async () => {
		const url = `${endpoints.coreApi}/esps`;

		const token = LocalStorageService.getToken();

		const response = await fetch(url, {
			method: "GET",
			cache: "no-cache",
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});

		return response.json();
	}
);
