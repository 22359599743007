import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const SearchableSelect = ({ label, placeholder, value, options, onChange, showId = false, showValue = false }) => {
	const [isActive, setIsActive] = useState(false);
	const [matchItem, setMatchItem] = useState(undefined);
	const [searchStr, setSearchStr] = useState("");
	const [filteredItems, setFilteredItems] = useState([]);
	const inputRef = useRef(null);

	useEffect(() => {
		const matchItem = options.find((item) => item.value === value);

		setMatchItem(matchItem);
	}, [value, options]);

	useEffect(() => {
		const filteredItems = options.filter((item) =>
			item.label.toLowerCase().includes(searchStr.toLowerCase())
		);

		setFilteredItems(filteredItems);
	}, [searchStr, options]);

	const activate = () => setIsActive(true);

	const deactivate = () => {
		setIsActive(false);
		setSearchStr("");
	};

	const onInputChangeHandler = (event) => setSearchStr(event.target.value);

	const getInputValue = () => {
		if (!isActive) {
			return (showValue && matchItem) ?
				`${matchItem.value} - ${matchItem.label}` :
				matchItem ? matchItem.label : "";
		}

		return searchStr;
	};

	const pickItem = (itemValue) => {
		onChange(itemValue);
		inputRef.current.blur();
	};

	return (
		<MainContainer>
			<label>{label}</label>

			<SearchInput
				placeholder={placeholder}
				isActive={isActive}
				ref={inputRef}
				value={getInputValue()}
				onFocus={activate}
				onBlur={deactivate}
				onChange={onInputChangeHandler}
				data-qa="search-input"
			/>
			<Icon className="icon-angle-down" />
			{isActive && (
				<ListContainer>
					{filteredItems.map((item) => (
						<ListItem
							key={item.value}
							onMouseDown={(event) => event.preventDefault()} //INFO: prevent unwanted onBlur
							onClick={() => pickItem(item.value)}
						>
							{item.label} {showId && <IdLabel>{item.value}</IdLabel>}
						</ListItem>
					))}
				</ListContainer>
			)}
		</MainContainer>
	);
};

const MainContainer = styled.div`
	position: relative;
`;

const IdLabel = styled.span`
	font-size: 9px;
	margin-left: 13px;
	color: #9d9a9a;
`;

const ListContainer = styled.div`
	position: absolute;
	max-height: 320px;
	width: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	box-shadow: 0 7px 9px rgb(0 0 0 / 20%);
	background: #fff;
	z-index: 5;
	background: #fff;
`;

const ListItem = styled.div`
	display: flex;
	align-items: center;
	padding-left: 11px;
	font-size: 12px;
	height: 42px;
	border-bottom: 1px solid rgb(232, 232, 232);
	cursor: pointer;
	&:hover {
		background-color: #e6f5ff;
	}
`;

const SearchInput = styled.input`
	width: 100%;
	height: 42px;
	padding: 11px 26px 11px 11px;
	border: ${({ isActive }) =>
		isActive ? "1px solid rgb(48, 61, 120)" : "1px solid rgb(232, 232, 232)"};
	font-size: 12px;
	&:focus {
		outline: none;
	}
`;

const Icon = styled.i`
	position: absolute;
	display: flex;
	align-items: center;
	height: 42px;
	bottom: 0;
	right: 10px;
	cursor: text;
	pointer-events: none;
`;

SearchableSelect.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.string,
		})
	).isRequired,
	onChange: PropTypes.func.isRequired,
	showId: PropTypes.bool,
	showValue: PropTypes.bool,
};

export default SearchableSelect;
