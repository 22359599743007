import { createEntityAdapter } from "@reduxjs/toolkit";

const inboxSeriesAdapter = createEntityAdapter({
	selectId: (inboxSeries) => inboxSeries.id,
});

export const {
	selectAll: selectAlInboxSeries,
} = inboxSeriesAdapter.getSelectors((state) => state.websites.response.data);

export default inboxSeriesAdapter;
