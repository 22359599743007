import React from "react";
import { useSelector } from "react-redux";
import {
	Reports,
	Tools,
	Users,
	Esps,
	Esp,
	Uploads,
	TopStats,
	Websites,
	CMSSeries,
	CMSSeriesEdit,
	CMSCampaignEdit,
	CMSCampaignDesign,
	CustomerRegistration,
	Fonts,
	WebsiteSettings,
	InboxEntry,
	InboxSeries,
	SignOut,
	ESPProfiles,
} from "./pages";
import { PAGE_MAP } from "./redux/page/constants";

export default () => {
	const page = useSelector((state) => state.page.page);
	switch (page) {
		case PAGE_MAP.REPORTS:
			return <Reports />;
		case PAGE_MAP.TOOLS:
			return <Tools />;
		case PAGE_MAP.USERS:
			return <Users />;
		case PAGE_MAP.ESPS:
			return <Esps />;
		case PAGE_MAP.ESP:
			return <Esp />;
		case PAGE_MAP.UPLOADS:
			return <Uploads />;
		case PAGE_MAP.TOP_STATS:
			return <TopStats />;
		case PAGE_MAP.WEBSITES:
			return <Websites />;
		case PAGE_MAP.INBOX_SERIES:
			return <InboxSeries />;
		case PAGE_MAP.WEBSITES_SETTINGS:
			return <WebsiteSettings />;
		case PAGE_MAP.INBOX_SERIES_EDIT:
		case PAGE_MAP.INBOX_CAMPAIGN_EDIT:
		case PAGE_MAP.INBOX_CAMPAIGN_DESIGN:
			return <InboxEntry />;
		case PAGE_MAP.CMS_SERIES:
			return <CMSSeries />;
		case PAGE_MAP.CMS_SERIES_EDIT:
			return <CMSSeriesEdit />;
		case PAGE_MAP.CMS_CAMPAIGN_EDIT:
			return <CMSCampaignEdit />;
		case PAGE_MAP.CMS_CAMPAIGN_DESIGN:
			return <CMSCampaignDesign />;
		case PAGE_MAP.CUSTOMER_REGISTRATION:
			return <CustomerRegistration />;
		case PAGE_MAP.FONTS:
			return <Fonts />;
		case PAGE_MAP.SIGNOUT:
			return <SignOut />;
		case PAGE_MAP.ESP_PROFILES:
			return <ESPProfiles />;
		default:
			return <span></span>;
	}
};
