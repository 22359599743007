import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import { INBOX_CAMPAIGN_URL } from "./constants";

export const getInboxType = createAsyncThunk("inbox/getInboxCampaign", async (params = {}) => {
	const url = `${endpoints.coreApi}${INBOX_CAMPAIGN_URL}`;
	const response = await ApiService.get(url, params);
	if (!response.ok) {
		throw new Error("Failed to load inbox purposes!");
	}

	return response.json();
});
