import { createEntityAdapter } from "@reduxjs/toolkit";

const creativesAdapter = createEntityAdapter({
	selectId: (creative) => creative.data.id,
});

export const {
	selectAll: selectAllCreatives,
	selectIds: selectCreativesIds,
	selectById: selectCreativeById,
} = creativesAdapter.getSelectors((state) => state.creatives.response.data);

export default creativesAdapter;
