import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { createNewInboxSeries } from "../../../redux/inboxSeries/thunks";

import { Button, Checkbox, SimpleText } from "../../../components";
import { FormInput, Dropdown } from "../../../components/form";

import { testTypes } from "../constants";

const NEW_INBOX_SERIES_SELECTOR = "0";

const newInboxSeriesForm = ({ closeForm, websiteId }) => {
	const [seriesInboxName, setSeriesInboxName] = useState("New inbox series"); // Done
	const [leaderName, setLeaderName] = useState("New welcome email"); // Done
	const [campaignPurpose, setCampaignPurpose] = useState(""); // Done
	const [variationName, setVariationName] = useState("New welcome variation"); // Done
	const [emailType, setEmailType] = useState(""); // Done
	const [createNewVariation, setCreateNewVariation] = useState(true); // Done
	const [testType, setTestType] = useState("");
	const [clickParams, setClickParams] = useState("");

	const {
		purposes: {
			response: { data: purposes = [] },
		},
		campaignTypes: {
			response: { data: campaignTypes = [] },
		},
	} = useSelector((state) => state);

	const isCreateInboxDisabled = createNewVariation
		? !seriesInboxName ||
		  !leaderName ||
		  !campaignPurpose ||
		  !variationName ||
		  !emailType ||
		  !testType
		: !seriesInboxName || !leaderName || !campaignPurpose;

	const dispatch = useDispatch();

	const onlyInboxPurposes = [{ value: "", name: "Select Purpose..." }].concat(
		purposes
			.filter(
				({ attributes = {} }) =>
					(attributes["industry-id"] === NEW_INBOX_SERIES_SELECTOR &&
						attributes["product"] === "inbox") ||
					attributes["product"] === "all"
			)
			.map(({ attributes }) => {
				return {
					value: attributes?.id,
					name: attributes?.name,
				};
			})
	);

	useEffect(() => {
		if (campaignPurpose) {
			let codeName = onlyInboxPurposes
				.filter((item) => item.value === campaignPurpose)[0]
				.name.replace(/\s/g, "")
				.toLowerCase();

			setClickParams(["&utm_campaign=email", codeName, "1_of_1"].join("_"));
		}
	}, [campaignPurpose]);

	const campaignTypesInbox = [{ value: "", name: "Select type..." }].concat(
		campaignTypes
			.filter(
				({ attributes = {} }) =>
					(attributes["product"] === "inbox" || attributes["product"] === "all") &&
					attributes["short-name"] !== "bPush"
			)
			.map(({ attributes }) => {
				return {
					value: attributes?.id,
					name: attributes?.name,
				};
			})
	);

	const createNewInboxSeriesButton = async () => {
		const response = await dispatch(
			createNewInboxSeries({
				parentName: leaderName,
				campaignPurposeId: campaignPurpose,
				product: "inbox",
				seriesInboxName: seriesInboxName,
				websiteId: websiteId,
				emailType: emailType,
				variationName: variationName,
				isChild: createNewVariation,
				testType: testType,
				clickParams: clickParams,
			})
		);

		const siteId = response?.payload?.data?.id;

		if (siteId) {
			window.location.href = `/experiences/inbox/${siteId}/edit`;
		}

		closeForm();
	};

	return (
		<FormContainer>
			<Label>New Series</Label>
			<FormInput
				dataQA="series-inbox-name"
				value={seriesInboxName}
				onChange={setSeriesInboxName}
				label="Series Name"
				placeholder="example.com"
			/>

			<Label>New Behavorial Logic</Label>
			<FormInput
				dataQA="leader-name"
				value={leaderName}
				onChange={setLeaderName}
				label="Leader Name"
				placeholder="example.com"
			/>
			<Dropdown
				width="100%"
				dataQA="select-purpose-dropdown"
				options={onlyInboxPurposes}
				value={campaignPurpose}
				onChange={(e) => {
					setCampaignPurpose(e.id);
				}}
				label="Purpose"
				margin={"5px 0 10px 0"}
				field="id"
				type="select"
			/>
			<CheckBoxContainer>
				<Checkbox
					defaultChecked={createNewVariation}
					onClick={() => setCreateNewVariation(!createNewVariation)}
				/>
				<SimpleText margin="0 0.429rem">Create New Variation</SimpleText>
			</CheckBoxContainer>
			{createNewVariation && (
				<>
					<FormInput
						dataQA="variation-name"
						value={variationName}
						onChange={setVariationName}
						label={
							testType
								? `${testType[0].toUpperCase()}${testType
										.slice(1)
										.toLowerCase()} Name`
								: "Variation Name"
						}
						placeholder="example.com"
					/>
					<Dropdown
						width="100%"
						dataQA="select-email-type-dropdown"
						value={emailType}
						onChange={(e) => {
							setEmailType(e.id);
						}}
						margin={"5px 0 10px 0"}
						label="Email Type"
						field="id"
						type="select"
						options={campaignTypesInbox}
					/>
					<Dropdown
						width="100%"
						dataQA="select-test-type-dropdown"
						value={testType}
						onChange={(e) => {
							setTestType(e.id);
						}}
						margin={"5px 0 10px 0"}
						label="Test Type"
						field="id"
						type="select"
						options={testTypes}
					/>
				</>
			)}
			<ButtonWrapper>
				<Button
					data-qa="create-blank-inbox-series-button"
					disabled={isCreateInboxDisabled}
					onClick={createNewInboxSeriesButton}
					theme="blue"
					fullWidth
				>
					Create
				</Button>
			</ButtonWrapper>
		</FormContainer>
	);
};

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
	max-width: 440px;

	button {
		margin-top: 5px;
	}
`;

const CheckBoxContainer = styled.div`
	display: flex;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
`;

const ButtonWrapper = styled.div`
	width: 230px;
`;

// const SpacerLabel = styled.div`
// 	color: #3a6277;
// 	font-size: 1rem;
// 	font-weight: 700;
// 	text-transform: uppercase;
// 	margin: 30px 0;
// `;

const Label = styled.label`
	display: block;
	margin: 5px 0;
	padding: 0;
	font-size: 12px;
	font-weight: 600;
	width: 100%;
	text-align: left;
	color: #535252;
`;

newInboxSeriesForm.propTypes = {
	closeForm: PropTypes.func.isRequired,
	websiteId: PropTypes.string.isRequired,
};

export default newInboxSeriesForm;
