import fetch from "isomorphic-fetch";
import {
	API_LIMIT,
	endpoints,
	reportTypes,
	backFillApiPath,
	submitIncompletFormApiPath,
} from "../../constants/common";
import { loadingScreen } from "../page/slice";
import { LocalStorageService } from "../../service/localStorage";
import { loadCampaignReports, loadComparison, loadConversion, loadEmail } from "./slice";

export const getReports = (type, website_id, parent_campaign_id, campaign_id, queryParamsObj) => {
	if (!type) {
		console.error("ERROR: try to get a report without a report type");
		return () => {};
	}
	const URL = getURL(type, website_id, parent_campaign_id, campaign_id, queryParamsObj);
	return (dispatch) => {
		dispatch(loadingScreen(true));
		return fetch(URL, {
			method: "GET",
			mode: "cors",
			cache: "no-cache",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${LocalStorageService.getToken()}`,
			},
		})
			.then((response) => {
				if (response.status === 200) {
					return response.json();
				}
				throw response.status;
				//TODO: We have to think about building an error page
			})
			.then((data) => {
				dispatch(
					reduxActionMap[type]({
						...data,
						type,
						website_id,
						parent_campaign_id,
						campaign_id,
					})
				);
				dispatch(loadingScreen(false));
			})
			.catch((errorMsg) => {
				dispatch(loadingScreen(false));
				alert(`ERROR: Unable to create report, ${errorMsg}`);
				console.error(`ERROR: Unable to call API, ${errorMsg}`);
			});
	};
};
export const getURL = (type, website_id, parent_campaign_id, campaign_id, queryParamsObj = {}) => {
	const endpoint = URLType[type];
	// INFO: API Design has change, only some endpoints for all reports will support LIMIT pagination
	const hasLimit = [metrics, emailCaptures, reportTypes.websiteConversions].some((elem) =>
		[endpoint, type].includes(elem)
	);
	const queryParams = convertObjToQueryString(queryParamsObj, hasLimit);
	// INFO: this made more sense for the first analytics api design, it may not make sense now.
	// Possible section for refactoring

	if (
		endpoint === metrics ||
		endpoint === summary ||
		endpoint === conversions ||
		endpoint === emailCaptures
	) {
		return (
			{
				// INFO: Campaign reports AKA Analytics 5
				[reportTypes.websiteCampaign]: `${URLMap[type]}/${website_id}/${endpoint}${queryParams}`,
				[reportTypes.parentCampaign]: `${URLMap[type]}/${parent_campaign_id}/${endpoint}${queryParams}`,
				[reportTypes.childCampaign]: `${URLMap[type]}/${campaign_id}/${endpoint}${queryParams}`,
				// INFO: Campaign Comparison reports AKA Analytics 6
				[reportTypes.websiteComparison]: `${URLMap[type]}/${website_id}/${endpoint}${queryParams}`,
				[reportTypes.parentCampaignComparison]: `${URLMap[type]}/${parent_campaign_id}/${endpoint}${queryParams}`,
				[reportTypes.childCampaignComparison]: `${URLMap[type]}/${campaign_id}/${endpoint}${queryParams}`,
				// INFO: Conversions report
				[reportTypes.websiteConversions]: `${URLMap[type]}/${website_id}/${endpoint}${queryParams}`,
				[reportTypes.parentConversions]: `${URLMap[type]}/${parent_campaign_id}/${endpoint}${queryParams}`,
				[reportTypes.childConversions]: `${URLMap[type]}/${campaign_id}/${endpoint}${queryParams}`,
				// INFO: Email Capture report
				[reportTypes.websiteEmailCapture]: `${URLMap[type]}/${website_id}/${endpoint}${queryParams}`,
				[reportTypes.parentEmailCapture]: `${URLMap[type]}/${parent_campaign_id}/${endpoint}${queryParams}`,
				[reportTypes.childEmailCapture]: `${URLMap[type]}/${campaign_id}/${endpoint}${queryParams}`,
			}[type] || "/unknown_report"
		);
	}
	return "/404";
};

function convertObjToQueryString(obj, hasLimit) {
	const paramsArray = [];
	for (const [key, value] of Object.entries(obj)) {
		paramsArray.push(`${key}=${value}`);
	}
	if (paramsArray.length === 0) {
		return "";
	}
	if (hasLimit) {
		return `?${paramsArray.join("&")}&limit=${API_LIMIT}`;
	}
	return `?${paramsArray.join("&")}`;
}
const metrics = "metrics";
const summary = "summary";
const conversions = "conversions";
const emailCaptures = "email-captures";

const URLType = {
	[reportTypes.websiteCampaign]: metrics,
	[reportTypes.parentCampaign]: metrics,
	[reportTypes.childCampaign]: metrics,
	[reportTypes.websiteComparison]: summary,
	[reportTypes.parentCampaignComparison]: summary,
	[reportTypes.childCampaignComparison]: summary,
	[reportTypes.websiteConversions]: conversions,
	[reportTypes.parentConversions]: conversions,
	[reportTypes.childConversions]: conversions,
	[reportTypes.websiteEmailCapture]: emailCaptures,
	[reportTypes.parentEmailCapture]: emailCaptures,
	[reportTypes.childEmailCapture]: emailCaptures,
};

const URLMap = {
	[reportTypes.websiteCampaign]: endpoints.websiteReportURL,
	[reportTypes.parentCampaign]: endpoints.parentCampaignReportURL,
	[reportTypes.childCampaign]: endpoints.childCampaignReportURL,
	[reportTypes.websiteComparison]: endpoints.websiteReportURL,
	[reportTypes.parentCampaignComparison]: endpoints.parentCampaignReportURL,
	[reportTypes.childCampaignComparison]: endpoints.childCampaignReportURL,

	[reportTypes.websiteConversions]: endpoints.websiteReportURL,
	[reportTypes.parentConversions]: endpoints.parentCampaignReportURL,
	[reportTypes.childConversions]: endpoints.childCampaignReportURL,
	[reportTypes.websiteEmailCapture]: endpoints.websiteReportURL,
	[reportTypes.parentEmailCapture]: endpoints.parentCampaignReportURL,
	[reportTypes.childEmailCapture]: endpoints.childCampaignReportURL,
};
const reduxActionMap = {
	[reportTypes.websiteCampaign]: loadCampaignReports,
	[reportTypes.parentCampaign]: loadCampaignReports,
	[reportTypes.childCampaign]: loadCampaignReports,
	[reportTypes.websiteComparison]: loadComparison,
	[reportTypes.parentCampaignComparison]: loadComparison,
	[reportTypes.childCampaignComparison]: loadComparison,
	[reportTypes.websiteConversions]: loadConversion,
	[reportTypes.parentConversions]: loadConversion,
	[reportTypes.childConversions]: loadConversion,
	[reportTypes.websiteEmailCapture]: loadEmail,
	[reportTypes.parentEmailCapture]: loadEmail,
	[reportTypes.childEmailCapture]: loadEmail,
};
export const downloadCSV = (url, fileName) => {
	fetch(url, {
		method: "GET",
		mode: "cors",
		cache: "no-cache",
		headers: {
			"Content-Type": "text/csv",
			Authorization: `Bearer ${LocalStorageService.getToken()}`,
			accept: "text/csv",
		},
	})
		.then((response) => {
			const reader = response.body.getReader();
			return new ReadableStream({
				start(controller) {
					function pump() {
						return reader.read().then(({ done, value }) => {
							// When no more data needs to be consumed, close the stream
							if (done) {
								controller.close();
								return;
							}
							// Enqueue the next data chunk into our target stream
							controller.enqueue(value);
							return pump();
						});
					}
					return pump();
				},
			});
		})
		.then((stream) => new Response(stream))
		.then((response) => response.blob())
		.then((blob) => URL.createObjectURL(blob))
		.then((blobURL) => {
			const anchor = document.createElement("a");
			anchor.download = fileName;
			anchor.href = blobURL;
			// Now, click it.
			if (document.createEvent) {
				const event = document.createEvent("MouseEvents");
				event.initEvent("click", true, true);
				anchor.dispatchEvent(event);
			} else {
				anchor.click();
			}
		});
};
export const backFillEmailCaptures = (website_id, startDate, endDate, updateMessage, dispatch) => {
	const url = `${endpoints.legacyApiDomain}${backFillApiPath}`;
	const body = `website_id=${website_id}&start_date=${startDate}&end_date=${endDate}`;
	const message = "Email Captures Affected";
	legacyApiPost(url, body, updateMessage, dispatch, message);
};

export const enableIncompleteFormsEmailCapture = (website_id, updateMessage, dispatch) => {
	const url = `${endpoints.legacyApiDomain}${submitIncompletFormApiPath}`;
	const body = `website_id=${website_id}`;
	const message = "Campaigns Affected";

	legacyApiPost(url, body, updateMessage, dispatch, message);
};

const legacyApiPost = (url, body, updateMessage, dispatch, message) => {
	dispatch(loadingScreen(true));
	fetch(url, {
		method: "POST",
		mode: "cors",
		cache: "no-cache",
		headers: {
			"Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
			Authorization: `Bearer ${LocalStorageService.getToken()}`,
			Accept: "application/json",
		},
		body,
	})
		.then((response) => {
			return response.json();
		})
		.then((json) => {
			dispatch(loadingScreen(false));
			if (json.capturesAffected !== undefined) {
				updateMessage(`${json.capturesAffected} ${message}`);
			} else {
				throw json;
			}
		})
		.catch((e) => {
			// INFO : this will match the current error message from legacy-api.
			updateMessage("Unable to submit, please try again in 30 seconds");
			dispatch(loadingScreen(false));
			console.error("ERROR: POST to legacy api, %o", e);
		});
};

export const sortVariations = (variations, method) => (
	[...variations].sort((variationA, variationB) => {
		let valueA = 0;
		let valueB = 0;

		switch (method) {
			case "campaign_conversion_rate":
				valueA = variationA?.stats?.ConversionStats?.ConversionRate;
				valueB = variationB?.stats?.ConversionStats?.ConversionRate;
				break;

			case "submit_rate":
				valueA = variationA?.stats?.EmailOptInRate;
				valueB = variationB?.stats?.EmailOptInRate;
				break;

			default:
				return 0;
		}

		return valueA - valueB;
	})
);
