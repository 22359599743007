import { createSlice } from "@reduxjs/toolkit";
import { getIndustries } from "./thunks";

const initialState = {
	isFetchError: false,
	response: {
		data: [],
	},
};

const industriesSlice = createSlice({
	name: "industries",
	initialState,
	reducers: {
		clearIndustriesApiError(state) {
			state.isFetchError = false;
		}
	},
	extraReducers: (builder) => {
		// getIndustries
		builder.addCase(getIndustries.fulfilled, (state, action) => {
			state.response = action.payload;
		});
		builder.addCase(getIndustries.rejected, (state) => {
			state.isFetchError = true;
		});
	},
});

export const {
	clearIndustriesApiError,
} = industriesSlice.actions;

export default industriesSlice.reducer;
