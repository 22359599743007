import React from "react";
import { Settings, BarChart2, Award, Book } from "react-feather";
import { ICON_SIZES } from "../../constants/common";

export const ArchiveButtons = [
	{
		title: "Active",
		value: 0,
		dataQA: "active-buttons-button",
	},
	{
		title: "Archived",
		value: 1,
		dataQA: "active-buttons-archived",
	},
	{
		title: "All",
		value: 2,
		dataQA: "active-buttons-all",
	},
];

export const COLORS_MAP = {
	on: "rgb(151, 212, 131)",
	test: "rgb(219, 164, 127)",
	off: "rgb(160, 158, 156)",
	disabled: "rgb(48, 61, 120)",
};

export const VersionButtons = [
	{
		title: "App",
		value: 2,
		dataQA: "version-buttons-app",
	},
	{
		title: "Legacy",
		value: 1,
		dataQA: "version-buttons-legacy",
	},
	{
		title: "All",
		value: 0,
		dataQA: "version-buttons-all",
	},
];

export const iconSettings = (websiteId) => [
	{
		name: "Settings",
		link: () => `/websites/${websiteId}/edit?tab=cms`,
		icon: () => <Settings aria-label="settings button" size={ICON_SIZES.medium} />,
		dataQA: "settings-button",
	},
	{
		name: "Report",
		link: function (flag, parentCampaignId, campaignId) {
			switch (flag) {
				case "websites":
					return `/reports?type=websiteCampaign&website_id=${websiteId}`;
				case "parent":
					return `/reports?type=parentCampaign&parent_campaign_id=${campaignId}&website_id=${websiteId}`;
				case "variation":
					return `/reports?type=childCampaign&campaign_id=${campaignId}&website_id=${websiteId}`;
				default:
					return "3";
			}
		},
		icon: () => <BarChart2 aria-label="report button" size={ICON_SIZES.medium} />,
		dataQA: "report-button",
	},
	{
		name: "Comparison",
		link: function (flag, parentCampaignId, campaignId) {
			switch (flag) {
				case "websites":
					return `/reports?type=websiteComparison&website_id=${websiteId}`;
				case "parent":
					return `/reports?type=parentCampaignComparison&website_id=${websiteId}&parent_campaign_id=${campaignId}`;
				case "variation":
					return `/reports?type=childCampaignComparison&website_id=${websiteId}&parent_campaign_id=${parentCampaignId}&campaign_id=${campaignId}`;
				default:
					return "3";
			}
		},
		icon: () => <Award aria-label="comparison button" size={ICON_SIZES.medium} />,
		dataQA: "comparison-button",
	},
	{
		name: "Stats",
		link: () => `/clients/stats?website_id=${websiteId}`,
		icon: () => <Book aria-label="stats button" size={ICON_SIZES.medium} />,
		dataQA: "stats-button",
	},
];
