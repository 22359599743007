import React from 'react'
import PropTypes from 'prop-types'
import { SubsectionBreak } from './PageSection.styled'
import { ListElement, Bullet, Content } from './NumberedList.styled'

const NumberedList = ({listElements}) => {
    return (
        listElements.map((listElement, i) => (
            <div key={`numbered-list-item-${i}`}>
                <SubsectionBreak />
                <ListElement>
                    <Bullet>{i+1}.</Bullet>
                    <Content>{listElement}</Content>
                </ListElement>
            </div>
        ))
    )
}

NumberedList.propTypes = {
    listElements: PropTypes.array.isRequired
}

export default NumberedList;
