import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import {
	WEBSITES_URL,
	WEBSITE_URL_PARAMS,
} from "./constants";

export const getWebsites = createAsyncThunk(
	"websites/getWebsites",
	async ({ params = {}, firstAsSelected }) => {
		const url = `${endpoints.coreApi}${WEBSITES_URL}`;
		const searchParams = { ...WEBSITE_URL_PARAMS, ...params };
		const response = await ApiService.getWithSearchParams(url, searchParams);

		if (response.status === 200) {
			const json = await response.json();

			return { json, firstAsSelected };
		}

		throw new Error("Failed to load websites!");
	}
);
