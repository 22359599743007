import { gql } from "urql";
import { COMMON_VALUES, COMPARISON_STATS, COMPARISON_OVERALL_STATS } from "./fragments";

const websiteComparisonQuery = gql`
	${COMPARISON_STATS}
	${COMMON_VALUES}
	${COMPARISON_OVERALL_STATS}
	query($websiteId: Int!, $start: DateTime!, $end: DateTime!, $campaignAttribution: OnsiteAttribution!) {
		website (id: $websiteId, start: $start, end: $end) {
			id
			onsiteProduct(campaignAttribution: $campaignAttribution) {
				campaigns {
					common {
						...commonValues
					}
					variations {
						common {
							...commonValues
						}
						stats {
							...comparisonStats
						}
					}
					combinedControl {
						stats {
							...comparisonStats
						}
					}
				}
			}
		}
		overall: website(id: $websiteId, start: $start, end: $end) {
			id
			onsiteProduct(campaignAttribution: ALL_CONVERSION) {
				campaigns {
					common {
						...commonValues
					}
					variations {
						common {
							...commonValues
						}
						stats {
							...comparisonOverallStats
						}
					}
					combinedControl {
						stats {
							...comparisonOverallStats
						}
					}
				}
			}
		}
	}
`;

export default websiteComparisonQuery;
