import styled from "styled-components";

const PREVIEW_TYPE_WIDTH_SIZES = {
    desktop: 1200,
    mobile: 414,
    mobilexs: 370
}

const PREVIEW_TYPE_HEIGHT = 3000;

export const Triangle = styled.div`
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top: 0;
    border-bottom: 10px solid black;
`;

export const ContentContainer = styled.div`
    position: absolute;
    pointer-events: auto;
    z-index: 99;
    left: ${props => props.left};
    top: 80px;
    animation-duration: 350ms;
    transition-duration: 350ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 760px;
`;

export const Content = styled.div`
    padding: 1.571rem;
    width: 760px;
    background: #5b7489;
    box-shadow: 0 0 10px 1px #9d9a9a;
    border-radius: 0;
    border: 0;
    flex: 1 1 auto;
    margin-top: 10px;
    position: absolute;
    left: 40px;
    cursor: default;
`;

export const HeaderContainer = styled.div`
	display: flex;
    height: 53px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e8;
    background-color: white;
    width: 100%;
`;

export const HeaderLabel = styled.span`
	color: #191919;
    font-size: 1rem;
    font-weight: 600;
    margin-left: 12px;
    margin-right: 12px;
`;

export const ToolTipLabel = styled.span`
    color: #214558;
    cursor: pointer;
    font-weight: 600;
    font-size: 0.857rem;
    font-weight: 400;
    text-decoration: underline;
    margin-left: 12px;
    margin-right: 12px;
`;

export const Container = styled.div`
	display: flex;
    flex-direction: column;
    background-color: white;
    width: 100%;
    height: 100%;
    border-left: 1px solid #e8e8e8;
`;

export const PreviewHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 53px;
    border-bottom: 1px solid #e8e8e8;
`;

export const RightContainer = styled.main`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    border-left: 1px solid #e8e8e8;
    overflow-y: auto;
`;

export const LeftContainer = styled.div`
    flex-basis: ${props => props.expanded ? "100%" : "70%"};
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
`;

export const PreviewAside = styled.aside`
	display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const ViewSizes = styled.div`
	display: flex;
    height: 40px;
    display: flex;
    justify-content: space-around;
    margin: 20px;
`;

export const Size = styled.div`
    padding-top: 0.786rem;
    padding-bottom: 0.786rem;
    padding-left: 0.524rem;
    padding-right: 0.524rem;
    border: 1px solid;
    border-color: ${props => props.selected ? "#303D78" : "#e8e8e8;" };
    background: #ffffff;
    color: ${props => props.selected ? "#303D78" : "#191919" };
    font-size: 0.714rem;
    line-height: 1.8;
    text-transform: uppercase;
    border-radius: 0;
    cursor: pointer;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
        border-color: #303D78;
        color: #303D78;
	}
`

export const DesignPreviewContainer = styled.div`
	display: flex;
    height: 100%;
    width: 100%;
`;

export const PreviewContainer = styled.div`
    flex-basis: 70%;
    display: flex;
    justify-content: center;
`

export const IFrame = styled.iframe`
    height: 3000px;
    width: ${props => `${PREVIEW_TYPE_WIDTH_SIZES[props.previewType]}px`};
    border: 0;
    transform: ${props => `scale(${props.zoomValue/100})`};
    background-color: white;
    transform-origin: 0 0;
    border: none;
    overflow-y: auto;
`

export const IFrameContainer = styled.div`
    overflow-y: clip;
    height: ${props => `${(PREVIEW_TYPE_HEIGHT * (props.zoomValue/100))}px`};
    width: ${props => `${(PREVIEW_TYPE_WIDTH_SIZES[props.previewType] * (props.zoomValue/100))}px`};
    border: 0;
`
