import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ButtonRow, DateRow } from "./components";

const Controls = ({
	reportOptions = {},
	onChangeReportOptions = () => {},
}) => {
	return (
		<Wrapper>
			<ButtonRow
				onClick={onChangeReportOptions}
			/>
			<DateRow
				reportOptions={reportOptions}
				onChangeReportOptions={onChangeReportOptions}
			/>
		</Wrapper>
	);
};
Controls.propTypes = {
	reportOptions: PropTypes.object.isRequired,
	onChangeReportOptions: PropTypes.func.isRequired,
};
const Wrapper = styled.div`
	margin: 10px 0;
	padding: 20px;
	background-color: #fff;
	min-width: 535px;
`;
export default Controls;
