import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Line } from "react-chartjs-2";

const CampaignGraph = ({ data , show}) => {
	const lineData = _formatData(data);

	if (!show) {
		return null;
	}

	return (
		<Background>
			<Line
				data={lineData}
				width={100}
				height={30}
				legend={{
					labels: {
						usePointStyle: true,
						boxWidth: 5,
						fontSize: 12,
					},
				}}
			/>
		</Background>
	);
};
CampaignGraph.propTypes = {
	data: PropTypes.array.isRequired,
	show: PropTypes.bool.isRequired,
};
const _formatData = (data = []) => {
	const dataMap = _createDataMap(data[0]);
	const format = (
		{ labels },
		{
			date = {},
			overall_visits = 0,
			eligible_visits = { top: 0 },
			overall_impressions = { top: 0 },
			clicks = { top: 0 },
			closes = { top: 0 },
			form_submits = { top: 0 },
			overall_conversions = { top: 0 },
		}
	) => {
		const xAxis = _createLabel(date?.bottom);
		labels.push(xAxis);

		// INFO: Put line data into dataMap
		dataMap.overall_visits.data.push({
			x: xAxis,
			y: convertStringToNum(overall_visits),
		});
		dataMap.eligible_visits.data.push({
			x: xAxis,
			y: convertStringToNum(eligible_visits.top),
		});
		dataMap.overall_impressions.data.push({
			x: xAxis,
			y: convertStringToNum(overall_impressions.top.split("/")[0]),
		});
		dataMap.clicks.data.push({
			x: xAxis,
			y: convertStringToNum(clicks.top),
		});
		dataMap.closes.data.push({
			x: xAxis,
			y: convertStringToNum(closes.top),
		});
		dataMap.form_submits.data.push({
			x: xAxis,
			y: convertStringToNum(form_submits.top),
		});
		dataMap.overall_conversions.data.push({
			x: xAxis,
			y: convertStringToNum(overall_conversions.top.split("/")[0]),
		});
		return { labels };
	};

	const graphData = data.reduce(format, { labels: [] });
	graphData.labels = graphData.labels.reverse();
	// INFO: convert dataMap into an array for react-chart to consume.
	graphData["datasets"] = Object.values(dataMap)
		.filter(({ label }) => label !== undefined)
		.map(dataset => ({ ...dataset, data: [...dataset.data].reverse() }));

	return graphData;
};
const convertStringToNum = (str) => {
	return Number(str.replace(/,/g, ""));
};
const _createLabel = (date = "") => {
	// eslint-disable-next-line no-unused-vars
	const [year, monthNum, dateNum] = date.split("-");
	const month = _monthMap[monthNum];
	return `${month} ${dateNum} ${year}`;
};
const _monthMap = {
	"01": "Jan",
	"02": "Feb",
	"03": "Mar",
	"04": "Apr",
	"05": "May",
	"06": "Jun",
	"07": "Jul",
	"08": "Aug",
	"09": "Sep",
	10: "Oct",
	11: "Nov",
	12: "Dec",
};
const _createDataMap = (obj = {}) => {
	const map = {};
	for (const [key] of Object.entries(obj)) {
		map[key] = {
			label: labelMap[key],
			borderColor: borderMap[key],
			lineTension: 0,
			data: [],
			pointStyle: "circle",
			borderWidth: 3,
		};
	}
	return map;
};
const labelMap = {
	overall_visits: "Overal Visits",
	eligible_visits: "Eligible Visits",
	overall_impressions: "Overall Impressions",
	clicks: "Clicks",
	closes: "Closes",
	form_submits: "Form Submits",
	overall_conversions: "Overall Conversions",
};
const borderMap = {
	overall_visits: "rgb(174, 199, 232)",
	eligible_visits: "rgb(255, 127, 14)",
	overall_impressions: "rgb(31, 119, 180)",
	clicks: "rgb(255, 152, 150)",
	closes: "rgb(148, 103, 189)",
	form_submits: "rgb(214, 39, 40)",
	overall_conversions: "rgb(152, 223, 138)",
};
const Background = styled.div`
	background-color: #fff;
	min-width: 535px;
`;

export default CampaignGraph;
