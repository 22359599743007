import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectAllWebsites } from "../../../redux/websites/websitesAdapter";
import { EmptyRow, Loader } from "../../../components";
import WebsiteRow from "./WebsiteRow";

let lastManagerName = '';

const insertManagerNameRow = (website) => {
	const currentManagerName = website.attributes["manager-name"] || "Manager not specified!";
	const previousManagerName = lastManagerName;
	lastManagerName = currentManagerName;

	if (currentManagerName !== previousManagerName) {
		return <ManagerNameRowContainer>{currentManagerName}</ManagerNameRowContainer>;
	}

	return null;
};

const WebsitesTable = ({ sortByManagers = false, isLoading }) => {
	const websites = useSelector(selectAllWebsites);
	const {
		response: { data: contracts = [] },
	} = useSelector((state) => state.contracts);

	const {
		response: { data: industries = [] },
	} = useSelector((state) => state.industries);

	const {
		response: { data: verticals = [] },
	} = useSelector((state) => state.verticals);



	if (websites.length === 0 && !isLoading) {
		return <EmptyRow>No Experiences Found</EmptyRow>;
	}

	return (
		<TableContainer>
			{isLoading && <Loader isLoading={isLoading} />}
			{websites.map((website) => (
				<React.Fragment key={website.id}>
					{sortByManagers && insertManagerNameRow(website)}
					<WebsiteRow
						website={website}
						contracts={contracts}
						industries={industries}
						verticals={verticals}
					/>
				</React.Fragment>
			))}
		</TableContainer>
	);
};

WebsitesTable.propTypes = {
	sortByManagers: PropTypes.bool,
	isLoading: PropTypes.bool,
};

const TableContainer = styled.div`
	width: 100%;
	max-height: 100%;
	overflow-y: scroll;
`;

const ManagerNameRowContainer = styled.div`
	height: 44px;
	line-height: 44px;
	font-size: 12px;
	font-weight: 600;
	color: rgb(33, 69, 88);
	padding: 0 11px;
	border: 1px solid rgb(232, 232, 232);
	background-color: white;
	margin-top: -1px;
	padding: 0 22px;
	&::first-letter {
		text-transform: capitalize;
	}
`;

export default WebsitesTable;
