import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { AlertCircle } from "react-feather";
import { ToolTip } from "../../../components";

import { ICON_SIZES } from "../../../constants/common";


const NoInfoIconLink = ({ url, tipText }) => {
	return (
		<IconLink href={url}>
			{tipText ? (
				<ToolTip text={tipText} position="bottom">
					<AlertCircle
						aria-label="No Information"
						size={ICON_SIZES.medium}
					/>
				</ToolTip>
			) : (
				<AlertCircle
					aria-label="No Information"
					size={ICON_SIZES.medium}
				/>
			)}
		</IconLink>
	);
};

NoInfoIconLink.propTypes = {
	url: PropTypes.string,
	tipText: PropTypes.string,
};

const IconLink = styled.a`
	font-size: 12px;
	font-weight: 600;
	width: 100px;
	margin: 0;
	& i {
		color: rgb(157, 154, 154);
	}
`;

export default NoInfoIconLink;
