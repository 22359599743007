import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Calendar = ({
	label = "",
	field = "",
	value = "",
	onChange = () => {},
}) => {
	const setChange = ({ target }) => {
		onChange({
			[field]: target.value,
		});
	};
	//TODO: find Calendar
	return (
		<LabelInline>
			{label}
			<Input type="date" value={value} onChange={setChange} />
		</LabelInline>
	);
};
Calendar.propTypes = {
	label: PropTypes.string.isRequired,
	field: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
};
const LabelInline = styled.label`
	display: inline-block;
	margin: 10px;
`;
const Input = styled.input`
	margin: 0 5px;
`;
export default Calendar;
