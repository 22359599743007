import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { getUploadFiles, postUploadFiles, deleteFile } from "../../redux/uploadFiles/thunks";
import { getUser } from "../../redux/user/thunks";
import { Loader, Button, Pagination, PopupModal, Wrapper } from "../../components";

import {
	Container,
	SubNav,
	SubNavItem,
	Header,
	Input,
	ListContainer,
	Row,
	Icon,
} from "./styledComponents";

const PER_PAGE = 30;

// TODO: Handle serverside error when uploads fail
const Uploads = () => {
	const {
		user,
		uploadFiles: {
			isLoading,
			response: { cdn_list, paging },
		},
		page: { params },
	} = useSelector((state) => state);
	const dispatch = useDispatch();

	const getModifiedPageNumber = (page) => (page === null ? "1" : page);
	const [page, setPage] = useState(getModifiedPageNumber(params.page));
	const [showModal, toggleModal] = useState(false);
	const [showPreviewModal, togglePreviewModal] = useState(false);
	const [uploadToPreview, setUploadToPreview] = useState(null);
	const [keepFileName, toggleKeepFileName] = useState(false);
	const [filePath, setFilePath] = useState("");
	const [images, setImages] = useState([]);
	const [isPosting, setIsPosting] = useState(false);

	const modifiedSetPage = (pageNum) => {
		const urlWithoutParams = window.location.href.split("?")[0];
		window.history.pushState("", "", `${urlWithoutParams}?page=${pageNum}`);
		setPage(pageNum);
	};

	// Back Button
	window.onpopstate = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const pageParam = urlParams.get("page");
		setPage(getModifiedPageNumber(pageParam));
	};

	const grabUploadsByPage = () => {
		let params = {
			page_number: page,
			max_per_page: PER_PAGE,
		};

		if (page === "all") {
			params = {
				page_number: 1,
				max_per_page: 1000,
			};
		}

		return dispatch(getUploadFiles(params));
	};

	const modifiedToggleModal = (show) => {
		if (show) {
			// clear upload states
			toggleKeepFileName(false);
			setFilePath("");
			setImages([]);
		}

		toggleModal(show);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
	};

	const initializeUpload = () => {
		document.getElementById("upload").click();
	};

	const appendBase64 = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () =>
				resolve({
					base64: reader.result,
					originalFile: file,
				});
			reader.onerror = (error) => reject(error);
		});
	};

	const loadImagePreviews = (files) => {
		Promise.all(files.map((file) => appendBase64(file))).then((images) => {
			setImages(images);
		});
	};

	const handleFileInput = (event) => {
		const files = Array.from(event.target.files);
		loadImagePreviews(files);
	};

	const submitUploads = () => {
		toggleModal(false);
		setIsPosting(true);

		const requests = images.map((image) => {
			const file = image.originalFile;
			const formData = new FormData();
			formData.append("path", "");
			formData.append("specific_file_path", filePath);
			formData.append("files[]", file);

			if (keepFileName) {
				formData.append("keep_file_name", keepFileName);
			}

			return dispatch(
				postUploadFiles({
					formData,
					queryParamsObj: {
						client_id: undefined,
						user_id: user.response.legacyUserId,
						creative_id: undefined,
						max_width: undefined,
						keep_file_name: keepFileName ? 1 : "",
						specific_file_path: filePath,
					},
				})
			);
		});

		Promise.allSettled(requests).then(() => {
			grabUploadsByPage();
			setIsPosting(false);
		});
	};

	const deleteUpload = async (file) => {
		const formData = new FormData();
		formData.append("file", file.name);
		await dispatch(deleteFile(formData));
		grabUploadsByPage();
	};

	const previewUpload = (file) => {
		setUploadToPreview(file.cdn_url);
		togglePreviewModal(true);
	};

	const handleStopDefaultBehavior = (event) => {
		event.stopPropagation();
		event.preventDefault();
	};

	const handleDropFiles = (event) => {
		handleStopDefaultBehavior(event);

		const { files } = event.dataTransfer;
		const filesArr = [];

		for (let i = 0; i < files.length; i++) {
			filesArr.push(files[i]);
		}

		loadImagePreviews(filesArr);
	};

	const renderFileList = () => {
		let display = (
			<>
				<ModifiedRow>You have not uploaded any files&#46;</ModifiedRow>
				<ModifiedRow></ModifiedRow>
			</>
		);

		if (cdn_list.length) {
			display = (
				<>
					<EqualHeightRow>
						<PreviewColumnHeader>Preview</PreviewColumnHeader>
						<DetailsColumnHeader>Details</DetailsColumnHeader>
					</EqualHeightRow>
					{cdn_list.map((file, index) => (
						<EqualHeightRow key={index}>
							<PreviewColumn>
								<MediumImage
									src={file.cdn_url}
									onClick={() => previewUpload(file)}
								/>
							</PreviewColumn>
							<DetailsColumn>
								<div>
									<input
										readOnly
										value={file.cdn_url}
										onFocus={(event) => event.target.select()}
										style={{ width: "95%", marginRight: "8px" }}
									/>
									<Icon
										onClick={() => deleteUpload(file)}
										className="u-font-size-base icon-trash"
									/>
								</div>
								<FileSize>{file.size}kb</FileSize>
								<FileDate>{file.date}</FileDate>
							</DetailsColumn>
						</EqualHeightRow>
					))}
					<EqualHeightRow>
						<Pagination
							totalPages={paging.num_of_page || 1}
							currentPage={page}
							setPage={modifiedSetPage}
						/>
					</EqualHeightRow>
				</>
			);
		}

		return display;
	};

	useEffect(() => {
		dispatch(getUser());
	}, []);

	useEffect(() => {
		grabUploadsByPage();
	}, [page]);

	return (
		<Wrapper>
			<Loader isLoading={isLoading || isPosting} />

			<SubNav>
				<SubNavItem href="/tools">Tools</SubNavItem>
				<SubNavItem onClick={() => modifiedToggleModal(true)}>New Upload</SubNavItem>
			</SubNav>

			<Container>
				<Header>Uploads</Header>
			</Container>

			<ModifiedListContainer>{renderFileList()}</ModifiedListContainer>

			<PopupModal
				showModal={showPreviewModal}
				toggleModal={togglePreviewModal}
				style={{ width: "80%" }}
			>
				<LargeImage src={uploadToPreview} />
			</PopupModal>

			<PopupModal
				showModal={showModal}
				toggleModal={modifiedToggleModal}
				style={{ width: "700px" }}
			>
				<form onSubmit={handleSubmit} id="upload_form">
					<UploadContainer
						onDragEnter={handleStopDefaultBehavior}
						onDragOver={handleStopDefaultBehavior}
						onDragLeave={handleStopDefaultBehavior}
						onDrop={handleDropFiles}
					>
						<input type="hidden" id="img_path" name="path" value="" />
						<Statement>
							To attach files drag &amp; drop here or click &#39;Choose Files&#39; to
							browse your computer.
						</Statement>
						<Statement>
							We support uploading multiple files at the same time&#46;
						</Statement>
						<Statement style={{ marginTop: "16px", cursor: "pointer" }}>
							Check to keep the filename
							<Input
								style={{ marginLeft: "8px" }}
								type="checkbox"
								checked={keepFileName}
								onChange={(event) => toggleKeepFileName(event.target.checked)}
								name="keep_file_name"
							/>
						</Statement>
						<Statement style={{ marginTop: "16px" }}>
							Specify file path&#58;
							<Input
								style={{ marginLeft: "8px", width: "30%" }}
								type="text"
								value={filePath}
								onChange={(event) => setFilePath(event.target.value)}
								name="specific_file_path"
							/>
						</Statement>
						<BottomContainer>
							<Input
								multiple
								type="file"
								name="files[]"
								id="upload"
								hidden
								onChange={handleFileInput}
							/>
							<Button theme="blue" onClick={initializeUpload}>
								Upload Files
							</Button>
						</BottomContainer>
					</UploadContainer>
					<PreviewContainer>
						{images.map((image, index) => (
							<SmallImageContainer key={index}>
								<SmallImage src={image.base64} />
								<ReadOnlyInput
									readOnly
									value={image.originalFile.name}
									onFocus={(event) => event.target.select()}
								/>
							</SmallImageContainer>
						))}
					</PreviewContainer>
					<Button theme="blue" onClick={submitUploads}>
						Done
					</Button>
				</form>
			</PopupModal>
		</Wrapper>
	);
};

const FileSize = styled.div`
	font-size: 10px;
	color: #5a5959;
	font-weight: bold;
	margin-top: 4px;
	margin-bottom: 4px;
`;

const FileDate = styled(FileSize)`
	font-weight: 400;
	margin: 0;
`;

const PreviewColumn = styled.div`
	width: 200px;
`;

const DetailsColumn = styled.div`
	width: calc(100% - 200px);
`;

const PreviewColumnHeader = styled(PreviewColumn)`
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 15px;
`;

const DetailsColumnHeader = styled(DetailsColumn)`
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 15px;
`;

const ReadOnlyInput = styled.input`
	width: 100%;
	margin-left: 5px;
`;

const SmallImage = styled.img`
	max-width: 100px;
	max-height: 60px;

	min-height: 60px;
	height: auto;
	width: auto;
	display: block;
`;

const MediumImage = styled.img`
	max-width: 180px;
	max-height: 108px;
	margin-top: 6px;
	margin-bottom: 6px;
	cursor: pointer;

	min-height: 108px;
	height: auto;
	width: auto;
	display: block;
`;

const LargeImage = styled.img`
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 660px;
	display: block;
	margin-left: auto;
	margin-right: auto;
`;

const SmallImageContainer = styled.div`
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	align-items: start;
`;

const UploadContainer = styled.div`
	border: 1px dashed #aeaeae;
	padding: 0.5em;
`;

const Statement = styled.label`
	font-size: 12px;
`;

const BottomContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		margin: 20px 0;
	}
`;

const PreviewContainer = styled.div`
	height: 150px;
	overflow: scroll;
`;

const ModifiedListContainer = styled(ListContainer)`
	background: #efefef;
`;

const ModifiedRow = styled(Row)`
	border-color: #e1e1e1;
`;

const EqualHeightRow = styled(ModifiedRow)`
	height: auto;
`;

export default Uploads;
