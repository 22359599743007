import { createSlice, current } from "@reduxjs/toolkit";
import {
	getInboxCampaigns,
	getInboxCampaign,
	duplicateInboxCampaign,
	saveInboxCampaign,
	createInboxCampaign,
	updateInboxCampaign,
} from "./thunks";
import inboxCampaignsAdapter from "./inboxCampaignsAdapter";
import compareData from "../businessLogic/compareData";

const initialState = {
	isLoading: false,
	isFetchError: false,
	response: {
		data: inboxCampaignsAdapter.getInitialState(),
		meta: {},
		included: [],
	},
	unSavedChanges: {},
	selectedInboxCampaign: null,
};

const inboxCampaigns = createSlice({
	name: "inboxCampaigns",
	initialState,
	reducers: {
		clearInboxCampaignsApiError(state) {
			state.isFetchError = false;
		},
	},
	extraReducers: (builder) => {
		// getInboxCampaigns
		builder.addCase(getInboxCampaigns.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getInboxCampaigns.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			state.response.included = action.payload.included;
			inboxCampaignsAdapter.setAll(state.response.data, action.payload.data);
		});
		builder.addCase(getInboxCampaigns.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxCampaignsAdapter.removeAll(state.response.data);
		});
		// getInboxCampaign
		builder.addCase(getInboxCampaign.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getInboxCampaign.fulfilled, (state, action) => {
			state.selectedInboxCampaign = action.payload?.data;
			state.response.included = action.payload.included;
			inboxCampaignsAdapter.upsertOne(state.response.data, action.payload?.data);
			state.isLoading = false;
		});
		builder.addCase(getInboxCampaign.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxCampaignsAdapter.removeAll(state.response.data);
		});
		// duplicateInboxCampaign
		builder.addCase(duplicateInboxCampaign.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(duplicateInboxCampaign.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			inboxCampaignsAdapter.upsertOne(state.response.data, action.payload.data);
		});
		builder.addCase(duplicateInboxCampaign.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxCampaignsAdapter.removeAll(state.response.data);
		});
		// saveInboxCampaign
		builder.addCase(saveInboxCampaign.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(saveInboxCampaign.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			inboxCampaignsAdapter.upsertOne(state.response.data, action.payload.data);
		});
		builder.addCase(saveInboxCampaign.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxCampaignsAdapter.removeAll(state.response.data);
		});
		// createInboxCampaign
		builder.addCase(createInboxCampaign.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(createInboxCampaign.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			inboxCampaignsAdapter.upsertOne(state.response.data, action.payload.data);
		});
		builder.addCase(createInboxCampaign.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxCampaignsAdapter.removeAll(state.response.data);
		});
		// updateInboxCampaign
		builder.addCase(updateInboxCampaign, (state, action) => {
			const {
				payload: { id },
			} = action;
			const currentState = current(state);
			const oldValues = currentState?.response.data.entities[id];
			const newValues = {
				...currentState.response.data.entities[id],
				attributes: {
					...currentState.response.data.entities[id].attributes,
					...state.unSavedChanges[id]?.attributes,
					...action.payload?.attributes,
				},
				relationships: {
					...currentState.response.data.entities[id].relationships,
					...state.unSavedChanges[id]?.relationships,
					...action.payload?.relationships,
				},
			};
			if (!compareData(oldValues, newValues)) {
				state.unSavedChanges[id] = {
					...state.unSavedChanges[id],
					...action.payload,
				};
			} else {
				delete state.unSavedChanges[id];
			}
		});
	},
});

export const { clearInboxCampaignsApiError } = inboxCampaigns.actions;

export default inboxCampaigns.reducer;
