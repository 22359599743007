import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { getCampaign } from "../../../redux/campaigns/thunks";
import { COLORS_MAP } from "../constants";

const VariationListBlock = ({
	variationId,
	changeCampaign,
	getTrafficType,
	selectedTrafficType,
	parentCampaign,
}) => {
	const [variation, setVariation] = useState({});
	const [variationTrafficType, setVariationTrafficType] = useState(getTrafficType(variation));
	const dispatch = useDispatch();
	const parentTrafficType = getTrafficType(parentCampaign);

	const {
		response: { data: campaignTypes = [] },
	} = useSelector((state) => state.campaignTypes);
	const { selectedCampaigns } = useSelector((state) => state.campaigns);
	const selectedCampaign = selectedCampaigns.length === 1 ? selectedCampaigns[0] : {};

	useEffect(() => {
		const fetchCampaignData = async () => {
			const response = await dispatch(getCampaign(variationId));
			const variationData = response?.payload?.data;
			setVariation(variationData);
		};
		fetchCampaignData();
	}, [variationId]);

	const getCampaignTypes = (campaignTypeId) => {
		if (!campaignTypeId) {
			campaignTypeId = variation?.relationships?.["campaign-type"]?.data?.id;
		}
		const campaignType = campaignTypes.find((purpose) => purpose.id === campaignTypeId);
		const campaignName = campaignType?.attributes?.name || "";

		return campaignName;
	};

	//Close! Need to figure out how to get the variation icons to change when the parent is changed to either "test" or "off"
	const getIconColor = (iconType) => {
		if (parentTrafficType === "on") {
			switch (iconType) {
				case "on":
					return COLORS_MAP.on;
				case "test":
					return COLORS_MAP.test;
				case "off":
					return COLORS_MAP.off;
				default:
					return COLORS_MAP.disabled;
			}
		} else if (parentTrafficType === "test" && iconType !== "off") {
			return COLORS_MAP[parentTrafficType];
		} else if (parentTrafficType === "off") {
			return COLORS_MAP[parentTrafficType];
		}

		switch (iconType) {
			case "on":
				return COLORS_MAP.on;
			case "test":
				return COLORS_MAP.test;
			case "off":
				return COLORS_MAP.off;
			default:
				return COLORS_MAP.disabled;
		}
	};

	const selectVariationHandler = () => {
		changeCampaign(variation);
	};

	const variationTraffic = parseInt(variation?.attributes?.["test-traffic"]);
	const campaignType = getCampaignTypes();
	const isSelected = selectedCampaign?.id === variationId;

	useEffect(() => {
		if (isSelected) {
			setVariationTrafficType(selectedTrafficType);
		} else {
			setVariationTrafficType(getTrafficType(variation));
		}
	}, [variation, getTrafficType, setVariationTrafficType, selectedTrafficType]);

	const backgroundColor = isSelected ? "rgb(232, 232, 232)" : "transparent";

	const vtf = !variationTraffic
		? "Loading"
		: variationId === selectedCampaign?.id
		? `${selectedCampaign.attributes?.["test-traffic"]}% - ${getCampaignTypes(
				selectedCampaign.relationships?.["campaign-type"]?.data?.id
		  )} - ${selectedCampaign.attributes?.name}`
		: `${variationTraffic}% - ${campaignType} = ${variation?.attributes?.name}`;

	const isArchived = !selectedCampaign?.attributes?.active;
	const isParentArchived = !parentCampaign?.attributes?.active;
	const isArchivedOrIsParentArchived = isArchived || isParentArchived;

	return (
		<VariationRowContainer
			bgColor={backgroundColor}
			onClick={selectVariationHandler}
			isActive={isSelected}
		>
			<Icon
				className="icon-circle"
				color={getIconColor(
					isArchivedOrIsParentArchived ? undefined : variationTrafficType
				)}
			/>

			<VariationContainer data-qa="variation-purpose">{vtf}</VariationContainer>
		</VariationRowContainer>
	);
};

const VariationRowContainer = styled.button`
	display: flex;
	align-items: flex-start;
	width: 100%;
	border-radius: 0;
	padding: 0.786rem 1.571rem;
	border: 0;
	background: transparent;
	color: #626161;
	font-size: 0.857rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
	font-weight: 600;
	line-height: 1.45;
	text-align: left;
	color: ${({ isActive }) => (isActive ? "rgb(48, 61, 120)" : "rgb(25, 25, 25)")};
	background-color: ${({ bgColor }) => bgColor || "transparent"};
`;

const Icon = styled.i`
	margin-right: 4px;
	padding-top: 3px;
	color: ${({ color }) => color};
`;

const VariationContainer = styled.span`
	padding: 0 0 0 0.786rem;
	font-size: 0.857rem;
	font-weight: 600;
`;

VariationListBlock.propTypes = {
	variationId: PropTypes.string.isRequired,
	changeCampaign: PropTypes.func.isRequired,
	selectedTrafficType: PropTypes.string.isRequired,
	getTrafficType: PropTypes.func.isRequired,
	parentCampaign: PropTypes.object,
};

export default VariationListBlock;
