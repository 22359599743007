import { merge } from "lodash";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import { CAMPAIGNS_URL, BLANK_CAMPAIGN_DATA } from "./constants";

export const getCampaigns = createAsyncThunk("campaigns/getCampaigns", async (params = {}) => {
	const url = `${endpoints.coreApi}${CAMPAIGNS_URL}`;
	const response = await ApiService.getWithSearchParams(url, params);

	if (!response.ok) {
		throw new Error("Failed to load campaigns!");
	}

	return response.json();
});

export const getCampaign = createAsyncThunk("campaigns/getCampaign", async (id) => {
	const url = `${endpoints.coreApi}${CAMPAIGNS_URL}/${id}`;
	const response = await ApiService.get(url);

	if (!response.ok) {
		throw new Error("Failed to load campaign!");
	}

	return response.json();
});

export const getCreativeCampaign = createAsyncThunk("campaigns/getCreativeCampaign", async (id) => {
	const url = `${endpoints.coreApi}${CAMPAIGNS_URL}/${id}?include=none`;
	const response = await ApiService.get(url);
	if (!response.ok) {
		throw new Error("Failed to load campaign!");
	}

	return response.json();
});

export const getCreativeChildrenCampaigns = createAsyncThunk("campaigns/getCreativeCampaigns", async (id) => {
	const url = `${endpoints.coreApi}${CAMPAIGNS_URL}/${id}?include=none`;
	const response = await ApiService.get(url);

	if (!response.ok) {
		throw new Error("Failed to load campaign!");
	}

	try {
		const parentCampaign = await response.json();
		const childCampaigns = parentCampaign.data.relationships.children.data;

		const childPromises = childCampaigns.map(({ id: childId }) => {
		const childUrl = `${endpoints.coreApi}${CAMPAIGNS_URL}/${childId}?include=none`;
			return ApiService.get(childUrl);
		});

		const childResponses = await Promise.all(childPromises);
		const childrenCampaigns = await Promise.all(childResponses.map((cr) => cr.json()));

		return new Promise((resolve, reject) => {
		resolve({ parentCampaign, childrenCampaigns });
		});
	}
	catch(error) {
		throw new Error("Failed to load child campaigns!");
	}

});

export const getCreativeChildrenCampaign = createAsyncThunk("campaigns/getCreativeChildrenCampaign", async (id) => {
	const url = `${endpoints.coreApi}${CAMPAIGNS_URL}/${id}?include=none`;
	const response = await ApiService.get(url);

	if (!response.ok) {
		throw new Error("Failed to load campaign!");
	}

	return response.json();
});

export const crossSiteDuplicateCampaign = createAsyncThunk(
	"campaigns/cross_site_duplicate",
	async ({ orderedParentCampaigns, targetSiteIds }) => {
		const url = `${endpoints.coreApi}/campaigns/cross_site_duplicate`;
		const ids = orderedParentCampaigns.map((campaign) => campaign.id).reverse();
		const linkCampaigns = 0;
		const valueIds = ids.join("&ids[]=");
		const valueTargetSiteIds = targetSiteIds.join("&targetSiteIds[]=");
		const body = `ids[]=${valueIds}&targetSiteIds[]=${valueTargetSiteIds}&linkCampaigns=${linkCampaigns}`;

		const response = await ApiService.postAsForm(url, { payload: body });

		if (!response.ok) {
			throw new Error("Failed to duplicate campaign");
		}

		return response.json();
	}
);

export const duplicateCampaign = createAsyncThunk("campaigns/duplicate", async ({ campaignId }) => {
	const url = `${endpoints.coreApi}/campaigns/duplicate`;

	const response = await ApiService.postAsForm(url, { payload: `id=${campaignId}` });

	if (!response.ok) {
		throw new Error("Failed to duplicate campaign");
	}
	return response.json();
});

export const createNewCampaign = createAsyncThunk(
	"campaigns/createCampaign",
	async ({ name, campaignPurposeId, parentCampaignTypeId, websiteId }) => {
		const url = `${endpoints.coreApi}${CAMPAIGNS_URL}`;

		const formData = {
			data: {
				attributes: {
					name: name,
				},
				relationships: {
					"campaign-purpose": {
						data: {
							type: "campaign-purposes",
							id: campaignPurposeId,
						},
					},
					"parent-campaign-type": {
						data: {
							type: "parent-campaign-types",
							id: parentCampaignTypeId,
						},
					},
					website: {
						data: {
							type: "websites",
							id: websiteId,
						},
					},
				},
			},
		};

		const submitData = merge(BLANK_CAMPAIGN_DATA, formData);
		const response = await ApiService.post(url, { payload: submitData });

		if (!response.ok) {
			throw new Error("Failed to load campaigns!");
		}

		return response.json();
	}
);

export const updateCampaignTraffic = createAsyncThunk(
	"campaigns/updateCampaignTraffic",
	async ({ id, test_traffic }) => {
		const url = `${endpoints.coreApi}${CAMPAIGNS_URL}/update_traffic`;
		const body = `id=${id}&test_traffic=${test_traffic}`;
		const response = await ApiService.postAsForm(url, { payload: body });

		if (!response.ok) {
			throw new Error("Failed to update campaign traffic!");
		}

		return response.json();
	}
);

export const updateCampaignTrafficType = createAsyncThunk(
	"campaigns/updateCampaignTrafficType",
	async ({ archived, ids, status, website_id }) => {
		const url = `${endpoints.coreApi}${CAMPAIGNS_URL}/update_status`;
		const body = `archived=${archived}&ids[]=${ids}&status=${status}&website_id=${website_id}`;
		const response = await ApiService.postAsForm(url, { payload: body });

		switch (status) {
			case "on":
				toast.success("Campaign successfully set on", { autoClose: 750 });
				break;
			case "test":
				toast.info("Campaign successfully set to test", { autoClose: 750 });
				break;
			case "wip":
				toast.info("Campaign successfully set to WIP", { autoClose: 750 });
				break;
			case "off":
				toast.warning("Campaign successfully set to off", { autoClose: 750 });
				break;
			default:
				break;
		}

		if (!response.ok) {
			throw new Error("Failed to update campaign traffic type!");
		}

		return response.json();
	}
);

export const updateCampaignActiveStatus = createAsyncThunk(
	"campaigns/updateCampaignActiveStatus",
	async ({ archived, ids, status, website_id }) => {
		const url = `${endpoints.coreApi}${CAMPAIGNS_URL}/update_status`;
		const valueIds = ids.join("&ids[]=");
		const body = `archived=${archived}&ids[]=${valueIds}&status=${status}&website_id=${website_id}`;
		const response = await ApiService.postAsForm(url, { payload: body });

		if (!response.ok) {
			throw new Error(`Failed to ${status} campaign!`);
		}

		return response.json();
	}
);

export const campaignsSetOrder = createAsyncThunk(
	"campaigns/campaignsSetOrder",
	async ({ website_id, campaign_ids }) => {
		const url = `${endpoints.coreApi}${CAMPAIGNS_URL}/set_order`;
		const paramsIds = campaign_ids.reduce((params, value) => {
			return `${params}&campaign_ids[]=${value}`;
		}, "");
		const body = `website_id=${website_id}${paramsIds}`;
		const response = await ApiService.postAsForm(url, { payload: body });

		if (!response.ok) {
			throw new Error("Failed to set campaigns order!");
		}

		return response.json();
	}
);

export const campaignSetName = createAsyncThunk("campaigns/campaignSetName", async (name) => name);

export const campaignSetPurpose = createAsyncThunk(
	"campaigns/campaignSetPurpose",
	async (purpose) => purpose
);

export const campaignSetSubpurpose = createAsyncThunk(
	"campaigns/campaignSetSubpurpose",
	async (subpurpose) => subpurpose
);

export const campaignSetDeploymentType = createAsyncThunk(
	"campaigns/campaignSetDeploymentType",
	async (type) => type
);

export const campaignSetTrafficType = createAsyncThunk(
	"campaigns/campaignSetTrafficType",
	async (type) => type
);

export const campaignSetTrafficPercentage = createAsyncThunk(
	"campaigns/campaignSetTrafficPercentage",
	async (percent) => percent
);
