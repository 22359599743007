import styled from "styled-components";

export const Title = styled.div`
	color: #191919;
	font-size: 1rem;
	font-weight: 600;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	position: relative;
	min-height: 3.143rem;
	padding: 0 0.786rem;
	background: #ffffff;
	z-index: 1;
	text-align: left;
	border-bottom: 1px solid #e8e8e8;
`;
