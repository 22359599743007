import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Plus } from "react-feather";
import { useSelector } from "react-redux";
import { useEspProfileActions } from "../../../hooks/esp-profiles/useEspProfileActions";
import { selectWebsiteId } from "../../../redux/espProfiles/selectors";
import { PopupModal } from "../../../components";
import ESPProfileForm, { FormActions } from "./ESPProfileForm";

const NewESPProfileButton = () => {
	const [showForm, setShowForm] = useState(false);
    const websiteId = useSelector(selectWebsiteId);
    const { createEspProfile, getEspProfiles, getEsps } = useEspProfileActions(websiteId);

    return(
        <>
            <PopupModal 
                showModal={showForm} 
                toggleModal={() => setShowForm(false)}>
				<ESPProfileForm 
                    closeForm={() => setShowForm(false)} 
                    formAction={FormActions.CREATE}
                    onSubmit={(profileDto) => {
                        createEspProfile(profileDto, {
                            onFulfilled: () => {
                                setShowForm(false);
                                getEspProfiles();
                                getEsps();
                            }
                        });
                    }}
                />
			</PopupModal>

            <div className="nav-bar_right">
                <Button 
                    data-qa="new-experience-button" 
                    onClick={() => setShowForm(true)}>
                    <Plus size={10}/> Create new Onsite ESP Profile
                </Button>
            </div>
        </>
    )
}

const Button = styled.button`
	font-size: 12px;
	font-weight: 600;
	font-stretch: 100%;
	line-height: 12px;
	background: none;
	border: none;
	color: #191919;
	cursor: pointer;
    padding-right: 15px;
	&:hover {
		color: white;
	}
`;

NewESPProfileButton.propTypes = {
    onClick: PropTypes.func,
}

export default NewESPProfileButton;
