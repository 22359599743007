import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { crossSiteDuplicateCampaign, createNewCampaign } from "../../../redux/campaigns/thunks";

import { Button } from "../../../components";
import { FormInput, Dropdown } from "../../../components/form";

const NEW_BEHAVIORAL_PURPOSE_SELECTOR = "2";

const newBehavioralExperienceForm = ({ closeForm, websiteId }) => {
	const [behavioralExperienceName, setBehavioralExperienceName] = useState(
		"New Behavioral Experience"
	);
	const [websiteIndustry, setWebsiteIndustry] = useState("");
	const [campaignPurpose, setCampaignPurpose] = useState("");
	const [campaignSubPurpose, setCampaignSubPurpose] = useState("");

	const {
		purposes: {
			response: { data: purposes = [] },
		},
		parentCampaignTypes: {
			response: { data: parentCampaignTypes = [] },
		},
		campaignTemplates: {
			response: { data: campaignTemplates = [] },
		},
	} = useSelector((state) => state);

	const isCreateExperienceDisabled =
		!behavioralExperienceName || !campaignPurpose || !campaignSubPurpose;

	const dispatch = useDispatch();

	const onlyCmsPurposes = [{ value: "", name: "Select Purpose..." }].concat(
		purposes
			.filter(
				({ attributes = {} }) =>
					attributes["industry-id"] === NEW_BEHAVIORAL_PURPOSE_SELECTOR
			)
			.map(({ attributes }) => {
				return {
					value: attributes?.id,
					name: attributes?.name,
				};
			})
	);

	const campaignTemplate = [{ value: "", name: "Select Campaign Template..." }].concat(
		campaignTemplates.map(({ attributes }) => {
			return {
				value: attributes?.id,
				name: attributes?.name,
			};
		})
	);

	const templateToCopy = campaignTemplates.find((template) => {
		if (template?.id === websiteIndustry) {
			return true;
		}
		return false;
	});

	const onlySubPurpose = purposes.find((purpose) => {
		if (purpose?.id === campaignPurpose) {
			return true;
		}
		return false;
	});

	const subArray = onlySubPurpose?.relationships?.["parent-campaign-types"]?.data || [];

	let list = [];
	subArray.forEach(({ id }) => {
		list.push(
			parentCampaignTypes.find((purpose) => {
				return purpose.id === id;
			})
		);
	});

	list = list
		.filter((listItem) => listItem)
		.map(({ attributes }) => {
			return {
				value: attributes.id,
				name: attributes.name,
				order: Number(attributes.order),
			};
		})
		.sort((numberOne, numberTwo) => numberOne.order - numberTwo.order);

	list.unshift({ order: 0, id: "", name: "Select Subpurpose..." });
	const duplicateButton = async () => {
		const response = await dispatch(
			crossSiteDuplicateCampaign({
				targetSiteIds: [websiteId],
				orderedParentCampaigns: [templateToCopy],
				linkCampaigns: 0,
			})
		);

		const siteId = response?.payload?.data[0]?.attributes?.id;

		if (siteId) {
			window.location.href = `/experiences/cms-legacy/${siteId}/edit`;
		}

		closeForm();
	};

	const createNewCampaignButton = async () => {
		const response = await dispatch(
			createNewCampaign({
				name: behavioralExperienceName,
				series: null, //copied from new CampaignForm series is not set
				websiteId: websiteId,
				campaignPurposeId: campaignPurpose,
				parentCampaignTypeId: campaignSubPurpose,
			})
		);

		const siteId = response?.payload?.data?.id;

		if (siteId) {
			window.location.href = `/experiences/cms-legacy/${siteId}/edit`;
		}

		closeForm();
	};

	return (
		<FormContainer>
			<Label>Start from a template</Label>
			<Dropdown
				width="100%"
				dataQA="select-template-dropdown"
				value={websiteIndustry}
				onChange={(e) => {
					setWebsiteIndustry(e.id);
				}}
				margin={"5px 0 10px 0"}
				label="Template"
				field="id"
				type="select"
				options={campaignTemplate}
			/>

			<ButtonWrapper>
				<Button
					data-qa="duplicate-template-button"
					disabled={!websiteIndustry}
					onClick={duplicateButton}
					theme="blue"
					fullWidth
				>
					Duplicate Template Campaign
				</Button>
			</ButtonWrapper>

			<SpacerLabel>or</SpacerLabel>

			<Label>Start from Scratch</Label>

			<Dropdown
				width="100%"
				dataQA="select-purpose-dropdown"
				options={onlyCmsPurposes}
				value={campaignPurpose}
				onChange={(e) => {
					setCampaignPurpose(e.id);
				}}
				label="Purpose"
				margin={"5px 0 10px 0"}
				field="id"
				type="select"
			/>

			<Dropdown
				width="100%"
				dataQA="select-subpurpose-dropdown"
				options={list}
				value={campaignSubPurpose}
				onChange={(e) => {
					setCampaignSubPurpose(e.id);
				}}
				label="Subpurpose"
				margin={"5px 0 10px 0"}
				field="id"
				type="select"
			/>

			<FormInput
				dataQA="behavioral-experience-name"
				value={behavioralExperienceName}
				onChange={setBehavioralExperienceName}
				label="Name"
				placeholder="example.com"
			/>

			<ButtonWrapper>
				<Button
					data-qa="create-blank-experience-button"
					disabled={isCreateExperienceDisabled}
					onClick={createNewCampaignButton}
					theme="blue"
					fullWidth
				>
					Create Blank Experience
				</Button>
			</ButtonWrapper>
		</FormContainer>
	);
};

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
	max-width: 440px;

	button {
		margin-top: 5px;
	}
`;

const ButtonWrapper = styled.div`
	width: 230px;
`;

const SpacerLabel = styled.div`
	color: #3a6277;
	font-size: 1rem;
	font-weight: 700;
	text-transform: uppercase;
	margin: 30px 0;
`;

const Label = styled.label`
	display: block;
	margin: 5px 0;
	padding: 0;
	font-size: 12px;
	font-weight: 600;
	width: 100%;
	text-align: left;
	color: #535252;
`;

newBehavioralExperienceForm.propTypes = {
	closeForm: PropTypes.func.isRequired,
	websiteId: PropTypes.string.isRequired,
};

export default newBehavioralExperienceForm;
