import { createSlice } from "@reduxjs/toolkit";
import { getCampaignTypes } from "./thunks";

const initialState = {
	isFetchError: false,
	response: {
		data: [],
	},
};

const campaignTypes = createSlice({
	name: "campaignTypes",
	initialState,
	reducers: {
		clearCampaignTypesApiError(state) {
			state.isFetchError = false;
		}
	},
	extraReducers: (builder) => {
		// getPurposes
		builder.addCase(getCampaignTypes.fulfilled, (state, action) => {
			state.response = action.payload;
		});
		builder.addCase(getCampaignTypes.rejected, (state) => {
			state.isFetchError = true;
		});
	},
});

export const {
	clearCampaignTypesApiError,
} = campaignTypes.actions;

export default campaignTypes.reducer;
