import React from "react";
import PropTypes from "prop-types";

const ReactLink = ({ className, href, children, fontWeight }) => {
	const onClick = (event) => {
		event.preventDefault();
		window.history.pushState({}, "", href);

		const navEvent = new PopStateEvent("popstate");
		window.dispatchEvent(navEvent);
	};

	return (
		<a className={className ?? ""} href={href} onClick={onClick} style={{ fontWeight }}>
			{children}
		</a>
	);
};

export default ReactLink;

ReactLink.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node.isRequired,
	href: PropTypes.string.isRequired,
	fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
