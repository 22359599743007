
export const UrlTemplate = {
    PREVIEW: '/preview?url=:url',
    CMS_CAMPAIGN: '/experiences/cms/:campaignId/edit',
    TOP_STATS: '/clients/stats?website_id=:websiteId',
    // TODO: "websites-legacy" will eventually be changed
    WEBSITE_SETTINGS: '/websites-legacy/:websiteId/edit',
    LEGACY_INBOX: '/clients/stats_inbox?website_id=:websiteId',
    REPORT: '/reports?type=websiteCampaign&website_id=:websiteId',
    /**
     * Removing this because it goes to the same place as LEGACY_INBOX;
     * this is true even in the ember version. I want to leave this as
     * a comment, however, as it should probably be included, but the
     * functionality is fundamentally broken.
     */
    //PERFORMANCE_REPORT: '/clients/stats_inbox?website_id=:websiteId',
    COMPARISON: '/reports?type=websiteComparison&website_id=:websiteId',
}

export const openNewTabWithUrl = (urlTemplate, params, target) => {
    const url = Object.entries(params).reduce((result, [key, value]) => {
        return result.replace(`:${key}`, value);
    }, urlTemplate);
    window.open(`${window.location.origin}${url}`, target, 'noopener,noreferrer');
};

export const generateSameDomainUrl = (urlTemplate, params) => {
    const url = Object.entries(params).reduce((result, [key, value]) => {
        return result.replace(`:${key}`, value);
    }, urlTemplate);
    return `${window.location.origin}${url}`
}
