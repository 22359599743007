import React, { useState } from "react";
import { useSelector } from "react-redux";
import Design from "./Design";
import Preview from "./Preview";
import {
    HeaderLabel,
    Container,
    DesignPreviewContainer,
    LeftContainer,
    RightContainer,
    PreviewAside,
    ViewSizes,
    Size,
    PreviewHeader
} from "./Design.styled";
import InboxToolTips from "./InboxToolTips";
import TestOptions from './TestOptions'


/**
 * TODO: need to figure out the scrolling for the whole
 * page. Not UX/UI friendly.
 */
const DesignContainer = () => {
    const [isDesignExpanded, setIsDesignExpanded] = useState(false);
    const [zoomValue, setZoomValue] = useState(75)
    const [previewType, setPreviewType] = useState("desktop")
    const campaign = useSelector((state) => state.inboxCampaigns?.selectedInboxCampaign);

	return (
        <Container>
            <DesignPreviewContainer>
                <LeftContainer expanded={isDesignExpanded}>
                    <InboxToolTips setIsDesignExpanded={setIsDesignExpanded} isDesignExpanded={isDesignExpanded} />
                    <Design campaign={campaign} isDesignExpanded={isDesignExpanded} setIsDesignExpanded={setIsDesignExpanded}/>
                </LeftContainer>
                {!isDesignExpanded && (
                    <RightContainer>
                        <PreviewHeader>
                            <HeaderLabel>
                                Preview
                            </HeaderLabel>
                            <PreviewAside>
                                <input
                                    type="range"
                                    min="20"
                                    max="75"
                                    value={zoomValue}
                                    className="slider"
                                    onChange={(e) => setZoomValue(e.currentTarget.value)} />
                                    <ViewSizes>
                                        <Size onClick={()=>setPreviewType("mobilexs")} selected={(previewType === "mobilexs")}>
                                            Mobile XS
                                        </Size>
                                        <Size onClick={()=>setPreviewType("mobile")} selected={(previewType === "mobile")}>
                                            Mobile
                                        </Size>
                                        <Size onClick={()=>setPreviewType("desktop")} selected={(previewType === "desktop")}>
                                            Desktop
                                        </Size>

                                    </ViewSizes>
                            </PreviewAside>
                        </PreviewHeader>
                        {campaign &&
                        <>
                            <Preview campaign={campaign} isDesignExpanded={isDesignExpanded} zoomValue={zoomValue} previewType={previewType} />
                            <TestOptions campaignId={campaign.id} itemValues={campaign?.attributes['test-items-json']} />
                        </>}
                    </RightContainer>
                )}

            </DesignPreviewContainer>

        </Container>
	);
};

export default DesignContainer;
