//reactApp/redux/website/thunks.js
export const WEBSITES_URL = "/websites";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";

export const getWebsite = createAsyncThunk("website/getWebsite", async (id) => {
	const url = `${endpoints.coreApi}${WEBSITES_URL}/${id}`;
	const response = await ApiService.get(url);

	if (!response.ok) {
		throw new Error("Failed to download website!");
	}

	return response.json();
});

export const saveWebsite = createAsyncThunk("website/saveWebsite", async (website) => {
	const url = `${endpoints.coreApi}${WEBSITES_URL}/${website.data.id}`;
	const response = await ApiService.patch(url, { payload: website });

	if (!response.ok) {
		throw new Error("Failed to save website!");
	}

	return response.json();
});

export const getWebsiteId = createAsyncThunk("website/getWebsiteId", async (campaignId) => {
	const url = `${endpoints.coreApi}/campaigns/${campaignId}`;
	const response = await ApiService.get(url);

	if (!response.ok) {
		throw new Error("Could not find website ID.");
	}

	return response.json();
});
