import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Button } from "../../../../../components";

const Pagination = ({
	currentDataCount = -1,
	total,
	limit,
	loadMore,
	style,
}) => {
	return (
		<PaginationWrapper styleOptions={style}>
			<Info>
				<span>Only {limit} rows of data can be loaded at a time.</span>
			</Info>
			<Info>
				<span>
					{currentDataCount}&nbsp;Rows
					{total !== undefined ? ` of ${total}` : ""}
				</span>
				<Button theme="blue" onClick={loadMore} disabled={loadMore ? false : true}>
					Load Next
				</Button>
			</Info>
		</PaginationWrapper>
	);
};
Pagination.propTypes = {
	currentDataCount: PropTypes.number.isRequired,
	total: PropTypes.number,
	limit: PropTypes.number,
	loadMore: PropTypes.func,
	style: PropTypes.object,
};
const PaginationWrapper = styled.div`
	position: fixed;
	right: 12.5vw;
	top: ${(props) => props?.styleOptions?.top || "225px"};
	@media (max-width: ${(props) => props?.styleOptions?.mediaWidth || "768px"}) {
		top: ${(props) => props?.styleOptions?.mediaTop || "225px"};
	}
	background: #fff;
	padding: 6px;
	border: 1px solid black;
`;
const Info = styled.div`
	display: flex;
	flex-direction: column;
	font-weight: 600;
	justify-content: space-between;
	& span {
		font-size: 10px;
	}
	& button {
		margin: 10px 22px 0 0;
		max-width: 100px;
	}
`;

export default Pagination;
