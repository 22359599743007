import { createEntityAdapter } from "@reduxjs/toolkit";

const childrenCampaignsAdapter = createEntityAdapter({
	selectId: (campaign) => campaign.id,
});

export const {
	selectAll: selectAllChildrenCampaigns,
} = childrenCampaignsAdapter.getSelectors((state) => state.campaigns.response.included);

export default childrenCampaignsAdapter;
