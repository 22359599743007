// eslint-disable-next-line no-unused-vars
import React from "react";
import styled from "styled-components";

const Table = styled.table`
	width: 100%;
	text-align: left;
	margin: 10px 0;
	min-width: 535px;
`;
export default Table;
