import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Select } from "../../../components";
import { WEBSITE_MODULES_FILTER_OPTIONS } from "../../../redux/websites/constants";

const WebsitesFilters = ({
	onChange,
	contractValue,
	industryValue,
	verticalValue,
	moduleValue,
	showValue,
}) => {
	const {
		contracts: {
			response: { data: contracts = [] },
		},
		industries: {
			response: { data: industries = [] },
		},
		verticals: {
			response: { data: verticals = [] },
		},
	} = useSelector((state) => state);

	const getListElements = (type) => {
		switch (type) {
			case "contracts":
				return [
					{ id: "", name: "All Contract Types" },
					...contracts.map((el) => el.attributes),
				];
			case "industries":
				return [
					{ id: "", name: "All Industries" },
					...industries.map((el) => el.attributes),
				];
			case "verticals":
				return [
					{ id: "", name: "All Verticals" },
					...verticals.map((el) => el.attributes),
				];
			case "modules":
				return [...WEBSITE_MODULES_FILTER_OPTIONS];
			default:
				return [];
		}
	};

	return (
		<FiltersContainer>
			<label>Filters</label>
			<ButtonsContainer>
				<Button
					onClick={() => onChange({ type: "show", value: "mine" })}
					isActive={showValue === "mine"}
					data-qa="filter-button-mine"
				>
					MINE
				</Button>
				<Button
					onClick={() =>
						onChange({ type: "show", value: "managers" })
					}
					isActive={showValue === "managers"}
					data-qa="filter-button-managers"
				>
					MANAGERS
				</Button>
				<Button
					onClick={() => onChange({ type: "show", value: "all" })}
					isActive={showValue === "all"}
					data-qa="filter-button-all"
				>
					ALL
				</Button>
			</ButtonsContainer>
			<Select
				data={getListElements("contracts")}
				value={contractValue}
				onChange={(value) => onChange({ type: "contract", value })}
				dataQA="contracts"
			/>
			<Select
				data={getListElements("industries")}
				value={industryValue}
				onChange={(value) => onChange({ type: "industry", value })}
				dataQA="industries"
			/>
			<Select
				data={getListElements("verticals")}
				value={verticalValue}
				onChange={(value) => onChange({ type: "vertical", value })}
				dataQA="verticals"
			/>
			<Select
				data={getListElements("modules")}
				value={moduleValue}
				onChange={(value) => onChange({ type: "module", value })}
				dataQA="modules"
			/>
		</FiltersContainer>
	);
};

WebsitesFilters.propTypes = {
	onChange: PropTypes.func.isRequired,
	contractValue: PropTypes.string.isRequired,
	industryValue: PropTypes.string.isRequired,
	verticalValue: PropTypes.string.isRequired,
	moduleValue: PropTypes.string.isRequired,
	showValue: PropTypes.string.isRequired,
};

const FiltersContainer = styled.div`
	margin: 11px 0;
	box-sizing: border-box;
	& > div:not(:first-of-type) {
		margin: 11px 0;
	}
`;

const ButtonsContainer = styled.div`
	display: flex;
	width: 100%;
`;

const Button = styled.button`
	flex: 1 1 0;
	height: 40px;
	background-color: white;
	border: ${({ isActive }) =>
		isActive
			? "1px solid rgb(48, 61, 120)"
			: "1px solid rgb(232, 232, 232)"};
	border-radius: 0;
	color: rgb(25, 25, 25);
	font-size: 10px;
	z-index: ${({ isActive }) => (isActive ? "2" : "1")};
	&:not(:first-child) {
		margin-left: -1px;
	}
	&:hover {
		border: 1px solid rgb(48, 61, 120);
		z-index: 2;
	}
`;

export default WebsitesFilters;
