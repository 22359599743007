import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Settings, BarChart, Menu  } from "react-feather";
import { ICON_SIZES } from "../../../../constants/common";

const HeaderComponent = ({ website_id = -1 }) => {
	return (
		<Header>
			<TextLinks website_id={website_id} />
			<IconLinks website_id={website_id} />
		</Header>
	);
};
HeaderComponent.propTypes = {
	website_id: PropTypes.number.isRequired,
};
const TextLinks = ({ website_id }) => {
	return (
		<Row>
			<li>
				<a href="/websites">Websites</a>
			</li>
			<li>
				<a href={`/experiences?website_id=${website_id}`}>Campaigns</a>
			</li>
		</Row>
	);
};
TextLinks.propTypes = {
	website_id: PropTypes.number.isRequired,
};
const IconLinks = ({ website_id }) => {
	return (
		<Row>
			<li>
				<a
					href={`/experiences?website_id=${website_id}`}
				>
					<Menu size={ICON_SIZES.medium} aria-label="Menu" />
				</a>
			</li>
			<li>
				<a
					href={`/websites/${website_id}/edit`}
				>
					<Settings size={ICON_SIZES.medium} aria-label="Settings" />
				</a>
			</li>
			<li>
				<a
					href={`/reports?type=websiteCampaign&website_id=${website_id}`}
				>
					<BarChart size={ICON_SIZES.medium} aria-label="bar chart" />
				</a>
			</li>
		</Row>
	);
};
IconLinks.propTypes = {
	website_id: PropTypes.number.isRequired,
};
const Header = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	align-items: flex-start;
`;
const Row = styled.ul`
	display: inline-block;

	& > li {
		font-weight: bolder;
		display: inline-block;
		list-style: none;
		margin: 0 1em;
	}
`;

export default HeaderComponent;
