import { createAsyncThunk } from "@reduxjs/toolkit";
import fetch from "isomorphic-fetch";

import { endpoints } from "../../constants/common";
import { LocalStorageService } from "../../service/localStorage";
import { appendQueryParams } from "../businessLogic/utils";

export const getUploadFiles = createAsyncThunk(
	"uploadFiles/getUploadFiles",
	async (queryParamsObj) => {
		const url = appendQueryParams(
			`${endpoints.legacyApiDomain}/clients/uploads_list`,
			queryParamsObj
		);

		const response = await fetch(url, {
			method: "GET",
			cache: "no-cache",
			headers: {
				Authorization: `Bearer ${LocalStorageService.getToken()}`,
			},
		});

		return await response.json();
	}
);

export const postUploadFiles = createAsyncThunk(
	"uploadFiles/postUploadFiles",
	async ({ formData, queryParamsObj }) => {
		const url = appendQueryParams(
			`${endpoints.legacyApiDomain}/clients/upload_file`,
			queryParamsObj
		);

		const response = await fetch(url, {
			cache: "no-cache",
			method: "POST",
			body: formData,
			headers: {
				Authorization: `Bearer ${LocalStorageService.getToken()}`,
			}
		});

		return await response.json();
	}
);

export const deleteFile = createAsyncThunk(
	"uploadFiles/deleteFile",
	async (formData) => {
		const url = `${endpoints.legacyApiDomain}/clients/delete_upload`;

		const response = await fetch(url, {
			cache: "no-cache",
			method: "POST",
			body: formData,
			headers: {
				Authorization: `Bearer ${LocalStorageService.getToken()}`,
			},
		});

		return await response.json();
	}
);
