import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector , useDispatch } from "react-redux";
import { getTopStats } from "../../../redux/topStats/thunks";
import { Loader, Wrapper } from "../../../components";

import {
	HeaderBar,
	HeaderLink,
	HeaderLinkText,
	Row,
	FillColumn,
	QuarterColumn,
	HalfColumn,
	ThreeFourthsColumn,
	SubHeader,
	Paragraph,
	Stat,
	StatLabel,
	BlueStat,
	BlueStatLabel,
	DashedDivider,
	InlineStatContainer,
	NotesContainer,
	Notes,
	Icon,
} from "./../styledComponents";
import AreaGraph from "./../areaGraph";
import FilterColumn from "./../filterColumn";
import {
	DAYS,
	WRAPPER_STYLE
} from "./../constants";
import {
	formatDollars,
	formatNumber,
	formatDate,
	formatPercentage,
	generateStartDate,
	generateEndDate,
	formatChartDate,
} from "./../utils";

const TopStats = () => {
	const {
		page: { params },
		topStats: {
			isLoading,
			response: {
				website,
				top_stats: {
					list_expansion,
					ibx_retargeting,
					usage,
					cms_modules,
					chart_data,
				},
			},
		},
	} = useSelector((state) => state);

	const generatedStartDate = generateStartDate();
	const generatedEndDate = generateEndDate();
	const dispatch = useDispatch();

	const [showLeftColumn, toggleLeftColumn] = useState(false);
	const [startDate, setStartDate] = useState(params.startDate || generatedStartDate);
	const [endDate, setEndDate] = useState(params.endDate || generatedEndDate);
	const [days, toggleDays] = useState(params.attribution || DAYS.TWENTY_FOUR);

	const displayedStartDate = formatDate(startDate);
	const displayedEndDate = formatDate(endDate);

	const pushUrlParams = ({ newStartDate, newEndDate, newDays }) => {
		const urlWithoutParams = window.location.href.split("?")[0];
		window.history.pushState("", "", `${urlWithoutParams}?website_id=${params.websiteId}&start_date=${newStartDate || startDate}&end_date=${newEndDate || endDate}&attribution=${newDays || days}`);
	};

	const modifiedSetStartDate = (newStartDate) => {
		setStartDate(newStartDate);
		pushUrlParams({ newStartDate });
	};

	const modifiedSetEndDate = (newEndDate) => {
		setEndDate(newEndDate);
		pushUrlParams({ newEndDate });
	};

	const modifiedToggleDays = (newDays) => {
		toggleDays(newDays);
		pushUrlParams({ newDays });
	};

	const handleBackButton = () => {
		const urlParams = new URLSearchParams(window.location.search);
		const startDateParam = urlParams.get("start_date");
		const endDateParam = urlParams.get("end_date");
		const daysParam = urlParams.get("attribution");

		setStartDate(startDateParam || generatedStartDate);
		setEndDate(endDateParam || generatedEndDate);
		toggleDays(daysParam || DAYS.TWENTY_FOUR);
	};

	useEffect(() => {
		dispatch(getTopStats({ websiteId: params.websiteId, queryParamsObj: {
			date_start: startDate,
			date_end: endDate,
			attribution: days,
		}}));
	}, [startDate, endDate, days]);

	useEffect(() => {
		window.addEventListener("popstate", handleBackButton);
		return () => window.removeEventListener("popstate", handleBackButton);
	}, [handleBackButton]);

	// Chart Data
	const emailSubmitsData = chart_data.submits.map((datum) => ({
		value: datum.submits,
		date: datum.dt_date,
	}));

	const clickRevenueData = chart_data.revenue.map((datum) => ({
		value: datum.revenue,
		date: datum.dt_date,
	}));

	const experiencesData = [];
	const variationsData = [];

	chart_data.usage.forEach((datum) => {
		experiencesData.push({
			value: datum.campaigns,
			date: datum.dt_date,
		});

		variationsData.push({
			value: datum.variations,
			date: datum.dt_date,
		});
	});

	if (isLoading) {
		return <Loader isLoading={isLoading} />;
	}

	return (
		<Wrapper style={WRAPPER_STYLE}>
			<div style={{ display: "flex" }}>
				{
					showLeftColumn &&
					<FilterColumn
						endDate={endDate}
						startDate={startDate}
						setStartDate={modifiedSetStartDate}
						setEndDate={modifiedSetEndDate}
						days={days}
						toggleDays={modifiedToggleDays}
					/>
				}
				<FillColumn>
					<HeaderBar>
						<div>
							<HeaderLink onClick={() => toggleLeftColumn(!showLeftColumn)}>
								<HeaderLinkText>Filters</HeaderLinkText>
							</HeaderLink>
						</div>
						<div style={{ display: "flex", height: "100%", alignItems: "center" }}>
							<HeaderLink style={{ backgroundColor: "rgb(245, 235, 225)" }}>
								<Icon className="icon-file-text-o" />
								<HeaderLinkText>Top Level Stats</HeaderLinkText>
							</HeaderLink>
							<HeaderLink href={`/clients/stats?website_id=${params.websiteId}`}>
								<Icon className="icon-file-text-o" />
								<HeaderLinkText>Detailed Stats</HeaderLinkText>
							</HeaderLink>
						</div>
						<div>
							<HeaderLink href={`/campaigns?website_id=${params.websiteId}`}>
								<Icon className="icon-th-list" />
							</HeaderLink>
							<HeaderLink href={`/clients/stats_inbox?website_id=${params.websiteId}`}>
								<Icon className="icon-envelope-o" />
							</HeaderLink>
							<HeaderLink href={`/websites/${params.websiteId}/edit`}>
								<Icon className="icon-cog" />
							</HeaderLink>
						</div>
					</HeaderBar>

					<MajorRowHeader>
						<RowHeader>Behavioral Email</RowHeader>
						<RowHeaderItem>
							{website.name} &nbsp; {`${displayedStartDate} to ${displayedEndDate}`}
						</RowHeaderItem>
					</MajorRowHeader>

					<Row>
						<QuarterColumn>
							<SubHeader>Identification &#38; List Expansion</SubHeader>
							<Paragraph>Ties visitor behavioral data to an email address&#44; unlocking previously impossible targeting opportunities both on and off site&#46;</Paragraph>
						</QuarterColumn>
						<QuarterColumn>
							<Stat>{formatNumber(list_expansion.live_to_date)}</Stat>
							<StatLabel>Total Email Submits to Date</StatLabel>
							<DashedDivider />
							<BlueStat>{formatNumber(list_expansion.current)}</BlueStat>
							<BlueStatLabel>Total Email Submits this Period</BlueStatLabel>
						</QuarterColumn>
						<HalfColumn>
							<AreaGraph
								label="Email Submits"
								rawData={emailSubmitsData}
								yAxisFormatter={formatNumber}
								xAxisFormatter={formatChartDate}
								tooltipFormatter={formatNumber}
							/>
						</HalfColumn>
					</Row>

					<Row>
						<QuarterColumn>
							<SubHeader>Inbox Retargeting</SubHeader>
							<Paragraph>Creates a new&#44; scalable revenue channel&#46; We deploy a series of hyper targeted emails triggered off on-site visitor behavior in order to drive traffic back to site&#46;</Paragraph>
						</QuarterColumn>
						<QuarterColumn>
							<Stat>{formatDollars(ibx_retargeting.total_revenue)}</Stat>
							<StatLabel>Total Click Revenue</StatLabel>
							<DashedDivider />
							<Stat>{formatNumber(ibx_retargeting.email_sends)}</Stat>
							<StatLabel>Email Sends</StatLabel>
							<DashedDivider />
							<div style={{ display: "flex" }}>
								<FilledColumnStatContainer>
									<Stat>{formatPercentage(ibx_retargeting.open_rate)}</Stat>
									<StatLabel>Open Rate</StatLabel>
								</FilledColumnStatContainer>
								<FilledColumnStatContainer>
									<Stat>{formatPercentage(ibx_retargeting.click_to_open_rate)}</Stat>
									<StatLabel>Click to Open Rate</StatLabel>
								</FilledColumnStatContainer>
							</div>
						</QuarterColumn>
						<HalfColumn>
							<AreaGraph
								label="Click Revenue"
								rawData={clickRevenueData}
								yAxisFormatter={formatDollars}
								xAxisFormatter={formatChartDate}
								tooltipFormatter={formatDollars}
							/>
						</HalfColumn>
					</Row>

					{
						cms_modules.length > 0 &&
						<>
							<MajorRowHeader>
								<RowHeader>Behavioral CMS</RowHeader>
								<RowHeaderItem>
									{website.name} &nbsp; {`${displayedStartDate} to ${displayedEndDate}`}
								</RowHeaderItem>
							</MajorRowHeader>

							{
								cms_modules
									.filter((mod) => {
										return (
											mod.module.revenue_lift ||
											mod.module.rate_lift ||
											mod.module.live_campaign ||
											mod.module.best_revenue_lift ||
											mod.module.best_revenue_conversion_rate_lift
										);
									})
									.map((mod, index) => (
										<Row key={index}>
											<QuarterColumn>
												<SubHeader>{mod.name}</SubHeader>
												<Paragraph>{mod.description}</Paragraph>
											</QuarterColumn>
											<ThreeFourthsColumn>
												<div style={{ display: "flex" }}>
													<FilledColumnStatContainer>
														<Stat>
															{formatDollars(mod.module.revenue_lift)}
														</Stat>
														<StatLabel>
															Aggregated Module<br />
															Total Revenue Lift
														</StatLabel>
													</FilledColumnStatContainer>
													<FilledColumnStatContainer>
														<Stat>
															{formatPercentage(mod.module.rate_lift)}
														</Stat>
														<StatLabel>
															Aggregated Module<br />
															Average Conversion Rate Lift
														</StatLabel>
													</FilledColumnStatContainer>
													<FilledColumnStatContainer>
														<Stat>
															{formatNumber(mod.module.live_campaign)}
															{mod.module.campaigns_with_immature_data > 0 && <span>&dagger;</span>}
														</Stat>
														<StatLabel>
															Aggregated Module<br />
															Total Experiences Live
														</StatLabel>
													</FilledColumnStatContainer>
												</div>
												<div style={{ display: "flex" }}>
													<FilledColumnStatContainer>
														<BlueStat>
															{formatDollars(mod.module.best_revenue_lift)}
															{mod.module.best_revenue_is_immature && <span>&dagger;</span>}
														</BlueStat>
														<BlueStatLabel>
															Best Performer in Module<br />
															Revenue Lift
														</BlueStatLabel>
													</FilledColumnStatContainer>
													<FilledColumnStatContainer>
														<BlueStat>
															{formatPercentage(mod.module.best_revenue_conversion_rate_lift)}
															{mod.module.best_revenue_is_immature && <span>&dagger;</span>}
														</BlueStat>
														<BlueStatLabel>
															Best Performer in Module<br />
															Conversion Rate Lift
														</BlueStatLabel>
													</FilledColumnStatContainer>
													<FilledColumnStatContainer>
														<SmallBlueStat>
															{mod.module.best_revenue_title}
														</SmallBlueStat>
														<BlueStatLabel>
															Best Performer in Module<br />
															Experience
														</BlueStatLabel>
													</FilledColumnStatContainer>
												</div>
											</ThreeFourthsColumn>
										</Row>
									))
							}

							<NotesContainer>
								<QuarterColumn />
								<ModifiedThreeFourthsColumn>
									<Notes>
										Behavioral CMS modules are run versus a control group to measure impact&#46;
										We use a 7 day attribution window which gives a fair assessment of how an experience influences a visitor&#46;
									</Notes>
								</ModifiedThreeFourthsColumn>
							</NotesContainer>
						</>
					}

					<MajorRowHeader>
						<RowHeader>Usage</RowHeader>
						<RowHeaderItem>
							{website.name} &nbsp; {`${displayedStartDate} to ${displayedEndDate}`}
						</RowHeaderItem>
					</MajorRowHeader>

					<Row>
						<QuarterColumn>
							<Paragraph>
								Bounce Exchange was created by marketers out of frustration with software products and vendors&#46;
							</Paragraph>
							<Paragraph>
								A central limitation of software is that the effectiveness depends on internal teams using it actively&#46;
								We find that most tools are used sparingly&#44; if at all&#46; For example&#44; the average website runs 1 A&#47;B test a month&#46;
							</Paragraph>
							<Paragraph>
								We believe in customer success&#44; not as a buzzword&#44; but as a reason for existing&#46;
								And we have structured our business model&#44; teams and incentives around ensuring you get value out of our software&#46;
							</Paragraph>
						</QuarterColumn>
						<ThreeFourthsColumn>
							<InnerRow style={{ border: "0", marginTop: "0" }}>
								<HalfColumn>
									<SubHeader>Segmentation Criteria</SubHeader>
									<Paragraph>Segmentation is a critical component of creating experiences for visitors that both enhance user experience and improve business performance&#46;</Paragraph>
									<Paragraph>Most tools rely on basic segmentation such as device type or new vs returning visitor&#46;</Paragraph>
									<Paragraph>By instead using a range of hyper granular criteria&#44; your website is transformed from an order taking instrument into an advanced marketing tool that nurtures audience groups based on their needs&#46;</Paragraph>
								</HalfColumn>
								<HalfColumn>
									<Stat>{formatNumber(usage.segments)}</Stat>
									<StatLabel>Total to Date</StatLabel>
								</HalfColumn>
							</InnerRow>
							<InnerRow>
								<HalfColumn>
									<SubHeader>Experiences Run</SubHeader>
									<Paragraph>
										Most websites are essentially a single experience for all visitors&#46;
										Our software creates targeted journeys for every audience segment&#44; all at different behavioral moments&#46;
									</Paragraph>
									<Paragraph>
										The goal is not to run as many experiences as possible at any one time&#44; but to ensure we deploy a set of strategies that drive business impact&#46;
									</Paragraph>
								</HalfColumn>
								<HalfColumn>
									<div style={{ display: "flex" }}>
										<HalfColumn>
											<Stat>{formatNumber(usage.campaigns_run.live_to_date)}</Stat>
											<StatLabel>Total to Date</StatLabel>
										</HalfColumn>
										<HalfColumn>
											<BlueStat>{formatNumber(usage.campaigns_run.current)}</BlueStat>
											<BlueStatLabel>This Period</BlueStatLabel>
										</HalfColumn>
									</div>
									<div>
										<AreaGraph
											label="Experiences Run"
											rawData={experiencesData}
											yAxisFormatter={formatNumber}
											xAxisFormatter={formatChartDate}
											tooltipFormatter={formatNumber}
										/>
									</div>
								</HalfColumn>
							</InnerRow>
							<InnerRow>
								<HalfColumn>
									<SubHeader>Variations Run</SubHeader>
									<Paragraph>We often run multiple variations of an experience to understand the optimal approach&#46;</Paragraph>
									<Paragraph>The goal is always to drive learning which leads back to improved business performance&#46;</Paragraph>
								</HalfColumn>
								<HalfColumn>
									<div style={{ display: "flex" }}>
										<HalfColumn>
											<Stat>{formatNumber(usage.variations_run.live_to_date)}</Stat>
											<StatLabel>Total to Date</StatLabel>
										</HalfColumn>
										<HalfColumn>
											<BlueStat>{formatNumber(usage.variations_run.current)}</BlueStat>
											<BlueStatLabel>This Period</BlueStatLabel>
										</HalfColumn>
									</div>
									<div>
										<AreaGraph
											label="Variations Run"
											rawData={variationsData}
											yAxisFormatter={formatNumber}
											xAxisFormatter={formatChartDate}
											tooltipFormatter={formatNumber}
										/>
									</div>
								</HalfColumn>
							</InnerRow>
						</ThreeFourthsColumn>
					</Row>
				</FillColumn>
			</div>
		</Wrapper>
	);
};

const SmallBlueStat = styled(BlueStat)`
	font-size: 20px;
	height: 102px;
	line-height: 1;
	padding-top: 21px;
`;

const MaxDashedDivider = styled(DashedDivider)`
	max-width: 100%;
`;

const FilledColumnStatContainer = styled(InlineStatContainer)`
	display: flex;
	width: 100%;
`;

const InnerRow = styled(Row)`
	margin-right: 0;
	margin-left: 0;
`;

const ModifiedThreeFourthsColumn = styled(ThreeFourthsColumn)`
	font-size: 10px;
`;

const MajorRowHeader = styled(Row)`
	justify-content: space-between;
	border: 0;
	padding-top: 0;
`;

const RowHeader = styled(FillColumn)`
	font-size: 52.5px;
	height: 71px;
`;

const RowHeaderItem = styled(RowHeader)`
	font-size: inherit;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	padding-bottom: 9.25px;
`;

export default TopStats;
