import styled from "styled-components";

export const Part = styled.div`
	color: #214558;
	font-weight: 600;
`;

export const Checkbox = styled.input`
	padding: 5px;
`;

export const Label = styled.label`
	font-size: 12px;
	cursor: "pointer";
	vertical-align: top;
	display: inline-block;
	margin: 0 3rem 0 0.5rem;
`;

export const Spacer = styled.div`
	align-items: center;
	display: flex;
	margin: 1rem;
	> * {
		margin: 0 1rem 0 0;
		flex-basis: ${(props) => props.flexBasis ?? null};
	}
`;

export const TitleContainer = styled.div`
	min-width: 110px;
	height: 44px;
	margin-right: 5px;
`;

export const SampleMastHeadContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	height: 100px;
`;
export const SampleMasthead = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 44px;
	margin-left: 11px;
	margin-right: 11px;
	border-color: #dbdbdb;
	background: #ffffff;
	z-index: 2;
	box-shadow: 0 0 4px #dbdbdb;
`;

export const SampleMastText = styled.div`
	margin: 0 0.786rem;
	color: #191919;
	&.label {
		font-size: 0.857rem;
		font-weight: 400;
	}
`;
export const SampleMastIcon = styled.i`
	margin: 0 0.786rem;
	color: #191919;
	&.icon-star {
		color: #ffd76e;
	}
`;

export const EligibilityRow = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 11px;
`;

export const EligibilityInputsContainer = styled.div`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
`;

export const EligibilityInput = styled.div`
	width: ${(props) => (props?.isSmall ? "12.681rem" : `15.857rem`)};
	margin: 0 0.786rem;
	color: #191919;
`;

export const AndStatementWrapper = styled.div`
	button {
		padding: 10px 0;
		display: flex !important;
		margin: 0 auto;
		svg {
			margin-right: 10px;
		}
	}
`;

export const Text = styled.div`
	color: 1px solid #214558;
	font-size: 0.857rem;
	font-weight: 400;
`;

export const CloseBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const Divider = styled.div`
	border-top: 1px solid #dbdbdb;
	max-width: 100%;
`;
