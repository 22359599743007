

export const getCalculatedStatus = function (active, enabled, test_mode, wip_mode) {
	if (active === false || active === '0') {
		return "archive";
	} else if (wip_mode === '1' || wip_mode === true) {
		return "wip";
	} else if (enabled === false || enabled === '0') {
		return "off";
	} else if (test_mode === '1' || test_mode === true) {
		return "test";
	} else {
		return "on";
	}
};

export const getPrioritizedStatus = function (status, parentStatus) {
	var priority = ["on", "test", "off", "archive"];
	return priority.indexOf(status) > priority.indexOf(parentStatus)
		? status
		: parentStatus;
};
