import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const themeSwitcher = (theme) => {
	switch (theme) {
		case "blue":
			return {
				color: "#fff",
				background: "#303d78",
				hover: "#1f274c",
			};
		case "gray":
			return {
				color: "#fff",
				background: "#9d9d9d",
				hover: "#848484",
			};
		default:
			return {
				color: "#222",
				background: "transparent",
				hover: null,
			};
	}
};

const ButtonComponent = ({
	children,
	isLoading = false,
	onClick = () => {},
	disabled = false,
	dataQA = "button",
	as = "button",
	url,
	fullWidth = false,
	type = "button",
	theme = "default",
	width = "180px",
}) => {
	const { color, background, hover } = themeSwitcher(theme);

	return (
		<Button
			as={as}
			type={type}
			href={as === "button" ? undefined : url}
			target={disabled ? undefined : "_blank"}
			data-qa={dataQA}
			disabled={disabled}
			onClick={onClick}
			fullWidth={fullWidth}
			background={background}
			hover={hover}
			color={color}
			width={width}
		>
			{children}
			{isLoading && <Spinner />}
		</Button>
	);
};

ButtonComponent.propTypes = {
	children: PropTypes.node.isRequired,
	isLoading: PropTypes.bool,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	url: PropTypes.string,
	dataQA: PropTypes.string,
	as: PropTypes.string,
	fullWidth: PropTypes.bool,
	style: PropTypes.string,
	width: PropTypes.string,
	type: PropTypes.oneOf(["button", "submit", "reset"]),
	theme: PropTypes.oneOf(["gray", "blue", "default"]),
};

const Button = styled.button`
	&& {
		position: relative;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		background: ${(props) => props.background};
		font-size: 12px;
		height: 40px;
		border: none;
		font-weight: 700;
		cursor: pointer;
		width: ${({ fullWidth, width }) => (fullWidth ? "100%" : width)};
		color: ${({ isLoading, color }) => (isLoading ? "transparent" : color)};
		border: none;
		&:hover {
			background: ${(props) => props.hover};
		}
		&:disabled {
			opacity: 0.5;
			&:hover {
				background: ${(props) => props.background};
			}
			cursor: not-allowed;
		}
	}
`;

const Spinner = styled.span`
	border: 0.2em solid white;
	border-radius: 50%;
	border-top: 0.2em solid #3d55cc;
	width: 1.5em;
	height: 1.5em;
	display: block;
	position: absolute;
	animation: spin 2s linear infinite;
`;

export default ButtonComponent;
