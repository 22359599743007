import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { reportTypes } from "../../../../constants/common";
import { Header, Body, Table, Link } from "./components";

const TableComponent = ({
	data = [],
	website_id = -1,
	parent_campaign_id = -1,
	campaign_id = -1,
	type = "",
	date_interval = "day",
	date_start,
	date_end,
}) => {
	return (
		<Table>
			<Header headers={columnArray} />
			<Body
				data={data}
				createCells={createCustomCell(
					type,
					website_id,
					parent_campaign_id,
					campaign_id,
					date_interval,
					date_start,
					date_end
				)}
			/>
		</Table>
	);
};
TableComponent.propTypes = {
	data: PropTypes.array.isRequired,
	type: PropTypes.string.isRequired,
	website_id: PropTypes.number.isRequired,
	parent_campaign_id: PropTypes.number,
	campaign_id: PropTypes.number,
	date_start: PropTypes.string.isRequired,
	date_end: PropTypes.string.isRequired,
	date_interval: PropTypes.string,
};
// TODO: this is too convoluted, think about refactoring.
// Good enough for a first pass to get the logic out.
const createCustomLink = (
	cell,
	row,
	type,
	website_id,
	parent_campaign_id,
	campaign_id,
	date_start,
	date_end
) => {
	const conversionReport = "overall_conversions";
	const emailCaptureReport = "form_submits";
	const idType = {
		[reportTypes.websiteCampaign]: "website_id",
		[reportTypes.parentCampaign]: "parent_campaign_id",
		[reportTypes.childCampaign]: "campaign_id",
	}[type];
	const id = {
		[reportTypes.websiteCampaign]: website_id,
		[reportTypes.parentCampaign]: parent_campaign_id,
		[reportTypes.childCampaign]: campaign_id,
	}[type];

	if (cell === emailCaptureReport) {
		const emailCaptureType = {
			[reportTypes.websiteCampaign]: reportTypes.websiteEmailCapture,
			[reportTypes.parentCampaign]: reportTypes.parentEmailCapture,
			[reportTypes.childCampaign]: reportTypes.childEmailCapture,
		}[type];
		return (
			<Link
				href={`/reports?type=${emailCaptureType}&website_id=${website_id}&${idType}=${id}&date_start=${date_start}&date_end=${date_end}`}
			>
				{row[cell].top}
			</Link>
		);
	}
	if (cell === conversionReport) {
		const conversionType = {
			[reportTypes.websiteCampaign]: reportTypes.websiteConversions,
			[reportTypes.parentCampaign]: reportTypes.parentConversions,
			[reportTypes.childCampaign]: reportTypes.childConversions,
		}[type];
		// INFO: analitics-api requires an exact date for the conversion report type
		const exactDateStart = `${date_start}T00:00:00Z`;
		const exactDateEnd = `${date_end}T23:59:59Z`;

		return (
			<Link
				href={`/reports?type=${conversionType}&website_id=${website_id}&${idType}=${id}&date_start=${exactDateStart}&date_end=${exactDateEnd}`}
			>
				{row[cell].top}
			</Link>
		);
	}
	return row[cell].top;
};
const createCustomCell = (
	type,
	website_id,
	parent_campaign_id,
	campaign_id,
	date_interval,
	date_start,
	date_end
) => {
	return (row = {}) => {
		const cellKeys = columnArray.map(({ id }) => id);
		const dates =
			getRangeRangeFromRow(date_interval, row.date.bottom) ||
			getDateRangeFromFilterOptions(date_start, date_end);
		return cellKeys.map((cell, index) => {
			if (typeof row[cell] === "object") {
				const content = createCustomLink(
					cell,
					row,
					type,
					website_id,
					parent_campaign_id,
					campaign_id,
					dates.from,
					dates.to
				);
				return (
					<Cell key={index}>
						<span>
							{content}
							<br />
							{row[cell].bottom}
						</span>
					</Cell>
				);
			}
			return <Cell key={index}>{row[cell]}</Cell>;
		});
	};
};
const Cell = styled.td`
	text-align: center;
`;
const columnArray = [
	{
		id: "date",
		name: "Date Range",
	},
	{
		id: "overall_visits",
		name: (
			<span>
				Overall
				<br />
				Visits
			</span>
		),
	},
	{
		id: "eligible_visits",
		name: (
			<span>
				Eligible
				<br />
				Visits
			</span>
		),
	},
	{
		id: "overall_impressions",
		name: (
			<span>
				Impressions
				<br />
				Unique / Overall
			</span>
		),
	},
	{
		id: "clicks",
		name: "Clicks",
	},
	{
		id: "closes",
		name: "Closes",
	},
	{
		id: "form_submits",
		name: (
			<span>
				Form
				<br />
				Submits
			</span>
		),
	},
	{
		id: "overall_conversions",
		name: (
			<span>
				Conversions/
				<br />
				Overall
			</span>
		),
	},
];

export default TableComponent;

function getRangeRangeFromRow(dateInterval, rowDate) {
	if (dateInterval === "day") {
		const date = new Date(rowDate).toLocaleDateString("sv-SE", { timeZone: "UTC" });
		return {
			from: date,
			to: date,
		};
	}

	if (dateInterval === "hour") {
		const fromDate = new Date(rowDate);
		const toDate = new Date(rowDate);

		toDate.setHours(toDate.getHours() + 1);

		return {
			from: fromDate.toISOString(),
			to: toDate.toISOString(),
		};
	}
}

function getDateRangeFromFilterOptions(dateStart, dateEnd) {
	return {
		from: dateStart,
		to: dateEnd,
	};
}
