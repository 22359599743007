import { gql } from "urql";
import { COMMON_VALUES, COMPARISON_STATS, COMPARISON_OVERALL_STATS } from "./fragments";

const childCampaignComparisonQuery = gql`
	${COMMON_VALUES}
	${COMPARISON_STATS}
	${COMPARISON_OVERALL_STATS}
	query($websiteId: Int!, $childId: Int!, $parentId: Int!, $start: DateTime!, $end: DateTime!, $campaignAttribution: OnsiteAttribution!) {
		website(id: $websiteId, start: $start, end: $end) {
			id
			onsiteProduct(campaignAttribution: $campaignAttribution) {
				campaign(id: $parentId) {
					common {
						...commonValues
					}
					combinedControl {
						stats {
							...comparisonStats
						}
					}
				}
				variation(id: $childId) {
					common {
						...commonValues
					}
					stats {
						...comparisonStats
					}
				}
			}
		}
		overall: website(id: $websiteId, start: $start, end: $end) {
			id
			onsiteProduct(campaignAttribution: ALL_CONVERSION) {
				campaign(id: $parentId) {
					common {
						...commonValues
					}
					combinedControl {
						stats {
							...comparisonOverallStats
						}
					}
				}
				variation(id: $childId) {
					common {
						...commonValues
					}
					stats {
						...comparisonOverallStats
					}
				}
			}
		}
	}
}
`;

export default childCampaignComparisonQuery;
