import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useQuery } from "urql";
import { reportTypes } from "../../constants/common";
import { Wrapper } from "../../components";
import {
	ComparisonHeader as Header,
	ComparisonControls as Controls,
	Title,
	ComparisonTableQL,
	Loader,
} from "./components";

import {
	websiteComparisonQuery, parentCampaignComparisonQuery, childCampaignComparisonQuery,
} from "./queries";
import { setDataQl } from "../../redux/report/slice";

const COMPARISON_QUERIES_MAP = {
	[reportTypes.childCampaignComparison]: childCampaignComparisonQuery,
	[reportTypes.parentCampaignComparison]: parentCampaignComparisonQuery,
	[reportTypes.websiteComparison]: websiteComparisonQuery,
};

const Comparison = ({
	website_id,
	campaign_id,
	parent_campaign_id,
	reportOptions,
	type,
	title = "",
	onChangeReportOptions,
}) => {
	const dispatch = useDispatch();
	const { date_start, date_end, conv_view, retroactive } = reportOptions;

	const convertToUpperCase = (value) => value ? value.toString().toUpperCase() : "ALL_CREDIT_30";

	const upperCaseConvView = convertToUpperCase(conv_view);
	const campaignAttribution = retroactive ? `${upperCaseConvView}_RETROACTIVE` : upperCaseConvView;
	const qlParamsForParent = type === reportTypes.parentCampaignComparison ? { parentId: parent_campaign_id } : undefined;
	const qlParamsForChild = type === reportTypes.childCampaignComparison
		? { parentId: parent_campaign_id, childId: campaign_id }
		: undefined;

	const [result] = useQuery({
		query: COMPARISON_QUERIES_MAP[type] || websiteComparisonQuery,
		pause: !date_start || !date_end, // wait on report options
		variables: {
			websiteId: website_id,
			start: date_start,
			end: date_end,
			campaignAttribution,
			...qlParamsForParent,
			...qlParamsForChild,
		},
	});

	const { data: QueryData, fetching } = result;

	useEffect(() => {
		if (QueryData) {
			dispatch(setDataQl(QueryData));
		}
	}, [QueryData]);

	useEffect(() => { dispatch(setDataQl(null)) }, []); // clear data on first render

	return (
		<Wrapper maxWidth="92vw" left="4%" title="Website Comparison | Wunderkind">
			<Loader isLoading={fetching} />
			<Header website_id={website_id} />
			<Controls
				reportOptions={reportOptions}
				onChangeReportOptions={onChangeReportOptions}
			/>
			<Title title={title} />
			{QueryData && <ComparisonTableQL fetching={fetching} />}
			
		</Wrapper>
	);
};
Comparison.propTypes = {
	website_id: PropTypes.number.isRequired,
	campaign_id: PropTypes.number.isRequired,
	parent_campaign_id: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	reportOptions: PropTypes.object.isRequired,
	onChangeReportOptions: PropTypes.func.isRequired,
	type: PropTypes.string.isRequired,
};

export default Comparison;
