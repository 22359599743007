import { createSlice } from "@reduxjs/toolkit";
import {
	getCreativeCampaign,
	getCampaigns,
	getCreativeChildrenCampaign,
	getCreativeChildrenCampaigns,
	updateCampaignTraffic,
	updateCampaignTrafficType,
	campaignsSetOrder,
	campaignSetName,
	campaignSetPurpose,
	campaignSetSubpurpose,
	campaignSetDeploymentType,
	campaignSetTrafficType,
	campaignSetTrafficPercentage,
} from "./thunks";
import campaignsAdapter from "./campaignsAdapter";
import childrenCampaignsAdapter from "./childrenCampaignsAdapter";

const initialState = {
	isLoading: false,
	isFetchError: false,
	response: {
		data: campaignsAdapter.getInitialState(),
		meta: {},
		included: childrenCampaignsAdapter.getInitialState(),
	},
	selectedCampaigns: [],
	selectedCreativeCampaigns: [],
	selectedCreativeChildrenCampaigns: [],
	selectedChildrenCreatives: [],
};

const campaignsSlice = createSlice({
	name: "campaigns",
	initialState,
	reducers: {
		clearCampaignsApiError(state) {
			state.isFetchError = false;
		},
		setSelectedCampaign(state, action) {
			state.selectedCampaigns = [action.payload];
		},
		addSelectedCampaign(state, action) {
			const newCampaign = action.payload;
			const isInSelected = state.selectedCampaigns.some((campaign) => {
				return campaign.id === newCampaign.id;
			});

			if (!isInSelected) {
				state.selectedCampaigns.push(newCampaign);
			}
		},
		clearSelectedCampaigns(state) {
			state.selectedCampaigns = [];
		},
		clearCreativeCampaigns(state) {
			state.selectedCreativeCampaigns = [];
			state.selectedCreativeChildrenCampaigns = [];
			state.selectedChildrenCreatives = [];
		}
	},
	extraReducers: (builder) => {
		// getCampaigns
		builder.addCase(getCampaigns.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getCampaigns.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			childrenCampaignsAdapter.setAll(state.response.included, action.payload.included);
			campaignsAdapter.setAll(state.response.data, action.payload.data);
		});
		builder.addCase(getCampaigns.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			campaignsAdapter.removeAll(state.response.data);
			childrenCampaignsAdapter.removeAll(state.response.included);
		});
		builder.addCase(getCreativeCampaign.pending, (state) => {
			state.isLoading = true;
			state.selectedCreativeCampaigns = initialState.selectedCampaigns;
			state.selectedCreativeChildrenCampaigns = initialState.selectedCreativeChildrenCampaigns;
		});
		builder.addCase(getCreativeCampaign.fulfilled, (state, action) => {
			state.isLoading = false;
			const newCampaign = action.payload;
			const { data } = action.payload.data.relationships.children;
			const isInSelected = state.selectedCreativeCampaigns.some((campaign) => {
				return campaign.id === newCampaign.id;
			});

			if (!isInSelected) {
				data.forEach((childData) => {
					state.selectedCreativeChildrenCampaigns.push(childData);
				});
				state.selectedCreativeCampaigns.push(newCampaign);
			}
		});
		builder.addCase(getCreativeCampaign.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			state.selectedCreativeCampaigns = initialState.selectedCampaigns;
		});
		builder.addCase(getCreativeChildrenCampaign.pending, (state) => {
			state.isLoading = true;
			state.selectedCreativeCampaigns = initialState.selectedCampaigns;
			state.selectedCreativeChildrenCampaigns = initialState.selectedCreativeChildrenCampaigns;
		});
		builder.addCase(getCreativeChildrenCampaign.fulfilled, (state, action) => {
			state.isLoading = false;
			const newCampaign = action.payload.data;
			const isInSelected = state.selectedChildrenCreatives.some((campaign) => {
				return campaign.id === newCampaign.id;
			});

			if (!isInSelected) {
				state.selectedChildrenCreatives.push(newCampaign);
			}
		});
		builder.addCase(getCreativeChildrenCampaign.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			state.selectedChildrenCreatives = initialState.selectedChildrenCreatives;
		});
		builder.addCase(getCreativeChildrenCampaigns.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			state.selectedChildrenCreatives = initialState.selectedChildrenCreatives;
		});
		builder.addCase(getCreativeChildrenCampaigns.pending, (state) => {
			state.isLoading = true;
			state.selectedCreativeCampaigns = initialState.selectedCampaigns;
			state.selectedCreativeChildrenCampaigns = initialState.selectedCreativeChildrenCampaigns;
		});
		builder.addCase(getCreativeChildrenCampaigns.fulfilled, (state, action) => {
			state.isLoading = false;
			const {parentCampaign, childrenCampaigns} = action.payload;
			state.selectedCreativeCampaigns = [parentCampaign];
			state.selectedCreativeChildrenCampaigns = [...childrenCampaigns];
		});
		// updateCampaignTrafficType
		builder.addCase(updateCampaignTrafficType.fulfilled, (state, action) => {
			action.payload.data.forEach((campaign) => {
				const isParentCampaign = state.response.data.ids.includes(campaign.id);

				if (isParentCampaign) {
					campaignsAdapter.setOne(state.response.data, campaign);
				} else {
					childrenCampaignsAdapter.setOne(state.response.included, campaign);
				}
			});
		});
		// campaignsSetOrder
		builder.addCase(campaignsSetOrder.fulfilled, (state, action) => {
			const parentCampaigns = [...action.payload.data];
			parentCampaigns.sort((a, b) => {
				return parseInt(a.attributes.order) - parseInt(b.attributes.order);
			});

			campaignsAdapter.setAll(state.response.data, parentCampaigns);
			childrenCampaignsAdapter.setAll(state.response.included, action.payload.included);
		});

		builder.addCase(campaignSetName.fulfilled, (state, { payload: { name, position } }) => {
			if (!position) {
				position = 0;
			}

			state.selectedCampaigns.every((campaign, index) => {
				if (index === position) {
					campaign.attributes.name = name;
					return false;
				}

				return true;
			});
		});

		builder.addCase(
			campaignSetPurpose.fulfilled,
			(state, { payload: { purpose, position } }) => {
				if (!position) {
					position = 0;
				}
				state.selectedCampaigns[position].relationships["campaign-purpose"].data.id =
					purpose;
			}
		);

		builder.addCase(
			campaignSetSubpurpose.fulfilled,
			(state, { payload: { subpurpose, position } }) => {
				if (!position) {
					position = 0;
				}
				state.selectedCampaigns[position].relationships["parent-campaign-type"].data.id =
					subpurpose;
			}
		);

		builder.addCase(
			campaignSetDeploymentType.fulfilled,
			(state, { payload: { type, position } }) => {
				if (!position) {
					position = 0;
				}
				state.selectedCampaigns[position].relationships["campaign-type"].data.id = type;
			}
		);

		builder.addCase(
			campaignSetTrafficType.fulfilled,
			(state, { payload: { type, position } }) => {
				if (!position) {
					position = 0;
				}
				state.selectedCampaigns[position].attributes["test-type"] = type;
			}
		);

		builder.addCase(
			campaignSetTrafficPercentage.fulfilled,
			(state, { payload: { percent, position } }) => {
				if (!position) {
					position = 0;
				}
				state.selectedCampaigns[position].attributes["test-traffic"] = percent;
			}
		);
	},
});

export const {
	clearCampaignsApiError,
	setSelectedCampaign,
	addSelectedCampaign,
	clearSelectedCampaigns,
	clearCreativeCampaigns,
} = campaignsSlice.actions;

export default campaignsSlice.reducer;
