import { formatDateTime } from "../../businessLogic/utils";
import { endpoints, reportTypes } from "../../../constants/common";
import reportsAdapter from "../reportsAdapter";

export const loadEmailCapture = (state, action) => {
	const { payload } = action;
	const rawData = [...(payload?.data || [])];
	const data = rawData.map((obj) => {
		return {
			...obj,
			created_at: formatDateTime(obj.created_at),
			updated_at: formatDateTime(obj.updated_at),
			esp_submitted: obj.esp_submitted ? "yes" : "no",
		};
	});

	//INFO for CSV logic
	const date_start = state.reportOptions.date_start;
	const date_end = state.reportOptions.date_end;
	const csvFileName = `${payload.website.name}_${date_start}_${date_end}.csv`;
	const type = payload.type;

	const domain = {
		[reportTypes.websiteEmailCapture]: endpoints.websiteReportURL,
		[reportTypes.parentEmailCapture]: endpoints.parentCampaignReportURL,
		[reportTypes.childEmailCapture]: endpoints.childCampaignReportURL,
	}[type];
	const id = {
		[reportTypes.websiteEmailCapture]: payload.website_id,
		[reportTypes.parentEmailCapture]: payload.parent_campaign_id,
		[reportTypes.childEmailCapture]: payload.campaign_id,
	}[type];
	const csvURL = `${domain}/${id}/email-captures?date_start=${date_start}&date_end=${date_end}`;

	// update State
	state.title = payload?.website?.name;
	state.makeApiCall = false;
	state.total = data.length;
	state.next_cursor = payload?.next_cursor;
	state.csvFileName = csvFileName;
	state.csvURL = csvURL;
	reportsAdapter.addMany(state.data, data);
};
