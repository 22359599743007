import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ScheduledWebView = ({ onChange, scheduleState }) => {
	return (
		<div>
				<ScheduledLabel>
					<input type="checkbox" onChange={(e) => onChange(e, "toggleScheduledTime")} />
					<label>Scheduled Webview Link</label>
				</ScheduledLabel>
				<div style={{ display: 	scheduleState.showScheduleTime ? "block": "none" }}>
					<Label>Scheduled Date</Label>
					<Input type="date" onChange={(e) => onChange(e, "updateDate")} />
					<Label>Scheduled Time</Label>
					<Input type="time" onChange={(e) => onChange(e, "updateTime")} />
					<Text>Please use your local timezone.</Text>
				</div>

		</div>
	)
}

const ScheduledLabel = styled.div`
	margin: 2rem 0 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
	label {
		margin: 0 0 0 0.5rem;
	}
`;

const Text = styled.p`
	font-size: 9.5px;
`;

const Label = styled.label`
	margin: 1rem 0 0 0;
	font-size: 10px;
`;

const Input = styled.input`
	width: 100%;
	padding: .5rem .25rem;
`;

ScheduledWebView.propTypes = {
	onChange: PropTypes.func,
	scheduleState: PropTypes.bool.isRequired,
};

export default ScheduledWebView;
