import React from "react";
import { SidebarWrapper } from "../../../../components";
import ESPSettingsNav from "./ESPSettingsNav";
import ESPSettingsTable from "./ESPSettingsTable";

const ESPProfilesContainer = () => {
	return (
		<SidebarWrapper
			pageTitle="Sites"
		>
			{/* 
				The SidebarWrapper is a stopgap to allow the
				sidebar in ember to remain in the interim
			*/}
			<ESPSettingsNav />
			<main>
				<ESPSettingsTable />
			</main>
		</SidebarWrapper>
	);
};

export default ESPProfilesContainer;
