import { useDispatch } from 'react-redux'
import { duplicateInboxCampaign, saveInboxCampaign } from '../../../../../redux/inboxCampaigns/thunks';
import { saveInboxSeries } from '../../../../../redux/inboxSeries/thunks';

export const getNavBarMethods = () => {
    const dispatch = useDispatch();

    const saveAll = ({ series, parents, variations }) => {
        dispatch(saveInboxSeries(series))
        parents.map(parent => dispatch(saveInboxCampaign(parent)))
        variations.map(variation => dispatch(saveInboxCampaign(variation)))
    }

    const save = ({ isSeriesPage, series, campaign }) => {
        isSeriesPage
            ? dispatch(saveInboxSeries(series))
            : dispatch(saveInboxCampaign(campaign))
    }

    const duplicateLogic = ({ campaignId }) => {
        dispatch(duplicateInboxCampaign(campaignId))
    }

    return {
        saveAll,
        save,
        duplicateLogic
    }
}
