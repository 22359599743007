import React from "react";
import PropTypes from "prop-types";

import {
	ConversationHeader as Header,
	ConversationTable as Table,
} from "./components";
import { Loader, Wrapper } from "../../components";
import { reportTypes } from "../../constants/common";

const Conversation = ({
	title = "",
	type = "",
	website_id = -1,
	parent_campaign_id = -1,
	campaign_id = -1,
	isLoading = false,
	data = [],
	summary,
	csvFileName,
	loadMore,
}) => {
	const id = {
		[reportTypes.websiteConversions]: website_id,
		[reportTypes.parentConversions]: parent_campaign_id,
		[reportTypes.childConversions]: campaign_id,
	}[type];
	const linkBack = {
		[reportTypes.websiteConversions]: reportTypes.websiteCampaign,
		[reportTypes.parentConversions]: reportTypes.parentCampaign,
		[reportTypes.childConversions]: reportTypes.childCampaign,
	}[type];
	return (
		<Wrapper>
			<Loader isLoading={isLoading} />
			<Header
				title={title}
				type={linkBack}
				id={id}
				data={data}
				csvFileName={csvFileName}
				loadMore={loadMore}
			/>
			<Table data={data} summary={summary} />
		</Wrapper>
	);
};
Conversation.propTypes = {
	title: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	website_id: PropTypes.number.isRequired,
	parent_campaign_id: PropTypes.number.isRequired,
	campaign_id: PropTypes.number.isRequired,
	isLoading: PropTypes.bool.isRequired,
	data: PropTypes.array.isRequired,
	summary: PropTypes.array.isRequired,
	csvFileName: PropTypes.string,
	loadMore: PropTypes.func,
};
export default Conversation;
