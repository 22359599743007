import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { downloadInboxCampaigns, downloadOnsiteCampaigns } from "../../../redux/websites/thunks";
import { Button } from "../../../components";

const RightButtonsBlock = () => {
	const [downloadCampaignButton, setDownloadCampaignButton] = useState(false);
	const dispatch = useDispatch();
	const { selectedWebsite } = useSelector((state) => state.websites);

	const disabled = !selectedWebsite;
	const websiteId = selectedWebsite?.id;

	const cmsStatsLink = websiteId
		? `/clients/stats?website_id=${websiteId}`
		: undefined;
	const inboxReportLink = websiteId
		? `/clients/stats_inbox?website_id=${websiteId}`
		: undefined;
	const dailyReportingLink = websiteId
		? `/reports?type=websiteCampaign&website_id=${websiteId}`
		: undefined;

	const downloadCampaign = async (value) => {
		setDownloadCampaignButton(true);
		let downloadMeta;
		if (value === "onsite") {
			const { meta } = await dispatch(downloadOnsiteCampaigns(selectedWebsite));
			downloadMeta = meta;
		}

		if (value === "inbox") {
			const { meta } = await dispatch(downloadInboxCampaigns(selectedWebsite));
			downloadMeta = meta;
		}

		if (downloadMeta.requestStatus === "fulfilled") {
			setDownloadCampaignButton(false);
		}
	}

	return (
		<ButtonsContainer>
			<Button
				disabled={disabled}
				url={cmsStatsLink}
				dataQA="cms-stats-button"
				as="a"
				fullWidth
				theme="blue"
			>
				CMS Stats
			</Button>
			<Button
				disabled={disabled}
				url={inboxReportLink}
				dataQA="inbox-report-button"
				as="a"
				fullWidth
				theme="blue"
			>
				Inbox Report
			</Button>
			<Button
				disabled={disabled}
				url={dailyReportingLink}
				dataQA="daily-reporting-button"
				as="a"
				fullWidth
				theme="blue"
			>
				Daily Reporting
			</Button>
			<Button
				as="button"
				onClick={() => downloadCampaign("inbox")}
				disabled={downloadCampaignButton}
				dataQA="download-inbox-campaigns"
				fullWidth
				theme="blue"
			>
				Download Inbox Campaigns
			</Button>
			<Button
				as="button"
				onClick={() => downloadCampaign("onsite")}
				disabled={downloadCampaignButton}
				dataQA="download-onsite-campaigns"
				fullWidth
				theme="blue"
			>
				Download CMS Campaigns
			</Button>
		</ButtonsContainer>
	);
};

const ButtonsContainer = styled.div`
	& > *:not(:last-child) {
		margin-bottom: 11px;
	}
`;

export default RightButtonsBlock;
