import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
	PopupModal,
	Header,
	SidebarPagination,
	Search,
	ResultsNumber,
	Wrapper,
	Button,
} from "../../components";
import { getWebsites } from "../../redux/websites/thunks";
import { getIndustries } from "../../redux/industries/thunks";
import { clearWebsitesApiError } from "../../redux/websites/slice";
import { clearIndustriesApiError } from "../../redux/industries/slice";
import { clearVerticalsApiError } from "../../redux/verticals/slice";
import { clearContractsApiError } from "../../redux/contracts/slice";
import { getContracts } from "../../redux/contracts/thunks";
import { getVerticals } from "../../redux/verticals/thunks";
import { useBindValueToParam } from "../../hooks";
import {
	NewWebsiteButton,
	WebsitesTable,
	RightButtonsBlock,
	RightInfoBlock,
	WebsitesFilters,
} from "./components";

const initParams = {
	q: "",
	limit: 50,
	page: 1,
	show: "mine",
	contract_id: "",
	industry_id: "",
	vertical_id: "",
	module: "",
};

const WebsitesLandingPage = () => {
	const {
		isLoading: websitesIsLoading,
		isFetchError: websitesIsFetchError,
		isCreateWebsiteError,
		response: {
			meta: { total_rows: totalRows = 0 },
		},
		selectedWebsite,
	} = useSelector((state) => state.websites);
	const params = new URLSearchParams(window.location.search);

	const { isFetchError: industriesIsFetchError } = useSelector((state) => state.industries);

	const { isFetchError: contractsIsFetchError } = useSelector((state) => state.contracts);

	const { isFetchError: verticalsIsFetchError } = useSelector((state) => state.verticals);

	const moduleQueryParam = params.get("module");
	const moduleShowParam = params.get("show");
	const moduleVerticalParam = params.get("vertical_id");
	const moduleIndustryId = params.get("industry_id");
	const moduleContractId = params.get("contract_id");
	const moduleQueryString = params.get("vertical_id");

	const [searchStr, setSearchStr] = useState(moduleQueryString || initParams.q);
	const [contractId, setContractId] = useState(moduleContractId || initParams.contract_id);
	const [industryId, setIndustryId] = useState(moduleIndustryId || initParams.industry_id);
	const [verticalId, setVerticalId] = useState(moduleVerticalParam || initParams.vertical_id);
	const [moduleId, setModuleId] = useState(moduleQueryParam || initParams.module);
	const [show, setShow] = useState(moduleShowParam || initParams.show);

	// we need this value to make a search request only for the last new search string
	const [lastSearchStr, setLastSearchStr] = useState("");
	const [pageNumber, setPageNumber] = useState(initParams.page);
	const [resultsOnPage, setResultsOnPage] = useState(initParams.limit);

	// bind necessary values to search params
	useBindValueToParam("contract_id", initParams.contract_id, contractId, setContractId);
	useBindValueToParam("industry_id", initParams.industry_id, industryId, setIndustryId);
	useBindValueToParam("vertical_id", initParams.vertical_id, verticalId, setVerticalId);
	useBindValueToParam("module", initParams.module, moduleId, setModuleId);
	useBindValueToParam("show", initParams.show, show, setShow);
	useBindValueToParam("limit", initParams.limit, resultsOnPage, setResultsOnPage);
	useBindValueToParam("page", initParams.page, pageNumber, setPageNumber);
	useBindValueToParam("q", initParams.q, searchStr, setSearchStr);

	const hasApiError =
		websitesIsFetchError ||
		isCreateWebsiteError ||
		industriesIsFetchError ||
		contractsIsFetchError ||
		verticalsIsFetchError;
	const dispatch = useDispatch();

	const refreshWebsites = async (firstAsSelected = false) => {
		setLastSearchStr(searchStr);

		await dispatch(
			getWebsites({
				params: {
					full_load: 1,
					limit: resultsOnPage,
					page: pageNumber,
					show,
					q: searchStr,
					contract_id: contractId,
					industry_id: industryId,
					vertical_id: verticalId,
					module: moduleId,
					include: "none",
					"fields[websites]":
						"id,name,website,active,manager_name,enabled,gbi_enabled,tracking_enabled,v2_enabled, v2_test_mode, dt_golive",
				},
				firstAsSelected,
			})
		);
	};

	const clearApiErrors = () => {
		dispatch(clearWebsitesApiError());
		dispatch(clearIndustriesApiError());
		dispatch(clearVerticalsApiError());
		dispatch(clearContractsApiError());
	};

	useEffect(() => {
		dispatch(getIndustries());
		dispatch(getContracts());
		dispatch(getVerticals());
	}, []);

	useEffect(() => {
		refreshWebsites(true);
	}, [pageNumber, resultsOnPage, contractId, industryId, verticalId, moduleId, show]);

	useEffect(() => {
		if (websitesIsLoading || (searchStr.length && searchStr.length < 3)) {
			return;
		} else if (searchStr !== lastSearchStr) {
			refreshWebsites(true);
		}
	}, [searchStr, websitesIsLoading]);

	useEffect(() => {
		setPageNumber(1);
	}, [searchStr, resultsOnPage, contractId, industryId, verticalId, moduleId, show]);

	const filterOnChange = (event) => {
		switch (event.type) {
			case "contract":
				setContractId(event.value);
				break;
			case "industry":
				setIndustryId(event.value);
				break;
			case "vertical":
				setVerticalId(event.value);
				break;
			case "module":
				setModuleId(event.value);
				break;
			case "show":
				setShow(event.value);
		}
	};

	const resetFilters = () => {
		setSearchStr(initParams.q);
		setResultsOnPage(initParams.limit);
		setPageNumber(initParams.page);
		setContractId(initParams.contract_id);
		setIndustryId(initParams.industry_id);
		setVerticalId(initParams.vertical_id);
		setModuleId(initParams.module);
		setShow(initParams.show);
	};

	return (
		<Wrapper
			left="0"
			maxWidth="100%"
			style={{
				display: "flex",
				justifyContent: "space-between",
				bottom: "0",
			}}
			title="Websites"
			pageTitle="Sites"
		>
			<Aside>
				<NoPaddingContainer>
					<SidebarPagination
						pageNumber={pageNumber}
						resultsOnPage={resultsOnPage}
						setPageNumber={setPageNumber}
						totalRows={totalRows}
					/>
				</NoPaddingContainer>
				<Search
					onChange={setSearchStr}
					value={searchStr}
					label="Search"
					placeholder="Website name, URL"
				/>
				<WebsitesFilters
					onChange={filterOnChange}
					contractValue={contractId}
					industryValue={industryId}
					verticalValue={verticalId}
					moduleValue={moduleId}
					showValue={show}
				/>
				<ResultsNumber
					totalRows={totalRows}
					onChange={setResultsOnPage}
					resultsNumber={resultsOnPage}
				/>
				<Button dataQA="reset-button" onClick={resetFilters} fullWidth theme="blue">
					Reset
				</Button>
			</Aside>
			<Main>
				{!websitesIsLoading && (
					<PopupModal showModal={hasApiError} toggleModal={clearApiErrors}>
						Something went wrong!
					</PopupModal>
				)}
				<Header leftComponent={<NewWebsiteButton />} />
				<WebsitesTable
					isLoading={websitesIsLoading}
					sortByManagers={["managers", "mine"].includes(show)}
				/>
			</Main>
			<Aside disabled={!selectedWebsite}>
				<RightButtonsBlock />
				<RightInfoBlock />
			</Aside>
		</Wrapper>
	);
};

const Aside = styled.aside`
	width: 270px;
	background-color: #ffffff;
	overflow-y: auto;
	padding: 22px;
	opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
	&::-webkit-scrollbar {
		display: none;
	}
`;

const Main = styled.main`
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
`;

const NoPaddingContainer = styled.div`
	margin: -22px -22px 0;
`;

export default WebsitesLandingPage;
