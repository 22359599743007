export const WRAPPER_STYLE = {
	maxWidth: "none",
	left: "0",
	background: "white",
	minHeight: "100%",
};

export const DAYS = {
	TWENTY_FOUR: "24h",
	SEVEN: "7d",
};
