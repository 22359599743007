import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PlusCircle } from "react-feather";
import { Section } from "../../../../../../components/form";
import { updateInboxCampaign } from "../../../../../../redux/inboxCampaigns/thunks";
import { useSelectInboxCampaigns } from "../../../../../../hooks/selectors/useSelectInboxCampaigns";
import EligibilityCriteriaForm from "./EligibilityCriteriaForm";
import { ICON_SIZES } from "../../../../../../constants/common";
import { Button } from "../../../../../../components";
import { AndStatementWrapper } from "./utils/Sections.styled";

const EligibilityCriteria = () => {
	const {
		params: { campaignId },
	} = useSelector((state) => state.page);
	const dispatch = useDispatch();
	const campaign = useSelector(useSelectInboxCampaigns)?.[campaignId];
	const criteriaData = JSON.parse(campaign?.attributes?.["criteria-json"] ?? null);

	const [eligibilityCriteria, setEligibilityCriteria] = useState(criteriaData);

	const handleNewCriteria = () => {
		const newValues = eligibilityCriteria.concat([[{ method: "" }]]);
		setEligibilityCriteria(newValues);
		dispatch(
			updateInboxCampaign({
				id: parseInt(campaignId),
				attributes: {
					"criteria-json": JSON.stringify(newValues),
				},
			})
		);
	};

	const handleDeleteCriteria = (index) => {
		const criteria = eligibilityCriteria.filter((_, i) => index !== i);
		setEligibilityCriteria(criteria);
		dispatch(
			updateInboxCampaign({
				id: parseInt(campaignId),
				attributes: {
					"criteria-json": JSON.stringify(criteria),
				},
			})
		);
	};

	const handleChange = (newValues, index) => {
		const newEligibilityCriteria = [...eligibilityCriteria];
		newEligibilityCriteria[index] = newValues;
		setEligibilityCriteria(newEligibilityCriteria);
		dispatch(
			updateInboxCampaign({
				id: parseInt(campaignId),
				attributes: {
					"criteria-json": JSON.stringify(newEligibilityCriteria),
				},
			})
		);
	};

	return (
		<AndStatementWrapper>
			<Section title="Eligibility Criteria">
				{criteriaData?.map((criteria, index) => (
					<EligibilityCriteriaForm
						key={`eligibility-criteria-${index}`}
						criteria={criteria}
						eligibilityCriteria={eligibilityCriteria}
						deleteCriteria={handleDeleteCriteria}
						setEligibilityCriteria={handleChange}
						index={index}
					/>
				))}
			</Section>
			<Button theme="blue" onClick={handleNewCriteria}>
				<PlusCircle size={ICON_SIZES.medium} />
				AND Statement
			</Button>
		</AndStatementWrapper>
	);
};

export default EligibilityCriteria;
