import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ChevronLeft, Settings, Award, Book } from "react-feather";

import { reportTypes } from "../../../../constants/common";
import { ICON_SIZES } from "../../../../constants/common";

const CampaignHeader = ({
	website_id,
	parent_campaign_id,
	campaign_id,
	type = "",
}) => (
	<Header>
		<BackLink id={website_id} />
		<NavLink
			website_id={website_id}
			parent_campaign_id={parent_campaign_id}
			campaign_id={campaign_id}
			type={type}
		/>
	</Header>
);

CampaignHeader.propTypes = {
	type: PropTypes.string.isRequired,
	website_id: PropTypes.number.isRequired,
	parent_campaign_id: PropTypes.number,
	campaign_id: PropTypes.number,
};

const BackLink = ({ id = -1 }) => (
	<a href={`/experiences/cms?website_id=${id}`}>
		<ChevronLeft size={ICON_SIZES.medium} /> Campaigns
	</a>
);


BackLink.propTypes = {
	id: PropTypes.number,
};

const NavLink = ({
	type = "",
	website_id,
	parent_campaign_id,
	campaign_id,
}) => {
	const editLink =
		{
			[reportTypes.websiteCampaign]: `/websites/${website_id}/edit`,
			[reportTypes.parentCampaign]: `/experiences/cms/${parent_campaign_id}/edit`,
			[reportTypes.childCampaign]: `/experiences/cms/${campaign_id}/edit`,
		}[type] || "";
	const comparisonLink =
		{
			[reportTypes.websiteCampaign]: `/reports?type=websiteComparison&website_id=${website_id}`,
			[reportTypes.parentCampaign]: `/reports?type=parentCampaignComparison&website_id=${website_id}&parent_campaign_id=${parent_campaign_id}`,
			[reportTypes.childCampaign]: `/reports?type=childCampaignComparison&website_id=${website_id}&parent_campaign_id=${parent_campaign_id}&campaign_id=${campaign_id}`,
		}[type] || "";

	return (
		<Links>
			<li>
				<BackLink className="icon-navicon " id={website_id} />
			</li>
			<li>
				<a href={editLink}>
					<Settings aria-label="settings" size={ICON_SIZES.medium} />
				</a>
			</li>
			<li>
				<a href={comparisonLink}>
					<Award aria-label="comparison"  size={ICON_SIZES.medium} />
				</a>
			</li>
			<li>
				<a href={`clients/stats?website_id=${website_id}`}>
					<Book aria-label="client stats"  size={ICON_SIZES.medium} />
				</a>
			</li>
		</Links>
	);
};

NavLink.propTypes = {
	type: PropTypes.string.isRequired,
	website_id: PropTypes.number.isRequired,
	parent_campaign_id: PropTypes.number,
	campaign_id: PropTypes.number,
};

const Header = styled.header`
	width: 100%;
	position: relative;
	margin: 10px;
	display: flex;
	align-items: center;
	a {
		display: flex;
		align-items: center;
		svg {
			margin-right: 5px;
		}
	};
`;

const Links = styled.ul`
	margin: 0 10px 0 auto;
	top: 0;
	li {
		display: inline-block;
		margin: 0.5em;
		list-style: none;
	}
`;

export default CampaignHeader;
