import React, { useState, useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { toast } from "react-toastify";
import {
	PageSection,
	SectionTitle,
	SubsectionBreak,
	PlusMinusToggle,
	InputText,
	InputNumber,
	Button,
} from "../../../../../components";
import CodeInput from "../../../components/code-input/codeInput";
import { openNewTabWithUrl, UrlTemplate } from "../../../../../components/utils/open-page";
import { endpoints } from "../../../../../constants/common";
import { isValidEmailAddress } from "../../../../../components/utils/email";
import sendRequest from "../../../../../components/navigation/utils/sendRequest";

const StateType = Object.freeze({
	EMAIL: "email",
	VARIABLES: "variables",
	INITIALIZE: "initialize",
	ITEMS_USER: "items_user",
	ITEMS_CART: "items_cart",
	ITEMS_ITEMS: "items_items",
	ITEMS_SAVED: "items_saved",
	INITIALIZE_ITEMS: "initialize_items",
	ITEMS_RECOMMENDED: "items_recommended",
});

const initialState = {
	email: "",
	testItems: {
		cart: 0,
		user: 0,
		items: 0,
		saved: 0,
		recommended: 0,
	},
	variables: "",
};

const stateReducer = (state, { type, data }) => {
	const prevItems = state.testItems;
	switch (type) {
		case StateType.EMAIL:
			return {
				...state,
				email: data,
			};
		case StateType.ITEMS_ITEMS:
			return {
				...state,
				testItems: {
					...prevItems,
					items: data,
				},
			};
		case StateType.ITEMS_SAVED:
			return {
				...state,
				testItems: {
					...prevItems,
					saved: data,
				},
			};
		case StateType.ITEMS_USER:
			return {
				...state,
				testItems: {
					...prevItems,
					user: data,
				},
			};
		case StateType.ITEMS_RECOMMENDED:
			return {
				...state,
				testItems: {
					...prevItems,
					recommended: data,
				},
			};
		case StateType.ITEMS_CART:
			return {
				...state,
				testItems: {
					...prevItems,
					cart: data,
				},
			};
		case StateType.VARIABLES:
			return {
				...state,
				variables: data,
			};
		case StateType.INITIALIZE:
			return {
				...data,
			};
		case StateType.INITIALIZE_ITEMS:
			data
				? JSON.parse(data).forEach(
						({ label, count }) => (prevItems[label.toLowerCase()] = count)
				  )
				: null;
			return {
				...state,
				testItems: {
					...prevItems,
				},
			};
		default:
			throw new Error();
	}
};

/**
 * This needs to be reassessed. It is taken from app/pods/inbox-series/route.js
 * and there is likely a better way of making this request. However, to expedite
 * getting the functionality into the reactApp we have this simplified copy.
 */
const sendTestEmail = (campaignId, email) => {
	toast.info(`Sending preview to ${email}`);
	sendRequest({
		url: `${endpoints.legacyApiDomain}/inbox/preview_email?campaign_id=${campaignId}&send_preview=${email}&extra_fields=bx/sendgrid`, // Arguments use in ember &esp_creds_id=${espCreds}&client_sender=${clientSender}&websiteId=${websiteId}`
		method: "GET",
		cache: "no-cache",
		headers: {
			Authorization: "Bearer " + window.retrieveTokenFromStorage(),
		},
		successToast: "Email sent!",
		failureToast: "Email not sent. Please try again.",
	});
};

const TestOptions = ({ campaignId, itemValues }) => {
	const [hideTestOptions, setHideTestOptions] = useState(true);
	const [designState, dispatchDesignState] = useReducer(stateReducer, initialState);

	useEffect(() => {
		dispatchDesignState({
			type: StateType.INITIALIZE_ITEMS,
			data: itemValues,
		});
	}, [itemValues]);

	return (
		<PageSection>
			<div style={{ display: "flex" }}>
				<SectionTitle>Test Options</SectionTitle>
				<PlusMinusToggle
					plusLabel="Show"
					minusLabel="Hide"
					value={hideTestOptions}
					setValue={() => setHideTestOptions(!hideTestOptions)}
					marginLeft="auto"
				/>
			</div>
			{!hideTestOptions && (
				<>
					<SubsectionBreak />
					<div style={{ display: "flex" }}>
						<InputText
							label="Email"
							value={designState.email}
							setValue={(e) =>
								dispatchDesignState({
									type: StateType.EMAIL,
									data: e.target.value,
								})
							}
							margin="0 1.572rem 0 0.785rem"
							width="15.857rem"
							flexBasis="none"
						/>
						<ButtonContainer>
							<Button
								theme="gray"
								disabled={!isValidEmailAddress(designState.email)}
								onClick={() => sendTestEmail(campaignId, designState.email)}
							>
								Send test email
							</Button>
							<Button
								onClick={() =>
									openNewTabWithUrl(UrlTemplate.PREVIEW, {
										url: encodeURIComponent(
											`${endpoints.legacyApiDomain}/inbox/preview_email?campaign_id=${campaignId}&nodeets=1`
										),
									})
								}
							>
								Full page preview
							</Button>
						</ButtonContainer>
					</div>
					<SubsectionBreak />
					<SectionTitle>Items</SectionTitle>
					<div style={{ display: "flex" }}>
						<Item
							label="Items"
							value={designState.testItems.items}
							setValue={(e) =>
								dispatchDesignState({
									type: StateType.ITEMS_ITEMS,
									data: e.target.value,
								})
							}
						/>
						<Item
							label="Saved"
							value={designState.testItems.saved}
							setValue={(e) =>
								dispatchDesignState({
									type: StateType.ITEMS_SAVED,
									data: e.target.value,
								})
							}
						/>
						<Item
							label="User"
							value={designState.testItems.user}
							setValue={(e) =>
								dispatchDesignState({
									type: StateType.ITEMS_USER,
									data: e.target.value,
								})
							}
						/>
						<Item
							label="Recommended"
							value={designState.testItems.recommended}
							setValue={(e) =>
								dispatchDesignState({
									type: StateType.ITEMS_RECOMMENDED,
									data: e.target.value,
								})
							}
						/>
						<Item
							label="Cart"
							value={designState.testItems.cart}
							setValue={(e) =>
								dispatchDesignState({
									type: StateType.ITEMS_CART,
									data: e.target.value,
								})
							}
						/>
					</div>
					<SubsectionBreak />
					<SectionTitle>Variables</SectionTitle>
					<div
						style={{
							marginLeft: "0.786rem",
							marginTop: "0.786rem",
						}}
					>
						<CodeInput />
					</div>
				</>
			)}
		</PageSection>
	);
};

const Item = ({ label, value, setValue }) => {
	return (
		<InputNumber
			label={label}
			value={value}
			setValue={(e) => setValue(e)}
			min="0"
			max="20"
			margin="0 0.785rem"
			width="7.143rem"
		/>
	);
};

const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	button {
		padding: 27px 0;
	}
`;

Item.propTypes = {
	label: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	setValue: PropTypes.func.isRequired,
};

TestOptions.propTypes = {
	itemValues: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TestOptions;
