import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Search = ({ onChange, value }) => {

  const changeHandler = (event) => {
    onChange(event.target.value);
  };

  return (
    <SearchContainer data-qa="search-input-container">
      <label>Search</label>
      <Input
        type="text"
        onChange={changeHandler}
        value={value}
        placeholder="Website name, URL"
		data-qa="search-input"
      />
    </SearchContainer>
  )
};

Search.defaultProps = {
	value: "",
};

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

const SearchContainer = styled.div`
  margin: 11px 0;
`;

const Input = styled.input`
  width: 100%;
  &:focus {
    border-color: #303D78;
    color: #191919;
    outline: none;
    background: none;
    &::placeholder {
      color: inherit;
    }
  }
`;

export default Search;
