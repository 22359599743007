import { gql } from "urql";

export const CAMPAIGN_STATS = gql`
	fragment campaignStats on OnsiteStats {
		Eligibility
		Visits
		TotalImpressions
		UniqueImpressions
		Closes
		TotalClicks
		EmailOptIns
		EligibilityRate
		TotalImpressionRate
		ClickRate
		CloseRate
		EmailOptInRate
		ConversionStats {
			Conversions
			ConversionRate
		}
	}
`;

export const COMMON_VALUES = gql`
	fragment commonValues on CommonCampaignAttributes {
		id
		name
		status
		purposeName
		purposeID
		testType
	}
`;

export const COMPARISON_OVERALL_STATS = gql`
	fragment comparisonOverallStats on OnsiteStats {
		ConversionsByGoal {
			Goal
			Stats {
				Conversions
			}
		}
		ConversionStats {
			Conversions
		}
	}
`;

export const COMPARISON_STATS = gql`
	fragment comparisonStats on OnsiteStats {
		Visits
		Eligibility
		EligibilityRate
		TotalImpressions
		TotalImpressionRate
		UniqueImpressions
		UniqueImpressionRate
		Closes
		CloseRate
		EmailOptIns
		EmailOptInRate
		AverageOrderValue
		UniqueClicks
		ClickRate
		ConversionStats {
			Conversions
			TotalRevenue
			ConversionRate
		}
		ConversionsByGoal {
			Goal
			Stats {
				Conversions
				TotalRevenue
				ConversionRate
			}
		}
		LiftStats {
			ControlLiftBreakdown {
				Combined {
					ConversionLift
					ConfidenceScore
				}
			}
		}
	}
`;
