import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ConversationHeader = ({ headers = [] }) => {
	const cells = headers.map(({ name }, index) => {
		if (index > 2) {
			return <THCenter key={index}>{name}</THCenter>;
		}
		return <th key={index}>{name}</th>;
	});
	return (
		<THead>
			<tr>{cells}</tr>
		</THead>
	);
};
ConversationHeader.propTypes = {
	headers: PropTypes.array.isRequired,
};
const THead = styled.thead`
	background-color: #f3f3f2;
	height: 50px;
	th {
		font-size: 11px;
		&:first-child {
			padding-left: 20px;
		}
		&:last-child {
			padding-right: 20px;
		}
		padding: 20px 0;
	}
    th
`;
const THCenter = styled.th`
	text-align: center;
`;
export default ConversationHeader;
