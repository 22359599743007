import React from "react";
import PropTypes from "prop-types";
import Link from "./link";
import TBody from "./tbody";

const ParentHeader = ({ name, purposeName, id, startDate, endDate }) => {
	return (
		<TBody cellPadding="0">
			<tr>
				<td colSpan="15">
					<h4>
						{`${purposeName} ${name}`}
						<Link
							style={{ "marginLeft": "10px" }}
							href={`/reports?type=parentEmailCapture&parent_campaign_id=${id}&date_start=${startDate}&date_end=${endDate}`}
						>
							Report Conversions Captures
						</Link>
					</h4>
				</td>
			</tr>
		</TBody>
	);
}

export default ParentHeader;

ParentHeader.propTypes = {
	name: PropTypes.string.isRequired,
	purposeName: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	startDate: PropTypes.string.isRequired,
	endDate: PropTypes.string.isRequired,
};
