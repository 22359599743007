import React, { useState, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
	useGetWebsiteFromInboxCampaign,
	useOutsideClick,
} from "../../../../../hooks";
import { PopupModal } from "../../../../../components";
import { CascadingBlocksModal } from "../../../components/modals";
import LiquidVariablesContent from "./liquidVariablesContent";
import EmailReference from "./emailReference";
import { HeaderLabel, HeaderContainer, ToolTipLabel } from "./Design.styled";

const InboxToolTips = ({ isDesignExpanded, setIsDesignExpanded }) => {
	const [isEmailRefOpen, setIsEmailRefOpen] = useState(false);
	const [isLiquidVariablesOpen, setIsLiquidVariablesOpen] = useState(false);
	const [isCascadingBlocksOpen, setIsCascadingBlocksOpen] = useState(false);
	const ref = useRef();

	const toggleModal = (modalToOpen, bool) => {
		closeAll();
		modalToOpen(bool);
	};

	const closeAll = () => {
		setIsEmailRefOpen(false);
		setIsLiquidVariablesOpen(false);
		setIsCascadingBlocksOpen(false);
	};

	useOutsideClick(ref, closeAll);

	const data = useGetWebsiteFromInboxCampaign();
	const liquidVariables = data?.attributes?.["custom-liquid-variable"];

	return (
		<HeaderContainer ref={ref}>
			<Aside>
				<HeaderLabel>Renderer</HeaderLabel>
				<ToolTipLabel>
					<span
						onClick={() =>
							toggleModal(setIsEmailRefOpen, !isEmailRefOpen)
						}
					>
						Email Reference
					</span>
					<EmailReference open={isEmailRefOpen} />
				</ToolTipLabel>
				<ToolTipLabel>
					<span
						onClick={() =>
							toggleModal(
								setIsLiquidVariablesOpen,
								!isLiquidVariablesOpen
							)
						}
					>
						Liquid Variables
					</span>
					<LiquidVariablesContent
						variables={liquidVariables}
						open={isLiquidVariablesOpen}
					/>
				</ToolTipLabel>
				<ToolTipLabel>
					<span
						onClick={() =>
							toggleModal(
								setIsCascadingBlocksOpen,
								!isCascadingBlocksOpen
							)
						}
					>
						Convert to Cascading Blocks
					</span>
					<PopupModal
						toggleModal={setIsCascadingBlocksOpen}
						closeOnOutClick={true}
						showModal={isCascadingBlocksOpen}
						style={{
							width: "35.714rem",
							maxWidth: "95%",
						}}
					>
						<CascadingBlocksModal
							closeModal={() => setIsCascadingBlocksOpen(false)}
						/>
					</PopupModal>
				</ToolTipLabel>
			</Aside>
			<HeaderLabel onClick={() => setIsDesignExpanded(!isDesignExpanded)}>
				<i
					className={`icon-chevron-${
						isDesignExpanded ? "left" : "right"
					}`}
				/>
			</HeaderLabel>
		</HeaderContainer>
	);
};

const Aside = styled.aside`
	display: flex;
`;

InboxToolTips.propTypes = {
	isDesignExpanded: PropTypes.bool.isRequired,
	setIsDesignExpanded: PropTypes.func.isRequired,
};

export default InboxToolTips;
