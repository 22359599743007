// eslint-disable-next-line no-unused-vars
import React from "react";
import styled from "styled-components";

export const Header = styled.h1`
	font-size: 18px;
	padding: 0;
	margin: 0;
	font-weight: bold;
`;

export const SubNav = styled.div`
	background-color: #e9e9e9;
	z-index: 1;
	position: relative;
`;

export const SubNavItem = styled.a`
	margin: 0.75em 0.5rem;
	color: #261f17 !important;
	font-size: 12px;
	font-weight: 700;
	display: inline-block;
	cursor: pointer;

	&:hover {
		color: #796561 !important;
	}
`;

export const Container = styled.div`
	background: white;
	padding: 1em 20px 1em 20px;
	margin-bottom: 0.75em;
`;

export const ListContainer = styled.div`
	background: white;
	margin-bottom: 0.75em;
	margin-top: 0.75em;
`;

export const Row = styled.div`
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e1e1e1;
	height: 50px;
	padding-left: 20px;
	padding-right: 20px;
`;

export const HalfColumn = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	align-items: center;
`

export const FillColumn = styled(HalfColumn)`
	width: 100%;
`;

export const LargeColumn = styled(HalfColumn)`
	width: 80%;
`;

export const SmallColumn = styled(HalfColumn)`
	width: 10%;
`;

export const TinyColumn = styled(HalfColumn)`
	width: 2%;
`;

export const Label = styled.label`
	display: initial;
	font-weight: initial;
	margin: initial;
	padding: initial;
	cursor: pointer;
`;

export const Select = styled.select`
	padding: 5px;
`;

export const Input = styled.input`
	padding: 5px;
`;

export const Icon = styled.i`
	cursor: pointer;
`;

export const ErrorMessage = styled.span`
	color: #CC3333;
	font-size: 14px;
	margin-left: 12px;
`;
