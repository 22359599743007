import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ToolTip } from "../../../components";

const CampaignHeaderButton = ({
	dataQa,
	text,
	dirtyStatus,
	as,
	url,
	children,
	toolTipContent,
}) => {
    const saveCampaign = (e) => {
        console.log(e);
    }

	return (
		<ToolTip
			content={toolTipContent} 
			position="bottom">
			<Button 
				as={as}
				href={!url ? undefined : url}
				target={!url ? undefined : "_blank"}
				data-qa={dataQa}
				dirtyStatus={dirtyStatus}
				onClick={() => saveCampaign(true)}>
				{!dirtyStatus ? children : undefined}
				{text ? text : ''} 
				{dirtyStatus ? children : undefined}
			</Button>
		</ToolTip>
	);
};

const Button = styled.a`
	&&{
		font-size: 12px;
		font-weight: 600;
		font-stretch: 100%;
		line-height: 12px;
		background: none;
		border: none;
		padding: 0 4px;
		cursor: pointer;
		display: flex;
		align-items: center;
		color: black;
		svg {
			color: ${({ dirtyStatus }) => dirtyStatus ? "#b64b25" : "#000000"}
		}
		&:hover, &:hover > * {
			color: ${({ dirtyStatus }) => dirtyStatus ? "#b64b25" : "#FFFFFF"}
		}
	}
`;

CampaignHeaderButton.propTypes = {
	dataQa: PropTypes.string.isRequired,
	onClick: PropTypes.func,
	text: PropTypes.string,
	dirtyStatus: PropTypes.bool,
	icon: PropTypes.string,
	as: PropTypes.string,
	url: PropTypes.string,
	children: PropTypes.node,
	toolTipContent: PropTypes.string,
};

CampaignHeaderButton.defaultProps = {
	dataQA: "campaign-edit-header-button",
	as: "a",
};
export default CampaignHeaderButton;
