import React from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import NavBar from "../NavBar";
import { NavigationLinks } from "../../constants/common";
import 'react-toastify/dist/ReactToastify.css';

/**
 * Returns the Absolute positioned Wrapper, rendering the navigation bar and content. Needed to sit on top of the Ember View.
 * @param {string} pageTitle
 * @param {node} children
 * @param {string} maxWidth
 * @param {string} left
 * @param {string} pageTitle
 * @param {object} style
 * @returns <Wrapper pageTitle="Testing" {...props} />
 */

const Wrapper = ({
	pageTitle = "",
	children,
	title,
	maxWidth,
	left,
	style,
}) => {
	// eslint-disable-next-line no-undef
	const topHeight = process.env.REACT_APP_ENVIRONMENT === "production" ? "22px" : "45px";
	return (
		<>
			<NavBar pageTitle={pageTitle} navigation={NavigationLinks} />
			<Helmet>
				<title>{title}</title>
			</Helmet>
			<WrapperContainer
				style={style}
				id="react-wrapper"
				topHeight={topHeight}
				maxWidth={maxWidth}
				left={left}
			>
				{children}
			</WrapperContainer>
			<ToastContainer
				position="top-center"
				limit={1}
				hideProgressBar
				autoClose={1500}
				theme="colored"
			/>
		</>
	);
};

Wrapper.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	maxWidth: PropTypes.string,
	left: PropTypes.string,
	style: PropTypes.object,
	pageTitle: PropTypes.string,
};

Wrapper.defaultProps = {
	maxWidth: "",
	left: "",
	style: {},
	topHeight: "",
	title: "",
};

const WrapperContainer = styled.div`
	position: absolute;
	top: ${({ topHeight }) => topHeight};
	width: 100%;
	max-width: ${({ maxWidth }) => maxWidth || "75vw"};
	left: ${({ left }) => left || "12.5%"};
`;

export default Wrapper;
