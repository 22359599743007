import React, { useState } from "react";
import { PlusCircle } from "react-feather";
import { Section, Dropdown } from "../../../../../../components/form";
import { Divider } from "../../../../../stats/styledComponents";
import { eligibilityThresholds } from "../../../../eligibility";
import { ICON_SIZES } from "../../../../../../constants/common";
import { Button } from "../../../../../../components";
import { AndStatementWrapper, Text } from "./utils/Sections.styled";

const initialInput = {
	label: "Choose...",
	value: "",
};

const DoNotSend = () => {
	const [input, setInput] = useState(initialInput);
	const addDoNotSendData = () => console.log("test");

	return (
		<AndStatementWrapper>
			<Section>
				<Text>For this series, send a maximum of:</Text>
				<Divider />
				<Dropdown
					label="Event"
					field="event"
					onChange={(event) => setInput(event.value.target)}
					value={input.value}
					options={eligibilityThresholds["field"]["options"]}
				/>
			</Section>
			<Button theme="blue" onClick={() => addDoNotSendData}>
				{/* add inputs here*/}
				<PlusCircle size={ICON_SIZES.medium} />
				AND Statement
			</Button>
		</AndStatementWrapper>
	);
};

export default DoNotSend;
