import React, { useMemo } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { groupBy } from "lodash";

import { TrafficTypeSwitcher, Search, Select } from "../../../components";
import { ArchiveButtons, VersionButtons } from "../constants";

const E_COMMERCE_INDUSTRY_ID = "2";

const changeFilter = (params, value, resetFunction) => {
	const url = window.location.pathname;
	const urlParams = new URLSearchParams(window.location.search);

	if (value) {
		urlParams.set(params, value);
		urlParams.delete("page");
		resetFunction(value);
	} else {
		urlParams.delete(params);
		resetFunction(value);
	}

	const newUrl = `${url}?${urlParams.toString()}`;
	window.history.pushState({ path: newUrl }, "", newUrl);
};

const createGroupedDropdown = (optionsArray, types) => {
	return Object.keys(types)
		.sort()
		.forEach((key) => {
			if (key !== "undefined") {
				optionsArray.push({
					groupName: key,
					options: types[key].map((el) => el.attributes),
				});
			}
		});
};

const CampaignsFilters = ({
	onChange,
	trafficTypeValue,
	searchValue,
	purposeValue,
	campaignValue,
	archiveFilter,
	setArchiveFilter,
	versionFilter,
	setVersionFilter,
}) => {
	const {
		purposes: {
			response: { data: purposes = [] },
		},
		industries: {
			response: { data: industries = [] },
		},
		campaignTypes: {
			response: { data: campaignTypes = [] },
		},
	} = useSelector((state) => state);

	const { data } = useSelector((state) => state.website.response);
	const INDUSTRY_ID = data?.relationships?.industry?.data;

	const getPurposeOptions = () => {
		let options = [{ id: "", name: "All Purposes" }];

		let onlyCmsPurposes = purposes.filter(
			({ attributes = {} }) =>
				attributes.product === "all" || attributes.product === "bouncex"
		);

		const groupedPurposes = groupBy(onlyCmsPurposes, ({ attributes = {} }) => {
			const industryId = attributes["industry-id"];
			const industry = industries.find((el) => el.id === industryId);
			return industry?.attributes?.name;
		});

		createGroupedDropdown(options, groupedPurposes);
		return options;
	};

	const getCampaignTypeOptions = () => {
		let options = [{ id: "", name: "All Deployments" }];
		const onlyCampaignPurposes = campaignTypes.filter(
			({ attributes = {} }) => attributes["optgroup-name"] !== null
		);
		const groupedCampaignTypes = groupBy(onlyCampaignPurposes, ({ attributes = {} }) => {
			return attributes["optgroup-name"];
		});

		createGroupedDropdown(options, groupedCampaignTypes);
		return options;
	};

	const memoizedPurposeOptions = useMemo(
		() => getPurposeOptions(),
		[purposes, industries, INDUSTRY_ID]
	);

	const memoizedGetCampaignTypeOptions = useMemo(() => getCampaignTypeOptions(), [campaignTypes]);

	const updateFilter = (type, value, filterType) => changeFilter(type, value, filterType);

	return (
		<FiltersContainer>
			<label>Filters</label>
			<TrafficTypeSwitcher
				onChange={(value) => onChange({ type: "trafficType", value })}
				value={trafficTypeValue}
			/>
			<Search
				dataQA="cms-search-input"
				value={searchValue}
				onChange={(value) => onChange({ type: "searchStr", value })}
				placeholder="Search..."
			/>
			<Select
				dataQA="purpose-dropdown"
				data={memoizedPurposeOptions}
				value={purposeValue}
				onChange={(value) => onChange({ type: "purpose", value })}
			/>
			<Select
				dataQA="campaign-types-dropdown"
				data={memoizedGetCampaignTypeOptions}
				value={campaignValue}
				onChange={(value) => onChange({ type: "campaign_type", value })}
			/>
			<ButtonContainer>
				{ArchiveButtons.map(({ title, value, dataQA }) => (
					<Button
						data-qa={`${dataQA}${archiveFilter === value ? "-active" : ""}`}
						key={title}
						isActive={archiveFilter === value}
						onClick={() => updateFilter("archived", value, setArchiveFilter)}
					>
						{title}
					</Button>
				))}
			</ButtonContainer>
			<ButtonContainer>
				{VersionButtons.map(({ title, value, dataQA }) => (
					<Button
						data-qa={`${dataQA}${versionFilter === value ? "-active" : ""}`}
						key={title}
						isActive={versionFilter === value}
						onClick={() => updateFilter("v", value, setVersionFilter)}
					>
						{title}
					</Button>
				))}
			</ButtonContainer>
		</FiltersContainer>
	);
};

const FiltersContainer = styled.div`
	margin: 11px 0;
	box-sizing: border-box;
	& > div:not(:first-of-type) {
		margin: 11px 0;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const Button = styled.button`
	flex: 1 1 auto;
	background: #ffffff;
	font-size: 10px;
	padding: 12px 0px;
	text-transform: uppercase;
	border-radius: 0;

	border: ${({ isActive }) =>
		isActive ? "1px solid rgb(48, 61, 120)" : "1px solid rgb(232, 232, 232)"};

	color: ${({ isActive }) => (isActive ? "rgb(48, 61, 120)" : "rgb(25, 25, 25)")};
`;

CampaignsFilters.propTypes = {
	onChange: PropTypes.func.isRequired,
	trafficTypeValue: PropTypes.arrayOf(PropTypes.oneOf(["on", "off", "test", "wip"])).isRequired,
	searchValue: PropTypes.string.isRequired,
	campaignValue: PropTypes.string.isRequired,
	purposeValue: PropTypes.string.isRequired,
	versionFilter: PropTypes.number.isRequired,
	setVersionFilter: PropTypes.func.isRequired,
	archiveFilter: PropTypes.number.isRequired,
	setArchiveFilter: PropTypes.func.isRequired,
};

export default CampaignsFilters;
