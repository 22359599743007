import React from "react";
import PropTypes from "prop-types";
import { PlusMinusToggleStyled, SubsectionBreak, SubsectionContainer } from "./PageSection.styled";

export const PlusMinusToggle = ({
	plusLabel,
	minusLabel,
	value,
	setValue,
	marginLeft,
	marginRight,
}) => {
	return (
		<PlusMinusToggleStyled
			marginLeft={marginLeft}
			marginRight={marginRight}
			onClick={() => setValue()}
		>
			<div style={{ marginRight: "0.6em" }}>{value ? "+" : "-"}</div>
			<div>{value ? plusLabel : minusLabel}</div>
		</PlusMinusToggleStyled>
	);
};

PlusMinusToggle.propTypes = {
	plusLabel: PropTypes.string.isRequired,
	minusLabel: PropTypes.string.isRequired,
	value: PropTypes.bool.isRequired,
	setValue: PropTypes.func.isRequired,
	marginLeft: PropTypes.string,
	marginRight: PropTypes.string,
};

export const MinimalSubsection = ({ children, display }) => {
	return (
		<>
			<SubsectionBreak />
			<SubsectionContainer margin="2.3rem" display={display}>
				{children}
			</SubsectionContainer>
		</>
	);
};

MinimalSubsection.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	display: PropTypes.string,
};
