import React from "react";
import PropTypes from "prop-types";

import {
	EmailCaptureHeader as Header,
	EmailCaptureTable as Table,
} from "./components";
import { Loader, Wrapper } from "../../components";
import { reportTypes } from "../../constants/common";

const EmailCapture = ({
	type = "-1",
	title = "-1",
	website_id = -1,
	parent_campaign_id = -1,
	campaign_id = -1,
	date_start = "-1",
	date_end = "-1",
	isLoading = false,
	data = [],
	total = -1,
	loadMore,
	csvFileName,
	csvURL,
}) => {
	const id = {
		[reportTypes.websiteEmailCapture]: website_id,
		[reportTypes.parentEmailCapture]: parent_campaign_id,
		[reportTypes.childEmailCapture]: campaign_id,
	}[type];
	const linkBack = {
		[reportTypes.websiteEmailCapture]: reportTypes.websiteCampaign,
		[reportTypes.parentEmailCapture]: reportTypes.parentCampaign,
		[reportTypes.childEmailCapture]: reportTypes.childCampaign,
	}[type];
	return (
		<Wrapper>
			<Loader isLoading={isLoading} />
			<Header
				title={title}
				type={linkBack}
				id={id}
				date_start={date_start}
				date_end={date_end}
				total={total}
				loadMore={loadMore}
				csvFileName={csvFileName}
				csvURL={csvURL}
				website_id={website_id}
			/>
			<Table data={data} />
		</Wrapper>
	);
};
EmailCapture.propTypes = {
	type: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	website_id: PropTypes.number.isRequired,
	parent_campaign_id: PropTypes.number.isRequired,
	campaign_id: PropTypes.number.isRequired,
	data: PropTypes.array.isRequired,
	date_start: PropTypes.string.isRequired,
	date_end: PropTypes.string.isRequired,
	total: PropTypes.number,
	isLoading: PropTypes.bool.isRequired,
	loadMore: PropTypes.func,
	csvFileName: PropTypes.string.isRequired,
	csvURL: PropTypes.string.isRequired,
};
export default EmailCapture;
