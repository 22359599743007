import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { ChevronLeft, ChevronRight } from "react-feather";
import { ICON_SIZES } from "../constants/common";

const SidebarPagination = ({
	pageNumber,
	resultsOnPage,
	setPageNumber,
	totalRows,
}) => {
	const sumResults = pageNumber * resultsOnPage;
	const endValue = sumResults < totalRows ? sumResults : totalRows;
	const startValue = sumResults - resultsOnPage + 1;
	const isFirstPage = pageNumber === 1;
	const isLastPage = pageNumber >= Math.ceil(totalRows / resultsOnPage);

	const decreasePageNumber = () => {
		if (isFirstPage) {
			return;
		}
		setPageNumber(pageNumber - 1);
	};

	const increasePageNumber = () => {
		if (isLastPage) {
			return;
		}
		setPageNumber(pageNumber + 1);
	};

	return (
		<PaginationContainer>
			<ChangePageButton
				onClick={decreasePageNumber}
				disabled={isFirstPage}
				data-qa="left-pagination-button"
			>
				<ChevronLeft aria-label="previous button" size={ICON_SIZES.large} />
			</ChangePageButton>

			<RangeInfo
				data-qa="range-info"
			>
				{totalRows
					? `${startValue}-${endValue} of ${totalRows}`
					: "No results"
				}
			</RangeInfo>

			<ChangePageButton
				onClick={increasePageNumber}
				dissabled={isLastPage}
				data-qa="right-pagination-button"
			>
				<ChevronRight aria-label="next button" size={ICON_SIZES.large} />
			</ChangePageButton>
		</PaginationContainer>
	);
};

SidebarPagination.propTypes = {
	setPageNumber: PropTypes.func.isRequired,
	pageNumber: PropTypes.number.isRequired,
	resultsOnPage: PropTypes.number.isRequired,
	totalRows: PropTypes.number.isRequired,
};

const PaginationContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 44px;
	background-color: #f5ebe1;
`;

const RangeInfo = styled.div`
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 12px;
	color: #303d78;
`;

const ChangePageButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 30px;
	color: ${(props) => (props.dissabled ? "" : "#303d78")};
	${(props) =>
		!props.dissabled &&
		css`
			&:hover {
				background-color: #1f274c;
				cursor: pointer;
				color: white;
			}
		`}
`;

export default SidebarPagination;
