import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { TrafficPercentageSetter, TrafficTypeSelector } from "../../../components";
import { updateCampaignTraffic, updateCampaignTrafficType } from "../../../redux/campaigns/thunks";
import { setSelectedCampaign, addSelectedCampaign } from "../../../redux/campaigns/slice";
import { CampaignInfoLink, CampaignTestLink } from ".";
import { getTrafficType } from "../../../utils";

const CampaignRow = ({
	campaign,
	isParent = false,
	bgColor,
	parentTrafficValue,
	parentTrafficType,
	isParentArchived,
	scheduledUTCValue,
	scheduledTime,
}) => {
	const campaignName = campaign?.attributes?.name;
	const campaignId = campaign?.id;
	const linkToEdit = `/experiences/cms-legacy/${campaignId}/edit`;
	const isArchived = !campaign?.attributes?.active;
	const isArchivedOrIsParentArchived = isArchived || isParentArchived;
	const dispatch = useDispatch();

	const campaignTraffic =
		typeof parentTrafficValue === "number"
			? parentTrafficValue
			: parseInt(campaign?.attributes?.["test-traffic"]);

	const {
		response: { data: purposes = [] },
	} = useSelector((state) => state.purposes);

	const {
		response: { data: campaignTypes = [] },
	} = useSelector((state) => state.campaignTypes);

	const {
		response: { data: parentCampaignTypes = [] },
	} = useSelector((state) => state.parentCampaignTypes);

	const { selectedCampaigns } = useSelector((state) => state.campaigns);

	const {
		params: { websiteId },
	} = useSelector((state) => state.page);

	const getPurpose = () => {
		if (isParent) {
			const parentCampaignTypeId = campaign?.relationships["parent-campaign-type"]?.data?.id;
			const campaignPurposeId = campaign?.relationships["campaign-purpose"]?.data?.id;
			const purpose = purposes.find((purpose) => purpose.id === campaignPurposeId);
			const purposeName = purpose?.attributes?.name;
			const parentCampaignType = parentCampaignTypes.find(
				(purpose) => purpose.id === parentCampaignTypeId
			);
			const parentCampaignTypeName = parentCampaignType?.attributes?.name;

			return `${purposeName} (${parentCampaignTypeName})`;
		}

		const campaignTypeId = campaign?.relationships["campaign-type"]?.data?.id;
		const isControl = campaign?.attributes["test-type"] === "control";
		const campaignType = campaignTypes.find((purpose) => purpose.id === campaignTypeId);
		const campaignName = campaignType?.attributes?.name || "";

		return isControl ? `${campaignName} (Control)` : campaignName;
	};

	const changeTrafficHandler = (value) => {
		dispatch(
			updateCampaignTraffic({
				id: campaignId,
				test_traffic: value,
			})
		);
	};

	const selectCampaignHandler = (event) => {
		if (event.ctrlKey || event.shiftKey || event.metaKey) {
			dispatch(addSelectedCampaign(campaign));

			return;
		}

		dispatch(setSelectedCampaign(campaign));
	};

	const changeTrafficTypeHandler = (value) => {
		dispatch(
			updateCampaignTrafficType({
				archived: isArchived ? 1 : 0,
				ids: [campaignId],
				status: value,
				website_id: websiteId,
			})
		);
	};

	const purpose = getPurpose();
	const trafficType = getTrafficType(campaign);
	const isSelected = selectedCampaigns.some((campaign) => campaign.id === campaignId);
	const dateEdited = campaign?.attributes['dt-edited'].substring(0,  campaign?.attributes['dt-edited'].indexOf(' ')) || '';

	const backgroundColor = isSelected
		? "rgb(146, 152, 182)"
		: isArchivedOrIsParentArchived
		? "rgb(172, 171, 169)"
		: bgColor;

	return (
		<CampaignRowContainer
			isParent={isParent}
			bgColor={backgroundColor}
			onClick={selectCampaignHandler}
			isSelected={isSelected}
			isArchived={isArchivedOrIsParentArchived}
			data-qa="campaign-row"
		>
			<PurposeContainer isParent={isParent} data-qa="campaign-purpose">
				<a href={linkToEdit}>{purpose}</a>
			</PurposeContainer>
			<NameContainer isParent={isParent} data-qa="campaign-name">
				<a href={linkToEdit}>{campaignName}</a>
			</NameContainer>
			<TrafficPercentageSetter
				value={campaignTraffic}
				onChange={changeTrafficHandler}
				disabled={isParent || isArchivedOrIsParentArchived}
				errorIfNotFull={isParent}
			/>
			<CampaignInfoLink isParent={isParent} campaignId={campaignId} dateEdited={dateEdited} />
			<CampaignTestLink
				scheduledTime={scheduledTime}
				scheduledUTCValue={scheduledUTCValue}
				campaignId={campaignId}
				isGray={trafficType === "off"}
			/>
			<TrafficTypeSelector
				value={isArchivedOrIsParentArchived ? undefined : trafficType}
				parentTrafficType={parentTrafficType}
				onChange={changeTrafficTypeHandler}
			/>
		</CampaignRowContainer>
	);
};

CampaignRow.propTypes = {
	campaign: PropTypes.object.isRequired,
	bgColor: PropTypes.string,
	isParent: PropTypes.bool,
	parentTrafficValue: PropTypes.number,
	parentTrafficType: PropTypes.oneOf(["on", "off", "test", "wip"]).isRequired,
	isParentArchived: PropTypes.bool,
	scheduledUTCValue: PropTypes.string,
	scheduledTime: PropTypes.bool,
};

const CampaignRowContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 11px;
	width: 100%;
	background-color: ${({ bgColor }) => bgColor || "transparent"};
	height: ${({ isParent }) => (isParent ? "53px" : "42px")};
	border: 1px solid;
	a,
	input,
	span {
		color: ${({ isSelected }) => (isSelected ? "rgb(255,255,255)" : "")};
	}
	border-color: ${({ isSelected, isArchived }) =>
		isSelected ? "rgb(48, 61, 120)" : isArchived ? "rgb(160, 158, 156)" : "rgb(232, 232, 232)"};
	z-index: ${({ isSelected }) => (isSelected ? "5" : "1")};
	&:not(:first-child) {
		margin-top: -1px;
	}
	&:hover {
		border-color: rgb(48, 61, 120);
		z-index: 5;
		filter: brightness(95%);
	}
`;

const PurposeContainer = styled.div`
	width: 180px;
	margin: 0 11px;
	font-size: 12px;
	font-weight: ${({ isParent }) => (isParent ? "600" : "400")};
`;

const NameContainer = styled(PurposeContainer)`
	flex-grow: 1;
`;

export default CampaignRow;
