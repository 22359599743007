import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Check, Zap, Slash, Paperclip } from "react-feather";
import { ICON_SIZES } from "../constants/common";

const colorMap = {
	on: "rgb(151, 212, 131)",
	off: "rgb(160, 158, 156)",
	wip: "rgb(155, 73, 118)",
	test: "rgb(219, 164, 127)",
};

const TrafficTypeSelector = ({ value, parentTrafficType, onChange }) => {
	const isOn = value === "on";
	const isWIP = value === "wip";
	const isTest = value === "test";
	const isOff = value === "off";

	const onActiveBgColor = parentTrafficType === "on" ? colorMap.on : colorMap[parentTrafficType];
	const offActiveBgColor =
		parentTrafficType === "on" ? colorMap.off : colorMap[parentTrafficType];
	const wipActiveBgColor =
		parentTrafficType === "on" ? colorMap.wip : colorMap[parentTrafficType];
	const testActiveBgColor =
		parentTrafficType === "on" ? colorMap.test : colorMap[parentTrafficType];

	return (
		<TrafficTypeSelectorContainer>
			<Item
				data-qa="button-campaign-on"
				bgColor={colorMap.on}
				isActive={isOn}
				activeBgColor={onActiveBgColor}
				onClick={() => onChange("on")}
			>
				<Check aria-label="campaign on button" size={ICON_SIZES.medium} />
			</Item>
			<Item
				data-qa="button-campaign-wip"
				bgColor={colorMap.wip}
				isActive={isWIP}
				activeBgColor={wipActiveBgColor}
				onClick={() => onChange("wip")}
			>
				<Paperclip aria-label="campaign wip button" size={ICON_SIZES.medium} />
			</Item>
			<Item
				data-qa="button-campaign-test"
				bgColor={colorMap.test}
				isActive={isTest}
				activeBgColor={testActiveBgColor}
				onClick={() => onChange("test")}
			>
				<Zap aria-label="campaign testing button" size={ICON_SIZES.medium} />
			</Item>
			<Item
				data-qa="button-campaign-off"
				bgColor={colorMap.off}
				isActive={isOff}
				activeBgColor={offActiveBgColor}
				onClick={() => onChange("off")}
			>
				<Slash aria-label="campaign off button" size={ICON_SIZES.medium} />
			</Item>
		</TrafficTypeSelectorContainer>
	);
};

const TrafficTypeSelectorContainer = styled.div`
	display: flex;
	width: 84px;
	margin: 0 11px;
`;

const Item = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 28px;
	height: 28px;
	padding: 3px 6px;
	border: 1px solid rgba(10, 10, 10, 0.33);
	cursor: ${({ isActive }) => (isActive ? "default" : "pointer")};
	background-color: ${({ isActive, activeBgColor }) =>
		isActive ? activeBgColor : "transparent"};
	border-color: ${({ isActive, activeBgColor }) =>
		isActive ? activeBgColor : "rgba(42, 44, 49, 0.1)"};
	&:nth-child(2) {
		border-left: none;
		border-right: none;
	}
	&:hover {
		background-color: ${({ isActive, bgColor, activeBgColor }) =>
			isActive ? activeBgColor : bgColor};
		border-color: ${({ isActive, bgColor, activeBgColor }) =>
			isActive ? activeBgColor : bgColor};
	}
	&:hover > svg {
		color: white;
	}
	svg {
		color: ${({ isActive }) => (isActive ? "white" : "rgba(10, 10, 10, 0.33)")};
	}
`;

const propsTrafficType = PropTypes.oneOf(["on", "off", "test", "wip"]);

TrafficTypeSelector.propTypes = {
	value: propsTrafficType,
	parentTrafficType: propsTrafficType,
	onChange: PropTypes.func.isRequired,
};

export default TrafficTypeSelector;
