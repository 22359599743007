import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TabsContainer, Tab, Container } from './TabbedContainer.styled'

const TabbedContainer = ({ tabsMap, defaultSelection, tabMargins, selectColor }) => {
    const [selectedTab, setSelectedTab] = useState(defaultSelection)
    return (
        <>
            <TabsContainer>
                {Object.keys(tabsMap).map((name) => (
                    <Tab
                        key={`tab-${name}`}
                        onClick={() => {
                            setSelectedTab(name)
                        }}
                        marginLeft={tabMargins?.marginLeft}
                        marginRight={tabMargins?.marginRight}
                        marginTop={tabMargins?.marginTop}
                        selectColor={selectColor}
                        isSelected={name === selectedTab}
                    >
                        {name}
                    </Tab>
                ))}
            </TabsContainer>
            {Object.keys(tabsMap).map((name) => (
                <Container key={`container-${name}`} isSelected={name === selectedTab}>
                    {tabsMap[name]}
                </Container>
            ))}
        </>
    )
}

TabbedContainer.propTypes = {
    tabsMap: PropTypes.object.isRequired,
    defaultSelection: PropTypes.string,
    tabMargins: PropTypes.shape({
        marginTop: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        marginLeft: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        marginRight: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
    }),
    selectColor: PropTypes.string,
}

export default TabbedContainer;
