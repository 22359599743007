import styled from "styled-components";

const Checkbox = styled.input`
    padding: 5px;
    margin: ${props => props.margin ?? null}
`

Checkbox.defaultProps = {
    type: 'checkbox'
}

export default Checkbox;