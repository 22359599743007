/* global process */

export const reportTypes = {
	//INFO: Top level reports
	websiteCampaign: "websiteCampaign",
	parentCampaign: "parentCampaign",
	childCampaign: "childCampaign",
	//INFO: Campaign Compare
	websiteComparison: "websiteComparison",
	parentCampaignComparison: "parentCampaignComparison",
	childCampaignComparison: "childCampaignComparison",
	//INFO: Campaign Conversion
	websiteConversions: "websiteConversions",
	parentConversions: "parentConversions",
	childConversions: "childConversions",
	//INFO: Campaign Capture
	websiteEmailCapture: "websiteEmailCapture",
	parentEmailCapture: "parentEmailCapture",
	childEmailCapture: "childEmailCapture",
};
export const reportIdMap = {
	websiteCampaign: "website_id",
	parentCampaign: "parent_campaign_id",
	childCampaign: "campaign_id",
	websiteComparison: "website_id",
	parentCampaignComparison: "parent_campaign_id",
	childCampaignComparison: "campaign_id",
	websiteConversions: "website_id",
	parentConversions: "parent_campaign_id",
	childConversions: "campaign_id",
	websiteEmailCapture: "website_id",
	parentEmailCapture: "parent_campaign_id",
	childEmailCapture: "campaign_id",
};

export const NavigationLinks = [
	{
		text: "CMS",
		href: "/experiences/cms",
		queryString: true,
		permission: "edit campaigns",
	},
	{
		text: "Inbox",
		href: "/experiences/inbox",
		queryString: true,
		permission: "view creatives",
	},
	{
		text: "Creatives",
		href: "/creatives",
		queryString: true,
		permission: "edit sites",
	},
	// {
	// 	text: "Fonts",
	// 	href: "/fonts",
	// 	queryString: true,
	// 	permission: "edit fonts",
	// },
	{
		text: "Websites",
		href: "/websites",
	},
	{
		text: "Users",
		href: "/users",
		permission: "manage users",
	},
	{
		text: "Tools",
		href: "/tools",
		permission: "use tools",
	},
	{
		text: "Log Out",
		href: "/",
	},
];

export const endpoints = {
	websiteReportURL: `${process.env.REACT_APP_ANALYTICS_API_URL}/analytics/websites`,
	parentCampaignReportURL: `${process.env.REACT_APP_ANALYTICS_API_URL}/analytics/parent-campaigns`,
	childCampaignReportURL: `${process.env.REACT_APP_ANALYTICS_API_URL}/analytics/campaigns`,
	coreApi: process.env.REACT_APP_BX_API_URL,
	connectUrl: process.env.REACT_APP_CONNECT_URL,
	// INFO: Used in uploads
	legacyApiDomain: process.env.REACT_APP_BX_URL,
	userApi: process.env.REACT_APP_AUTH_URL,
	cssUri: process.env.REACT_APP_CREATIVE_BASE_STYLES,
	campaignUrl: process.env.REACT_APP_CAMPAIGN_URL,
};

export const API_LIMIT = 250;
export const backFillApiPath = "/clients/backfill_captures";
export const submitIncompletFormApiPath = "/clients/set_submit_uncompleted_for_backfill";

/**
 * This regex is take from http://emailregex.com/ and should
 * match just about any standard email address.
 */
export const emailAddressRegex =
	/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

// INFO: some shared drop down values are shared across components and api calls
// we nee to make sure we use the same value and no magic numbers.
export const sharedDropDownValues = {
	viewBy: {
		day: "day",
		hour: "hour",
	},
};

export const ICON_SIZES = {
	small: 10,
	medium: 16,
	large: 20,
	extra_large: 24,
};
