import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PopupModal } from "../../../components";
import NewFontForm from "./NewFontForm";

const NewFontButton = () => {
	const [showForm, setShowForm] = useState(false);

	return (
		<>
			<PopupModal
				showModal={showForm}
				toggleModal={() => setShowForm(false)}
			>
				<NewFontForm closeForm={() => setShowForm(false)}/>
			</PopupModal>
			<Button data-qa="new-font-button" onClick={() => setShowForm(true)}>
				New Font
			</Button>
		</>
	);
};

const Button = styled.button`
	font-size: 12px;
	font-weight: 600;
	font-stretch: 100%;
	line-height: 12px;
	background: none;
	border: none;
	padding-left: 22px;
	color: #191919;
	cursor: pointer;
	&:hover {
		color: white;
	}
`;

NewFontButton.propTypes = {
};

export default NewFontButton;
