import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CreativeRenderService } from "../../service/creativeRender";

const IFRAME_WIDTH = 1200;
const IFRAME_HEIGHT = 800;

const CreativePreviewStep = ({
	stepNumber,
	showStepNumber,
	fonts,
	fixedPlacement,
	creative,
}) => {
	const { renderedCreative: _renderedCreative } = useSelector((state) => state.creatives);
	const renderedCreative = creative ?? _renderedCreative;
	const { webfonts } = useSelector((state) => state.fonts);
	const [value, setValue] = useState({
		scale: 0,
		height: "auto",
	});

	const iFrameRef = useRef(null);

	useEffect(() => {
		if (!renderedCreative) {
			return;
		}

		CreativeRenderService.writeIframe(
			iFrameRef.current.contentWindow.document,
			renderedCreative,
			fonts,
			webfonts.siteFontsStylesheet,
			webfonts.lookup
		);

		CreativeRenderService.setActiveStep(
			iFrameRef.current.contentWindow.document,
			fixedPlacement,
			stepNumber
		);
	}, [renderedCreative]);

	useEffect(() => {
		const parentContainerWidth =
			iFrameRef?.current?.parentElement?.clientWidth;
		const scaleValue = parentContainerWidth / IFRAME_WIDTH;

		setValue({
			scale: scaleValue,
			height: `${IFRAME_HEIGHT * scaleValue}px`,
		});
	}, []);

	return (
		<div>
			{showStepNumber && (
				<StepNumberContainer>{stepNumber}</StepNumberContainer>
			)}
			<FrameContainer height={value.height}>
				<Frame
					ref={iFrameRef}
					height={IFRAME_HEIGHT}
					width={IFRAME_WIDTH}
					scaleValue={value.scale}
				/>
			</FrameContainer>
		</div>
	);
};

const bgUrl =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAAJElEQVQYV" +
	"2Pcu23TfwYk4Ozlx4jMZ6SDAmT7QGx0K1EcRBsFADIYHJmtX5wiAAAAAElFTkSuQmCC";

const FrameContainer = styled.div`
	background: ${`url(${bgUrl}) #ffffff repeat`};
	height: ${({ height }) => height};
	overflow: hidden;
`;

const StepNumberContainer = styled.div`
	font-size: 20px;
	text-align: center;
	margin: 18px 0;
`;

const Frame = styled.iframe`
	border: none;
	transform: scale(${({ scaleValue }) => scaleValue});
	transform-origin: 0 0;
`;

CreativePreviewStep.propTypes = {
	stepNumber: PropTypes.number.isRequired,
	fonts: PropTypes.arrayOf(PropTypes.string),
	showStepNumber: PropTypes.bool,
	fixedPlacement: PropTypes.object.isRequired,
	creative: PropTypes.shape({}),
};

CreativePreviewStep.defaultProps = {
	showStepNumber: false,
	fonts: [],
};

export default CreativePreviewStep;
