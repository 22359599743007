import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const RightInboxInfoBlock = () => {
	const { selectedCampaigns } = useSelector((state) => state?.inbox);

    const {
		campaignTypes: {
			response: { data: campaignTypes = [] },
		},
	} = useSelector((state) => state);


	let isSingleCampaign, isMultipleCampaigns, dtEdited, name;


	if (selectedCampaigns) {
        name = selectedCampaigns[0]?.attributes?.name;
        dtEdited = selectedCampaigns[0]?.attributes["dt-edited"];
		isSingleCampaign = !!selectedCampaigns.length;
		isMultipleCampaigns = selectedCampaigns.length > 1;
	}

	const getPurpose = () => {
        const campaignTypeId = selectedCampaigns[0]?.relationships?.["campaign-type"]?.data?.id;

        const onlyCampaignTypes = campaignTypes.filter(
            ({ attributes = {}, type = "" }) =>
                (attributes.product === "inbox" || attributes.product === "all") &&
                type === "campaign_types"
        );
    
        let newArray = onlyCampaignTypes.map((el) => {
            let val = {};
            val.id = el.id;
            val.name = el.attributes.name;
            return val;
        });
        const campaignIdName = newArray.filter((option) => option.id === campaignTypeId);
        const purposeName = campaignIdName[0]?.name === "Not Set" ? "" : campaignIdName[0]?.name;

        return purposeName;
	};

	return (
		<InfoContainer>
			{isSingleCampaign && (
				<>
					{isMultipleCampaigns ? (
						<List>
							{selectedCampaigns.map((campaign) => (
								<ListElement key={campaign.id}>
									{campaign.attributes?.name}
								</ListElement>
							))}
						</List>
					) : (
						<>
							<Title data-qa="header-brand">
								{getPurpose()} - {name}
							</Title>
							<Element>
								Last edited on{" "}
								{new Date(dtEdited?.toString().replace(/-/g, "/")).toDateString()}{" "}
							</Element>
						</>
					)}
				</>
			)}
		</InfoContainer>
	);
};

const InfoContainer = styled.div`
	margin-bottom: 22px;
`;

const Title = styled.h1`
	font-size: 14px;
	font-weight: 600;
	margin: 0 0 5px;
`;

const Element = styled.div`
	margin: 5px 0;
	font-size: 12px;
`;

const List = styled.ul`
	margin: 0;
`;

const ListElement = styled.li`
	font-weight: 600;
	font-size: 12px;
	margin: 5px 0;
`;

export default RightInboxInfoBlock;
