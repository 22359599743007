import styled from "styled-components";

export const HeaderBar = styled.div`
	background-color: #25B89D;
	height: 49px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 0.75em;
`;

export const HeaderLink = styled.a`
	color: #2a2c31;
	padding-left: 8px;
	padding-right: 8px;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	font-weight: 600;
	height: 100%;
	justify-content: center;

	&:hover * {
		color: #fff;
		transition: 0.5s color;
	}
`;

export const HeaderLinkText = styled.span`
	font-size: 12px;
	color: #2a2c31;
`;

export const Notes = styled.p`
	font-size: 10px;
	line-height: 22px;
`;

export const NotesContainer = styled.div`
	margin-left: 5%;
	margin-right: 5%;
	margin-top: 75px;
	display: flex;
`;

export const Row = styled.div`
	margin-left: 5%;
	margin-right: 5%;
	display: flex;
	border-top: 1px solid #dbdbdb;
	margin-top: 0.625rem;
	padding-top: 0.625rem;
`;

export const FillColumn = styled.div`
	width: 100%;
`;

export const QuarterColumn = styled.div`
	width: 25%;
`;

export const HalfColumn = styled.div`
	width: 50%;
`;

export const ThreeFourthsColumn = styled.div`
	width: 75%;
`;

export const SubHeader = styled.div`
	font-size: 20px;
`;

export const Paragraph = styled.p`
	font-size: 12.5px;
	line-height: 27.5px;
	color: #214558;
	max-width: 90%;
`;

export const Stat = styled.div`
	font-size: 75px;
`;

export const BlueStat = styled(Stat)`
	color: #76B3DF;
`;

export const StatLabel = styled.div`
	font-size: 10px;
`;

export const BlueStatLabel = styled(StatLabel)`
	color: #76B3DF;
`;

export const DashedDivider = styled.div`
	border-top: 1px dashed #dbdbdb;
	margin-top: 12.5px;
	padding-top: 12.5px;
	max-width: 90%;
`;

export const Divider = styled.div`
	border-top: 1px solid #dbdbdb;
	max-width: 100%;
`;

export const DividerWithTextHeader = styled.div`
display: block;
  background-color: #dbdbdb;
  text-align: center;

`;

export const DividerWithText = styled.span`
  display: inline-block;
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
`;


export const InlineStatContainer = styled.div`
	display: inline-flex;
	flex-direction: column;
`;

export const LeftColumnContainer = styled.div`
	min-width: 270px;
`;

export const Button = styled.button`
	background-color: #fff;
	border: 1px solid #e4e4e4;
	padding: 15px 25px;
	outline: 0;
	font-size: 12px;

	&:hover {
		background-color: #FBFCFD;
	}
`;

export const BlueButton = styled.button`
	background-color: #3d55cc;
	color: #ffffff;
	border: 0;
	padding: 10px 15px;
	outline: 0;
	font-size: 12px;

	&:hover {
		background-color: #2d42a9;
	}
`;

export const Icon = styled.i`
	color: #2a2c31;
`;

export const TableWrapper = styled.div`
	margin: 70px;
`;

export const Table = styled.table`
	width: 100%;
    font-size: 11px;
    border-collapse: collapse;
    border-spacing: 0;
`;

export const TableHead = styled.thead`
	border-bottom: 3px double #dbdbdb;
    vertical-align: middle;
`;

export const TableHeader = styled.th`
	font-weight: 400;
    padding: 3px 18px 3px 10px;
    color: #3A6277;
    position: relative;
`;

export const TableBody = styled.tbody`
	tr:nth-child(odd) {
		background-color: #f6f6f6;
	}
`;

export const TableData = styled.td`
	padding: 12px 8px;
    text-align: center;
    color: #535252;
    font-weight: 400;
    text-shadow: 1px 1px #fff;
`;

export const TableFooter = styled.tfoot`
	border-color: #dbdbdb;
    border-width: 1px;
    border-style: solid;
`;

