import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import styled from "styled-components";

import { updateCampaignActiveStatus } from "../../../redux/campaigns/thunks";
import { Button } from "../../../components";

const ArchiveCampaignWindow = ({ selectedCampaigns, toggleArchiveModal, refreshCampaigns }) => {
	const dispatch = useDispatch();
	const isMultipleCampaigns = selectedCampaigns.length > 1;
	const hasArchived = selectedCampaigns.some((campaign) => !campaign.attributes?.active);
	const status = hasArchived ? "unarchive" : "archive";

	const changeCampaignActiveStatus = async () => {
		const response = await dispatch(
			updateCampaignActiveStatus({
				archived: 0,
				ids: selectedCampaigns.map((campaign) => campaign.id),
				status: status,
				website_id: selectedCampaigns[0]?.relationships?.website?.data?.id,
			})
		);

		const { requestStatus } = response?.meta;

		if (requestStatus === "fulfilled") {
			toggleArchiveModal();
			refreshCampaigns();

			toast.success(`Campaign ${status}d successfully`, {
				autoClose: 1000,
			});
		} else {
			toast.error("An error occurred", { autoClose: 1000 });
		}
	};

	return (
		<>
			<Header>{status.toUpperCase()}</Header>
			<p>
				{isMultipleCampaigns
					? `Are you sure you want to ${status} ${selectedCampaigns.length} campaigns?`
					: `Are you sure you want to ${status} this campaign?`}
			</p>

			<ButtonWrapper>
				<Button
					data-qa="archive-selected-campaign-button"
					onClick={changeCampaignActiveStatus}
					theme="blue"
				>
					{status.toUpperCase()}
				</Button>
			</ButtonWrapper>
		</>
	);
};

const Header = styled.div`
	display: block;
	margin: 5px 0;
	padding: 0;
	font-size: 18px;
	font-weight: bold;
	width: 100%;
	text-align: center;
	color: #535252;
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	button {
		max-width: 150px;
	}
`;

ArchiveCampaignWindow.propTypes = {
	selectedCampaigns: PropTypes.arrayOf(
		PropTypes.shape({
			attributes: PropTypes.shape({
				name: PropTypes.string,
				active: PropTypes.boolean,
			}).isRequired,
			relationships: PropTypes.shape({
				website: PropTypes.shape({
					data: PropTypes.shape({
						id: PropTypes.string,
					}),
				}),
			}).isRequired,
			id: PropTypes.string.isRequired,
		})
	),
	toggleArchiveModal: PropTypes.func.isRequired,
	refreshCampaigns: PropTypes.func.isRequired,
};

export default ArchiveCampaignWindow;
