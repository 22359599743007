import styled from "styled-components";

export const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	color: #191919;
	flex-basis: ${(props) => props.flexBasis ?? null};
	padding: 0;
	font-size: 0.857rem;
	font-weight: 600;
	position: relative;
	margin: ${(props) => props.margin ?? null};
	width: ${(props) => props.width ?? null};
	min-width: ${(props) => props.minWidth ?? null};
	height: fit-content;
`;

export const InputLabel = styled.div`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	position: absolute;
	top: 0.9em;
	right: 1em;
	left: 1.1em;
	z-index: 2;
	font-size: 0.714rem;
	font-weight: 400;
	pointer-events: none;
	text-align: left;
`;

export const Input = styled.input`
	padding: 0.786rem;
	border: 1px solid #c3c1c1;
	border-radius: 0;
	background: #ffffff;
	color: inherit;
	font-size: 0.857rem;
	font-weight: 400;
	width: 100%;
	padding-top: 1.786rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
`;

export const Select = styled.select`
	padding: 1.786rem 0.786rem 0.786rem;
	border: 1px solid #c3c1c1;
	border-radius: 0;
	background: #ffffff;
	color: inherit;
	font-size: 0.857rem;
	font-weight: 400;
	width: ${(props) => props.width ?? null};
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
`;
