import { useDispatch } from "react-redux";

export const useCallbackDispatch = () => {
	const dispatch = useDispatch();
	return async (thunkAction, callbacks = {}) => {
		const { meta = {}, payload = {} } = await dispatch(thunkAction);
		const {
			onFulfilled = () => {},
			onRejected = () => {},
			onPending = () => {},
		} = callbacks;

		if (meta.requestStatus === 'pending') {
			onPending(payload);
		}
		
		if (meta.requestStatus === 'fulfilled') {
			onFulfilled(payload);
		}

		if (meta.requestStatus === 'rejected') {
			onRejected(payload);
		}
	}
};
