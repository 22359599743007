import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getInboxCampaign } from "../../../../redux/inboxCampaigns/thunks";
import { getInboxSeries } from "../../../../redux/inboxSeries/thunks";
import { useSelectInboxCampaigns } from "../../../../hooks/selectors/useSelectInboxCampaigns";

export const useGetDefaultUTMValues = (inbox) => {
	const dispatch = useDispatch();
	const { params } = useSelector((state) => state.page);
	const {
		purposes: {
			response: { data: purposesData },
		},
	} = useSelector((state) => state);

	const campaigns = useSelector(useSelectInboxCampaigns);
	const series = useSelector((state) => state.inboxSeries.response.data.entities);

	const isSeries = !!params?.seriesId;
	const isParent = !isSeries && inbox?.attributes?.["test-type"] === "parent";
	const isControlOrVariation = !isSeries && inbox?.attributes?.["test-type"] !== "parent";

	useEffect(() => {
		if (isSeries) {
			inbox.relationships.children.data.map((campaign) => {
				if (!campaigns[campaign.id]) {
					dispatch(getInboxCampaign(campaign.id));
				}
			});
		}
		if (isParent && !series[inbox.relationships?.["inbox-series"]?.data?.id]) {
			dispatch(getInboxSeries(inbox.relationships?.["inbox-series"]?.data?.id));
		}
		if (isControlOrVariation && !campaigns[inbox?.relationships?.parent?.data?.id]) {
			dispatch(getInboxCampaign(inbox?.relationships?.parent?.data?.id));
		}
	}, [inbox]);

	const splitParams = (campaign) => {
		const splitObject = {};
		const clickParams = campaign?.attributes?.["click-params"];
		const splitParams = clickParams?.slice(1).split("&");
		const utmKeys = ["utm_campaign", "utm_term", "utm_source", "utm_medium"];
		const additionalData = [];
		splitParams?.forEach((param) => {
			const utm = param?.split("=");
			if (utmKeys.includes(utm[0])) {
				return (splitObject[utm[0]] = utm[1]);
			}
			additionalData.push(`${utm[0]}=${utm[1]}`);
		});
		const additional = additionalData.join("&");
		splitObject["utm_additional"] = additional;
		return splitObject;
	};

	const joinParams = (utmState) => {
		const utmCampaign = utmState?.["utm_campaign"]
			? `utm_campaign=${utmState["utm_campaign"]}`
			: "";
		const utmSource = `utm_source=${utmState?.["utm_source"] ?? "medium"}`;
		const utmTerm = `utm_term=${utmState?.["utm_term"] ?? "triggered"}`;
		const utmAdditional = `${utmState?.["utm_additional"]}`;
		return ["", utmCampaign, utmSource, utmTerm, utmAdditional].join("&");
	};

	const defaultUtmCampaignDefault = (campaign) => {
		const purpose = purposesData.find(
			(purpose) => purpose.id === campaign.relationships?.["campaign-purpose"]?.data?.id
		);
		const parentSeries = series[campaign.relationships["inbox-series"].data.id];
		const seriesLength = parentSeries?.relationships.children.data.length ?? 0;
		const parentPosition =
			parentSeries?.relationships?.children?.data?.findIndex(
				(childCampaign) => childCampaign.id === campaign.id
			) ?? -1;
		return `email_${purpose?.attributes?.["code-name"]}_${
			parentPosition + 1
		}_of_${seriesLength}`;
	};

	let utmData;
	let utmCampaignDefault;
	let clickParams;

	if (isParent || isControlOrVariation) {
		if (isParent) {
			utmData = splitParams(inbox);
			utmData["utm-override"] = inbox.attributes["utm-override"];
			clickParams = inbox.attributes["click-params"];
			utmCampaignDefault = defaultUtmCampaignDefault(inbox);
		}
		if (isControlOrVariation) {
			const parent = campaigns[inbox.relationships.parent.data.id];
			if (parent) {
				utmData = splitParams(parent);
				if (inbox.attributes["click-params"]) {
					const variantSplitParams = splitParams(inbox);
					Object.keys(variantSplitParams).map(
						(key) => (utmData[key] = variantSplitParams[key])
					);
				}
				utmData["utm-override"] = parent?.attributes?.["utm-override"];
				clickParams = joinParams(utmData);
				utmCampaignDefault = defaultUtmCampaignDefault(parent);
			}
		}
	}
	if (isSeries) {
		utmData = inbox.relationships.children.data.map((child) => ({
			...splitParams(campaigns[child.id]?.["click-params"]),
			utmCampaignDefault: defaultUtmCampaignDefault(campaigns[child.id]),
		}));
	}

	return {
		utmTermDefault: "triggered",
		utmMediumDefault: "email",
		utmSourceDefault: "wunderkind",
		utmCampaignDefault,
		utmData,
		joinParams,
		clickParams,
	};
};
