import { transform, isArray, kebabCase, isObject } from "lodash";
import FeatureFlag from "../app/utils/feature-flags/featureFlags";

const formatDateString = (date = new Date()) => {
	return date.toISOString().split("T")[0];
};
const getCurrentDayString = () => {
	const currentDay = new Date();
	return formatDateString(currentDay);
};

const kebabize = (obj) =>
	transform(obj, (acc, value, key, target) => {
		const camelKey = isArray(target) ? key : kebabCase(key);
		acc[camelKey] = isObject(value) ? kebabize(value) : value;
	});

export { getCurrentDayString, formatDateString, kebabize, FeatureFlag };

export const safeJsonParse = (data, defaultReturn = {}) => {
	try {
		const json = JSON.parse(data);
		return json;
	} catch(err) {
		return defaultReturn;
	}
}

export const toBoolean = (value) => {
    const num = parseInt(value, 10);
    return num === 0 ? false : true; 
};

export const toInteger = (value) => {
    return value === true ? 1 : 0;
};

export const toFloat = (value) => {
	// eslint-disable-next-line no-eq-null, eqeqeq
	if (value == null || value === '') {
		return 0;
	}

	const float = parseFloat(value, 10);

	return isNaN(float) ? 0 : float;
};

export const getTrafficType = (campaign) => {
	const hasTestMode = campaign?.attributes?.["test-mode"];
	const hasWIPMode = campaign?.attributes?.["wip-mode"];
	const hasEnabled = campaign?.attributes?.enabled;

	const isTestMode = hasEnabled && hasTestMode && !hasWIPMode;
	const isWIPMode = !hasEnabled && hasTestMode && hasWIPMode;
	const isEnabled = hasEnabled && !hasTestMode && !hasWIPMode;

	if (isTestMode) {
		return "test";
	}

	if (isWIPMode) {
		return "wip";
	}

	if (isEnabled) {
		return "on";
	}

	return "off";
};

export const getParentTrafficValue = (childrenCampaigns, parentTrafficType) => {
	let enableValue = 0;
	let testValue = 0;
	let wipValue = 0;

	childrenCampaigns.forEach((childCampaign) => {
		const traffic = parseInt(childCampaign?.attributes?.["test-traffic"]);
		const status = getTrafficType(childCampaign);

		if (status === "wip") {
			wipValue += traffic;
		}

		if (status === "test") {
			testValue += traffic;
		}

		if (status === "on") {
			enableValue += traffic;
		}
	});

	if (parentTrafficType === "on" || parentTrafficType === "off") {
		return enableValue;
	}

	if (enableValue) {
		return enableValue;
	}

	if (testValue) {
		return enableValue;
	}

	return wipValue;
};
