import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
	ActivationWidget,
	PageNavbar,
	PageNavbarLink,
	PageNavbarView,
	PopupModal,
} from "../../../../components";
import BasicOptions from "../../../../components/navigation/utils/BasicOptions";
import {
	seriesPropTypes,
	parentPropTypes,
	variationPropTypes,
	seriesPagePropTypes,
} from "../../propTypes";
import { NewVariationModal } from "../modals";
import NewBehavioralLogicModal from "../modals/NewBehavioralLogicModal";
import { getPurposes } from "../../../../redux/purposes/thunks";
import { getWebsite } from "../../../../redux/websites/thunks";
import { getIndustries } from "../../../../redux/industries/thunks";
import { UrlTemplate } from "../../../../components/utils/open-page";
import { getNavBarMethods } from "./utils/nav-bar-methods";
import { useSelectInboxCampaigns } from "./../../../../hooks/selectors";

const InboxNavBar = ({ series, parents, variations }) => {
	const dispatch = useDispatch();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { params } = useSelector((state) => state.page);
	const campaigns = useSelector(useSelectInboxCampaigns);
	const {
		inboxSeries: {
			response: { included: seriesIncluded },
		},
		inboxCampaigns: {
			response: { included: campaignsIncluded },
		},
	} = useSelector((state) => state);
	const campaign = campaigns[params?.campaignId];
	const isSeriesPage = params?.seriesId;
	const { saveAll, save, duplicateLogic } = getNavBarMethods();

	const isVariantPage = campaign?.["test-type"] === "variation";
	const isDesignPage = location.pathname.includes("design");
	const duplicateText = isVariantPage ? "Duplicate Variation" : "Duplicate Behavioral Logic";

	useEffect(() => {
		dispatch(getIndustries());
		dispatch(getPurposes());
	}, []);

	const dispatchWebsite = (included) => {
		const { id: websiteId = null } = included.find((seriesComponent) => {
			return seriesComponent.type === "websites";
		});
		dispatch(getWebsite(websiteId));
	};

	useEffect(() => {
		if (seriesIncluded && seriesIncluded.length) {
			dispatchWebsite(seriesIncluded);
		} else if (campaignsIncluded && campaignsIncluded.length) {
			dispatchWebsite(campaignsIncluded);
		}
	}, [seriesIncluded, campaignsIncluded]);

	const Save = () => (
		<PageNavbarLink
			key="Save"
			name="Save"
			callback={() => save({ isSeriesPage, series, campaign })}
		/>
	);
	const SaveAll = () => (
		<PageNavbarLink
			key="Save All"
			name="Save All"
			callback={() => saveAll({ series, parents, variations })}
			tip="Save all marked with "
			appendTipIcon="icon-asterisk u-color-status-red"
		/>
	);
	const Duplicate = () => (
		<PageNavbarLink
			key={duplicateText}
			name={duplicateText}
			callback={() => duplicateLogic({ campaignId: params?.campaignId })}
			tip="This will also duplicate all its variations"
		/>
	);
	const NewVariation = () => (
		<PageNavbarLink
			key="New Variation"
			name="New Variation"
			callback={() => setIsModalOpen(true)}
		/>
	);
	const NewBehavior = () => (
		<PageNavbarLink
			key="New Behavioral Logic"
			name="New Behavioral Logic"
			callback={() => setIsModalOpen(true)}
		/>
	);

	const left = isSeriesPage
		? [<Save key="save" />, <SaveAll key="saveAll" />, <NewBehavior key="new-behavior" />]
		: [
				<Save key="save" />,
				<SaveAll key="saveAll" />,
				<Duplicate key="duplicate" />,
				<NewVariation key="new-variation" />,
		  ];

	const right = isSeriesPage
		? [<BasicOptions key="options" disabledOptions={[UrlTemplate.COMPARISON]} />]
		: [
				<PageNavbarLink key="log" name="log" callback={() => alert("Open logs")} />,
				<PageNavbarLink key="diff" name="diff" callback={() => alert("Diff")} />,
				<BasicOptions key="options" />,
				<ActivationWidget key="activation" />,
		  ];

	const middle = isVariantPage
		? [
				<PageNavbarView
					name="Strategy"
					link={`/experiences/inbox-react/${params?.campaignId}/edit`}
					iconClass={"icon-pencil tab-btn_icon"}
					highlight={!isDesignPage}
					key="strategy"
				/>,
				<PageNavbarView
					name="Design"
					link={`/experiences/inbox-react/${params?.campaignId}/design`}
					iconClass={"icon-eye tab-btn_icon"}
					highlight={isDesignPage}
					key="design"
				/>,
		  ]
		: [];

	return (
		<>
			<PageNavbar left={left} middle={middle} right={right} />
			<PopupModal
				toggleModal={setIsModalOpen}
				closeOnOutClick={true}
				showModal={isModalOpen}
				style={{
					width: "35.714rem",
					maxWidth: "95%",
				}}
			>
				{isSeriesPage ? (
					<NewBehavioralLogicModal closeModal={() => setIsModalOpen(false)} />
				) : (
					<NewVariationModal closeModal={() => setIsModalOpen(false)} />
				)}
			</PopupModal>
		</>
	);
};

InboxNavBar.propTypes = {
	parents: PropTypes.arrayOf(parentPropTypes),
	variations: PropTypes.arrayOf(variationPropTypes),
	series: PropTypes.oneOfType([seriesPropTypes, seriesPagePropTypes]),
};

export default InboxNavBar;
