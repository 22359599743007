import { endpoints } from "../constants/common";
import httpStatusCodes from "../constants/httpStatusCodes";
import { CAMPAIGNS_URL } from "../redux/campaigns/constants";
import { LocalStorageService } from './localStorage';

/**
 * ESP Profiles API Data Schema
 * ```
 	{
		"data": {
			"type": "email_capture_esp_profiles",
			"attributes": {
				"name": "Email Capture ESP Profile",
				"esp-data": "{...}", // Contains JSON stringified object with `{ field_name: value, ... }`
				"esp-dont-wait": 0,
				"esp-submit-uncompleted": 0,
				"ignore-previous-captures": 0,
				"submission-redirect": ""
				"submission-redirect-delay": 0,
				"notes": ""
			},
			"relationships": {
				"esp": {
					"data": {
						"type": "esps",
						"id": "436"
					}
				},
				// This is optional and represents the cloned email capture ESP profile
				"source-email-capture-esp-profile": {
					"data": {
						"type": "email-capture-esp-profiles",
						"id": "1"
					}
				}
			}
		}
	}
	```
 */
const getESPProfilesURL = (websiteId, espProfileId) => {
	// eslint-disable-next-line no-eq-null, eqeqeq
	if (websiteId == null) {
		return '';
	}

	// eslint-disable-next-line no-eq-null, eqeqeq
	const resourceSegment = espProfileId == null ? '' : `/${espProfileId}`;

	//https://core-api.stg.wunderkind.co/websites/1828/email_capture_esp_profiles
	return `${endpoints.coreApi}/websites/${websiteId}/email_capture_esp_profiles${resourceSegment}`;
};

const sendRequest = async (url, method, options) => {
	if (!url) {
		throw new Error("Request URL is empty");
	}

	const { body, headers } = options || {};
	const token = LocalStorageService.getToken();
	const baseHeaders = {
		Authorization: `Bearer ${token}`,
		'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
	};

	// eslint-disable-next-line no-eq-null, eqeqeq
	const config = body == null
		? { method, headers: { ...headers, ...baseHeaders } }
		: { method, headers: { ...headers, ...baseHeaders }, body: JSON.stringify(body) };

	const response = await fetch(url, config);

	if (response.ok) {
		return response.json();
	}

	throw new Error(`${response.status}: ${httpStatusCodes[response.status]}`);
}

export const createProfile = async (websiteId, profile = {}) => {
	const url = getESPProfilesURL(websiteId);

	return await sendRequest(url, 'POST', { body: profile });
};

export const getProfiles = async (websiteId) => {
	const url = getESPProfilesURL(websiteId);

	return await sendRequest(url, 'GET');
};

export const getProfile = async (websiteId, espProfileId) => {
	const url = getESPProfilesURL(websiteId, espProfileId);

	return await sendRequest(url, 'GET');
};

export const updateProfile = async (websiteId, espProfileId, profile = {}) => {
	const url = getESPProfilesURL(websiteId, espProfileId);

	return await sendRequest(url, 'PUT', { body: profile });
};

export const getCampaignsByProfile = async (websiteId, espProfileId) => {
	const url = `${endpoints.coreApi}${CAMPAIGNS_URL}?website_id=${websiteId}&email_capture_esp_profile_id=${espProfileId}&include=children`;
	return await sendRequest(url, 'GET');
};

export const getEsps = async () => {
	const url = `${endpoints.coreApi}/esps`;
	return await sendRequest(url, 'GET');
};
