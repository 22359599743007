import React, { useEffect, useRef } from 'react';
import PropTypes from "prop-types";
import { useCodeMirror } from '@uiw/react-codemirror';
import { EditorView } from '@codemirror/view';

const NotesBlock = ({
	parentCampaign
}) => {
	const campaignNotes = parentCampaign?.attributes?.notes;
	const editor = useRef();
	const { setContainer } = useCodeMirror({
		container: editor.current,
		value: campaignNotes,
		extensions: [EditorView.lineWrapping],
        minHeight: "200px",
		height: "auto",
		border: "1px #f0f0f0 solid",
		background: "#f8f8f8",
	});

	useEffect(() => {
		if (editor.current) {
			setContainer(editor.current);
		}
	}, [editor.current]);

	return <div ref={editor} />;
}

NotesBlock.propTypes = {
	parentCampaign: PropTypes.object.isRequired,
};

export default NotesBlock;
