import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/common";
import { ApiService } from "../../service/api";
import { LocalStorageService } from "../../service/localStorage";
import { INBOX_CAMPAIGNS_URL } from "./constants";

export const getInboxCampaigns = createAsyncThunk(
	"inboxCampaigns/getInboxCampaigns",
	async (path = "") => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}`;
		const response = await ApiService.get(`${url}/${path}`);

		if (!response.ok) {
			throw new Error("Failed to load campaigns!");
		}

		return response.json();
	}
);

export const getInboxCampaign = createAsyncThunk(
	"inboxCampaigns/getInboxCampaign",
	async (campaignId) => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}/${campaignId}`;
		const response = await ApiService.get(url);

		if (!response.ok) {
			throw new Error("Failed to load inbox campaigns!");
		}

		return response.json();
	}
);

export const duplicateInboxCampaign = createAsyncThunk(
	"inboxCampaigns/duplicateInboxCampaign",
	async (campaignId) => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}/duplicate`;

		const token = LocalStorageService.getToken();
		const response = await fetch(url, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
				"Content-Type": "application/x-www-form-urlencoded",
			},
			body: `id=${campaignId}`,
		});

		if (!response.ok) {
			throw new Error("Failed to load inbox campaigns!");
		}

		return response.json();
	}
);

export const saveInboxCampaign = createAsyncThunk(
	"inboxCampaigns/saveInboxCampaign",
	async (campaign) => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}/${campaign.id}`;
		const response = await ApiService.patch(url, { payload: { data: campaign } });

		if (!response.ok) {
			throw new Error("Failed to load inbox campaigns!");
		}

		return response.json();
	}
);

export const createInboxCampaign = createAsyncThunk(
	"inboxCampaigns/createInboxCampaign",
	async (campaign) => {
		const url = `${endpoints.coreApi}${INBOX_CAMPAIGNS_URL}`;
		const response = await ApiService.post(url, { payload: { data: campaign } });

		if (!response.ok) {
			throw new Error("Failed to load inbox campaigns!");
		}

		return response.json();
	}
);

export const updateInboxCampaign = createAction("inboxCampaigns/updateInboxCampaign");
