import { useEffect } from "react";
import { handleLogout } from "@frontend/authentication";

const SignOut = () => {
	const url = new URLSearchParams(window.location.search);
	window.logoutReturnOverride = url.get("redirect");

	useEffect(() => {
		handleLogout();
	}, []);

	return null;
}

export default SignOut;
