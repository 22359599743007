import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PopupModal } from "../../../components";
import NewInboxSeriesForm from "./NewInboxSeriesForm";

const NewInboxSeriesButton = ({ websiteId }) => {
	const [showForm, setShowForm] = useState(false);

	return (
		<>
			<PopupModal showModal={showForm} toggleModal={() => setShowForm(false)}>
				<NewInboxSeriesForm websiteId={websiteId} closeForm={() => setShowForm(false)} />
			</PopupModal>
			<Button data-qa="new-font-button" onClick={() => setShowForm(true)}>
				New Inbox Series
			</Button>
		</>
	);
};

const Button = styled.button`
	font-size: 12px;
	font-weight: 600;
	font-stretch: 100%;
	line-height: 12px;
	background: none;
	border: none;
	color: #191919;
	cursor: pointer;
	&:hover {
		color: white;
	}
`;

NewInboxSeriesButton.propTypes = {
	onClick: PropTypes.func,
	websiteId: PropTypes.string.isRequired,
};

export default NewInboxSeriesButton;
