/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import styled from "styled-components";
import { AlertCircle } from "react-feather";
import { Wrapper } from "../../components";
import { ICON_SIZES, endpoints } from "../../constants/common";

const UsersLandingPage = () => {
	return (
		<Wrapper>
			<Users>
				<Label>Users</Label>
				<Message>
					<AlertCircle size={ICON_SIZES.large} /> This setting has moved to&nbsp;
					<a href={endpoints.connectUrl} target="_blank">Connect</a>
				</Message>
			</Users>
		</Wrapper>
	);
};

const Users = styled.div`
	margin-top:20px;
    background-color: #fff;
    border-radius: 6px;
    margin-left: 10px;
    color: #000;
	display: flex;
	flex-direction:row;
	justify-content: space-between;
`;

const Label = styled.span`
	display:inline-block;
	color: #000;
	font-weight:bolder;
	width:10%;
	padding: 20px;
`;

const Message = styled.span`
	display: flex;
	margin-left: 20px;
	background-color:#f9f1cb;
	padding: 20px;
    border-radius: 6px;
	border-top-left-radius: 0;
    border-bottom-left-radius: 0;
	width: 90%;

	svg {
		margin-right: 10px;
	}
`;

export default UsersLandingPage;
