import { createSlice } from "@reduxjs/toolkit";
import { getUser } from "./thunks";

const initialState = {
	isLoading: false,
	response: {
		data: [],
	},
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// getUser
		builder.addCase(getUser.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getUser.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response = action.payload;
		});
		builder.addCase(getUser.rejected, (state) => {
			state = initialState;
		});
	},
});

export default userSlice.reducer;
