import fetch from "isomorphic-fetch";
import { isPlainObject, forIn, isArray } from "lodash";
import { LocalStorageService } from "./localStorage";

const createUrlParams = (params) => {
	const searchParams = new URLSearchParams();

	if (isPlainObject(params)) {
		forIn(params, (value, key) => {
			if (isArray(value)) {
				value.forEach((el) => searchParams.append(key, el));
			} else {
				searchParams.append(key, value);
			}
		});
	}

	return searchParams;
};


const get = (url) => {
	const token = LocalStorageService.getToken();

	return fetch(url, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
};

const post = (url, { params, payload = {} }) => {
	const searchParams = createUrlParams(params).toString();
	const token = LocalStorageService.getToken();
	const uri = searchParams ? `${url}?${searchParams}` : url;

	return fetch(uri, {
		method: "POST",

		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});
};

const getWithSearchParams = (url, params) => {
	const searchParams = createUrlParams(params).toString();
	const token = LocalStorageService.getToken();
	const uri = searchParams ? `${url}?${searchParams}` : url;

	return fetch(uri, {
		method: "GET",
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

const postAsForm = (url, { params, payload = {} }) => {
	const searchParams = createUrlParams(params).toString();
	const token = LocalStorageService.getToken();
	const uri = searchParams ? `${url}?${searchParams}` : url;

	return fetch(uri, {
		method: "POST",

		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
		},
		body: payload,
	});
};

const patch = (url, {payload}) => {

	const token = LocalStorageService.getToken();
	return fetch(url, {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${token}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify(payload),
	});
};

export const ApiService = {
	get,
	post,
	postAsForm,
	getWithSearchParams,
	patch
};
