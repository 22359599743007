import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { TabbedContainer } from "../../../../../components";
import { CodeInput } from "../../../components/code-input";
import { getCreativeJson } from "../../utils/paramsParsing";

const designCodeInitialState = {
	campaignId: null,
	headerCode: "",
	mainCode: "",
	footerCode: "",
};

const DesignCodeType = Object.freeze({
	MAIN_CODE: "main_code",
	INITIALIZE: "initialize",
	FOOTER_CODE: "footer_code",
	HEADER_CODE: "header_code",
});

const designCodeReducer = (state, { type, data }) => {
	switch (type) {
		case DesignCodeType.INITIALIZE:
			return {
				campaignId: data.id,
				headerCode: getCreativeJson(data, "header"),
				mainCode: getCreativeJson(data, "main"),
				footerCode: getCreativeJson(data, "footer"),
			};
		case DesignCodeType.HEADER_CODE:
			return {
				...state,
				headerCode: data,
			};
		case DesignCodeType.MAIN_CODE:
			return {
				...state,
				mainCode: data,
			};
		case DesignCodeType.FOOTER_CODE:
			return {
				...state,
				footerCode: data,
			};
		default:
			return state;
	}
};

const Design = ({ campaign }) => {
	const [designCodeState, dispatchCodeState] = useReducer(
		designCodeReducer,
		designCodeInitialState
	);

	useEffect(() => {
		if (campaign) {
			dispatchCodeState({
				type: DesignCodeType.INITIALIZE,
				data: campaign,
			});
		}
	}, [campaign]);

	return (
		<>
			{designCodeState.campaignId && (
				<TabbedContainer
					tabsMap={{
						header: (
							<CodeInput
								code={designCodeState.headerCode}
								height="100%"
								onChange={(data) =>
									dispatchCodeState({
										type: DesignCodeType.HEADER_CODE,
										data,
									})
								}
							/>
						),
						main: (
							<CodeInput
								code={designCodeState.mainCode}
								height="100%"
								onChange={(data) =>
									dispatchCodeState({
										type: DesignCodeType.MAIN_CODE,
										data,
									})
								}
							/>
						),
						footer: (
							<CodeInput
								code={designCodeState.footerCode}
								height="100%"
								onChange={(data) =>
									dispatchCodeState({
										type: DesignCodeType.FOOTER_CODE,
										data,
									})
								}
							/>
						),
					}}
					defaultSelection="header"
				/>
			)}
		</>
	);
};

Design.propTypes = {
	campaign: PropTypes.object,
};

export default Design;
