import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import LoadingImage from "../../images/loading.gif";

/**
 * Returns the LoaderComponent, showing a Loading gif and Loading text
 * @param {bool} isLoading shows the loading component
 * @param {bool} isFullScreen displays the loading screen in either fullscreen or in the parent component
 * @returns <LoaderComponent isLoading />
 */

const LoaderComponent = ({ isLoading, isFullScreen = false }) => {
	if (isLoading) {
		return (
			<Loader isFullScreen={isFullScreen}>
				<Spinner>
					<img src={LoadingImage} alt="loading..." />
					<p>Loading...</p>
				</Spinner>
			</Loader>
		);
	}
	return <span></span>;
};

const Loader = styled.div`
	position: ${({ isFullScreen }) => isFullScreen ? "fixed" : "relative"};
	background-color: rgba(240, 248, 255, 0.85);
	z-index: 100;
	height: ${({ isFullScreen }) => isFullScreen ? "100vh" : "100%"};
	width: ${({ isFullScreen }) => isFullScreen ? "100vw" : "100%"};
	left: 0;
	top: 0;
`;

const Spinner = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	p {
		font-size: 12px;
		font-style: italic;
	}
`;

LoaderComponent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	isFullScreen: PropTypes.bool,
};

export default LoaderComponent;
