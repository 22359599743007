import { sharedDropDownValues, reportTypes } from "../../../constants/common";
import { createWebsiteCampaignData } from "../../businessLogic";
import reportsAdapter from "../reportsAdapter";

export const loadCampaignData = (state, action) => {
	const { payload } = action;
	const reportType = payload.type;
	let title = "";
	let date_start;
	let date_end;
	let date_interval;
	let totalNumOfData = 0;
	let data = [];
	if (reportType === reportTypes.websiteCampaign) {
		title = `${payload.website.name} All Campaigns Analytics`;
	} else if (
		reportType === reportTypes.parentCampaign ||
		reportType === reportTypes.childCampaign
	) {
		title = payload.campaign_name;
	}
	date_interval = state.reportOptions.date_interval;
	const now = new Date();
	data = createWebsiteCampaignData(reportType, payload.data, date_interval).filter(
		({ date }) => new Date(date.bottom) <= now
	);
	date_start = state.reportOptions.date_start;
	date_end = state.reportOptions.date_end;

	if (payload.next_date !== null) {
		totalNumOfData = getDataCount(
			date_start || payload?.website?.golive_date,
			date_end,
			date_interval
		);
	} else {
		totalNumOfData = data.length;
	}
	// update State
	state.title = title;
	state.makeApiCall = false;
	state.totalNumOfData = totalNumOfData;
	state.next_date = payload.next_date;
	reportsAdapter.addMany(state.data, data);
};

const getDataCount = (date_start, date_end, date_interval) => {
	const endDate = new Date(date_end);
	const startDate = new Date(date_start);
	const diff = endDate.getTime() - startDate.getTime();
	//INFO +1 to include the end day as part of the count
	const numOfDays = diff / (1000 * 3600 * 24) + 1;
	const hoursInDay = 24;
	const { hour, day } = sharedDropDownValues.viewBy;
	const currentDate = new Date();
	const remainingDayHours = 24 - currentDate.getHours();
	const map = {
		[hour]: hoursInDay * numOfDays - remainingDayHours,
		[day]: numOfDays,
	};
	return map[date_interval] || 0;
};
