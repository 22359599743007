import { createSlice } from "@reduxjs/toolkit";
import { getInboxType } from "./thunks";
import inboxTypesAdapter from "./inboxTypesAdapter";

const initialState = {
	isLoading: false,
	isFetchError: false,
	response: {
		data: inboxTypesAdapter.getInitialState(),
		meta: {},
		included: [],
	},
	selectedCampaign: null,
};

const inboxCampaignTypesSlice = createSlice({
	name: "inboxCampaignTypes",
	initialState,
	reducers: {
		clearCampaignsApiError(state) {
			state.isFetchError = false;
		},
	},
	extraReducers: (builder) => {
		// getInboxType
		builder.addCase(getInboxType.pending, (state) => {
			state.isLoading = true;
		});
		builder.addCase(getInboxType.fulfilled, (state, action) => {
			state.isLoading = false;
			state.response.meta = action.payload.meta;
			state.response.included = action.payload.included;
			inboxTypesAdapter.setMany(state.response.data, action.payload.data);
		});
		builder.addCase(getInboxType.rejected, (state) => {
			state.isLoading = false;
			state.isFetchError = true;
			inboxTypesAdapter.removeAll(state.response.data);
		});
	},
});

export const { clearCampaignsApiError, clearInboxApiError } = inboxCampaignTypesSlice.actions;

export default inboxCampaignTypesSlice.reducer;
