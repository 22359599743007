import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components"
import { ToolTip } from "../../../components";

import { updateFonts } from "../../../redux/fonts/thunks";


const FontsRow = ({ font }) => {
	const { attributes, relationships } = font;
	// const dispatch = useDispatch();

	const openModal = () => {
		console.log("open edit modal");
		console.log(font);
	}

	const updateFont = (value) => {
		// dispatch(updateFonts({ font, value }))
	}

	//@ todo - styling, making sure clicking on family name opens the edit / archive modal
	return (
		<FontRow>
			<FamilyName onClick={() => openModal()}>{attributes?.family}</FamilyName>
			<FontWeight>
				<ToolTip position="bottom" text="Font Weight">
					{attributes?.weight}
				</ToolTip>
			</FontWeight>
			<div>
				<ToolTip position="bottom" text="Font Style">
					{attributes?.style}
				</ToolTip>
			</div>

			{/* <WebsiteList data={relationships?.websites?.data} /> */}

			<FontArchiveButton onClick={() => updateFont("archive")}>{attributes?.active ? "archive": "unarchive"}</FontArchiveButton>
		</FontRow>
	);
};

const FamilyName = styled.div`
	cursor: pointer;
	width: 240px;
	font-weight: 600;
	&:hover {
		color: #2a2c31;
	}
`;

const FontArchiveButton = styled.button`
	margin-left: auto;
`;

const FontWeight = styled.div`
	width: 175px;
`;

const FontRow = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 20px;
	width: 100%;
	background-color: #fff;
	color: #535252;
	height: 44px;
	/* height:  ${({ isParent }) => (isParent ? "53px" : "42px")}; */
	border: 1px solid;
	border-color: ${({ isSelected }) => isSelected
		? "rgb(48, 61, 120)"
		: "rgb(232, 232, 232)"
	};
	z-index: ${({ isSelected }) => isSelected ? "5" : "1"};
	&:not(:first-child) {
		margin-top: -1px;
	}
	&:hover {
		border-color: rgb(48, 61, 120);
		z-index: 5;
		filter: brightness(95%);
	}
`;


// @ todo fix this so proper shaping
FontsRow.propTypes = {
	font: PropTypes.object
}

export default FontsRow;
