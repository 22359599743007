import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { selectFormattedEsps, selectProfileNames } from "../../../redux/espProfiles/selectors";
import { Button, SimpleText, Checkbox, Select, InputText } from "../../../components";
import { InputContainer } from "../../../components/styles";
import { generateClonedProfileName, toEspApiFieldsDto } from "../edit/esp-profiles/utils";
import { toBoolean } from "../../../utils";
import { createValidator, required, url } from "../../../validation";

const widths = {
    labelWidth: "15%",
    inputWidth: "50%",
    splitInputWidth: "80%",
    textareaWidth: "100%",
    fieldInputWidth: "100%",
};

// '*' denotes needed form field
// =============================
// dtDeleted
// dtEdited
// dtInserted
// espData *
// espDontWait *
// espId *
// espProfileId * For cloning
// espSubmitUncompleted *
// id
// ignorePreviousCaptures *
// name *
// notes *
// submissionRedirect *
// submissionRedirectDelay *
// websiteId
//
// Needed for create esp profile api
// =================================
// "name": "Email Capture ESP Profile",
// "esp-data": "{...}",
// "esp-dont-wait": 0,
// "esp-submit-uncompleted": 0,
// "ignore-previous-captures": 0,
// "submission-redirect": ""
// "submission-redirect-delay": 0,
// "notes": ""

const DEFAULT_ECAP_PROFILE_FORM_FIELDS = {
    espData: '{}',
    espDontWait: true,
    espId: '',
    espProfileId: '',
    espSubmitUncompleted: false,
    ignorePreviousCaptures: false,
    name: '',
    notes: '',
    submissionRedirect: '',
    submissionRedirectDelay: 2,
};

export const FormActions = {
	CREATE: 'create',
	CLONE: 'clone',
	EDIT: 'edit',
	DELETE: 'delete',
};

const createDynamicValidator = (espApiFormFields = {}) => {
    const espFieldArray = Object.entries(espApiFormFields);
    // const espFieldValidations = espFieldArray.reduce((result, [label, field]) => {
    //     return toBoolean(field?.required)
    //         ? { ...result, [label]: [required()] }
    //         : { ...result };
    // }, {});

    const execValidation = createValidator({
        name: [required()],
        espId: [required()],
        submissionRedirect: [url()],
        // ...espFieldValidations,
    });

    const validate = (fields) => {
        const dynamicFields = espFieldArray.reduce((result, [label, field]) => {
            return { ...result, [label]: field?.value };
        }, {});

        return execValidation({ ...fields, ...dynamicFields });
    };

    return validate;
};

const getEspApiFormFields = (fields, esps) => {
    const { espId, espData } = fields;
    const selectedEsp = esps.find(({ id }) => id === espId);
    const espApiFields = selectedEsp ? toEspApiFieldsDto(espData, selectedEsp?.fields) : {};
    return espApiFields;
};

const getEspDataString = (apiFields) => {
    const apiFieldValues = Object.entries(apiFields).reduce((result, [key, field]) => {
        return {
            ...result,
            [key]: field?.value,
        };
    }, {});
    return JSON.stringify(apiFieldValues);
};

const ESPProfileForm = ({ formattedProfile, closeForm, formAction, onSubmit }) => {
    /** State management */
    const initialProfileState = {
        ...DEFAULT_ECAP_PROFILE_FORM_FIELDS,
        ...(formattedProfile || {}),
    };
    const formattedEsps = useSelector(selectFormattedEsps);
    const profileNames = useSelector(selectProfileNames);
    const profileName = initialProfileState.name && formAction === FormActions.CLONE
        ? generateClonedProfileName(initialProfileState.name, profileNames)
        : initialProfileState.name;
    const [fields, setFields] = useState({
        ...initialProfileState,
        name: profileName,
    });
    const [showErrors, setShowErrors] = useState(false);

    /** Local vars */
    const espApiFormFields = getEspApiFormFields(fields, formattedEsps);
    const validate = createDynamicValidator(espApiFormFields);
    const validations = validate(fields);
    const isValid = Object.keys(validations).length === 0;

    const handleUpdateEspFieldValue = (key) => (value) => {
        setFields({
            ...fields,
            [key]: value,
        });
    };

    const handleOnSummit = () => {
        if (isValid) {
            onSubmit(fields);
        } else {
            setShowErrors(true);
        }
    };

    const formHeading = {
        [FormActions.CREATE]: 'Create new Onsite ESP Profile',
        [FormActions.CLONE]: `Clone ${formattedProfile?.name}`,
        [FormActions.EDIT]: `Edit ${formattedProfile?.name}`,
    };

    const formButtonText = {
        [FormActions.CREATE]: 'Create',
        [FormActions.CLONE]: 'Clone',
        [FormActions.EDIT]: 'Edit',
    };

    return(
        <Container>
            <HeaderContainer>
                <Header>
                    {formHeading[formAction]}
                </Header>
            </HeaderContainer>

            <FormContainer>
                <FormRow>
                    <Label>
                        Onsite ESP Profile Name
                    </Label>
                    <ErrorWrapper message={showErrors ? validations.name : ''}>
                        <InputText
                            width={widths.inputWidth}
                            dataQA="esp-name"
                            label="ESP Name"
                            required="true"
                            setValue={({ target }) => {
                                handleUpdateEspFieldValue('name')(target.value);
                            }}
                            value={fields?.name}
                        />
                    </ErrorWrapper>
                </FormRow>

                <FormRow>
                    <Label>
                        API Settings
                    </Label>
                    <ErrorWrapper message={showErrors ? validations.espId : ''}>
                        <Select
                            width={widths.inputWidth}
                            dataQA="esp-id"
                            data={[{ id: '', name: '' }, ...formattedEsps]}
                            value={fields?.espId}
                            onChange={(value) => {
                                handleUpdateEspFieldValue('espId')(value);
                            }}
                        />
                    </ErrorWrapper>
                </FormRow>

                <FormRow>
                    <CheckboxText
                        labelId="submitUncompleted"
                        checked={fields?.espSubmitUncompleted}
                        handleCheck={(value) => {
                            handleUpdateEspFieldValue('espSubmitUncompleted')(value)
                        }}
                    >
                        Submit Incomplete
                    </CheckboxText>

                    <CheckboxText
                        labelId="espDontWait"
                        checked={fields?.espDontWait}
                        handleCheck={(value) => {
                            handleUpdateEspFieldValue('espDontWait')(value);
                        }}
                    >
                        Wait for ESP
                    </CheckboxText>

                    <CheckboxText
                        labelId="showPostSubmitters"
                        checked={fields?.ignorePreviousCaptures}
                        handleCheck={(value) => {
                            handleUpdateEspFieldValue('ignorePreviousCaptures')(value);
                        }}
                    >
                        Show to Past Submitters
                    </CheckboxText>
                </FormRow>

                <FormSection>
                    <FormRow>
                        <Label>
                            Post Submit
                        </Label>
                        <ErrorWrapper message={showErrors ? validations.submissionRedirect : ''}>
                            <InputText
                                width={widths.splitInputWidth}
                                dataQA="esp-submission-redirect"
                                label="Enter URL"
                                required="true"
                                setValue={({ target }) => {
                                    handleUpdateEspFieldValue('submissionRedirect')(target.value);
                                }}
                                value={fields?.submissionRedirect}
                            />
                        </ErrorWrapper>

                        <InputText
                            width={widths.splitInputWidth}
                            dataQA="esp-submission-redirect-delay"
                            label="Enter Delay Redirect for..."
                            setValue={({ target }) => {
                                handleUpdateEspFieldValue('submissionRedirectDelay')(target.value);
                            }}
                            value={fields?.submissionRedirectDelay}
                        />
                    </FormRow>

                    {Object.entries(espApiFormFields).map(([label, field]) => {
                        return (
                            <ESPApiField
                                key={label}
                                label={label}
                                field={field}
                                // validationMessage={showErrors ? validations[label] : ''}
                                onChange={(field) => {
                                    const newEspData = { ...espApiFormFields, ...field };
                                    const espData = getEspDataString(newEspData);
                                    handleUpdateEspFieldValue('espData')(espData);
                                }}
                            />
                        );
                    })}
                </FormSection>

                <FormRow>
                    <Label>Notes</Label>
                    <InputTextArea
                        dataQA="notes"
                        placeholder="Try entering some goals and notes..."
                        onChange={({ target }) => {
                            handleUpdateEspFieldValue('notes')(target.value);
                        }}
                        value={fields?.notes}
                    />
                </FormRow>

                <ButtonContainer>
                    <Button
                        data-qa="submit-esp-button"
                        onClick={handleOnSummit}
                        theme="blue"
                        width="90px"
                    >
                        {formButtonText[formAction]}
                    </Button>

                    <Button
                        data-qa="cancel-esp-button"
                        onClick={closeForm}
                        theme="blue"
                        width="90px"
                    >
                        Cancel
                    </Button>
                </ButtonContainer>
            </FormContainer>
        </Container>
    )
}

const CheckboxText = ({ labelId, checked, handleCheck, children, margin }) => {
    return(
        <div
            style={{
                margin: margin ?? "0 0.786rem",
                display: "flex",
                alignItems: "center"
            }}
        >
            <Checkbox
                id={labelId}
                onChange={({ target }) => handleCheck(target.checked)}
                defaultChecked={checked}
            />

            <SimpleText
                as="label"
                htmlFor={labelId}
                margin="0 0.429rem">
                {children}
            </SimpleText>
        </div>
    )
};

const ESPApiField = ({
    label,
    field,
    // validationMessage = '',
    onChange = () => {},
}) => {
    const getChoices = () => {
        const choices = (field?.choices || []).map((choice) => {
            return {
                name: choice,
                id: choice
            }
        });

        return [{ id: '', name: ''}, ...choices];
    };

    const handleOnChange = (value) => {
        onChange({ [label]: { ...field, value } });
    };

    const required = toBoolean(field?.required);

    return(
        <FieldRow>
            <FieldLabel htmlFor={label}>
                {label}
                {required && <Required>Required</Required>}
            </FieldLabel>
            {/* <ErrorWrapper message={validationMessage}> */}
                {field?.type === "dd" && (
                    <Select
                        width={widths.fieldInputWidth}
                        dataQA={`${label}-api-settings-textarea`}
                        data={getChoices()}
                        value={field?.value}
                        onChange={(value) => handleOnChange(value)}
                        // required
                    />
                )}
                {field?.type  === "text" && (
                    <InputContainer
                        width={widths.fieldInputWidth}
                    >
                        <InputTextArea
                            dataQA={`${label}-api-settings-textarea`}
                            placeholder="Try entering some goals and notes..."
                            onChange={({ target }) => handleOnChange(target.value)}
                            value={field?.value}
                            // required
                        />
                    </InputContainer>
                )}

                {(field?.type === "input" || field?.type === "password") && (
                    <div
                        style={{
                            width: "100%",
                        }}
                        className="error-container">
                        <InputText
                            style={{
                                gridColumn: "2 / 3",
                                gridRow: "1 / 3",
                            }}
                            type={field?.type}
                            width={widths.fieldInputWidth}
                            dataQA={`${label}-api-settings-input-${field?.type}`}
                            label={"Enter " + label}
                            setValue={({ target }) => handleOnChange(target.value)}
                            value={field?.value}
                            // required
                        />
                    </div>
                )}
            {/* </ErrorWrapper> */}
        </FieldRow>
    )
};

const ErrorWrapper = ({ message = '', children }) => {
    const hasError = !!message;
    return (
        <Error hasError={hasError}>
            {children}
            {message && (
                <span className="error-message">
                    {message}
                </span>
            )}
        </Error>
    )
};

ESPApiField.propTypes = {
    field: PropTypes.object,
    label: PropTypes.string,
    validationMessage: PropTypes.string,
    onChange: PropTypes.func,
}

ESPProfileForm.propTypes = {
    closeForm: PropTypes.func.isRequired,
    formAction: PropTypes.string.isRequired,
    formattedProfile: PropTypes.shape({
        name: PropTypes.string,
    }),
    onSubmit: PropTypes.func,
}

CheckboxText.propTypes = {
    labelId: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
	margin: PropTypes.string,
    checked: PropTypes.bool,
    handleCheck: PropTypes.func.isRequired,
};

ErrorWrapper.propTypes = {
    message: PropTypes.string,
    children: PropTypes.node,
};

const FormSection = styled.section`

`

const Container = styled.div`
    overflow: auto;
    min-width: 50vw;
`

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 22px;
`

const Header = styled.h5`
    font-size: 14px;
    font-weight: 600;
`

const FormContainer = styled.form`
    background-color: white;
    padding: 20px 0;
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid #f5f5f5;
`

const FormRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 15px;
    padding: 20px 10px;
    &:not(:nth-last-child(2)) {
        border-bottom: 3px solid #f5f5f5;
    }
`

const FieldRow = styled.div`
    display: flex;
    align-items: top;

    padding: 20px 10px;
    &:last-child {
        border-bottom: 3px solid #f5f5f5;
    }
`
const Label = styled.label`
    min-width: 90px;
    max-width: 90px;
`

const FieldLabel = styled(Label)`
    width: 30%;
    min-width: unset;
    max-width: unset;
`

const InputTextArea = styled.textarea`
    width: ${widths.textareaWidth};
    min-height: 100px;
    resize: vertical;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    button {
        margin: 30px 15px 0;
    }
`

const Required = styled.p`
    font-weight: normal;
    font-size: 12px;
    display: block;
    margin: 5px 0 0 0;
`

const Error = styled.div`
    width: 100%;

    & .error-message {
        font-size: 11px;
        color: ${props => props.hasError ? "#b64b25" : "inherit"};
    }
`

export default ESPProfileForm;
