import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { set, get } from "lodash";
import { Wrapper, Loader } from "../../components";
import { getWebsite, saveWebsite } from "../../redux/website/thunks";
import { InputContainer, InputLabel, Input, Select } from "../../components/styles";
import { Button, Checkbox } from "../../components";
import { usePrevious } from "../../hooks/usePrevious";
import { getValidationErrors } from "./validation";

export const getInitialFormState = () => ({
	apiIntegrations: [
		{
			int: "",
			url: "",
			clientId: "",
			clientSecret: "",
		},
	],
	integrations: {
		email: { prop: "", int: "" },
		login: { prop: "", int: "", val: "" },
		registration: { prop: "", int: "", val: "" },
		paidSubs: { prop: "", int: "", val: "" },
		loyalty: { prop: "", int: "", val: "" },
	},
	pageCount: {
		interval: '0',
		pageTypes: [],
	},
});

const CustomerRegistrationPage = () => {
	const dispatch = useDispatch();

	const [hasErrors, setHasErrors] = useState(false);

	const {
		params: { website_id },
	} = useSelector((state) => state.page);

	const {
		isLoading,
		isFetchError,
		isUpdating,
		hasUpdateErrors,
		response: { data: websiteData },
	} = useSelector((state) => state.website);

	const [formState, setFormState] = useState(getInitialFormState());
	const [formErrors, setFormErrors] = useState({});
	const [submitClicked, setSubmitClicked] = useState(false);
	const crSettings = websiteData?.attributes?.["customer-registration-settings"];

	useEffect(() => {
		if (!isLoading && !isFetchError && crSettings) {
			try {
				const backendData = JSON.parse(crSettings);
				setFormState(backendData);
			} catch (e) {
				toast.error("Failed to load settings.", { autoClose: false });
				console.log("Error", e);
			}
		}
	}, [isLoading, isFetchError]);

	useEffect(() => {
		if (!website_id) {
			setHasErrors(true);
			return;
		}
		dispatch(getWebsite(website_id));
	}, []);

	useEffect(() => {
		if (submitClicked) {
			formHasValidationErrors();
		}
	}, [formState]);

	const wasUpdating = usePrevious(isUpdating);
	useEffect(() => {
		if (!wasUpdating || isUpdating) {
			return;
		}
		if (hasUpdateErrors) {
			toast.error("Failed to save Customer Registration Settings.", { autoClose: false });
		} else {
			toast.success("Customer Registration Settings updated.", { autoClose: 1000 });
		}
	}, [isUpdating, hasUpdateErrors]);

	const title = "Customer Registration";

	const hasFetchErrors = () => !isLoading && (hasErrors || isFetchError);
	const isWebsiteDataLoaded = () => !hasFetchErrors() && websiteData?.attributes;

	const hasFailedToLoad = () => {
		if (hasFetchErrors()) {
			toast.error(
				`Error fetching Customer Registration Settings for website_id "${website_id}"`,
				{ autoClose: false }
			);
		}
	};

	const getPageTypes = () => {
		let pageTypes = [];
		if (websiteData?.attributes?.["page-type-json"]) {
			try {
				const pageTypesJson = JSON.parse(websiteData?.attributes?.["page-type-json"]);
				pageTypes = Object.keys(pageTypesJson);
			} catch (e) {
				toast.error("Failed to load page types.", { autoClose: false });
				console.log(e);
			}
		}
		return pageTypes;
	};

	const renderCheckboxPageTypes = () => {
		const pageTypes = getPageTypes();
		if (!pageTypes.length) {
			return (
				<InlineText style={{ width: "25%", paddingLeft: 0 }}>
					No page types found.
				</InlineText>
			);
		}

		return pageTypes.map((page) => (
			<InputContainer key={page} className="input-container-checkbox">
				<Checkbox
					checked={formState.pageCount.pageTypes.indexOf(page) > -1}
					name={page}
					onChange={handleCheckboxChange}
				/>
				<InputLabel>{page}</InputLabel>
			</InputContainer>
		));
	};

	const buildIntegrationSelect = (name) => {
		const errorClass = formErrors[name] ? 'error' : '';
		const className = `select-container ${errorClass}`;
		const selectValue = get(formState, name);
		return (
			<InputContainer className={className}>
				<InputLabel>Integration</InputLabel>
				<Select 
					name={name} 
					onChange={handleFieldChange} 
					value={selectValue} 
					title={formErrors[name]}
				>
					<option value="">Select integration...</option>
					<option value="blueconic">Blueconic</option>
					<option value="loyaltyplus">Merkle - LoyaltyPlus</option>
				</Select>
			</InputContainer>
		);
	};

	const buildInputField = (label, name, type = "text", extraClasses="") => {
		const inputValue = get(formState, name);
		const errorClass = formErrors[name] ? 'error' : '';
		const className = `input-container-${type} ${extraClasses} ${errorClass}`;
		return (
			<InputContainer className={className}>
				<InputLabel>{label}</InputLabel>
				<Input
					type={type}
					value={inputValue}
					name={name}
					onChange={handleFieldChange}
					onBlur={handleInputBlur}
					title={formErrors[name]}
				/>
			</InputContainer>
		);
	};

	const handleFieldChange = (e) => {
		const { name: propPath, value } = e.target;
		setFormState((prevFormState) => {
			return { ...set(prevFormState, propPath, value) };
		});
	};

	const handleInputBlur = (e) => {
		const { name: propPath, value } = e.target;
		setFormState((prevFormState) => {
			return { ...set(prevFormState, propPath, value.trim()) };
		});
	};

	const handleCheckboxChange = (e) => {
		const { name: pageName, checked } = e.target;
		setFormState((prevFormState) => {
			const indexOfPage = prevFormState.pageCount.pageTypes.indexOf(pageName);
			if (checked && indexOfPage === -1) {
				prevFormState.pageCount.pageTypes.push(pageName);
			}
			if (!checked && indexOfPage > -1) {
				prevFormState.pageCount.pageTypes.splice(indexOfPage, 1);
			}
			return { ...prevFormState };
		});
	};

	const formHasValidationErrors = () => {
		const validationErrors = getValidationErrors(formState);
		setFormErrors(validationErrors);
		return !!Object.keys(validationErrors).length;
	}

	const saveSettings = () => {
		setSubmitClicked(true);
		if (formHasValidationErrors()) {
			toast.error(
				`Validation errors found!`,
				{ autoClose: 1000 }
			);
			return;
		}

		let customerRegistrationSettings;
		try {
			customerRegistrationSettings = JSON.stringify(formState);
		} catch (e) {
			toast.error("Failed to save customer registration settings.", { autoClose: 1000 });
			console.log(e);
			return;
		}

		const websitePayload = {
			data: {
				id: website_id,
				attributes: {
					"customer-registration-settings": customerRegistrationSettings,
				},
			},
		};
		dispatch(saveWebsite(websitePayload));
	};

	const hasFormDataChanged = () => {
		if (crSettings) {
			return crSettings !== JSON.stringify(formState);
		}
		return JSON.stringify(getInitialFormState()) !== JSON.stringify(formState);
	};

	const shouldDisableSaveButton = () => {
		return !hasFormDataChanged() || isUpdating || !!Object.keys(formErrors).length;
	};

	const renderSaveButton = () => {
		const isDisabled = shouldDisableSaveButton();
		const label = isDisabled ? "Save" : "Save *";
		const theme = isDisabled ? "gray" : "blue";
		return (
			<Button
				theme={theme}
				onClick={saveSettings}
				disabled={isDisabled}
				isLoading={isUpdating}
			>
				{label}
			</Button>
		);
	};

	return (
		<Wrapper title={title} pageTitle={title}>
			<Loader isLoading={isLoading} />
			{isWebsiteDataLoaded() && (
				<>
					<AnotherCustomHeader>
						Customer Registration Settings for "{websiteData?.attributes?.name}"
					</AnotherCustomHeader>
					<AnotherCustomHeader>API Settings</AnotherCustomHeader>
					<FormSection>
						{buildIntegrationSelect("apiIntegrations[0].int")}
						{buildInputField("Integration URL", "apiIntegrations[0].url")}
						{buildInputField(
							"Integration Client ID (Consumer Key/API key)",
							"apiIntegrations[0].clientId"
						)}
						{buildInputField(
							"Integration Client Secret",
							"apiIntegrations[0].clientSecret",
							"password"
						)}
					</FormSection>

					<AnotherCustomHeader>Front End SDK Settings</AnotherCustomHeader>
					<FormSection>
						{buildInputField(
							"Visitor's email property",
							"integrations.email.prop"
						)}
						{buildIntegrationSelect("integrations.email.int")}
						{buildInputField(
							"Visitor's login status property",
							"integrations.login.prop",
							"text",
							"variation-1"
						)}
						{buildInputField(
							"Visitor's login status value",
							"integrations.login.val",
							"text",
							"variation-1"
						)}
						{buildIntegrationSelect("integrations.login.int")}
						{buildInputField(
							"Visitor's registration status property",
							"integrations.registration.prop",
							"text",
							"variation-1"
						)}
						{buildInputField(
							"Visitor's registration status value",
							"integrations.registration.val",
							"text",
							"variation-1"
						)}						
						{buildIntegrationSelect("integrations.registration.int")}
						{buildInputField(
							"Visitor's paid subscription status property",
							"integrations.paidSubs.prop",
							"text",
							"variation-1"
						)}
						{buildInputField(
							"Visitor's paid subscription status value",
							"integrations.paidSubs.val",
							"text",
							"variation-1"
						)}						
						{buildIntegrationSelect("integrations.paidSubs.int")}
						{buildInputField(
							"Visitor's loyalty program status property",
							"integrations.loyalty.prop",
							"text",
							"variation-1"
						)}
						{buildInputField(
							"Visitor's loyalty program status value",
							"integrations.loyalty.val",
							"text",
							"variation-1"
						)}						
						{buildIntegrationSelect("integrations.loyalty.int")}						
					</FormSection>

					<AnotherCustomHeader>Page Counter Settings</AnotherCustomHeader>
					<FormSection className="page-count">
						<InlineText>Interval:</InlineText>
						{buildInputField("", "pageCount.interval", "number")}
						<InlineText>Days</InlineText>
					</FormSection>

					<AnotherCustomHeader>Page Types to be counted</AnotherCustomHeader>
					<FormSection className="page-types">{renderCheckboxPageTypes()}</FormSection>
					{renderSaveButton()}
				</>
			)}
			{hasFailedToLoad()}
		</Wrapper>
	);
};

// TODO: Standarize common components
const AnotherCustomHeader = styled.h4`
	color: #261f17;
	font-size: 12px;
	font-weight: 700;
	margin-bottom: 0;
`;

const InlineText = styled.span`
	font-size: 12px;
`;

const FormSection = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 5px 0 15px;

	&.page-count {
		justify-content: start;
	}

	&.page-types {
		justify-content: start;
	}

	${InputContainer} {
		flex-grow: 0;
		width: 49.8%;
		margin-bottom: 5px;

		&.variation-1 {
			width: 24.7%;
		}

		&.error {
			input, select {
				border: 1px solid #d14;
				background: #f2dede;
			}
		}

		&.input-container-number {
			width: 5%;
			margin: 0 0.786rem;
			input[type="number"] {
				padding-top: 0.786rem;
			}
		}

		&.input-container-checkbox {
			width: 12%;
			flex-direction: row;
			${InputLabel} {
				font-size: 12px;
				position: initial;
				margin: 0 10px;
			}
		}

		${Input} {
			padding-top: 1.9rem;
		}
	}

	${InlineText} {
		padding: 0.786rem 0;
	}
`;

export default CustomerRegistrationPage;
