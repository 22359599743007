import React from "react";
import { Section } from "../../../../../../components/form";
import { CodeInput } from "../../../../components/code-input";

const UnsubscribeESPExtraFields = () => {
	const code = "console.log('UnsubscribeESPExtraFields');";

	return (
		<Section title="Unsubscribe ESP Extra Fields">
			<CodeInput code={code} />
		</Section>
	);
};

export default UnsubscribeESPExtraFields;
