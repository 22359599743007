import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "../../../components";
import { Dropdown, FormInput } from "../../../components/form";
import { createNewWebsite } from "../../../redux/websites/thunks";

const NewWebsiteForm = ({ closeForm }) => {
	const [websiteName, setWebsiteName] = useState("");
	const [websiteURL, setWebsiteURL] = useState("");
	const [websiteIndustry, setWebsiteIndustry] = useState("");
	const {
		response: { data: industries = [] },
	} = useSelector((state) => state.industries);
	const isDisabled = !websiteName || !websiteURL;
	const dispatch = useDispatch();

	const options = industries.map(({ attributes }) => {
		return {
			value: attributes?.id,
			name: attributes?.name,
		};
	});

	const submitForm = async () => {
		const response = await dispatch(
			createNewWebsite({
				name: websiteName,
				website: websiteURL,
				industryId: websiteIndustry,
				notSaveInStore: true,
			})
		);
		const siteId = response?.payload?.json?.data?.id;

		if (siteId) {
			window.location.href = `/websites-legacy/${siteId}/edit`;
		}

		closeForm();
	};

	return (
		<FormContainer>
			<FormInput
				value={websiteName}
				onChange={setWebsiteName}
				label="Name"
				placeholder="New website"
			/>
			<FormInput
				value={websiteURL}
				onChange={setWebsiteURL}
				label="Website URL"
				placeholder="example.com"
			/>
			<Dropdown
				width="100%"
				field="id"
				options={options}
				value={websiteIndustry}
				onChange={(e) => {
					setWebsiteIndustry(e.id);
				}}
				label="Client Type - Industry"
				type="select"
				margin={"5px 0 10px 0"}
			/>
			<Button disabled={isDisabled} onClick={submitForm} theme="blue">
				Create Website
			</Button>
		</FormContainer>
	);
};

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
	button {
		margin-top: 5px;
	}
`;

NewWebsiteForm.propTypes = {
	closeForm: PropTypes.func.isRequired,
};
export default NewWebsiteForm;
