import { toast } from "react-toastify";
import { useCallbackDispatch } from "../useCallbackDispatch";
import {
	createProfileThunk,
	cloneProfileThunk,
	updateProfileThunk,
	deleteProfileThunk,
	getProfilesThunk,
	getProfileThunk,
	getEspsThunk,
	getCampaignsByProfileThunk,
} from "../../redux/espProfiles/thunks";

const createEspProfileCallbacks = {
	onFulfilled: () => {
		toast.success('ESP Profile successfully created!');
	},
	onRejected: () => {
		toast.error('There was an error creating the ESP Profile');
	},
};

const cloneEspProfileCallbacks = {
	onFulfilled: () => {
		toast.success('ESP Profile successfully cloned!');
	},
	onRejected: () => {
		toast.error('There was an error cloning the ESP Profile');
	},
};

const updateEspProfileCallbacks = {
	onFulfilled: () => {
		toast.success('ESP Profile successfully updated!');
	},
	onRejected: () => {
		toast.error('There was an error updating the ESP Profile');
	},
};

const deleteEspProfileCallbacks = {
	onFulfilled: () => {
		toast.success('ESP Profile successfully deleted!');
	},
	onRejected: () => {
		toast.error('There was an error deleting the ESP Profile');
	},
};

// Quick util to abstract combining the base callbacks
const mergeCallbacks = (callbacksA = {}, callbacksB = {}) => ({
	onPending: (payload) => {
		callbacksA?.onPending?.(payload);
		callbacksB?.onPending?.(payload);
	},
	onFulfilled: (payload) => {
		callbacksA?.onFulfilled?.(payload);
		callbacksB?.onFulfilled(payload);
	},
	onRejected: (payload) => {
		callbacksA?.onRejected?.(payload);
		callbacksB?.onRejected?.(payload);
	},
});

export const useEspProfileActions = (websiteId) => {
	const dispatch = useCallbackDispatch();

	const createEspProfile = (profileDto, callbacks = {}) => {
		return dispatch(
			createProfileThunk({ websiteId, profileDto }),
			mergeCallbacks(createEspProfileCallbacks, callbacks),
		);
	};

	const cloneEspProfile = (profileDto, callbacks = {}) => {
		return dispatch(
			cloneProfileThunk({ websiteId, profileDto }),
			mergeCallbacks(cloneEspProfileCallbacks, callbacks),
		);
	};

	const updateEspProfile = (espProfileId, profileDto, callbacks = {}) => {
		return dispatch(
			updateProfileThunk({ websiteId, espProfileId, profileDto }),
			mergeCallbacks(updateEspProfileCallbacks, callbacks),
		);
	};

	const deleteEspProfile = (espProfileId, profileDto, callbacks = {}) => {
		return dispatch(
			deleteProfileThunk({ websiteId, espProfileId, profileDto }),
			mergeCallbacks(deleteEspProfileCallbacks, callbacks),
		);
	};

	const getEspProfiles = (callbacks = {}) => {
		return dispatch(getProfilesThunk({ websiteId }), callbacks);
	};

	const getEspProfile = (espProfileId, callbacks = {}) => {
		return dispatch(getProfileThunk({ websiteId, espProfileId }), callbacks);
	};

	const getEsps = (callbacks = {}) => {
		return dispatch(getEspsThunk(), callbacks);
	};

	const getCampaignsByProfile = (espProfileId, callbacks = {}) => {
		return dispatch(getCampaignsByProfileThunk({ websiteId, espProfileId }), callbacks);
	};

	return {
		createEspProfile,
		cloneEspProfile,
		updateEspProfile,
		deleteEspProfile,
		getEspProfiles,
		getEspProfile,
		getEsps,
		getCampaignsByProfile,
	};
};
