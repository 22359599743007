import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { ToolTip, ReactLink } from "../../../components";
import {
	seriesPropTypes,
	seriesPagePropTypes,
	parentPropTypes,
	variationPropTypes,
} from "../propTypes";
import VariationsLSBContainer from "./VariationsLSBContainer";

const ParentsLSBContainer = ({ parents, variations }) => {
	const { error } = parents;
	const [errorTooltip, setShowErrorTooltip] = useState(false);
	const { params } = useSelector((state) => state.page);

	return parents?.map((parent, index) => {
		return (
			parent?.attributes?.active && (
				<div key={parent?.attributes?.id}>
					<h1 className="nav-list_header">{`Part ${index + 1} - Behavorial Logic`}</h1>
					<ReactLink href={`/experiences/inbox-react/${parent?.id}/edit`}>
						<LinkDiv selected={params?.campaignId === String(parent?.id)} bold={false}>
							<Circle
								className={`icon-circle ${
									parent.attributes.active ? "u-color-on" : "u-color-off"
								}`}
							/>
							<Text>{parent?.attributes?.name}</Text>
							{error && (
								<div>
									<Triangle
										onMouseEnter={() => setShowErrorTooltip(true)}
										onMouseLeave={() => setShowErrorTooltip(false)}
									>
										triangle error
									</Triangle>
									{errorTooltip && <ToolTip content={error}>test</ToolTip>}
								</div>
							)}
						</LinkDiv>
					</ReactLink>
					<VariationsLSBContainer variations={variations} parent={parent} />
				</div>
			)
		);
	});
};

const LinkDiv = styled.div`
	display: flex;
	font-weight: ${(props) => (props.selected ? "bold" : "")};
	text-align: center;
	flex-direction: row;
	background-color: ${(props) => (props.selected ? "#e8e8e8" : "")} !important;
	padding: 0.75rem;
`;

const Circle = styled.i`
	display: inline-block;
	margin-right: 2px;
	height: 100%;
	place-self: center;
	margin: 0 1rem 1rem 1rem;
`;

const Triangle = styled.div`
	width: 100px;
	height: 100px;
	position: relative;
	font-size: 20px;
	top: 200px;
	text-align: center;
	margin: auto;
`;

const Text = styled.div`
	color: #626161;
	font-size: 0.857rem;
	font-family: "Open Sans", "Helvetica Neue", "Helvetica", "Arial";
	font-weight: ${(props) => (props.bold ? "bold" : 600)};
	line-height: 1.45;
	text-align: left;
`;

ParentsLSBContainer.propTypes = {
	parents: PropTypes.arrayOf(parentPropTypes),
	variations: PropTypes.arrayOf(variationPropTypes),
	series: PropTypes.oneOfType([seriesPropTypes, seriesPagePropTypes]),
};

export default ParentsLSBContainer;
