import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Button } from "../../../../components";

const CascadingBlocksModal = ({ closeModal }) => {
    return (
        <>
            <Header>
                Convert to Blocks
            </Header>
            <SubHeader>
                You will not be able to undo this. Are you sure you want to convert?
            </SubHeader>
            <ButtonGroup>
                <Button theme="blue">
                    Yes, convert it
                </Button>
                <Button theme="blue" onClick={closeModal}>
                    No, thanks
                </Button>
            </ButtonGroup>
        </>
    )
}

const Header = styled.h1`
    color: black;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
`;

const SubHeader = styled.h2`
    color: black;
    font-size: 18px;
    text-align: center;
    margin: 16px 32px;
`;

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
	gap: 10px;
`;


CascadingBlocksModal.propTypes = {
    closeModal: PropTypes.func.isRequired
};


export default CascadingBlocksModal;
