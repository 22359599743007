import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../components";
import { useCreateLookerDashLink } from "../../../hooks";
import { duplicateCampaign } from "../../../redux/campaigns/thunks";

const RightCMSButtonsBlock = ({
	toggleArchiveModal,
	toggleCloneModal,
	websiteUrl,
	refreshCampaigns,
	disabled,
}) => {
	const dispatch = useDispatch();
	const {
		params: { websiteId },
	} = useSelector((state) => state.page);

	const { selectedCampaigns } = useSelector((state) => state.campaigns);

	const isMultipleCampaigns = selectedCampaigns.length > 1;
	const campaignId = isMultipleCampaigns ? undefined : selectedCampaigns[0]?.id;
	const hasArchivedCampaign = selectedCampaigns.some((campaign) => !campaign?.attributes?.active);
	const archiveCampaignButton = hasArchivedCampaign ? "Unarchive" : "Archive";
	const hasParentCampaign = selectedCampaigns.some(
		(campaign) => campaign.attributes?.["test-type"] === "parent"
	);
	const bxTestParam = selectedCampaigns.reduce((prevValue, curValue) => {
		return prevValue + `bxtest${curValue.id}`;
	}, "");

	const getDailyReportingParams = () => {
		const params = new URLSearchParams();
		const parentIds = new Set();
		const childrenIds = [];

		params.set("website_id", websiteId);
		selectedCampaigns.forEach((campaign) => {
			const isParent = campaign.attributes?.["test-type"] === "parent";

			if (isParent) {
				parentIds.add(campaign.id);
			} else {
				childrenIds.push(campaign.id);
				parentIds.add(campaign.relationships?.parent?.data?.id);
			}
		});

		if (parentIds.size) {
			params.set("type", "parentCampaign");
			params.set("parent_campaign_id", Array.from(parentIds).join(","));
		}

		if (childrenIds.length) {
			params.set("type", "childCampaign");
			params.set("campaign_id", childrenIds.join(","));
		}

		return params;
	};

	const getCampaignStatsParams = () => {
		const params = new URLSearchParams();
		const parentIds = new Set();

		selectedCampaigns.forEach((campaign) => {
			const parentId = campaign.relationships?.parent?.data?.id;
			parentIds.add(parentId || campaign.id);
		});

		params.set("website_id", websiteId);
		params.set("campaign_ids", [...parentIds].join(","));

		return params;
	};

	// have to do this because ember legacy can not work with the encoded URL string
	const dailyReportingParams = decodeURIComponent(getDailyReportingParams().toString());
	const campaignStatsParams = decodeURIComponent(getCampaignStatsParams().toString());

	const dailyReportingLink = !disabled
		? `/reports?${dailyReportingParams.toString()}`
		: undefined;
	const testCampaignLink = !disabled ? `https://${websiteUrl}?${bxTestParam}` : undefined;
	const campaignStatsLink = !disabled
		? `/clients/stats?${campaignStatsParams.toString()}`
		: undefined;

	const { lookerDashLink } = useCreateLookerDashLink(campaignId);

	const duplicateCampaignThunk = async () => {
		const response = await dispatch(
			duplicateCampaign({
				campaignId: campaignId,
			})
		);

		toast.info("Campaign Duplicating...", { autoClose: 500 });

		const { requestStatus } = response?.meta;
		if (requestStatus === "fulfilled") {
			refreshCampaigns();
			toast.success("Campaign duplicated successfully", {
				autoClose: 1000,
			});
		} else {
			toast.error("An error occurred", { autoClose: 1000 });
		}
	};

	return (
		<ButtonsContainer>
			{!isMultipleCampaigns && (
				<Button
					onClick={() => duplicateCampaignThunk()}
					dataQA="duplicate-button"
					fullWidth
					theme="blue"
					disabled={disabled}
				>
					{hasParentCampaign ? "Duplicate Group" : "Duplicate Variation"}
				</Button>
			)}

			<Button
				dataQA="archive-button"
				onClick={toggleArchiveModal}
				fullWidth
				theme="blue"
				disabled={disabled}
			>
				{archiveCampaignButton}
			</Button>

			<Button
				dataQA="view-stats-button"
				as="a"
				url={campaignStatsLink}
				fullWidth
				theme="blue"
				disabled={disabled}
			>
				View Stats
			</Button>

			<Button
				dataQA="view-daily-reporting-button"
				as="a"
				url={dailyReportingLink}
				fullWidth
				theme="blue"
				disabled={disabled}
			>
				View Daily Reporting
			</Button>

			<Button
				dataQA="test-selected-campaigns-button"
				as="a"
				url={testCampaignLink}
				fullWidth
				theme="blue"
				disabled={disabled}
			>
				{`Test Selected ${isMultipleCampaigns ? "Campaigns" : "Campaign"}`}
			</Button>

			{/* These buttons only appear on parent campaigns so they should not be children */}
			{hasParentCampaign && (
				<>
					<Button
						dataQA="clone-campaign-to-website-button"
						onClick={toggleCloneModal}
						fullWidth
						theme="blue"
						disabled={disabled}
					>
						{`Clone ${isMultipleCampaigns ? "Campaigns" : "Campaign"}  to Website`}
					</Button>

					{!isMultipleCampaigns && (
						<Button
							dataQA="looker-dashboard-button"
							as="a"
							url={lookerDashLink}
							fullWidth
							theme="blue"
							disabled={disabled}
						>
							3-in-1 Looker Dashboard
						</Button>
					)}
				</>
			)}
		</ButtonsContainer>
	);
};

export const ButtonContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const ButtonsContainer = styled.div`
	& > *:not(:last-child) {
		margin-bottom: 11px;
	}
`;

RightCMSButtonsBlock.propTypes = {
	toggleArchiveModal: PropTypes.func.isRequired,
	toggleCloneModal: PropTypes.func.isRequired,
	websiteUrl: PropTypes.string,
	refreshCampaigns: PropTypes.func.isRequired,
};

export default RightCMSButtonsBlock;
