import React from "react";
import PropTypes from "prop-types";
import { List, BarChart } from "react-feather";
import styled from "styled-components";
import { ICON_SIZES } from "../../constants/common";

const HeaderSortButtons = ({ view, changeView }) => (
	<NavbarMiddle>
		<Button data-qa="navbar-list-button" active={view === "list"} onClick={() => changeView("list")}>
			<List aria-label="list view button" size={ICON_SIZES.large} />
			<ButtonText>List</ButtonText>
		</Button>
		<Button data-qa="navbar-reorder-button" active={view === "reorder"} onClick={() => changeView("reorder")}>
			<BarChart aria-label="Reorder view button" size={ICON_SIZES.large} style={{ transform: 'rotateX(-180deg) rotateZ(-270deg)' }} />
			<ButtonText>Reorder</ButtonText>
		</Button>
	</NavbarMiddle>
);

const NavbarMiddle = styled.div`
	margin-left: auto;
	margin-right: auto;
	height: 44px;
	display: flex;
	flex-direction: row;
`;

const Button = styled.button`
	border: 0;
	height: 44px;
	width: 90px;
	display: flex;
	flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
	background: ${({ active }) => active ? "#F5EBE1" : "none"};
`;

const ButtonText = styled.p`
	font-size: 10px;
	margin: 0;
	padding: 0;
`;

HeaderSortButtons.propTypes = {
	view: PropTypes.string.isRequired,
	changeView: PropTypes.func.isRequired,
};

export default HeaderSortButtons;
