import { useState, useEffect } from 'react';

/*
	A simple hook for debouncing value that changes quickly.
	Options:
		isAwait - a boolean flag usefull when changing values
			performs an async operation(e.g. a call API).
			By using it, we can avoid race condition.
			Set true if the request starts and false at the end.
*/

export const useDebouncedValue = (value, delay, options = {}) => {
	const { isAwait } = options;
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (!isAwait) {
				setDebouncedValue(value);
			}
		}, delay);

		return () => {
			clearTimeout(timer);
		};
	}, [value, isAwait]);

	return debouncedValue;
}
