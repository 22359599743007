import styled from 'styled-components'

export const HeaderText = styled.div`
    font-weight: 600;
    font-size: 0.857rem;
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};
    white-space: nowrap;
`

export const CadenceSettingStyle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`
