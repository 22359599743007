import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Select = ({ data, value, onChange, dataQA, width }) => {
	const changeHandler = (event) => {
		onChange(event.target.value);
	};

	const getOptionElement = (id, name, active) => {
		const disabled = typeof active === "boolean" ? !active : false;

		return <option key={id} value={id} disabled={disabled}>{name}</option>;
	};

	const getGroupOfOptions = (name, options) => {
		const optionsList = options.map((opt) =>
			getOptionElement(opt.id, opt.name, opt.active)
		);

		return <optgroup label={name} key={name}>{optionsList}</optgroup>;
	};

	return (
		<SelectContainer style={{width: width}}>
			<SelectList value={value} data-qa={`select-menu-${dataQA}`} onChange={changeHandler}>
				{data.map((el) => {
					const { groupName, options } = el;

					if (groupName) {
						return getGroupOfOptions(groupName, options);
					}

					return (
						<option
							key={el.id}
							value={el.id}
						>
							{el.name}
						</option>
					)
				})}
			</SelectList>
			<Icon className="icon-angle-down" />
		</SelectContainer>
	);
};

const SelectContainer = styled.div`
	position: relative;
	width: ${(width) => {
		width ? width : '';
	}};
`;

const SelectList = styled.select`
	width: 100%;
	padding: 11px 29px 11px 11px;
	border: 1px solid rgb(232, 232, 232);
	appearance: none;
`;

const Icon = styled.i`
	position: absolute;
	top: 11px;
	right: 11px;
	pointer-events: none;
`;

const optionShape = PropTypes.shape({
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	active: PropTypes.bool,
});

Select.propTypes = {
	data: PropTypes.arrayOf(PropTypes.oneOfType([
		optionShape,
		PropTypes.shape({
			groupName: PropTypes.string.isRequired,
			options: PropTypes.arrayOf(optionShape).isRequired,
		}),
	])).isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	dataQA: PropTypes.string.isRequired,
	width: PropTypes.string,
};

export default Select;
